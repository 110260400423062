import {CheckOutlined, EyeOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {FormRow} from "../components/form/DynamicForm";
import ParentModule from "../shared/module/CrudModule";
import {addFilter} from "../services/HooksManager";
import PageViewer from "../components/PageViewer";
import {navigate} from "../shared/router";
import {__} from "../translations/i18n";
import CrudModule from "../components/CrudModule";
import {fetchStores} from "./StoreModule";
import moment from 'moment';
import DateUtils from "../utils/DateUtils";
import CrudDetails from "../components/CrudDetails";
import WarrantyDetails from "../components/warranty/WarrantyDetails";
import {ReactNode} from "react";
import { FormLayout } from "antd/lib/form/Form";
import DWEnvironmentManager from "../services/DWEnvironmentManager";
import { MenuNode } from "../components/MenuComponent";
import ObjectUtils from "../utils/ObjectUtil";

export interface Warranties {
    id: string,
    sku_upc: string
    serial_number: string
    activation: string
    store_user_act: string
    return: Date | string
    store_user_ret: string
    card_substitution: string
}

class WarrantyModule extends ParentModule {

    warrantyBadgeUid = 'warrantyBadgeUid';

    init = () => {
        super.init();
        addFilter ( 'app_sub_menu', this.addMenu );

        addFilter('app_routes', (routes: any) => {
            routes[this.getRoute()] = {
                component: PageViewer,
                props: {content: <CrudModule tabs={['listing']} module={this}/>, selectedKey: this.getRoute()},
                visibility: 'private'
            };
            routes[this.getRouteDetail()] = {
                component: PageViewer,
                props: {content: <CrudDetails module={this} tabs={["details"]}/>},
                visibility: 'private'
            };
            return routes;
        });

        addFilter('search_form_data_' + this.getModuleKey(), this.getSearchData);

        addFilter('crud_detail_view_component_' + this.getModuleKey(), this.getDetailsComponent)
    }

    addMenu = (menu: MenuNode[]) => {
        menu.push({
            label: <i >{__('menu.digital_warranties')}</i>,
            icon: <CheckOutlined />,
            position: '10',
            key: this.getRoute()
        });

        return menu;
    }

    getModuleKey = (): string => {
        return "warranties";
    }

    fetchStores = async (key: string) => {
        return await fetchStores(key)
    }

    __getFormFields = (): FormRow[] => {

        return [
            {
                fields: [
                    {
                        name: 'dateFrom',
                        label: __('warranties.fields.from'),
                        type: 'date',
                        required: false,
                        format: 'DD/MM/YYYY',
                        colConf: {
                            span: 2
                        }
                    },
                    {
                        name: 'dateTo',
                        label: __('warranties.fields.to'),
                        type: 'date',
                        required: false,
                        format: 'DD/MM/YYYY',
                        colConf: {
                            span: 2
                        }
                    },
                    {
                        name: 'itemUpc',
                        label: 'SKU/UPC',
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'itemSerialNumber',
                        label: __('warranties.fields.serialNumber'),
                        type: 'text',
                        required: false,
                        requiredMessage: "test",
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'returned',
                        label: __('warranties.fields.returned'),
                        type: 'select',
                        required: false,
                        selectValue: [
                            { label: __ ( "warranties.fields.values.all" ), value: '' },
                            { label: __ ( "warranties.fields.values.reso" ), value: 'true' },
                            { label: __ ( "warranties.fields.values.noReso" ), value: 'false' }
                        ],
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'externalCode',
                        label: __('warranties.fields.storeType'),
                        type: 'select',
                        required: false,
                        selectValue: [
                            {label: __ ( "warranties.fields.values.all" ), value: ''},
                            {label: "Buotique", value: 'boutique'},
                            {label: "Corner", value: 'corner'},
                            {label: "Retailer", value: 'retailer'},
                            {label: "Shop in shop", value: 'shop in shop'},
                        ],
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'storeId',
                        label: __('warranties.fields.store'),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: (key: string) => (this.fetchStores(key)),
                        colConf: {
                            span: 4
                        }
                    },
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    {name: __('common.reset'), type: 'button', htmlType: 'reset', block: 'true', buttonType: "default", icon: <ReloadOutlined />, colConf: { span: 2, offset: 10}},
                    {name: __('common.search'), type: 'button', htmlType: 'submit', block: 'true', icon: <SearchOutlined />, colConf: { span: 2}}
                ]
            }
        ];

    }

    getSearchData = (data: { [key: string]: any }) => {

        let dataClone: { [key: string]: any } = {};
        Object.keys(data).map((key) => {
            if (moment.isMoment(data[key])) {
                dataClone[key] = data[key].format('YYYY/MM/DD');
            } else {
                dataClone[key] = data[key];
            }
        });

        return dataClone;
    }

    __getColumnsTable = () => {
        return [
            {
                title: 'SKU/UPC',
                dataIndex: 'sku_upc',
                key: 'item.upc',
                sorter: true
            },
            {
                title: __('warranties.columns.serialNumber'),
                dataIndex: 'serial_number',
                key: 'serial_number'
            },
            {
                title: __('warranties.columns.activation'),
                dataIndex: 'activation',
                key: 'creationDate',
                render: (date: string) => <>
                    {date.split(" ")[0]}<br/>
                    {date.split(" ")[1]}
                </>,
                sorter: true
            },
            {
                title: __('warranties.columns.storeUserAct'),
                dataIndex: 'store_user_act',
                key: 'store_user_act'
            },
            {
                title: __('warranties.columns.return'),
                dataIndex: 'return',
                key: 'returnDate',
                render: (date: string) => <>
                    {date.split(" ")[0]}<br/>
                    {date.split(" ")[1]}
                </>,
                sorter: true
            },
            {
                title: __('warranties.columns.storeUserRef'),
                dataIndex: 'store_user_ret',
                key: 'store_user_ret'
            },
            {
                title: __('warranties.columns.card'),
                dataIndex: 'card_substitution',
                key: 'card_substitution'
            },
            {
                title: __('warranties.columns.details'),
                dataIndex: 'id',
                key: 'id',
                render: (id: string | number) => (
                    <Button onClick={() => navigate(this.getRouteDetail(id))} icon={<EyeOutlined/>} size={"small"}
                            shape={"circle"} type="primary"/>
                )
            }
        ];
    }

    __getMapData = (row: any): Warranties => {
        return {
            id: row.id,
            sku_upc: row.item.upc,
            serial_number: row.item.serialNumber,
            activation: DateUtils.formatDate(row.creationDate),
            store_user_act: `${row.creationStore.description}(${row.creationStore.externalCode})`,
            return: row.returnDate ? DateUtils.formatDate(row.returnDate) : '',
            store_user_ret: row.returnStore ? row.returnStore.description : '',
            card_substitution: "..."
        }
    }

    __getLayout = (): FormLayout => {
        return "vertical";
    }

    getDetailsComponent = (component:ReactNode, entity:any) => {
        return (<WarrantyDetails entity={entity} />)
    }

    getWarrantyByBadgeUrl() {
        return this.getModuleKey() + `/operations/check`;
    }

    getWarrantyByBadge(id:  number | string) {

        const provider = DWEnvironmentManager.getDataProvider();
        let params:{[key: string]: any} = {}
        params[this.warrantyBadgeUid] = id

        const conf = {
            params: params,
            url: this.getWarrantyByBadgeUrl()
        }

        return provider.getList(1, 0, conf, this.getModuleKey(), false).then(res => {
            // Uso getList ma ritorna un solo oggetto, no array
            if (res) {
                return res
            }
        })
    }
}

export const fetchSoldToParty = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();

    return provider.getList(20, 0, {},
        'stores/soldtoparties')
        .then((response) =>
            ObjectUtils.stringIncludes(response, key)
        );
}

export const fetchSoldToParties = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();

    return provider.getList(20, 0, {},
        'stores/soldtoparties')
        .then((response) =>
            ObjectUtils.stringIncludesWithOutLabel(response, key)
        );
}

const module = new WarrantyModule();
export default module;
