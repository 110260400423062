import {Component } from "react";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import {__} from "../../translations/i18n";
import { fetchUsers } from "../../modules/StoreModule";
import { FormRow } from "../form/DynamicForm";
import CrudForm from "../CrudForm";
import { EditOutlined } from "@ant-design/icons";

interface Props {
    entity: any,
    module: string,
    edit: (data: any) => any,
    users: any
}

interface State {
    users: any,
    loadedUser: boolean
}

export default class StoreEdit extends Component<Props, State> {


    state: State = {
        users: [],
        loadedUser: false
    }

    provider = DWEnvironmentManager.getDataProvider ();

    async componentDidMount(): Promise<void> {
        const users = await this.props.users;
        this.setState({users, loadedUser: true});
    }

    getEditFormFields = (): FormRow[] => {
        return [
            {
                fields: [
                    { 
                        name: 'description', 
                        label:__('stores.fields.store'), 
                        type: 'text', 
                        required: true, 
                        requiredMessage: __('stores.fields.errors.store'),
                        defaultValue: this.props.entity.description,
                        value: this.props.entity.description,
                        colConf: { span: 24 } 
                    },
                    { 
                        name: 'address', 
                        label:__('stores.fields.address'), 
                        type: 'text',  
                        required: false,
                        defaultValue: this.props.entity.address,
                        value: this.props.entity.address,
                        colConf: { span: 24 } 
                    },
                    {
                        name: 'users',
                        label: __('stores.fields.users'),
                        type: 'autocompleteselect',
                        required: false,
                        
                        initialValue: this.state.users,
                        mode: 'multiple',
                        fetchOptions: (key: string) => (fetchUsers(key)), 
                        colConf: { span: 24 }
                    },
                    {
                        name: 'externalCode',
                        label: __('stores.fields.storeType'),
                        type: 'select',
                        required: false,
                        defaultValue: this.props.entity.externalCode,
                        value: this.props.entity.externalCode,
                        selectValue: [
                            {label: "Boutique", value: 'boutique'},
                            {label: "Corner", value: 'corner'},
                            {label: "Retailer", value: 'retailer'},
                            {label: "Shop in shop", value: 'shop in shop'},
                        ], 
                        colConf: { span: 24 }
                    },
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    { name: 'addStore', label:__('stores.fields.edit'), type: 'button', htmlType: 'submit', icon: <EditOutlined />}
                ],
                rowConf: {
                    gutter: [24,24],
                    justify: "center",
                    align: "top"
                }
            }
        ];
    }

    render() {
        const {users,loadedUser} = this.state;
        const {module} = this.props;

        if (users.length <= 0 && !loadedUser ) {
            return <div />
        }
    
        return (<CrudForm module={module} callback={this.props.edit} getFormFields={this.getEditFormFields} cardSize={12} type={"edit"}/>)
    }
}