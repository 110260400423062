import { ReactNode } from "react";
import { DWEnvironmentManager, IService } from "./DWEnvironmentManager";
import dWUserManager from "./DWUserManager";
import { applyActions, applyFilters } from "./HooksManager";

export interface Bread {
    key: string,
    route: string,
    label: ReactNode
}

class NavigationManager implements IService {

    dwEnvironmentManager: DWEnvironmentManager | undefined = undefined;
    breadcrumbs: {[key: string]:Bread} = {};
    reloadBreadcrumb: any = () => {};

    getServiceKey(): string {
        return 'navigationManager'
    }

    getBreadcrumbs = () => {
        return applyFilters('navigation_manager_breadcrumbs', this.breadcrumbs, this);
    }

    addBreadcrumb = (breadcrumb: Bread) => {
        //applyFilters
        this.breadcrumbs[breadcrumb.key] = breadcrumb;

    }

    cleanBreadcumbs = () => {
        this.breadcrumbs = {};
    }

    setReloadBreadcrumb = (reloadBreadcrumb :any) => {
        this.reloadBreadcrumb = reloadBreadcrumb;
    }

    reloadBreadcrumbs = () => {
        this.reloadBreadcrumb(this.breadcrumbs);
    }

    /**
     * Init
     */
    init = async (dwEnvironmentManager: DWEnvironmentManager) => {

        this.dwEnvironmentManager = dwEnvironmentManager;

    }

    onRouteChange = async (location: any) => {
        this.cleanBreadcumbs();
        applyActions('nav_route_change', location, this);
        this.reloadBreadcrumbs();
        await dWUserManager.reloadLoggedUser();
    }
}

const navigationManager = new NavigationManager();
export default navigationManager;
