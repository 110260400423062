/* eslint-disable import/no-anonymous-default-export */
export default {
    app: {
        url: 'www.bulgari.com'
    },
    common: {
        close: '닫기'
    },
    menu: {
        digital_prewarranties: '사전 활성화',
        areas: '지역',
        profiles: '인가 프로필',
        manual: '시계 설명서',
        help: '도움말',
        info: '정보',
        massive_activations: '대규모 활성화',
        partnerinventories: '재고'
    },
    warranties: {
        title: 'DW 활성화',
        columns: {
            photo: '사진',
            cardSequential: '일련번호 카드',
            sap: 'SAP 코드',
            description: '설명',
            expiredDate: '만료일',
            activationType: '활성화 유형',
            activationUser: '활성화 사용자/매장',
            storeUser: '매장/사용자 반납',
            substitution: '대체',
            serialNumber: '일련번호',
            activation: '활성화',
            return: '반납',
            returnUser: '반납 사용자/매장',
            details: '세부정보',
            operatingCountry: '운영 국가/지역',
            parent: '상위',
            isoCode: 'ISO 코드',
            edit: '수정',
            returnDeactivate: '반납/비활성화',
            status: '상태',
            storeCode: '매장 코드'
        },
        fields: {
            sequentialCard: '일련번호 카드',
            sapCode: 'SAP 코드',
            serialNumber: '일련번호',
            reso: '반납',
            storeType: '매장 유형',
            materialType: '소재 유형',
            nameCodeStore: '이름/매장 코드',
            operatingCountry: '운영 국가/지역',
            activationType: '활성화 유형',
            onBehalfOf: '대표로',
            masterCode: '마스터 코드',
            soldToParty: '판매 대상',
            responsibilityArea: '책임 지역',
            isoCode: 'ISO 코드',
            parent: '상위',
            activationDate: '활성화 날짜',
            store: '매장',
            user: '사용자',
            add: '새 제품 활성화',
            errors: {
                sapCode: 'SAP 코드를 입력하세요!',
                activationDate: '활성화 날짜를 선택하세요!',
                serialNumber: '일련번호를 입력하세요!',
                store: '매장을 입력하세요!',
                user: '사용자를 입력하세요!',
                sequentialCard: '일련번호 카드를 입력하세요!',
            },
            values: {
                all: '모두',
                reso: '반납',
                noReso: '반납 없음',
                accessories: '액세서리',
                watches: '시계',
                jewels: '보석',
                fineJewelry: '하이 주얼리',
                manuale: '설명서',
                appManual: '앱 설명서',
                yes: '예',
                no: '아니요',
                internal: '내부',
                external: '외부',
                prospect: '예비',
                open: '열림',
                closed: '닫힘',
                phaseOut: '페이즈 아웃',
                future: '미래',
                notActivated: '비활성화됨',
                pending: '대기 중',
                activated: '활성화됨'
            },
        },
        add: {
            success: '활성화가 성공적으로 추가되었습니다.'
        },
        changeExpiration: {
            success: '만료 날짜가 성공적으로 업데이트되었습니다.',
            error: '업데이트 실패',
            errorDescription: '만료 날짜가 업데이트되지 않았습니다.'

        },
        warning: '일련번호와 SAP 코드가 호환되지 않습니다',
        error: {
            watchInvalidSerialTitle: '일련번호와 SAP 코드 불일치',
            watchInvalidSerialDescription: '선택한 시계의 일련번호와 SAP 코드가 일치하지 않습니다.',
            warrantyAlreadyPresentTitle: '이미 보증이 있음',
            warrantyAlreadyPresentDescription: '선택한 제품에 이미 보증이 있습니다'
        }
    },
    prewarranties: {
        fields: {
            activationDate: '사전 활성화 날짜'
        },
        add: {
            success: '사전 활성화가 성공적으로 추가되었습니다'
        },
        details: {
            image: "이미지",
            product: "제품",
            date: "날짜",
            store: "매장",
            user: "사용자",
            delete: "사전 활성화 삭제",
            activation: "활성화",
        },
        error: {
            prewarrantyAlreadyPresentTitle: '사전 보증 이미 존재',
            prewarrantyAlreadyPresentDescription: '선택한 제품에 이미 사전 보증이 있습니다',
            checkSerialSAPErrorTitle: '일련번호와 SAP 코드 불일치',
            checkSerialSAPErrorDescription: '일련번호와 SAP 코드가 일치하지 않습니다.'
        }
    },
    partnerinventories: {
        fields: {
            inventoryDateFrom: '재고 날짜 시작',
            inventoryDateTo: '재고 날짜 종료',
            sapCode: 'SAP 코드',
            serialNumbers: '일련번호',
            store: '매장',
            soldTo: '판매 대상',
            creationDateFrom: '생성 날짜 시작',
            creationDateTo: '생성 날짜 종료',
            lastInventory: '최근 재고'
        },
        columns: {
            inventoryDate: '재고 날짜',
            serialNumber: '일련번호',
            store: '매장',
            soldTo: '판매 대상',
            product: '제품',
            creationDate: '생성 날짜',
            storeCode: '매장 코드',
            quantity: '수량',
            sapCode: 'SAP 코드'
        },
        button: {
            upload: '재고 업로드',
            download: '양식 다운로드'
        }
    },
    hierarchies: {
        add: '새 지역 추가',
        success: '새 지역이 성공적으로 추가되었습니다',
        entity: {
            singular_name: "새 지역",
            plural_name: "새 지역"
        },
        edit: {
            modify: '지역 수정',
            success: '지역이 성공적으로 수정되었습니다'
        }
    },
    settings: {
        choose_language: '언어 선택',
        language: '언어',
    },
    stores: {
        fields: {
            code: "코드",
            telephone: "전화",
            state: "상태",
            bvlAfss: "BVL Afss",
            openingDate: "오픈 날짜",
            closingDate: "닫는 날짜",
            phaseOutDate: "페이즈 아웃 날짜",
            phaseOut: "페이즈 아웃",
            soldToParty: "판매 대상",
            responsabilityArea: "책임 지역",
            masterCodeCode: "마스터 코드 코드",
            masterCodeName: "마스터 코드 이름",
            soldTo: "판매 대상",
            shipTo: "배송 대상",
            operatingCountry: "운영 국가/지역",
            areaDefaultEmail: "기본 이메일 지역",
            nfcReader: "USB NFC 리더 디바이스",
            smartphone: "스마트폰 디바이스"
        },
        details: {
            activateStore: "매장 활성화",
            sendAgain: "다시 보내기",
            activateSmartphone: "스마트폰 활성화",
            requestActivationSuccess: "활성화 요청이 성공적으로 완료되었습니다",
            activationSuccess: "매장 활성화 완료!",
            activate: "활성화",
            askActivation: "매장 활성화 확인",
            askActivationDescription: "이 매장을 정말 활성화 하시겠습니까:"
        }
    },
    profiles: {
        columns: {
            profileName: '프로필 이름',
            jobId: '작업 ID',
            departmentId: '부서 ID',
            jobDescription: '작업 설명',
            departmentDescription: '부서 설명',
            storeType: '매장 유형',
            storeId: '이름/매장 코드',
            dwArea: 'DW 지역',
            edit: '수정',
        },
        fields: {
            profileName: '프로필 이름',
            jobId: '작업 ID',
            departmentId: '부서 ID',
            jobDescription: '작업 설명',
            departmentDescription: '부서 설명',
            storeType: '매장 유형',
            storeId: '이름/매장 코드',
            dwArea: 'DW 지역',
            add: '새 프로필 추가',
            edit: '프로필 수정',
            delete: '프로필 삭제',
            created: '매장 생성됨'
        },
        upload: {
            label: "프로필로 변환하려면 파일 xls를 업로드할 수 있습니다.",
            name: "파일을 여기로 클릭하거나 드래그하여 업로드하세요",
            error: "파일 업로드 실패.",
            success: "파일이 성공적으로 업로드되었습니다."
        }
    },
    manual: {
        columns: {
            photo: '사진',
            sapCode: 'SAP 코드',
            instruction: '설명서',
            brochure: '브로슈어',
        },
        fields: {
            sapCode: 'SAP 코드'
        }
    },
    help: {
        ask_support: '지원 요청',
        how_to_use: '사용 방법',
        how_to_use_video: '아래 비디오를 참조하여 Digital Warranty 기능 사용법에 대한 빠른 안내를 확인하세요',
        sent: '이메일 전송됨',
        not_sent: '이메일 전송되지 않음',
        sales_activate_watches: '시계 활성화를 위한 판매 태그 사용 방법',
        shipping_activate_watches: '보석 활성화를 위한 배송 태그 사용 방법',
        sales_activate_accessories: '액세서리 활성화를 위한 판매 태그 사용 방법',
        active_watches_type: '시계를 활성화하려면 다음을 입력하십시오:',
        sap_code: 'SAP 코드',
        serial_number: '일련 번호'
    },
    splash: {
        welcome: "에 오신 것을 환영합니다.",
        presentation: "DIGITAL WARRANTY & AUTHENTICITY는 정품 및 보증을 완전히 디지털 방식으로 관리하기 위해 개발된 애플리케이션입니다. 환영합니다.",
        enter: '들어가기'
    },
    info: {
        header: {
            watches: '시계 - 보증 정보',
            accessories: '액세서리 - 정품 정보',
            jewellery_pieces: '보석 - 정품 및 보증 정보',
            high_jewellery: '하이 주얼리 - 정품 정보'
        },
        body: {
            watches: '이 시계는 스위스 시계 산업의 가장 높은 품질 기준을 준수하여 스위스 Neuchâtel의 Bulgari Horlogerie SA에서 제작된 Bulgari의 제품입니다. 구입 시 활성화 된 보증은 세계 어디서나 적용되며 모든 제조 결함을 보장합니다. 본 보증은 공식 및 인가된 Bulgari 판매 네트워크, Bulgari 매장 또는 공식 판매 대리점에서 구입한 제품에만 적용됩니다. 제품의 특정 보증 조건은 카드의 QR 코드를 스캔하거나 NFC 기능을 통해 액세스 할 수 있습니다. 귀하의 Bulgari 시계는 구매 시 판매자가 서명하고 날짜를 기록하여 국제 보증서를 올바르게 활성화하면 본 보증만 적용됩니다. 보증 기간 동안 제조 결함이 발견되면 시계는 무료로 수리됩니다. 본 보증은 시계 및 액세서리의 일반적인 마모, 분실, 도난, 오용 또는 방치, 일반적인 손상, 개조 또는 승인되지 않은 타사에 의한 시도된 수리를 포함하지 않습니다. 본 국제 보증은 시계의 기능 오류, 결함 또는 부정확성으로 인한 간접적 또는 부수적인 손해를 보상하지 않습니다. 중요: 유지 관리 또는 수리가 필요한 경우 귀하의 Bulgari 시계와 해당 국제 보증서를 당사 부티크 또는 공인된 판매 대리점 중 하나로 가져갈 수 있습니다. 귀하의 국제 보증 및 정품성 카드는 제품의 사용 설명서와 유지 관리 지침, 보증 조건 및 보증 기간을 스캔하여 액세스할 수 있도록 QR 코드 또는 NFC 기능을 사용하여 액세스할 수도 있습니다. 본 국제 보증은 관련 법령에서 규정하는 기타 권리에 영향을 미치지 않습니다.',
            accessories: '이 제품은 불가리의 원본 제작물로 가장 엄격한 품질 테스트를 준수하고 있습니다. 카드를 활성화하면 구매 날짜로부터 전 세계 어디에서나 제조 결함이 모두 보상됩니다. 이 Bulgari 제품에는 패시브 NFC (근거리 통신) 태그가 포함되어 있습니다. 태그에는 제품에 관한 정보 (칩 고유 ID, 제품 코드, 정품 서명)가 포함되어 있어 정품성을 보장하기 위한 것입니다. 이 태그는 NFC 장치에서 읽을 수 있습니다. 이 태그에는 개인 데이터가 없으며 제품 소유자를 식별할 수 없습니다. 자세한 정보는 privacy@bulgari.com으로 문의하십시오.',
            jewellery_pieces: '이 보석은 가장 엄격한 품질 기준을 준수하여 만들어진 Bulgari의 원본 제작물입니다. 보석은 구매한 Bulgari 부티크나 공인된 Bulgari 보석 판매 대리점에서 구매 시 활성화해야하며 제조 결함이 있을 경우 구매 날짜로부터 24개월 동안 전 세계 어디에서나 보상됩니다. 이 보증은 태그에 제품에 새겨진 일련 번호가 인증서에 표시되어 있어야만 적용됩니다. 만일 인증서가 활성화되지 않으면 본 보증이 적용되지 않습니다. 제품에 새겨진 일련 번호가 명확히 읽을 수 없거나 지워진 경우, 본 보증은 적용되지 않습니다. 보증 기간 동안 제조 결함이 발견되면 보석은 무료로 수리됩니다. 본 보증은 보석의 보편적인 마모, 부정한 사용, 부실한 관리, 방치 또는 사고로 인한 결함이나 손상을 보상하지 않습니다. 분실이나 도난된 경우 본 보증은 적용되지 않습니다. 본 국제 보증서를 활성화하지 않았거나 승인되지 않은 타사에 의해 제품이 개조되었거나 유지 관리나 수리가 필요한 경우 본 국제 보증은 적용되지 않습니다. 본 국제 보증은 관련 법령에서 규정하는 기타 권리에 영향을 미치지 않습니다.',
            high_jewellery: '이 보석은 가장 엄격한 품질 기준을 준수하여 만들어진 Bulgari의 원본 제작물입니다. 보석은 구매한 Bulgari 부티크나 공인된 Bulgari 보석 판매 대리점에서 구매 시 활성화해야하며 제조 결함이 있을 경우 구매 날짜로부터 24개월 동안 전 세계 어디에서나 보상됩니다. 이 보증은 태그에 제품에 새겨진 일련 번호가 인증서에 표시되어 있어야만 적용됩니다. 만일 인증서가 활성화되지 않으면 본 보증이 적용되지 않습니다. 제품에 새겨진 일련 번호가 명확히 읽을 수 없거나 지워진 경우, 본 보증은 적용되지 않습니다. 보증 기간 동안 제조 결함이 발견되면 보석은 무료로 수리됩니다. 본 보증은 보석의 보편적인 마모, 부정한 사용, 부실한 관리, 방치 또는 사고로 인한 결함이나 손상을 보상하지 않습니다. 분실이나 도난된 경우 본 보증은 적용되지 않습니다. 본 국제 보증서를 활성화하지 않았거나 승인되지 않은 타사에 의해 제품이 개조되었거나 유지 관리나 수리가 필요한 경우 본 국제 보증은 적용되지 않습니다. 본 국제 보증은 관련 법령에서 규정하는 기타 권리에 영향을 미치지 않습니다.',
        }
    },
    return: {
        title: "HJ 제품의 일련 번호 또는 SAP 코드를 입력하세요",
        return: "반품",
        fields: {
            serial: "일련 번호",
            next: "다음",
            required: "일련 번호는 필수 항목입니다"
        },
        choiceProduct: "반품 제품 선택",
        summaryProduct: "제품 요약",
        codeSAP: "SAP 코드",
        activationDate: "활성화 날짜",
        store: "매장",
        expiryDate: "만료 날짜",
        duration: "기간",
        reasonReturn: "아래 양식에 반품 이유를 작성하세요",
        errorTitle: "반품 불가능",
        errorSubTitle: "이 제품의 반품이 허용되지 않습니다.",
        imageProduct: "제품 이미지",
        infoProduct: "제품 정보",
        infoActivation: "활성화 정보",
        warrantyExpired: "보증 기간 만료",
        successTitle: "반품 성공!",
        successSubtitle: "선택한 제품이 성공적으로 반품되었습니다."
    },
    massive_activation: {
        label: "엑셀 파일을 업로드하여 활성화로 변환할 수 있습니다.",
        name: "파일을 여기로 드래그 & 드롭하거나 클릭하세요.",
        error: "파일 업로드 실패",
        success: "파일이 성공적으로 업로드되었습니다."
    },
    status: {
        title: "HJ 제품의 일련 번호 또는 SAP 코드를 입력하세요",
        status: "상태",
        fields: {
            serial: "일련 번호",
            next: "다음",
            required: "일련 번호는 필수 항목입니다",
            email: "이메일",
            emailDescription: "여러 이메일 주소를 ','로 구분 (Enter 키를 눌러 확인)"
        },
        choiceProduct: "제품 선택",
        summaryProduct: "제품 요약",
        codeSAP: "SAP 코드",
        activationDate: "활성화 날짜",
        store: "매장",
        user: "사용자",
        expiryDate: "만료 날짜",
        duration: "기간",
        reasonReturn: "아래 양식에 반품 이유를 작성하세요",
        errorTitle: "상태를 확인할 수 없음",
        errorSubTitle: "이 제품의 상태를 확인할 수 없습니다.",
        imageProduct: "제품 이미지",
        infoProduct: "제품 정보",
        infoActivation: "활성화 정보",
        warrantyExpired: "보증 기간 만료",
        successTitle: "상태 확인 성공!",
        successSubtitle: "선택한 제품의 상태를 성공적으로 확인했습니다.",
        manualProduct: "수동 제품 설명서",
        versionPdf: "PDF 버전",
        returnDate: "반품 날짜",
        versionPdfTitle: "제품에 대한 요약",
        versionPdfSubTitle: "PDF를 다운로드하거나 방금 제품을 활성화한 고객에게 보낼 수 있습니다.",
        sendEmail: "이메일로 보내기",
        downloadPdf: "PDF 다운로드",
        sendEmailSuccess: "이메일 전송 성공!",
        manualProductTitle: "수동 제품 설명서",
        manualProductSubTitle: "PDF를 다운로드하세요.",
        instructions: "사용 설명서",
        care: "관리",
        infoExpiryDate: "만료 날짜 정보",
        changeExpiryDate: "만료 날짜 변경",
        confirmChangeExpiryDate: "확인",
        serialNumberError: "잘못된 일련 번호!",
        serialNumberErrorMsg: "일련 번호가 존재하지 않습니다."
    },
    users: {
        fields: {
            managedStores: '관리하는 매장',
            managedStoreTypes: '관리하는 매장 유형'
        },
        columns: {
            storeCode: '매장 코드',
            role: '역할'
        }
    },
    landing: {
        sapCode: 'SAP 코드',
        expiration_date: '만료 날짜',
        warranty_duration: '보증 기간',
        download_manual: '설명서 다운로드',
        download_brochure: '브로슈어 다운로드',
        extend_warranty: '보증 연장',
        sp_pt_disclaimer: '본 국제 보증은 해당 국가 법률에 따라 귀하가 가질 수 있는 다른 권리에 영향을 미치지 않습니다.'
    }
}