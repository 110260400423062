// eslint-disable-next-line import/no-anonymous-default-export
export default {
    app: {
        name: 'Garantía Digital de Temera',
        url: 'www.buccellati.com'
    },
    error: {
        generic_error: 'Error Genérico',
        error: 'Error',
        forbidden: 'Prohibido',
        not_found: 'No Encontrado',
        unauthorized: 'No Autorizado',
        invalid_credentials: '¡Credenciales no válidas!',
        duplicateKey: 'Clave Duplicada',
        api: 'Error API {{method}} {{api}}:\n{{error_message}}',
        requiredRowEditTable: 'La tabla debe tener al menos una fila',
        error_upload_file: 'Carga de archivo fallida'
    },
    success: {
        confirm_delete: "Elemento eliminado con éxito",
        confirm_update: "Actualización exitosa",
        confirm_password: "Contraseña cambiada, ahora puede iniciar sesión en la aplicación"
    },
    warning: {
        ask_delete: '¿Seguro que desea eliminar el elemento?',
        unreversible_delete: "La eliminación será irreversible",
        warning: 'Advertencia'
    },
    dashboard: {
        module_name: 'Tablero'
    },
    common: {
        confirm: 'Confirmar',
        cancel: 'Cancelar',
        save: 'Guardar',
        reset: 'Restablecer',
        search: 'Buscar',
        table: {
            total: 'Total {{total}} resultados',
            export: 'Exportar',
            changeMe: 'Cámbiame',
            key: 'Clave',
            value: 'Valor',
            attributes: 'Atributos',
            addRow: 'Agregar Fila',
            keyDescription: 'Descripción de Clave',
            valueDescription: 'Descripción de Valor'
        },
        edit: 'Editar',
        details: 'Detalles',
        addRow: 'Agregar Fila',
        delete: 'Eliminar',
        success: 'Éxito',
        fieldRequired: 'Campo Obligatorio',
        api: 'API',
        email: 'Correo Electrónico',
        goBack: 'Atrás',
        goAhead: 'Siguiente',
        description: 'Descripción',
        import_success: 'Archivo cargado exitosamente',
        click_upload: 'Haga clic para cargar un archivo'
    },
    menu: {
        dashboard: 'Tablero',
        users: 'Usuarios',
        stores: 'Tiendas',
        products: 'Productos',
        logout: 'Cerrar Sesión',
        digital_warranties: 'Garantías Digitales',
        settings: 'Configuración',
        about: 'Acerca de',
        modal: {
            copyright: 'Copyright © 2015 Temera. Todos los derechos reservados'
        },
        controlPanel: 'Panel de Control'
    },
    login: {
        username: 'Nombre de Usuario',
        password: 'Contraseña',
        confirm_password: 'Confirmar Contraseña',
        sign_in: 'Iniciar Sesión',
        mandatory_username: '¡Por favor ingrese su nombre de usuario!',
        mandatory_password: '¡Por favor ingrese su contraseña!',
        mandatory_email: '¡Por favor ingrese su correo electrónico!',
        mandatory_confirm_password: '¡Por favor confirme su contraseña!',
        forgot_password: '¿Olvidó su contraseña?',
        remember_me: 'Recuérdame',
        failed: 'Inicio de sesión fallido',
        recover_password_title: 'Complete el formulario a continuación para restablecer su contraseña',
        recover_password_subtitle: 'Recibirá un correo electrónico con un enlace para restablecer la contraseña',
        recover_success: 'Correo electrónico enviado con éxito',
        activation_user_title: 'Configure su contraseña de inicio de sesión',
        activation_user_subtitle: 'Esto solo será necesario en el primer inicio de sesión',
        header: 'GARANTÍA DIGITAL Y AUTENTICIDAD',
        fields: {
            username: 'Nombre de Usuario',
            email: 'Correo Electrónico',
            send: 'Enviar'
        },
        placeholder: {
            input_password: 'Ingrese la contraseña',
            input_confirm_password: 'Vuelva a ingresar la contraseña'
        }
    },
    loginSSO: {
        label: 'Iniciar sesión con su cuenta',
        sso_button: 'Iniciar sesión SSO',
        with_credentials: 'Iniciar sesión con sus credenciales'
    },
    module: {
        listing: {
            title: 'Gestión de {{moduleName}}'
        },
        add: {
            title: 'Agregar {{entityName}}'
        },
        edit: {
            title: 'Editar {{entityName}}'
        },
        details: {
            title: 'Detalles de {{entityName}}'
        },
        upload: {
            title: 'Cargar'
        }
    },
    users: {
        module_name: "Usuarios",
        entity: {
            singular_name: "Usuario",
            plural_name: "Usuarios"
        },
        details: {
            store: 'Tienda',
            email: 'Correo Electrónico'
        },
        columns: {
            details: 'Detalles',
            store: 'Tienda',
            modified: 'Modificado',
            created: 'Creado',
            surname: 'Apellido',
            name: 'Nombre',
            admin: 'Administrador',
            username: 'Nombre de Usuario',
            personal: 'Personal',
            exception: 'Excepción'
        },
        fields: {
            username: 'Nombre de Usuario',
            surname: 'Apellido',
            name: 'Nombre',
            storeType: 'Tipo de Tienda',
            store: 'Tienda',
            password: 'Contraseña',
            confirmPassword: 'Confirmar Contraseña',
            email: 'Correo Electrónico',
            role: 'Rol',
            admin: 'Administrador',
            attributes: 'Atributos',
            add: 'Agregar Usuario',
            area: 'Área',
            edit: 'Editar Usuario',
            userSoldTo: 'Usuario Vendido A',
            errors: {
                username: 'Por favor ingrese un nombre de usuario',
                password: 'Por favor ingrese una contraseña'
            }
        },
        add: {
            success: 'Usuario agregado con éxito',
            errorPasswordMatch: 'Las contraseñas no coinciden'
        },
        edit: {
            success: 'Usuario editado con éxito'
        }
    },
    prewarranties: {
        module_name: "Preactivaciones",
        entity: {
            singular_name: "Preactivación",
            plural_name: "Preactivaciones"
        },
    },
    hierarchies: {
        module_name: "Área",
        entity: {
            singular_name: "Nueva Área",
            plural_name: "Nuevas Áreas"
        },
    },
    partnerinventory: {
        module_name: "Inventario"
    },
    warranties: {
        module_name: "Activaciones",
        entity: {
            singular_name: "Activación",
            plural_name: "Activaciones"
        },
        columns: {
            details: 'Detalles',
            store: 'Tienda/Usuario Ret',
            card: 'Tarjeta de Reemplazo',
            return: 'Devolución',
            storeUserAct: 'Tienda/Usuario Act',
            activation: 'Activación',
            serialNumber: 'Número de Serie',
        },
        details: {
            image: "Imagen",
            product: "Producto",
            date: "Fecha",
            store: "Tienda",
            user: "Operador",
            delete: "Eliminar Activación",
            activation: "Activación",
            return: "Devolución",
            productPage: "Página del Producto",
            downloadPdf: "Descargar PDF",
        },
        fields: {
            from: 'Desde',
            to: 'Hasta',
            serialNumber: 'Número de Serie',
            returned: 'Devuelto',
            storeType: 'Tipo de Tienda',
            store: 'Tienda'
        }
    },
    stores: {
        module_name: "Tiendas",
        entity: {
            singular_name: "Tienda",
            plural_name: "Tiendas"
        },
        columns: {
            details: 'Detalles',
            store: 'Tienda',
            type: 'Tipo',
            activation: 'Activación',
            operationCountry: 'País/Región de Operación',
            ISOCode: 'Código ISO',
            soldTo: 'Vendido a',
            status: 'Estado'
        },
        fields: {
            from: 'Desde',
            to: 'Hasta',
            storeType: 'Tipo de Tienda',
            store: 'Tienda',
            address: 'Dirección',
            users: 'Usuarios',
            add: 'Agregar Tienda',
            edit: 'Editar Tienda',
            nameCodeStore: 'Nombre/Código de Tienda',
            activationStatus: 'Estado de Activación',
            operatingCountry: "País/Región de Operación",
            sold_to: 'Vendido a',
            ship_to: 'Enviado a',
            errors: {
                store: 'Por favor ingrese el nombre de la tienda'
            }
        },
        details: {
            users: 'Usuarios',
            lastAccess: 'último acceso: ',
            active: 'Activo',
            expectedDevices: 'Dispositivos Previstos',
            usbDevice: 'Lector USB NFC',
            mobileDevice: 'Teléfono Inteligente'
        },
        add: {
            success: 'Tienda creada con éxito'
        },
        edit: {
            success: 'Tienda editada con éxito'
        }
    },
    masterdata: {
        module_name: "Productos",
        entity: {
            singular_name: "Producto",
            plural_name: "Productos"
        }
    },
    settings: {
        set_system: 'Configurar su sistema',
        save_system: "Guardar"
    },
    products: {
        columns: {
            details: 'Detalles',
            shortDescription: 'Descripción Corta',
            description: 'Descripción',
            creationDate: 'Fecha de Creación',
            photo: 'Foto',
            marketingDescription: 'Descripción de Marketing',
            materialType: 'Tipo de Material',
            nfcEmbedded: 'NFC Habilitado',
        },
        fields: {
            id: 'ID',
            code: 'Código',
            description: 'Descripción',
            attributes: 'Atributos',
            materialType: 'Tipo de Material',
            NFC: 'NFC Habilitado'
        },
        edit: {
            successEditDescription: 'Producto actualizado exitosamente'
        }
    },
    geolocation: {
        not_enabled: 'Geolocalización no habilitada'
    },
    image: {
        masterdata_image: "Imagen del Producto"
    },
    landing: {
        identified_product: "Producto Identificado",
        sku_upc: "SKU/UPC",
        serial: "Número de Serie",
        activation_store: "Tienda de Activación",
        activation_date: "Fecha de Activación",
        information: "Más información sobre la activación del producto",
        card: "Tarjeta de Aterrizaje"
    },
    profiles: {
        module_name: "Perfiles",
        entity: {
            singular_name: 'Perfil'
        }
    },
    'bu-manual': {
        module_name: "Manual",
        entity: {
            singular_name: 'Manual'
        }
    }
}
