import {call, CallRequestConfig, download} from "../../../shared/api";
import DWDataProvider from "../DWDataProvider";
import {applyFilters} from "../../../services/HooksManager";
import {Method} from "axios";
import ObjectUtils from "../../../utils/ObjectUtil";
import {DWEnvironmentManager} from "../../../services/DWEnvironmentManager";

class DWDataProviderImp implements DWDataProvider {

    dwEnvironmentManager: DWEnvironmentManager | undefined = undefined;

    endpoint = '';
    pathOne = '/';
    pathSendEmail = 'mail/send/snow';

    getServiceKey(): string {
        return 'dataProvider'
    }

    init = async (dwEnvironmentManager: DWEnvironmentManager) => {
        this.dwEnvironmentManager = dwEnvironmentManager
    }

    private __getEndpoint(overrideModule?: string) {
        return overrideModule ?? this.endpoint;
    }

    applyPreRequestFilter = (call: string, method: Method, path: string, request: CallRequestConfig<any>): {
        method: Method,
        path: string,
        request: CallRequestConfig<any>
    } => {

        /**
         * Prepara la richiesta per il methdo ${call} del Data Provider
         * @hook data_pre_${call}
         * @param {method:Method, path: string, request: CallRequestConfig<any>} dati della richiesta
         */
        return applyFilters(`data_pre_${call}`, {
            method: method,
            path: path,
            request: request
        });
    }

    private static __initRequest(parameters: CallRequestConfig<any> | undefined, request: any) {
        return ObjectUtils.assignDeep(ObjectUtils.filterIfValueIsEmpty(parameters as any), ObjectUtils.filterIfValueIsEmpty(request));
    }

    async mailSend( parameters?: CallRequestConfig<any>, overrideModule?: string ): Promise<any>{

        let res = DWDataProviderImp.__initRequest(parameters, {});
        const {method, path, request} = this.applyPreRequestFilter('send_email', 'POST', this.__getEndpoint(overrideModule) + this.pathSendEmail, res);
        return await call<any[]>(method, path, request);
    }

    async deleteOneBu( entity:string, id:string | number, parameters?: CallRequestConfig<any>, overrideModule?: string ): Promise<any>{

        let url = entity + this.pathOne + id

        let res = DWDataProviderImp.__initRequest(parameters, {});
        const {method, path, request} = this.applyPreRequestFilter('delete_one_bu', 'DELETE', this.__getEndpoint(overrideModule) + url, res);
        return await call<any[]>(method, path, request);
    }

    async deleteWarranties( parameters?: CallRequestConfig<any>, overrideModule?: string ): Promise<any>{

        let res = DWDataProviderImp.__initRequest(parameters, {});
        const {method, path, request} = this.applyPreRequestFilter('delete_prewarranties', 'DELETE', this.__getEndpoint(overrideModule), res);
        return await call<any[]>(method, path, request);
    }

    async upload( parameters?: CallRequestConfig<any>, overrideModule?: string ): Promise<any>{

        let res = DWDataProviderImp.__initRequest(parameters, {});
        const {method, path, request} = this.applyPreRequestFilter('upload_file', 'POST', this.__getEndpoint(overrideModule), res);
        return await call<any[]>(method, path, request);
    }

}

export default new DWDataProviderImp();
