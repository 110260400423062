import ParentModule from "../../shared/module/CrudModule";
import { addFilter } from "../../services/HooksManager";
import ResetPassword from "../pages/ResetPassword";


class ResetPasswordModule extends ParentModule {

    init = () => {
        addFilter('app_routes', (routes: any) => {
            routes['/'+this.getModuleKey()] = {component: ResetPassword, visibility: 'public', props: {content: <ResetPassword/>}};
            return routes;
        }, 10);
    }
  
    getModuleKey = (): string => {
        return "resetPassword";
    }

}

const resetPasswordModule = new ResetPasswordModule();
export default resetPasswordModule;
