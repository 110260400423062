/* eslint-disable import/no-anonymous-default-export */
export default {
    app: {
        name: 'Temera Digital Warranty',
        url: 'www.buccellati.com'
    },
    error: {
        generic_error: 'Generic Error',
        error: 'Error',
        forbidden: 'Forbidden',
        not_found: 'Not Found',
        unauthorized: 'Unauthorized',
        invalid_credentials: 'Invalid Credentials!',
        duplicateKey: 'Duplicate Key',
        api: 'API Error {{method}} {{api}} :\n {{error_message}}',
        requiredRowEditTable: 'The table must be have a row',
        error_upload_file: 'File upload failed'
    },
    success: {
        confirm_delete: "Item successfully removed",
        confirm_update: "Update successfully",
        confirm_password: "Password changed, you can now log in from the app"
    },
    warning: {
        ask_delete: 'Are you sure about delete?',
        unreversible_delete: "The cancellation will be irreversible",
        warning: 'Warning'
    },
    dashboard: {
        module_name: 'Dashboard'
    },
    common: {
        confirm: 'Confirm',
        cancel: 'Cancel',
        save: 'Save',
        reset: 'Reset',
        search: 'Search',
        table: {
            total: 'Total {{total}} items',
            export: 'Export',
            changeMe: 'Change Me',
            key: 'Key',
            value: 'Value',
            attributes: 'Attributes',
            addRow: 'Add Row',
            keyDescription: 'Key Description',
            valueDescription: 'Value Description'
        },
        edit: 'Edit',
        details: 'Details',
        addRow: 'Add Row',
        delete: 'Delete',
        success: 'Success',
        fieldRequired: 'Required Field',
        api: 'API',
        email: 'Email',
        goBack: 'Back',
        goAhead: 'Forward',
        description: 'Description',
        import_success: 'File uploaded successfully',
        click_upload: 'Click to upload a file'
    },
    menu: {
        dashboard: 'Dashboaard',
        users: 'Users',
        stores: 'Stores',
        products: 'Products',
        logout: 'Logout',
        digital_warranties: 'Digital Warranties',
        settings: 'Settings',
        about: 'About',
        modal: {
            copyright: 'Copyright © 2015 Temera All rights reserved'
        },
        controlPanel:'Control Panel'
    },
    login: {
        username: 'Username',
        password: 'Password',
        confirm_password: 'Confirm password',
        sign_in: 'Sign In',
        mandatory_username: 'Please input your username!',
        mandatory_password: 'Please input your password!',
        mandatory_confirm_password: 'Please confirm yout password!',
        mandatory_email:'Please input your email!',
        forgot_password: 'Forgot your password?',
        remember_me: 'Remember me',
        failed: 'Login failed',
        recover_password_title: 'Fill out the form below to reset your password',
        recover_password_subtitle: 'You will receive an email containing a link to reset your password',
        recover_success: 'Mail sent successfully',
        activation_user_title: 'Configure your access password',
        activation_user_subtitle: 'This operation will necessary only for the first access',
        header: 'DIGITAL WARRANTY & AUTHENTICITY',
        fields: {
            username: 'Username',
            email: 'Email',
            send: 'Send'
        },
        placeholder: {
            input_password: 'Input password',
            input_confirm_password: 'Input again the password'
        }
    },
    loginSSO: {
        label: 'Login to your Account',
        sso_button: 'SSO Login',
        with_credentials : 'Login with Credentials'
    },
    module: {
        listing: {
            title: '{{moduleName}} Management'
        },
        add: {
            title: 'Add {{entityName}}'
        },
        edit: {
            title: 'Edit {{entityName}}'
        },
        details: {
            title: '{{entityName}} Details'
        },
        upload:{
            title: 'Upload'
         }
    },
    users: {
        module_name: "User",
        entity: {
            singular_name: "User",
            plural_name: "Users"
        },
        details: {
            store: 'Store',
            email: 'Email'
        },
        columns: {
            details: 'Details',
            store: 'Store',
            modified: 'Modified',
            created: 'Created',
            surname: 'Surname',
            name: 'Name',
            admin: 'Admin',
            username: 'Username',
            personal: 'Personal',
            exception: 'Exception'
        },
        fields: {
            username: 'Username',
            surname: 'Surname',
            name: 'Name',
            storeType: 'Store Type',
            store: 'Store',
            area: 'Area',
            password: 'Password',
            confirmPassword: 'Confirm Password',
            email: 'Email',
            role: 'Role',
            admin: 'Admin',
            attributes: 'Attributes',
            add: 'Add User',
            edit: 'Edit User',
            userSoldTo: 'User Sold To',
            errors: {
                username: 'Insert an Username',
                password: 'Insert a Password'
            }
        },
        add: {
            success: 'User addedd successfully',
            errorPasswordMatch: 'Password did not match'
        },
        edit: {
            success: 'User updated successfully'
        }
    },
    prewarranties: {
        module_name: "Pre Activation",
        entity: {
            singular_name: "Pre activation",
            plural_name: "Pre activation"
        },
    },
    hierarchies: {
        module_name: "Area",
        entity: {
            singular_name: "New area",
            plural_name: "New area"
        },
    },
    partnerinventory: {
        module_name: "Inventories"
    },
    warranties: {
        module_name: "Activation",
        entity: {
            singular_name: "Activation",
            plural_name: "Activations"
        },
        columns: {
            details: 'Details',
            storeUserRef: 'Store/User Ret',
            card: 'Card Substitution',
            return: 'Return',
            storeUserAct: 'Store/User Act',
            activation: 'Activation',
            serialNumber: 'Serial Number',
        },
        details: {
            image: "Image",
            product: "Product",
            date: "Date",
            store: "Store",
            user: "User",
            delete: "Delete Activation",
            activation: "Activation",
            return: "Return",
            productPage: "Product Page",
            downloadPdf: "Download PDF"
        },
        fields: {
            from: 'From',
            to: 'To',
            serialNumber: 'Serial Number',
            returned: 'Returned',
            storeType: 'Store Type',
            store: 'Store'
        }
    },
    stores: {
        module_name: "Store",
        entity: {
            singular_name: "Store",
            plural_name: "Stores"
        },
        columns: {
            details: 'Details',
            store: 'Store',
            type: 'Type',
            activation: 'Activation',
            operationCountry: 'Operation Country/Region',
            ISOCode: 'ISO Code',
            soldTo: 'Sold To',
            status: 'Status'
        },
        fields: {
            from: 'From',
            to: 'To',
            storeType: 'Store Type',
            store: 'Store',
            address: 'Address',
            users: 'Users',
            add: 'Add Store',
            edit: 'Edit Store',
            nameCodeStore: 'Name/Code Store',
            activationStatus: 'Status activation',
            operatingCountry: "Operating country/region",
            sold_to: 'Sold To',
            ship_to: 'Ship To',
            errors: {
                store: 'Insert the name of the store'
            }
        },
        details: {
            users: 'Users',
            lastAccess: 'last access: ',
            active: 'Active',
            expectedDevices: 'Expected Devices',
            usbDevice: 'USB NFC Reader',
            mobileDevice: 'Smartphone'
        },
        add: {
            success: 'Store added successfully'
        },
        edit: {
            success: 'Store updated successfully!'
        }
    },
    masterdata: {
        module_name: "Product",
        entity: {
            singular_name: "Product",
            plural_name: "Products"
        }
    },
    settings: {
        set_system: 'Set your system',
        save_system: "Save Changes"
    },
    products: {
        columns: {
            details: 'Details',
            shortDescription: 'Short Description',
            description: 'Description',
            creationDate: 'Creation Date',
            photo: 'Photo',
            marketingDescription: 'Marketing description ',
            materialType: 'Material type',
            nfcEmbedded: 'NFC embedded',
        },
        fields: {
            id: 'Id',
            code: 'Code',
            description: 'Description',
            attributes: 'Attributes',
            materialType: 'Material type',
            NFC: 'NFC EMBEDDED'
        },
        edit: {
            successEditDescription: 'Product successfully updated!'
        }
    },
    landing: {
        identified_product: "Identified product",
        sku_upc: "SKU/UPC",
        serial: "Serial number",
        activation_store: "Activation store",
        activation_date: "Activation date",
        information: "More informations about the product activation",
        card: "Landign Card"
    },
    geolocation: {
        not_enabled: 'Geolocation is not enabled'
    },
    image: {
        masterdata_image: "Product Image"
    },
    profiles: {
        module_name: "Profiles",
        entity: {
            singular_name: 'Profile'
        }
    },
    'bu-manual':{
        module_name: "Manual",
        entity:{
            singular_name: 'Manual'
        }
    }
}
