import { Button, Form, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { Component } from "react";
import AttributesTable from "../../AttributesTable";
import FieldProps, { FieldState } from "./FieldProps";

export default class TableField extends Component<{} & FieldProps, FieldState> {

    constructor(props: FieldProps | Readonly<FieldProps>) {
        super(props);
        this.state = {};
    }



    render() {
        const { field } = this.props;
        return (
            <Form.Item
                label={field.label ?? field.name}
                name={field.name}
                rules={[
                    {
                        required: field.required,
                        message: field.requiredMessage,
                    },
                ]}
            >
                <AttributesTable column={this.props.field?.editableColumnConf} dataSourceProps={this.props.field?.dataSourceProps}/>
            </Form.Item>
        )
    }
}