import { ControlOutlined } from "@ant-design/icons";
import { __ } from "../translations/i18n";
import { addFilter, applyFilters } from "../services/HooksManager";
import ParentModule from "../shared/module/CrudModule";
import { MenuNode } from "../components/MenuComponent";

interface State {
    collapsed: boolean
}

interface Props {
    content: JSX.Element[]
    location: any
}

class ControlPanelModule extends ParentModule{

    init = () => {
        addFilter('app_menu', this.addMenu);
    }
    
    state: { collapsed: boolean; };

    constructor() {
        super();
        this.state = {
            collapsed: false
        };
    }

    setCollapsed() {
        this.setState({
            collapsed: true
        });
    }

    setState(arg0: { collapsed: boolean; }) {
        throw new Error("Method not implemented.");
    }

    addMenu = (menu: MenuNode[]) => {
      
        let children = applyFilters('app_sub_menu', [])
        menu.push({
            label: <i onClick={this.setCollapsed}> {__('menu.controlPanel')}</i>,
            icon: <ControlOutlined onClick={this.setCollapsed} />,
            position: '200',
            children: children,
            key: this.getModuleKey()
        })

        return menu;
    }

    getModuleKey = (): string => {
        return "controlPanel";
    }

}


const controlPanelModule = new ControlPanelModule()
export default controlPanelModule;
