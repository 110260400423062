import { Col, Row, Steps } from "antd";
import { Component } from "react";
import { ClipLoader } from "react-spinners";
import DWEnvironmentManager from "../../../services/DWEnvironmentManager";
import { addAction } from "../../../services/HooksManager";
import { __ } from "../../../translations/i18n";
import { __b } from "../../translations/i18n";
import FirstStep from "./StatusFirstStep";
import SecondStep from "./StatusSecondStep";
import { errorToast } from "../../../services/NotificationManager";


interface State {
    currentTabs: number,
    product: any,
    productDetail: any[],
    loadProduct: boolean,
    result?: 'error' | 'ok',
    errorSubTitle?: string,
    errorTitle?: string,
    versionPdfOpen: boolean,
    manualProductOpen: boolean
}

interface Props {
    module: any
}


export default class StatusComponent extends Component<Props, State> {
    
    state: State =  {
        currentTabs: 0,
        product: {},
        productDetail: [],
        loadProduct: false,
        versionPdfOpen: false,
        manualProductOpen: false
    }

    navManager = DWEnvironmentManager.getNavigationManager();

    componentDidMount(): void {
        // init breadcrumb, da verificare perchp non va
        addAction('nav_route_change', this.onRouteChange);
    }

    onRouteChange = (location: any, navManager: any) => {
        const route = this.getRoute();
        const basename = DWEnvironmentManager.getBasename();
        if (location.pathname.indexOf(route) === 0) {
            navManager.addBreadcrumb({key: `dashboard`, route: `${basename}dashboard`, label: <span>{__('dashboard.module_name')}</span>})
            navManager.addBreadcrumb({key: `${this.props.module}_details`, route: this.getRouteBasename(), label: <span>{__b('status.status')}</span>})
        }
    }

    getRouteBasename = () => {
        const basename = DWEnvironmentManager.getBasename();
        return `${basename}status`
    }

    getRoute = () => {
        return '/status';
    }

    finishFirstStep = async (data: any) => {
        // chiamata che controlla il seriale, se seriale corretto andiamo su secondo step - altrimenti si ferma
        // se chiamata andata a buon fine allora precarica i dari del seriale e poi passa a secondo step
        this.setState({loadProduct: true});
        const provider = DWEnvironmentManager.getDataProvider();
        let product = await provider.getList(undefined, undefined, {}, `warranties/operations?itemSerialNumber=${data.serial}`);
        let preWarrProd = await provider.getList(undefined, undefined, {}, `prewarranties/operations?itemSerialNumber=${data.serial}`);
        product = [...product, ...preWarrProd];
        if (product !== null && product.length > 0) {
            this.setState({product: product});
            // effettuare chiamata find per informazioni prodotto.
            let productFind: any[] = [];

            for await (const prod of product) {
                const products = await provider.addOne({data: [prod.item.upc]}, `masterdata/find`);
                let object = {};
                let vl = {value: product.find( (pr: any) => pr.item.upc === prod.item.upc), detail: products[prod.item.upc]};
                object = {...object, ...vl};
                
                productFind.push(object);
            }

            this.setState({productDetail: productFind});
            this.setState({loadProduct: false});
            this.setState({currentTabs: 1});
        } else {
            this.setState({loadProduct: false});
            errorToast(__b("status.serialNumberError"), __b("status.serialNumberErrorMsg"));   
        }
    }

    goBack = ()=> this.setState({currentTabs: 0});

    downloadPdf = async (data: any) => {
        // download pdf
    }

    manualProduct = async (product: any) => {
        // download manuale prodotto
    }

    steps = [
        {
          title: '',
        },
        {
          title: '',
        },
      ];
      

    render() {
        const {currentTabs, loadProduct, result, productDetail} = this.state;

        if (loadProduct) {
            return <div id={"loader-container"} className="loader-container" style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}><ClipLoader size={150} /></div>
        } else {
            return (
                <>
                {!result && 
                    <>
                        <Row gutter={[24, 24]} justify={"center"} style={{marginTop: 50}}>
                            <Col span={2}>
                                <Steps current={currentTabs} items={this.steps} size={"small"} />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} justify={"center"} style={{marginTop: 20}}>
                            {currentTabs === 0 && (
                                <Col span={8}>
                                    <FirstStep finishFirstStep={this.finishFirstStep}/>
                                </Col>
                            )}
                            {currentTabs === 1 && (
                                <Col span={20}>
                                    <SecondStep product={productDetail} goBack={this.goBack} downloadPdf={this.downloadPdf} manualProduct={this.manualProduct} />
                                </Col>
                            )}
                        </Row>
                    </>
                }
                </>
            )
        }
    }
        

}