import {Button, Popconfirm} from "antd";
import moment from "moment";
import React, {ReactNode} from "react";
import {User} from "../../models/User";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import {addFilter} from "../../services/HooksManager";
import {closeModal, successToast, warningModal} from "../../services/NotificationManager";
import {CallRequestConfig} from "../../shared/api";
import ParentModule from "../../shared/module/CrudModule";
import {navigate} from "../../shared/router";
import {__} from "../../translations/i18n";
import DateUtils from "../../utils/DateUtils";
import buUserManager from "../services/BuUserManager";
import {__b} from "../translations/i18n";
import ObjectsUtil from "../utils/ObjectsUtil";
import DWDataProviderImp from "../providers/implementation/DWDataProviderImp";
import {fetchStoresCompanyCode as fetchSoldToParty} from "./PartnerInventoriesModule"

class StoreModule extends ParentModule {

    entity: any;
    users: any;

    init = () => {

        addFilter('stores_table_search_form', this.addFieldsTosearch, 50);
        addFilter('stores_table_columns', this.addToTableColumns, 50);
        addFilter('stores_table_data_row', this.addDataToTableRow, 50);
        addFilter('stores_edit_form', this.addFieldsToEdit, 50);
        addFilter('stores_edit_method', () => {return this.edit});
        addFilter('stores_add_form', this.addFieldsToAdd, 50);
        addFilter('stores_add_method', () => {return this.add});
        addFilter('crud_detail_edit_component_stores', this.getDetailData, 50);
        addFilter('stores_details_section_active', this.detailSectionActive, 50);
        addFilter('crud_tabs_stores', this.crudTabs);
        addFilter('crud_details_tabs_stores', this.modifyEditModule);
    }

    crudTabs = (tabsItem: any) => {
        const tabs = tabsItem;
        
        if (!buUserManager.userCanAddStores()) {
            tabs.pop();
        }
        
        return tabs;
    }

    modifyEditModule = (tabsItems: any) => {
        console.log(tabsItems);
        const tabs = tabsItems;

        if (!buUserManager.userCanEditStores()) {
            tabs.pop();
        }

        return tabsItems;
    }


    detailSectionActive = (component: ReactNode) => {
        return <>
            {component}
            {!this.entity.activationDate && !this.entity.activationPending && (
                <><Button style={{marginTop: 20, marginRight: 20}}
                          onClick={() => this.activateSmartphone(this.entity)}>{__b("stores.details.activateSmartphone")}</Button>
                    <Button style={{marginTop: 20}}
                            onClick={() => this.requestActivation(this.entity)}>{__b("stores.details.activateStore")}</Button></>
            )}
            {!this.entity.activationDate && this.entity.activationPending && (
                <Button style={{marginTop: 20}}
                        onClick={() => this.requestActivation(this.entity)}>{__b("stores.details.sendAgain")}</Button>
            )}
        </>;
    }

    activateSmartphone = async (data: any) => {
        const provider = DWEnvironmentManager.getDataProvider();

        const parameters: CallRequestConfig<any> = {
            params: {
                activateUsers: 'false',
                sendEmail: 'true'
            },
            data: {
                id: data.id
            }
        }
        let res = await provider.addOne(parameters, `stores/${data.id}/activate`);
        if (res?.value) {
            successToast(__('common.success'), __b('stores.details.activationSuccess'));
        }
    }

    requestActivation = async (data: any) => {
        const provider = DWEnvironmentManager.getDataProvider();

        const parameters: CallRequestConfig<any> = {
            data: {
                id: data.id
            }
        }
        let res = await provider.addOne(parameters, `stores/${data.id}/requestactivation`);
        if (res?.value) {
            successToast(__('common.success'), __b('stores.details.requestActivationSuccess'));
        }
    }

    getDetailData = (component: any, entity: any) => {
        this.users = component.props.users;
        this.entity = entity;
        return component;
    }

    add = async (data:any) => {

        const provider = DWEnvironmentManager.getDataProvider();

        // ------ parametri fissi non settabili
        if( data.operatingCountry ) {
            data.hierarchy =  await provider.getOne ( data.operatingCountry , {}, 'hierarchies' );
        }

        data.attributes = {
            Aura_DefaultStore_Email: data.areaDefaultEmail,
            expectedDevicesReaders: 0,
            expectedDevicesSmartphones: 0,
            ship_to: data.shipTo ?? '',
            sold_to: data.sold_to ?? ''
        }
        // -----

        delete data.areaDefaultEmail;
        delete data.operatingCountry;
        delete data.addStore;
        delete data.shipTo;
        delete data.sold_to;

        // convert moment object in date
        if( data.openingDate ) {
            data.openingDate = moment ( data.openingDate ).format ()
        }
        if( data.closingDate ) {
            data.closingDate = moment ( data.closingDate ).format ()
        }
        if( data.phaseOutDate ) {
            data.phaseOutDate = moment ( data.phaseOutDate ).format ()
        }

        const req = {
            data: Object.assign({}, data),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json, text/plain, */*'
            },
        };

        if( await provider.addOne ( req, 'stores' ) ) {
            successToast ( __b ( 'stores.fields.created' ), '' )
            navigate ( '/stores' );
        }
    }

    edit = async (data: any) => {

        const users = await this.users;

        const provider = DWEnvironmentManager.getDataProvider();
        
        // ------ parametri fissi non settabili
        if( data.operatingCountry ) {
            data.hierarchy =  await provider.getOne ( data.operatingCountry[0] , {}, 'hierarchies' );
        }

        if( data.hierarchy == null ) {
            data.hierarchy =  await provider.getOne ( data.operatingCountry.value , {}, 'hierarchies' );
        }

        data.attributes = {
            Aura_DefaultStore_Email: data.areaDefaultEmail,
            expectedDevicesReaders: 0,
            expectedDevicesSmartphones: 0,
            ship_to: data.shipTo ?? '',
            sold_to: data.sold_to ?? ''
        }
        // -----

        delete data.areaDefaultEmail;
        delete data.operatingCountry;
        delete data.addStore;
        delete data.shipTo;
        delete data.sold_to;
        delete data.addStore;

        if (data.status) {
            data.status = data.status.toUpperCase();
        }

        // convert moment object in date
        if( data.openingDate ) {
            data.openingDate = moment ( data.openingDate ).format ()
        }
        if( data.closingDate ) {
            data.closingDate = moment ( data.closingDate ).format ()
        }
        if( data.phaseOutDate ) {
            data.phaseOutDate = moment ( data.phaseOutDate ).format ()
        }
        data.activationDate = this.entity.activationDate

        const req = {
            data: Object.assign({}, data),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json, text/plain, */*'
            },
        };

        const res = await provider.addOne ( req, `stores/${this.entity.id}` );

        let updateUser: User[] = []
        if (data.users) {
            data.users.forEach(async (u : any) => {
                const user : User = await provider.getOne(u.value,{} , 'users');
                user.store = res;
                user.storeId = res.id;
                updateUser.push(user);
                DWEnvironmentManager.getUserDataProvider().update(user.id, user);
            });
        }
        
        if (users.length > 0 && updateUser.length > 0) {
            users.forEach(async (u : any) => {
                const findUser = updateUser.find( (user: any) => user.id = u.value);
                if (!findUser) {
                   const user : User = await provider.getOne(u.value,{} , 'users');
                   user.store = null;
                   user.storeId = null;
                   DWEnvironmentManager.getUserDataProvider().update(user.id, user)
                }
            });
        }

        if(res) {
            successToast ( __('common.success'), __( 'stores.edit.success' ) )
            navigate ( '/stores' );
        }

    }

    addFieldsToAdd = (fields: any[], module :any) => {

        let code = {
            name: 'code',
            label: __b('stores.fields.code'),
            type: 'text',
            required: false,
            colConf: {
                span: 12
            }
        };

        let telephone = {
            name: 'phone',
            label: __b('stores.fields.telephone'),
            type: 'text',
            required: false,
            colConf: {
                span: 12
            }
        };

        let state = {
            name: 'status',
            label: __b('stores.fields.state'),
            type: 'select',
            required: false,
            selectValue: [
                {label: __b ( "warranties.fields.values.prospect" ), value: 'PROSPECT'},
                {label: __b ( "warranties.fields.values.open" ), value: 'OPEN'},
                {label: __b ( "warranties.fields.values.closed" ), value: 'CLOSED'},
                {label: __b ( "warranties.fields.values.phaseOut" ), value: 'PHASE_OUT'},
                {label: __b ( "warranties.fields.values.future" ), value: 'FUTURE'},
            ],
            colConf: {
                span: 12
            }
        };

        let bvlAfss = {
            name: 'afss',
            label: __b('stores.fields.bvlAfss'),
            type: 'select',
            required: false,
            selectValue: [
                {label: __b ( "warranties.fields.values.internal" ), value: 'INTERNAL'},
                {label: __b ( "warranties.fields.values.external" ), value: 'EXTERNAL'}
            ],
            colConf: {
                span: 12
            }
        };

        let openingDate = {
            name: 'openingDate',
            label: __b('stores.fields.openingDate'),
            type: 'date',
            required: false,
            format: 'DD/MM/YYYY',
            colConf: {
                span: 12
            }
        };

        let closingDate = {
            name: 'closingDate',
            label: __b('stores.fields.closingDate'),
            type: 'date',
            required: false,
            format: 'DD/MM/YYYY',
            colConf: {
                span: 12
            }
        };

        let phaseOutDate = {
            name: 'phaseOutDate',
            label: __b('stores.fields.phaseOutDate'),
            type: 'date',
            required: false,
            format: 'DD/MM/YYYY',
            colConf: {
                span: 12
            }
        };

        let phaseOut = {
            name: 'phaseOut',
            label: __b('stores.fields.phaseOut'),
            type: 'select',
            required: false,
            selectValue: [
                {label: __b ( "warranties.fields.values.yes" ), value: 'true'},
                {label: __b ( "warranties.fields.values.no" ), value: 'false'}
            ],
            colConf: {
                span: 12
            }
        };

        let soldToParty = {
            name: 'soldToParty',
            label: __b('stores.fields.soldToParty'),
            type: 'text',
            required: false,
            colConf: {
                span: 12
            }
        };

        let responsabilityArea = {
            name: 'responsabilityArea',
            label: __b('stores.fields.responsabilityArea'),
            type: 'text',
            required: false,
            colConf: {
                span: 12
            }
        };

        let masterCodeCode = {
            name: 'masterCodeCode',
            label: __b('stores.fields.masterCodeCode'),
            type: 'text',
            required: false,
            colConf: {
                span: 12
            }
        };

        let masterCodeName = {
            name: 'masterCodeName',
            label: __b('stores.fields.masterCodeName'),
            type: 'text',
            required: false,
            colConf: {
                span: 12
            }
        };

        let soldTo = {
            name: 'sold_to',
            label: __b('stores.fields.soldTo'),
            type: 'text',
            required: false,
            colConf: {
                span: 12
            }
        };

        let shipTo = {
            name: 'shipTo',
            label: __b('stores.fields.shipTo'),
            type: 'text',
            required: false,
            colConf: {
                span: 12
            }
        };

        let operatingCountry = {
            name: 'operatingCountry',
            label: __b('stores.fields.operatingCountry'),
            type: 'autocompleteselect',
            required: false,
            fetchOptions: (key: string) => (fetchCountry(key)),
            colConf: {
                span: 24
            }
        };

        let areaDefaultEmail = {
            name: 'areaDefaultEmail',
            label: __b('stores.fields.areaDefaultEmail'),
            type: 'text',
            required: false,
            colConf: {
                span: 24
            }
        };

        // cambio grandezza campo per Store e StoreType
        let indexStore = fields[0]['fields'].findIndex( (store: any) => store.name === 'description');
        fields[0]['fields'][indexStore].colConf.span = 12;

        let indexStoreType = fields[0]['fields'].findIndex( (store: any) => store.name === 'externalCode');
        fields[0]['fields'][indexStoreType].colConf.span = 12;
        fields[0]['fields'][indexStoreType].selectValue = [
            { label: __b ( "warranties.fields.values.all" ), value: '' },
            { label: "Ownership", value: 'ownership' },
            { label: "Wholesale", value: 'wholesale' },
            { label: "Travel Retail", value: 'travel retail' },
            { label: "Franchisee", value: 'franchisee' },
            { label: "Service Center", value: 'service center' },
            { label: "Family and Friends", value: 'family and friends' },
            { label: "Vintage", value: 'vintage' },
            { label: "Online", value: 'online' }
        ];

        // Aggiungo i campi in ordine per la verticalizzazione
        fields[0]['fields'].splice(0, 0, code);
        fields[0]['fields'].splice(1, 0, telephone);
        fields[0]['fields'].splice(3, 0, fields[0]['fields'][5]);
        fields[0]['fields'].splice(4, 0, state);
        fields[0]['fields'].splice(5, 0, bvlAfss);
        fields[0]['fields'].splice(6, 0, openingDate);
        fields[0]['fields'].splice(7, 0, closingDate);
        fields[0]['fields'].splice(8, 0, phaseOutDate);
        fields[0]['fields'].splice(9, 0, phaseOut);
        fields[0]['fields'].splice(10, 0, soldToParty);
        fields[0]['fields'].splice(11, 0, responsabilityArea);
        fields[0]['fields'].splice(12, 0, masterCodeCode);
        fields[0]['fields'].splice(13, 0, masterCodeName);
        fields[0]['fields'].splice(14, 0, soldTo);
        fields[0]['fields'].splice(15, 0, shipTo);
        fields[0]['fields'].splice(16, 0, operatingCountry);
        fields[0]['fields'].splice(17, 0, areaDefaultEmail);
        fields[0]['fields'].splice(14, 0, fields[0]['fields'][18]);
        fields[0]['fields'].splice(18, 0, fields[0]['fields'][20]);
        fields[0]['fields'].splice(20, 3);

        return fields;
    }

    addFieldsToEdit = (fields: any[], module :any) => {

        let code = {
            name: 'code',
            label: __b('stores.fields.code'),
            type: 'text',
            required: false,
            defaultValue: this.entity.code,
            value: this.entity.code,
            colConf: {
                span: 12
            }
        };

        let telephone = {
            name: 'phone',
            label: __b('stores.fields.telephone'),
            type: 'text',
            required: false,
            defaultValue: this.entity.phone,
            value: this.entity.phone,
            colConf: {
                span: 12
            }
        };

        let state = {
            name: 'status',
            label: __b('stores.fields.state'),
            type: 'select',
            defaultValue: this.entity?.status ? this.entity.status.toLowerCase() : undefined,
            value: this.entity?.status ? this.entity.status.toLowerCase() : undefined,
            required: false,
            selectValue: [
                {label: __b ( "warranties.fields.values.prospect" ), value: 'prospect'},
                {label: __b ( "warranties.fields.values.open" ), value: 'open'},
                {label: __b ( "warranties.fields.values.closed" ), value: 'closed'},
                {label: __b ( "warranties.fields.values.phaseOut" ), value: 'phase out'},
                {label: __b ( "warranties.fields.values.future" ), value: 'future'},
            ],
            colConf: {
                span: 12
            }
        };

        let bvlAfss = {
            name: 'afss',
            label: __b('stores.fields.bvlAfss'),
            type: 'select',
            required: false,
            defaultValue: this.entity.afss ? this.entity.afss.toLowerCase() : undefined,
            value: this.entity.afss ? this.entity.afss.toLowerCase() : undefined,
            selectValue: [
                {label: __b ( "warranties.fields.values.internal" ), value: 'internal'},
                {label: __b ( "warranties.fields.values.external" ), value: 'external'}
            ],
            colConf: {
                span: 12
            }
        };

        let openingDate = {
            name: 'openingDate',
            label: __b('stores.fields.openingDate'),
            type: 'date',
            required: false,
            defaultValue: this.entity.openingDate ? moment(this.entity.openingDate) : undefined,
            value: this.entity.openingDate ? moment(this.entity.openingDate) : undefined,
            format: 'DD/MM/YYYY',
            colConf: {
                span: 12
            }
        };

        let closingDate = {
            name: 'closingDate',
            label: __b('stores.fields.closingDate'),
            type: 'date',
            required: false,
            defaultValue: this.entity.closingDate ? moment(this.entity.closingDate) : undefined,
            value: this.entity.closingDate ? moment(this.entity.closingDate) : undefined,
            format: 'DD/MM/YYYY',
            colConf: {
                span: 12
            }
        };

        let phaseOutDate = {
            name: 'phaseOutDate',
            label: __b('stores.fields.phaseOutDate'),
            type: 'date',
            required: false,
            defaultValue: this.entity.phaseOutDate ? moment(this.entity.phaseOutDate) : undefined,
            value:  this.entity.phaseOutDate ? moment(this.entity.phaseOutDate) : undefined,
            format: 'DD/MM/YYYY',
            colConf: {
                span: 12
            }
        };

        let phaseOut = {
            name: 'phaseOut',
            label: __b('stores.fields.phaseOut'),
            type: 'select',
            required: false,
            defaultValue: this.entity.phaseOut,
            value: this.entity.phaseOut,
            selectValue: [
                {label: __b ( "warranties.fields.values.yes" ), value: 'true'},
                {label: __b ( "warranties.fields.values.no" ), value: 'false'}
            ],
            colConf: {
                span: 12
            }
        };

        let soldToParty = {
            name: 'soldToParty',
            label: __b('stores.fields.soldToParty'),
            type: 'text',
            required: false,
            defaultValue: this.entity.soldToParty,
            value: this.entity.soldToParty,
            colConf: {
                span: 12
            }
        };

        let responsabilityArea = {
            name: 'responsibilityArea',
            label: __b('stores.fields.responsabilityArea'),
            type: 'text',
            required: false,
            defaultValue: this.entity.responsibilityArea,
            value: this.entity.responsibilityArea,
            colConf: {
                span: 12
            }
        };

        let masterCodeCode = {
            name: 'masterCodeCode',
            label: __b('stores.fields.masterCodeCode'),
            defaultValue: this.entity.masterCodeCode,
            value: this.entity.masterCodeCode,
            type: 'text',
            required: false,
            colConf: {
                span: 12
            }
        };

        let masterCodeName = {
            name: 'masterCodeName',
            label: __b('stores.fields.masterCodeName'),
            defaultValue: this.entity.masterCodeName,
            value: this.entity.masterCodeName,
            type: 'text',
            required: false,
            colConf: {
                span: 12
            }
        };

        let soldTo = {
            name: 'sold_to',
            label: __b('stores.fields.soldTo'),
            defaultValue: this.entity.attributes?.sold_to ?? undefined ,
            value: this.entity.attributes?.sold_to ?? undefined,
            type: 'text',
            required: false,
            colConf: {
                span: 12
            }
        };

        let shipTo = {
            name: 'shipTo',
            label: __b('stores.fields.shipTo'),
            type: 'text',
            required: false,
            colConf: {
                span: 12
            }
        };

        // this.entity.hierarchy ? {label: this.entity.hierarchy.description, value: this.entity.hierarchy.id} : undefined
        let operatingCountry = {
            name: 'operatingCountry',
            label: __b('stores.fields.operatingCountry'),
            type: 'autocompleteselect',
            required: false,
            initialValue: this.entity.hierarchy ? {label: this.entity.hierarchy.description, value: this.entity.hierarchy.id } : undefined,
            value: this.entity.hierarchy ? {label: this.entity.hierarchy.description, value: this.entity.hierarchy.id } : undefined,
            mode: 'multiple',
            fetchOptions: (key: string) => (fetchCountry(key)),
            colConf: {
                span: 24
            }
        };

        let areaDefaultEmail = {
            name: 'areaDefaultEmail',
            label: __b('stores.fields.areaDefaultEmail'),
            initialValue: this.entity.attributes?.Aura_DefaultStore_Email ?? undefined,
            value:  this.entity.attributes?.Aura_DefaultStore_Email ?? undefined,
            type: 'text',
            required: false,
            colConf: {
                span: 24
            }
        };

        // cambio grandezza campo per Store e StoreType
        let indexStore = fields[0]['fields'].findIndex( (store: any) => store.name === 'description');
        fields[0]['fields'][indexStore].colConf.span = 12;

        let indexStoreType = fields[0]['fields'].findIndex( (store: any) => store.name === 'externalCode');
        fields[0]['fields'][indexStoreType].colConf.span = 12;
        fields[0]['fields'][indexStoreType].selectValue = [
            { label: __b ( "warranties.fields.values.all" ), value: '' },
            { label: "Ownership", value: 'ownership' },
            { label: "Wholesale", value: 'wholesale' },
            { label: "Travel Retail", value: 'travel retail' },
            { label: "Franchisee", value: 'franchisee' },
            { label: "Service Center", value: 'service center' },
            { label: "Family and Friends", value: 'family and friends' },
            { label: "Vintage", value: 'vintage' },
            { label: "Online", value: 'online' }
        ];

        fields[0]['fields'][indexStoreType].initialValue = this.entity.externalCode ? this.entity.externalCode : undefined;
        fields[0]['fields'][indexStoreType].value = this.entity.externalCode ? this.entity.externalCode : undefined;

        // Aggiungo i campi in ordine per la verticalizzazione
        fields[0]['fields'].splice(0, 0, code);
        fields[0]['fields'].splice(1, 0, telephone);
        fields[0]['fields'].splice(3, 0, fields[0]['fields'][5]);
        fields[0]['fields'].splice(4, 0, state);
        fields[0]['fields'].splice(5, 0, bvlAfss);
        fields[0]['fields'].splice(6, 0, openingDate);
        fields[0]['fields'].splice(7, 0, closingDate);
        fields[0]['fields'].splice(8, 0, phaseOutDate);
        fields[0]['fields'].splice(9, 0, phaseOut);
        fields[0]['fields'].splice(10, 0, soldToParty);
        fields[0]['fields'].splice(11, 0, responsabilityArea);
        fields[0]['fields'].splice(12, 0, masterCodeCode);
        fields[0]['fields'].splice(13, 0, masterCodeName);
        fields[0]['fields'].splice(14, 0, soldTo);
        fields[0]['fields'].splice(15, 0, shipTo);
        fields[0]['fields'].splice(16, 0, operatingCountry);
        fields[0]['fields'].splice(17, 0, areaDefaultEmail);
        fields[0]['fields'].splice(14, 0, fields[0]['fields'][18]);
        fields[0]['fields'].splice(18, 0, fields[0]['fields'][20]);
        fields[0]['fields'].splice(20, 3);

        return fields;
    }


    addFieldsTosearch = (fields: any[], module :any) => {

        // removed override fields
        fields[0]['fields'].splice(2, 2);

        fields[0]['fields'].push(
            {
                name: 'externalCode',
                label: __('stores.fields.storeType'),
                type: 'select',
                required: false,
                selectValue: [
                    { label: __b ( "warranties.fields.values.all" ), value: '' },
                    { label: "Ownership", value: 'ownership' },
                    { label: "Wholesale", value: 'wholesale' },
                    { label: "Travel Retail", value: 'travel retail' },
                    { label: "Franchisee", value: 'franchisee' },
                    { label: "Service Center", value: 'service center' },
                    { label: "Family and Friends", value: 'family and friends' },
                    { label: "Vintage", value: 'vintage' },
                    { label: "Online", value: 'online' }
                ],
                colConf: {
                    span: 6
                }
            },
            {
                name: 'activationStatus',
                label: __('stores.fields.activationStatus'),
                type: 'select',
                required: false,
                selectValue: [
                    {label: __b ( "warranties.fields.values.all" ), value: 'all'},
                    {label: __b ( "warranties.fields.values.notActivated" ), value: 'NOT_ACTIVATED'},
                    {label: __b ( "warranties.fields.values.pending" ), value: 'PENDING'},
                    {label: __b ( "warranties.fields.values.activated" ), value: 'ACTIVATED'}
                ],
                colConf: { span: 6 }
            },
            {
                name: 'store',
                label: __('stores.fields.nameCodeStore'),
                type: 'autocompleteselect',
                required: false,
                mode: 'multiple',
                fetchOptions: (key: string) => (this.fetchStores(key)),
                colConf: {
                    span: 6
                }
            },
            {
                name: 'hierarchy',
                label: __('stores.fields.operatingCountry'),
                type: 'autocompleteselect',
                required: false,
                mode: 'multiple',
                fetchOptions: (key: string) => (this.fetchHierarchies(key)),
                colConf: {
                    span: 6
                }
            },
            {
                name: 'sold_to',
                label: __('stores.fields.sold_to'),
                type: 'autocompleteselect',
                required: false,
                mode: 'multiple',
                fetchOptions: (key: string) => (this.fetchSoldToParty(key)),
                colConf: {
                    span: 6
                }
            },
            {
                name: 'ship_to',
                label: __('stores.fields.ship_to'),
                type: 'autocompleteselect',
                required: false,
                mode: 'multiple',
                fetchOptions: (key: string) => (this.fetchStoresCompanyCode(key)),
                colConf: {
                    span: 6
                }
            }
        );

        return fields;
    }

    addToTableColumns = (cols: any[]) => {
        cols.splice ( 2, 0,
            {
                title: __('stores.columns.activation'),
                dataIndex: 'activation',
                key: 'activation',
                sorter: true
            },
            {
                title: __('stores.columns.operationCountry'),
                dataIndex: 'operationCountry',
                key: 'operationCountry',
                sorter: true
            },
            {
                title: __('stores.columns.ISOCode'),
                dataIndex: 'ISOCode',
                key: 'ISOCode'
            },
            {
                title: __('stores.columns.soldTo'),
                dataIndex: 'soldTo',
                key: 'soldTo'
            },
            {
                title: __('stores.columns.status'),
                dataIndex: 'status',
                key: 'status'
            },
         )

        return cols
    }

    addDataToTableRow = (row: any, data: any) => {
        return {
            id: data.id,
            description: <div> <strong>{data.code} - {data.description}</strong> <div>{data.address} </div> </div>,
            storeType: data.externalCode,
            activation: data.activationDate ? DateUtils.formatDate ( data.activationDate ) : this.activateButtonListing(data),
            operationCountry: data?.hierarchy?.description,
            ISOCode: data?.hierarchy?.isoCode,
            soldTo: data?.attributes?.sold_to,
            status: data.status
        }
    }

    activateButtonListing = (data: any) => {
        return <>
            <Button onClick={() => this.showModal(data)}>{__b("stores.details.activate")}</Button>
        </>
    }

    showModal = (data: any) => {
        console.log(data);
        return warningModal ( {
            title: __b ( "stores.details.askActivation" ),
            content: <>{__b ( "stores.details.askActivationDescription" )} <br></br> {data?.code} - {data?.description}</>,
            onOk: args => {
                this.activateSmartphone(data).then( d => {
                    closeModal();
                    navigate(`/stores/${data.id}`);
                });
            },
            closable: true
        } );
    }

    getModuleKey = (): string => {
        return "bu-stores";
    }

    fetchStores = async (key: string) => {
        return await fetchStores(key)
    }

    fetchHierarchies = async (key: string) => {
        return await fetchHierarchies(key)
    }

    fetchStoresCompanyCode = async (key: string) => {
        return await fetchStoresCompanyCode(key)
    }

    fetchSoldToParty = async (key: string) => {
        return await fetchSoldToParty(key)
    }
}

const storeModule = new StoreModule();
export default storeModule;


export const fetchStores = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();
    const moduleManager = DWEnvironmentManager.getModuleManager();
    const storeModule = moduleManager.getModule('stores');

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, storeModule.getModuleKey())
        .then((response) =>
            response.map((res: any) => ({
                label: `${res.code} - ${res.description}`,
                value: `${res.code}-${res.description}`,
            })),
        );
}

export const fetchStoresCompanyCode = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();
    const moduleManager = DWEnvironmentManager.getModuleManager();
    const storeModule = moduleManager.getModule('stores');

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, storeModule.getModuleKey())
        .then ( ( response ) =>
           storesFiltered(response)
        );
}

export const storesFiltered = (response: any) => {
    let filtered = response.filter(( res: any ) => res.masterCodeCode );

    return filtered.map ( ( res: any ) => ( {
        label: res?.masterCodeCode,
        value: res?.masterCodeCode
    } ) )
}

export const fetchHierarchies = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, 'hierarchies')
        .then((response) =>
            response.map((res: any) => ({
                label: res.description,
                value: res.code,
            })),
        );
}

export const fetchCountry = async (key: string) => {
    const provider = DWEnvironmentManager.getDataProvider();
    const moduleManager = DWEnvironmentManager.getModuleManager();
    const hierarchiesModule = moduleManager.getModule('hierarchies');

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, hierarchiesModule.getModuleKey())
        .then ( ( response ) =>
           ObjectsUtil.stringIncludesDescription(response, key)
        );
}
