import { ArrowLeftOutlined, DownloadOutlined, FilePdfOutlined, MailOutlined } from "@ant-design/icons"
import { Button, Card, Col, Divider, Form, Image, Modal, Row, Select, DatePicker } from "antd"
import Paragraph from "antd/lib/typography/Paragraph"
import Text from "antd/lib/typography/Text"
import Title from "antd/lib/typography/Title"

import UserCapability from "../../config/UserCapabilityConfig";
import { useEffect, useState } from "react"
import DWEnvironmentManager from "../../../services/DWEnvironmentManager"
import { successToast } from "../../../services/NotificationManager"
import { __ } from "../../../translations/i18n"
import DateUtils from "../../../utils/DateUtils"
import { __b } from "../../translations/i18n"
import { RangePickerProps } from "antd/lib/date-picker"
import moment from "moment";
import { errorToast } from "../../../services/NotificationManager";
import { call, CallRequestConfig } from "../../../shared/api";
import dWUserManager from "../../../services/DWUserManager"

interface Props {
    product: any[],
    goBack: () => any,
    downloadPdf: (data: any) => any,
    manualProduct: (data: any) => any
}

const SecondStep = (props: Props) => {

    const [product, setProduct] = useState<any>();
    const [creationDate, setCreationDate] = useState<any>();
    const [returnDate, setReturnDate] = useState<any>();
    const [isExpired, setIsExpired] = useState<boolean>();
    const [versionPdfOpen, setVersionPdfOpen] = useState<boolean>(false);
    const [manualProductOpen, setManualProductOpen] = useState<boolean>(false);
    const [emailPdfForm, setEmailPdfForm] = useState<boolean>(false);
    const [expirationDate, setExpirationDate] = useState<any>();
    const [newExpirationDate, setNewExpirationDate] = useState<any>();
    const configCapability = UserCapability.getUserCapability();
    const storage = DWEnvironmentManager.getStorage();


    useEffect(() => {
        if (props.product.length === 1) {
            setProduct(props.product[0]);
        }
    }, []);

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        return current <= moment(expirationDate, "DD/MM/YYYY")
    };

    useEffect(() => {

        if (product) {
            setCreationDate(DateUtils.formatDateWithoutTime(product.value.creationDate));
            setReturnDate(DateUtils.formatDateWithoutTime(product.value.returnDate));
            setExpirationDate(DateUtils.formatDateWithoutTime(product.value.expirationDate));
            if (new Date(product.value.expirationDate).getTime() < new Date().getTime()) {
                setIsExpired(true);
            }
        }

    }, [product])

    const downloadPdf = async () => {
        // download pdf
        const provider = DWEnvironmentManager.getDataProvider()

        // return pdf
        const req = {
            params: Object.assign({}, {
                warranty: product.value.id
            }),
            timeout: 200000,
            responseType: "arraybuffer"
        };
        await provider.export(req, "warranties/pdf");

    }

    const sendEmailPdf = async (data: any) => {
        // send Email for pdf
        const provider = DWEnvironmentManager.getDataProvider();
        const res = await provider.addOne({ data: data.email }, `/mail/send/warranty?warranty=${product.value.id}`);
        if (res) {
            setVersionPdfOpen(false);
            successToast(__('common.success'), __b('status.sendEmailSuccess'));
        }
    }

    const downloadInstructions = async () => {
        window.open(`${DWEnvironmentManager.getStoragePath()}/assets/pdf/${product.detail.attributes?.manual.value}`);
    }

    const downloadCare = async () => {
        window.open(`${DWEnvironmentManager.getStoragePath()}/assets/pdf/${product.detail.attributes?.brochure.value}`);
    }

    const changeExpirationDate = async () => {
        if (!!newExpirationDate) {
            const baseUrl = DWEnvironmentManager.getStoragePath();
            const method = 'POST';
            const path = 'tmr-api/bulgaridw-web/warranties/admin/updateExpiration';
            const request: CallRequestConfig<any> = {
                params: {
                    warrantyId: product.value.id,
                    newDate: newExpirationDate.replaceAll("/", "-")
                },
                baseURL: baseUrl
            };
            const res = await call<any>(method, path, request);
            if ((res !== undefined) && (res !== null) && (!!res.expirationDate)) {
                setExpirationDate(DateUtils.formatDateWithoutTime(res.expirationDate))
                successToast(__('common.success'), __b('warranties.changeExpiration.success'));
            } else {
                errorToast(__b('warranties.changeExpiration.error'), __b('warranties.changeExpiration.errorDescription'))
            }
        }
    }

    const getDate = (val: any) => {
        if (!!val) {
            setNewExpirationDate(DateUtils.formatDateWithoutTime(val.toDate()));

        } else {
            setNewExpirationDate(null);
        }
    }



    return (
        <>
            <Row gutter={[24, 24]}>
                {props.product?.length > 1 && !product && (
                    <Col span={24} style={{ textAlign: "center", marginTop: 20 }}>
                        <Title level={3}>{__b('status.choiceProduct')}</Title>
                    </Col>)}
                {props.product?.length > 1 && !product && props.product.map((prod: any) =>
                    <Col span={8} style={{ marginTop: 20 }}>
                        <Card hoverable={true} onClick={() => setProduct(prod)} style={{ minHeight: 670 }}>
                            <Col span={24}>
                                <Title level={4}>{prod.value?.item?.upc}</Title>

                                <Title level={4}>{prod.detail?.attributes?.AestheticLine?.valueDescription}</Title>
                            </Col>
                            <Image fallback={DWEnvironmentManager.getNotFoundImg()} preview={false} src={`${DWEnvironmentManager.getStoragePath()}assets/images/imported/${prod.value?.item?.upc}.jpg`} style={{ marginBottom: 20 }} />
                            <Title level={5}>{__('common.description')}</Title>
                            <Paragraph>{prod.detail.attributes?.longDescription?.valueDescription}</Paragraph>
                        </Card>
                    </Col>
                )}
                {product &&
                    <>
                        <Col span={24} style={{ textAlign: "center" }}>
                            <Title level={3}>{__b('status.summaryProduct')}</Title>
                            <Title level={4}>{product.detail?.attributes?.AestheticLine?.valueDescription}</Title>
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Row gutter={[24, 24]}>
                                    <Col span={8}>
                                        <Divider style={{ fontSize: 20 }}>{__b('status.imageProduct')}</Divider>
                                        <Image fallback={DWEnvironmentManager.getNotFoundImg()} src={`${DWEnvironmentManager.getStoragePath()}assets/images/imported/${product.value?.item?.upc}.jpg`} />
                                    </Col>
                                    <Col span={8}>
                                        <Divider style={{ fontSize: 20 }}>{__b('status.infoProduct')}</Divider>
                                        <Paragraph>
                                            <Text>{__b('status.codeSAP')}</Text>
                                            <Text strong> {product.detail.code}</Text>
                                        </Paragraph>
                                        <Paragraph>
                                            <Text>{__b('status.fields.serial')}</Text>
                                            <Text strong> {product.value.item.serialNumber}</Text>
                                        </Paragraph>
                                        <Paragraph>
                                            <Text strong> {product.detail?.attributes?.AestheticLine?.valueDescription}</Text>
                                        </Paragraph>
                                        {product.detail?.attributes?.manual && <Button onClick={() => setManualProductOpen(true)} size={"large"}>{__b('status.manualProduct')}</Button>}
                                    </Col>
                                    <Col span={8}>
                                        <Divider style={{ fontSize: 20 }}>{__b('status.infoActivation')}</Divider>
                                        <Title level={5} type={"secondary"}>{__b('status.activationDate')}</Title>
                                        <Paragraph><strong><Text delete={isExpired}>{creationDate}</Text></strong></Paragraph>
                                        <Title level={5} type={"secondary"}>{__b('status.store')}</Title>
                                        <Paragraph><strong><Text >{product.value.creationStore.code} - {product.value.creationStore.description} ({product.value.creationStore.externalCode})</Text></strong></Paragraph>
                                        <Title level={5} type={"secondary"}>{__b('status.user')}</Title>
                                        <Paragraph><strong><Text >{product.value.createdBy.name} {product.value.createdBy.surname}</Text></strong></Paragraph>
                                        {(product.value.returnDate !== null) && (product.value.returnDate !== undefined) &&
                                            <><Title level={5} type={"secondary"}>{__b('status.returnDate')}</Title>
                                                <Paragraph><strong><Text>{returnDate}</Text></strong></Paragraph>
                                                <Title level={5} type={"secondary"}>{__b('status.store')}</Title>
                                                <Paragraph><strong><Text>{product.value.returnStore.code} - {product.value.returnStore.description} ({product.value.returnStore.externalCode})</Text></strong></Paragraph>
                                                <Title level={5} type={"secondary"}>{__b('status.user')}</Title>
                                                <Paragraph><strong><Text>{product.value.returnedBy.name} {product.value.returnedBy.surname}</Text></strong></Paragraph>
                                            </>
                                        }
                                        <Title level={5} type={"secondary"}>{__b('return.duration')}</Title>
                                        <Paragraph><Text>{DateUtils.getDurationWarranty(product.value.creationDate, product.value.expirationDate)}</Text></Paragraph>
                                        {configCapability.bulgari_store_after_sales.accessSalesPerson &&
                                            <>
                                                <br />
                                                <Divider style={{ fontSize: 20 }}>{__b('status.infoExpiryDate')}</Divider>
                                                <Title level={5} type={"secondary"}>{__b('status.expiryDate')}</Title>
                                                <Paragraph><strong><Text delete={isExpired}>{expirationDate}</Text></strong></Paragraph>
                                                {dWUserManager.hasCapability('warrantyExtension') &&
                                                    <>
                                                        <Title level={5} type={"secondary"}>{__b('status.changeExpiryDate')}</Title>
                                                        <Row>
                                                            <DatePicker defaultPickerValue={moment(expirationDate, "DD/MM/YYYY")} disabledDate={disabledDate} onChange={getDate} />
                                                            <Button onClick={changeExpirationDate}> {__b('status.confirmChangeExpiryDate')}</Button>
                                                        </Row>
                                                    </>
                                                }
                                            </>
                                        }
                                    </Col>
                                    {isExpired &&
                                        <>
                                            <Col span={24}>
                                                <Divider style={{ fontSize: 20, marginTop: 50 }}><Title level={4} type={"danger"}>{__b('status.warrantyExpired')}</Title></Divider>
                                            </Col>
                                        </>}
                                </Row>
                            </Card>
                        </Col>


                    </>
                }
            </Row>
            <Row gutter={[24, 24]} justify={"center"} style={{ marginTop: 70 }}>
                <Col span={8}>
                    <Button onClick={props.goBack} block={true} size={"large"} type={"default"} style={{ marginBottom: 40 }} icon={<ArrowLeftOutlined />} >{__('common.goBack')}</Button>
                </Col>
                {product &&
                    <>
                        <Col span={8}>
                            <Button onClick={() => setVersionPdfOpen(true)} block={true} size={"large"} type="primary" icon={<FilePdfOutlined />} >{__b('status.versionPdf')}</Button>
                        </Col>
                    </>}
            </Row>
            {product && (
                <>
                    <Modal open={versionPdfOpen} centered footer={null} onCancel={() => setVersionPdfOpen(false)}>
                        <Row gutter={[24, 24]} justify="center">
                            <Col span={20} style={{ textAlign: "center", marginBottom: 40 }}>
                                <Title level={2} >{__b('status.versionPdfTitle')}</Title>
                                <Title level={2} >{product.detail.attributes?.AestheticLine?.valueDescription}</Title>
                            </Col>
                        </ Row>
                        {!emailPdfForm && (
                            <Row gutter={[24, 24]} justify="center">
                                <Col span={20} style={{ textAlign: "center" }}>
                                    <Paragraph><Text italic>{__b('status.versionPdfSubTitle')}</Text></Paragraph>
                                </Col>
                                <Col span={10} style={{ textAlign: "center" }}>
                                    <Button onClick={() => setEmailPdfForm(true)} block={true} size={"large"} icon={<MailOutlined />}>{__b('status.sendEmail')}</Button>
                                </Col>
                                <Col span={10} style={{ textAlign: "center" }}>
                                    <Button onClick={downloadPdf} block={true} size={"large"} type="primary" icon={<DownloadOutlined />}>{__b('status.downloadPdf')}</Button>
                                </Col>
                            </Row>
                        )}
                        {emailPdfForm && (
                            <Row gutter={[24, 24]} justify="center">
                                <Col span={20} style={{ textAlign: "center" }}>
                                    <Form layout="vertical" id={__b('status.fields.email')} onFinish={sendEmailPdf}>
                                        <Form.Item label={__b('status.fields.email')} name={__b('status.fields.email').toLowerCase()} rules={[{ required: true, message: __b('return.fields.required') }]}>
                                            <Select mode={"tags"} />
                                        </Form.Item>
                                    </Form>
                                </ Col>
                                <Col span={10} style={{ textAlign: "center" }}>
                                    <Button onClick={() => setEmailPdfForm(false)} block={true} size={"large"} type={"default"} icon={<ArrowLeftOutlined />}>{__('common.back')}</Button>
                                </Col>
                                <Col span={10} style={{ textAlign: "center" }}>
                                    <Button form={__b('status.fields.email')} htmlType="submit" block={true} size={"large"} type={"primary"} icon={<MailOutlined />}>{__b('status.sendEmail')}</Button>
                                </Col>
                            </Row>
                        )}
                    </Modal>
                    <Modal open={manualProductOpen} centered footer={null} onCancel={() => setManualProductOpen(false)}>
                        <Row gutter={[24, 24]} justify="center">
                            <Col span={20} style={{ textAlign: "center" }}>
                                <Title level={2}>{__b('status.manualProduct')}</Title>
                                <Title level={2} >{product.detail.attributes?.AestheticLine?.valueDescription}</Title>
                                <Paragraph><Text italic>{__b('status.manualProductSubTitle')}</Text></Paragraph>
                            </Col>
                            <Col span={10} style={{ textAlign: "center" }}>
                                <Button onClick={downloadInstructions} block={true} size={"large"} type={"primary"} icon={<DownloadOutlined />} >{__b('status.instructions')}</Button>
                            </Col>
                            <Col span={10} style={{ textAlign: "center" }}>
                                <Button onClick={downloadCare} block={true} size={"large"} type={"primary"} icon={<DownloadOutlined />} >{__b('status.care')}</Button>
                            </Col>
                        </Row>
                    </Modal>
                </>
            )}
        </>
    )
}
export default SecondStep;
