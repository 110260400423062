// eslint-disable-next-line import/no-anonymous-default-export
export default {
    app: {
        name: 'Temera数字保修',
        url: 'www.buccellati.com'
    },
    error: {
        generic_error: '通用错误',
        error: '错误',
        forbidden: '禁止',
        not_found: '未找到',
        unauthorized: '未经授权',
        invalid_credentials: '凭证无效！',
        duplicateKey: '重复键',
        api: 'API错误 {{method}} {{api}}:\n {{error_message}}',
        requiredRowEditTable: '表格必须至少包含一行',
        error_upload_file: '文件上传失败'
    },
    success: {
        confirm_delete: '成功删除项目',
        confirm_update: '成功更新',
        confirm_password: '密码已更改，现在您可以从应用程序登录'
    },
    warning: {
        ask_delete: '确定要删除该元素吗？',
        unreversible_delete: '删除将无法恢复',
        warning: '警告'
    },
    dashboard: {
        module_name: '仪表板'
    },
    common: {
        confirm: '确认',
        cancel: '取消',
        save: '保存',
        reset: '重置',
        search: '搜索',
        table: {
            total: '共 {{total}} 个结果',
            export: '导出',
            changeMe: '更改我',
            key: '键',
            value: '值',
            attributes: '属性',
            addRow: '添加行',
            keyDescription: '键描述',
            valueDescription: '值描述'
        },
        edit: '编辑',
        details: '详情',
        addRow: '添加行',
        delete: '删除',
        success: '成功',
        fieldRequired: '必填字段',
        api: 'API',
        email: '电子邮件',
        goBack: '返回',
        goAhead: '前进',
        description: '描述',
        import_success: '文件上传成功',
        click_upload: '点击上传文件'
    },
    menu: {
        dashboard: '仪表板',
        users: '用户',
        stores: '商店',
        products: '产品',
        logout: '注销',
        digital_warranties: '数字保修',
        settings: '设置',
        about: '关于',
        modal: {
            copyright: '版权 © 2015 Temera。保留所有权利'
        },
        controlPanel: '控制面板'
    },
    login: {
        username: '用户名',
        password: '密码',
        confirm_password: '确认密码',
        sign_in: '登录',
        mandatory_username: '请输入您的用户名！',
        mandatory_password: '请输入您的密码！',
        mandatory_email: '请输入您的电子邮件！',
        mandatory_confirm_password: '请确认您的密码！',
        forgot_password: '忘记密码？',
        remember_me: '记住我',
        failed: '认证失败',
        recover_password_title: '填写下面的表格以重置您的密码',
        recover_password_subtitle: '您将收到包含重置密码链接的电子邮件',
        recover_success: '邮件发送成功',
        activation_user_title: '设置您的访问密码',
        activation_user_subtitle: '此操作仅在第一次登录时需要',
        header: '数字保修与真实性',
        fields: {
            username: '用户名',
            email: '电子邮件',
            send: '发送'
        },
        placeholder: {
            input_password: '输入密码',
            input_confirm_password: '再次输入密码'
        }
    },
    loginSSO: {
        label: '使用您的帐户登录',
        sso_button: 'SSO登录',
        with_credentials: '使用凭据登录'
    },
    module: {
        listing: {
            title: '管理{{moduleName}}'
        },
        add: {
            title: '添加{{entityName}}'
        },
        edit: {
            title: '编辑{{entityName}}'
        },
        details: {
            title: '关于{{entityName}}的详细信息'
        },
        upload: {
            title: '上传'
        }
    },
    users: {
        module_name: '用户',
        entity: {
            singular_name: '用户',
            plural_name: '用户'
        },
        details: {
            store: '商店',
            email: '电子邮件'
        },
        columns: {
            details: '详情',
            store: '商店',
            modified: '修改',
            created: '创建',
            surname: '姓氏',
            name: '名字',
            admin: '管理员',
            username: '用户名',
            personal: '个人',
            exception: '异常'
        },
        fields: {
            username: '用户名',
            surname: '姓氏',
            name: '名字',
            storeType: '商店类型',
            store: '商店',
            password: '密码',
            confirmPassword: '确认密码',
            email: '电子邮件',
            role: '角色',
            admin: '管理员',
            attributes: '属性',
            add: '添加用户',
            area: '区域',
            edit: '编辑用户',
            userSoldTo: '用户售给',
            errors: {
                username: '请输入用户名',
                password: '请输入密码'
            }
        },
        add: {
            success: '成功添加用户',
            errorPasswordMatch: '密码不匹配'
        },
        edit: {
            success: '成功编辑用户'
        }
    },
    prewarranties: {
        module_name: '预保修',
        entity: {
            singular_name: '预保修',
            plural_name: '预保修'
        }
    },
    hierarchies: {
        module_name: '区域',
        entity: {
            singular_name: '新区域',
            plural_name: '新区域'
        },
    },
    partnerinventory: {
        module_name: '库存'
    },
    warranties: {
        module_name: '保修',
        entity: {
            singular_name: '保修',
            plural_name: '保修'
        },
        columns: {
            details: '详情',
            store: '商店/用户 Ret',
            card: '更换卡',
            return: '退货',
            storeUserAct: '商店/用户 Act',
            activation: '激活',
            serialNumber: '序列号',
        },
        details: {
            image: '图像',
            product: '产品',
            date: '日期',
            store: '商店',
            user: '操作员',
            delete: '删除保修',
            activation: '激活',
            return: '退货',
            productPage: '产品页面',
            downloadPdf: '下载PDF'
        },
        fields: {
            from: '从',
            to: '到',
            serialNumber: '序列号',
            returned: '退货',
            storeType: '商店类型',
            store: '商店'
        }
    },
    stores: {
        module_name: '商店',
        entity: {
            singular_name: '商店',
            plural_name: '商店'
        },
        columns: {
            details: '详情',
            store: '商店',
            type: '类型',
            activation: '激活',
            operationCountry: '操作国家/地区',
            ISOCode: 'ISO代码',
            soldTo: '售给',
            status: '状态'
        },
        fields: {
            from: '从',
            to: '到',
            storeType: '商店类型',
            store: '商店',
            address: '地址',
            users: '用户',
            add: '添加商店',
            edit: '编辑商店',
            nameCodeStore: '名称/代码商店',
            activationStatus: '激活状态',
            operatingCountry: '操作国家/地区',
            sold_to: '售给',
            ship_to: '发货到',
            errors: {
                store: '请输入商店名称'
            }
        },
        details: {
            users: '用户',
            lastAccess: '最后访问：',
            active: '活跃',
            expectedDevices: '预期设备',
            usbDevice: 'USB NFC阅读器',
            mobileDevice: '智能手机'
        },
        add: {
            success: '商店创建成功'
        },
        edit: {
            success: '商店编辑成功！'
        }
    },
    masterdata: {
        module_name: '产品',
        entity: {
            singular_name: '产品',
            plural_name: '产品'
        }
    },
    settings: {
        set_system: '配置您的系统',
        save_system: '保存'
    },
    products: {
        columns: {
            details: '详情',
            shortDescription: '简短描述',
            description: '描述',
            creationDate: '创建日期',
            photo: '照片',
            marketingDescription: '营销描述',
            materialType: '材料类型',
            nfcEmbedded: '内置NFC',
        },
        fields: {
            id: 'ID',
            code: '代码',
            description: '描述',
            attributes: '属性',
            materialType: '材料类型',
            NFC: 'NFC已启用'
        },
        edit: {
            successEditDescription: '产品更新成功！'
        }
    },
    geolocation: {
        not_enabled: '地理位置未启用'
    },
    image: {
        masterdata_image: '产品图片'
    },
    landing: {
        identified_product: '已识别产品',
        sku_upc: 'SKU/UPC',
        serial: '序列号',
        activation_store: '激活商店',
        activation_date: '激活日期',
        information: '产品激活的详细信息',
        card: '降落卡'
    },
    profiles: {
        module_name: '配置文件',
        entity: {
            singular_name: '配置文件'
        }
    },
    'bu-manual': {
        module_name: '手册',
        entity: {
            singular_name: '手册'
        }
    }
}
