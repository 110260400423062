import {__} from "../../translations/i18n";
import {addFilter} from "../../services/HooksManager";
import SplashPage from "../pages/SplashPage";
import ParentModule from "../../shared/module/CrudModule";


class SplashModule extends ParentModule {

    init = () => {
        addFilter('app_routes', (routes: any) => {
            routes[this.getRoute()] = {component: SplashPage, visibility: 'public', props: {content: <SplashPage/>}};
            return routes;
        }, 10);
    }

    getModuleKey = (): string => {
        return "welcome";
    }

}

const splashModule = new SplashModule();
export default splashModule;
