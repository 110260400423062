import {Button, Form, Row} from "antd";
import React, {Component} from "react";
import fieldMap from './fields/fields';

export interface EditFormField {
    [key: string]: any
}

export interface EditRow {
    style?: {
        className: string
    },
    fields: EditFormField[]
}

interface Props {
    EditFormFields: EditRow[],
    onFilters: (e: any) => any
}

interface RowProps {
    row: EditRow
}

interface State {
}

class DynamicEditFormRow extends Component<{} & RowProps, State> {

    constructor(props: RowProps | Readonly<RowProps>) {
        super(props);
        this.state = {};
    }

    render() {
        const {row} = this.props;
        const {fields} = row;
        return (

            <Row>
                {
                    fields.map((field, index) => {

                        if (fieldMap[field.type] === undefined) {
                            throw Error("Field Type \"" + field.type + "\" not handled");
                        }

                        const EditFieldComponent = fieldMap[field.type];
                        const fieldProps = {
                            'field': field
                        };

                        return (
                            <EditFieldComponent key={'field_' + field.name + '_' + index} {...fieldProps} />
                        );
                    })
                }
            </Row>
        )
    }

}

export default class DynamicEditForm extends Component< {
}

& Props, State>
{

    constructor(props
:
    Props | Readonly<Props>
)
    {
        super(props);
        this.state = {};
    }

    render()
    {
        const {EditFormFields} = this.props;
        return (
            <div>
                <Form onFinish={(e) => this.props.onFilters(e)}>
                    {
                        EditFormFields.map((row, index) => {
                            return (
                                <DynamicEditFormRow key={'row-' + index} row={row}/>
                            );
                        })
                    }
                </Form>
            </div>
        )
    }
}




