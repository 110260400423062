import { Component } from "react";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { __ } from "../../translations/i18n";
import CrudForm from "../CrudForm";
import { FormRow } from "../form/DynamicForm";

interface Prop {
    entity: any,
    module: string,
    edit: (data: any) => any,
    getAttributes: (data: any) => any
}

interface State {
    product: any,
    dataTable?: any
}

class ProductEdit extends Component<Prop, State> {

    state = {
        product: {}
    }

    moduleManager = DWEnvironmentManager.getModuleManager();
    provider = DWEnvironmentManager.getDataProvider ();

    getEditFormFields = (): FormRow[] => {

        return [
            {
                fields: [
                    {
                        name: 'id',
                        label: __('products.fields.id'),
                        type: 'text',
                        value: this.props.entity.id,
                        defaultValue: this.props.entity.id,
                        required: false,
                        disabled: true,
                        colConf: {
                            span: 24
                        }
                    }
                ]
            },
            {
                fields: [
                    {
                        name: 'code',
                        label: __('products.fields.code'),
                        type: 'text',
                        value: this.props.entity.code,
                        defaultValue: this.props.entity.code,
                        required: false,
                        colConf: {
                            span: 24
                        }
                    }
                ]
            },
            {
                fields: [
                    {
                        name: 'description',
                        label: __('products.fields.description'),
                        type: 'text',
                        value: this.props.entity.description,
                        defaultValue: this.props.entity.description,
                        required: false,
                        colConf: {
                            span: 24
                        }
                    }
                ]
            }
        ];

    }

    getAttributes = (data: any) => {
        if (this.props.getAttributes) {
            this.props.getAttributes(data);
        }
        
        this.setState({dataTable: data});
    }

    getEditTable = () => {
        return {
            getAttributes: this.getAttributes,
            columnsTable: [
                {
                    title: __('common.table.key'),
                    dataIndex: 'key',
                    key: 'key'
                },
                {
                    title: __('common.table.keyDescription'),
                    dataIndex: 'keyDescription',
                    key: 'keyDescription'
                },
                {
                    title: __('common.table.value'),
                    dataIndex: 'value',
                    key: 'value'
                },
                {
                    title: __('common.table.valueDescription'),
                    dataIndex: 'valueDescription',
                    key: 'valueDescription'
                }
            ],
            formFields: [
                {
                    fields:[
                        { name: "key", label: __('common.table.key'), type: 'text', required: true, requiredMessage: __('common.fieldRequired'), colConf: {span: 12} },
                        { name: "keyDescription", label: __('common.table.keyDescription'), type: 'text', required: true, requiredMessage: __('common.fieldRequired'), colConf: {span: 12} },
                        { name: 'value', label: __('common.table.value'), type: 'text', required: true, requiredMessage: __('common.fieldRequired'), colConf: {span: 12} },
                        { name: 'valueDescription', label: __('common.table.valueDescription'), type: 'text', required: true, requiredMessage: __('common.fieldRequired'), colConf: {span: 12} },
                    ]
                }
            ],
            dataSource: this.getDataSource(),
            label: __('common.table.attributes'),
            labelButton: __('common.save'),
            required: false
        }
    }

    getDataSource = () => {
        if (this.props.entity.attributes !== null) {
            let attributes = [];
            for (const property in this.props.entity.attributes) {
                let obj = {key: property, keyDescription: this.props.entity.attributes[property].keyDescription, value: this.props.entity.attributes[property].value, valueDescription: this.props.entity.attributes[property].valueDescription};
                attributes.push(obj);
              }
              return attributes;
        }
        return [];
    }

    render() {
        const {module} = this.props;
        return (<CrudForm module={module} callback={this.props.edit} getFormFields={this.getEditFormFields} cardSize={20} type={"edit"} editTable={this.getEditTable()} />);
    }


 }
export default ProductEdit;