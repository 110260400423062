import {Component} from "react";
import {fetchStores} from "../../modules/StoreModule";
import {fetchRolesByKey} from "../../modules/UserModule";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import {applyActions} from "../../services/HooksManager";
import {__} from "../../translations/i18n";
import CrudForm from "../CrudForm";
import {FormRow} from "../form/DynamicForm";

interface Props {
    entity: any,
    module: string,
    edit: (data: any) => any,
    roles: any,
    getAttributes?: (data: any) => any,
    soldTo: any,
}

interface State {
    roles: any[],
    dataTable?: any,
    soldTo: any
}

export default class UserEdit extends Component<Props, State> {

    state: State = {
        roles: [],
        soldTo: undefined
    }

    provider = DWEnvironmentManager.getDataProvider ();

    async componentDidMount(): Promise<void> {
        const roles = await this.props.roles;
        // const soldTo = await this.props.soldTo;
        //addFilter('users_edit_on_change_state_fields', this.onChangeFilter);
        /*const soldToParty: any[] | Promise<any[]> = await fetchSoldToParties("").then(async res => {
            let resEdit: any[] = [];
            for await (const s of this.props.entity.soldTo) {
                for await (const r of res) {
                    let matching = new RegExp(s);
                    let sold = String(r.value).match(matching);
                    if (sold != null) {
                        resEdit.push(r);
                    }
                }
            }
            return resEdit
        });*/
        // console.log(soldTo);
        console.log('userEdit',roles);
        applyActions('user_edit_loaded_roles', roles);
        applyActions('user_edit_loaded_user_sold_to', this.props.entity.soldTo);
        // this.setState({roles: roles, soldTo: soldTo});
        this.setState({roles: roles});

    }

    getEditFormFields = (): FormRow[] => {
        console.log('edit form field', this.state.soldTo);
        return [
            {
                fields: [
                    { name: 'username', label:__('users.fields.username'), type: 'text', required: true, requiredMessage: __('users.fields.errors.username'), colConf: { span: 24 }, defaultValue: this.props.entity.username, value: this.props.entity.username },
                    { name: 'password', label:__('users.fields.password'), type: 'text', textType: 'password', required: false, requiredMessage: __('users.fields.errors.password'), colConf: { span: 12 } },
                    { name: 'confirmPassword', label:__('users.fields.confirmPassword'), type: 'text', textType: 'password', required: false, requiredMessage: __('users.fields.errors.password'), colConf: { span: 12 } },
                    {
                        name: 'email',
                        label:__('users.fields.email'),
                        type: 'select',
                        selectType:'email',
                        mode: 'tags',
                        required: false,
                        visibility: 'hidden',
                        colConf: { span: 24 },
                        initialValue: this.props.entity.attributes?.email?.split(','),
                    },
                    { name: 'name', label:__('users.fields.name'), type: 'text', required: false , colConf: { span: 12 } , defaultValue: this.props.entity.name, value: this.props.entity.name},
                    { name: 'surname', label:__('users.fields.surname'), type: 'text', required: false, colConf: { span: 12 }, defaultValue: this.props.entity.surname, value: this.props.entity.surname },
                    {
                        name: 'role',
                        label:__('users.fields.role'),
                        type: 'autocompleteselect',
                        required: false,
                        fetchOptions: (key: string) => (fetchRolesByKey(key)),
                        filterOption: (input: any, option: any) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase()),
                        initialValue: {label: this.props.entity.role?.description, value: this.props.entity.role?.id},
                        allowClear: true,
                        colConf: { span: 12 }
                    },
                    {
                        name: 'storeId',
                        label: __('users.fields.store'),
                        type: 'autocompleteselect',
                        required: false,
                        initialValue: this.checkEmail(),
                        fetchOptions: (key: string) => (fetchStores(key)),
                        colConf: { span: 12 },
                        allowClear: true
                    },
                    { name: 'admin', label:__('users.fields.admin'), type: 'checkbox', required: false ,value: this.props.entity.admin, colConf: { span: 12 } }
                ]
            }
        ];
    }

    /*onChangeFilter = (formFields: any, changedFields: any) => {
        console.log(this.state.soldTo);
        if (this.state.soldTo) {


            // indici campi
            const soldToIndex = formFields[0].fields.findIndex((f: any) => f.name === 'soldTo');

            // eliminare campo store types
            if (soldToIndex !== -1) {
                formFields[0].fields.splice(soldToIndex, {
                    name: 'soldTo',
                    label: __('users.fields.userSoldTo'),
                    type: 'autocompleteselect',
                    required: false,
                    mode: 'multiple',
                    fetchOptions: (key: string) => (fetchSoldToParties(key)),
                    initialValue: this.state.soldTo ?? undefined,
                    colConf: {
                        span: 24
                    },
                });
            }
        }

        return formFields;
    }*/

    getAttributes = (data: any) => {
        if (this.props.getAttributes) {
            this.props.getAttributes(data);
        }

        this.setState({dataTable: data});
    }

    getInitialValue = (selectedSoldTo: any[], soldToOptions: any[]) => {
        let resEdit: any[] = [];
        selectedSoldTo.forEach((s: string) => {
            soldToOptions.forEach( (r: any) => {
                let matching = new RegExp(s);
                let sold = String(r.value).match(matching);
                if (sold != null) {
                    resEdit.push(r);
                }
            })
        })
        return resEdit
    }

    getEditTable = () => {
        return {
            getAttributes: this.getAttributes,
            columnsTable: [
                {
                    title: __('common.table.key'),
                    dataIndex: 'key',
                    key: 'key'
                },
                {
                    title: __('common.table.value'),
                    dataIndex: 'value',
                    key: 'value'
                }],
            formFields: [
                {
                    fields:[
                        { name: "key", label: __('common.table.key'), type: 'text', required: true, requiredMessage: __('common.fieldRequired'), colConf: {span: 12} },
                        { name: 'value', label: __('common.table.value'), type: 'text', required: true, requiredMessage: __('common.fieldRequired'), colConf: {span: 12} },
                    ]
                }
            ],
            dataSource: this.getDataSource(),
            label: __('common.table.attributes'),
            labelButton: __('users.fields.edit'),
            required: false
        }
    }

    getDataSource = () => {
        if (this.props.entity.attributes !== null) {
            let attributes = [];
            for (const property in this.props.entity.attributes) {
                if (property !== "email") {
                    let obj = {key: property, value: this.props.entity.attributes[property]};
                    attributes.push(obj);
                }
            }
            return attributes;
        }
        return [];
    }

    checkEmail(): any{
        return this.props.entity.store ? {label: `${this.props.entity.store?.code} - ${this.props.entity.store?.description}`, value: this.props.entity.store?.id} : ""
    }

    render() {
        const {module} = this.props
        return (<CrudForm module={module} callback={this.props.edit} getFormFields={this.getEditFormFields} cardSize={12} type={"edit"} editTable={this.getEditTable()} entity={this.props.entity} />);
    }
}