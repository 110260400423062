// eslint-disable-next-line import/no-anonymous-default-export
export default {
    app: {
        name: 'ضمان ديجيتال تيميرا',
        url: 'www.buccellati.com'
    },
    error: {
        generic_error: 'خطأ عام',
        error: 'خطأ',
        forbidden: 'ممنوع',
        not_found: 'غير موجود',
        unauthorized: 'غير مصرح له',
        invalid_credentials: 'بيانات اعتماد غير صحيحة!',
        duplicateKey: 'مفتاح مكرر',
        api: 'خطأ في الAPI {{method}} {{api}} :\n {{error_message}}',
        requiredRowEditTable: 'يجب أن تحتوي الجدول على صف واحد على الأقل',
        error_upload_file: 'فشل تحميل الملف.'
    },
    success: {
        confirm_delete: "تم حذف العنصر بنجاح",
        confirm_update: "تم تحديث التغيير بنجاح",
        confirm_password: "تم تغيير كلمة المرور، يمكنك الآن تسجيل الدخول إلى التطبيق"
    },
    warning: {
        ask_delete: 'هل أنت متأكد أنك تريد حذف العنصر؟',
        unreversible_delete: "لن يمكن التراجع عن عملية الحذف",
        warning: 'تحذير'
    },
    dashboard: {
        module_name: 'لوحة التحكم'
    },
    common: {
        confirm: 'تأكيد',
        cancel: 'إلغاء',
        save: 'حفظ',
        reset: 'إعادة تعيين',
        search: 'بحث',
        table: {
            total: 'مجموع {{total}} نتائج',
            export: 'تصدير',
            changeMe: 'تغيير لي',
            key: 'مفتاح',
            value: 'قيمة',
            attributes: 'السمات',
            addRow: 'إضافة صف',
            keyDescription: 'وصف المفتاح',
            valueDescription: 'وصف القيمة'
        },
        edit: 'تحرير',
        details: 'تفاصيل',
        addRow: 'إضافة صف',
        delete: 'حذف',
        success: 'نجاح',
        fieldRequired: 'حقل مطلوب',
        api: 'واجهة برمجة التطبيقات',
        email: 'البريد الإلكتروني',
        goBack: 'العودة',
        goAhead: 'المضي قدماً',
        description: 'الوصف',
        import_success: 'تم تحميل الملف بنجاح',
        click_upload: 'انقر لتحميل ملف'
    },
    menu: {
        dashboard: 'لوحة التحكم',
        users: 'المستخدمين',
        stores: 'المتاجر',
        products: 'المنتجات',
        logout: 'تسجيل الخروج',
        digital_warranties: 'ضمانات ديجيتال',
        settings: 'الإعدادات',
        about: 'نبذة عن',
        modal: {
            copyright: 'حقوق النشر © 2015 تيميرا. جميع الحقوق محفوظة'
        },
        controlPanel: 'لوحة التحكم'
    },
    login: {
        username: 'اسم المستخدم',
        password: 'كلمة المرور',
        confirm_password: 'تأكيد كلمة المرور',
        sign_in: 'تسجيل الدخول',
        mandatory_username: 'الرجاء إدخال اسم المستخدم!',
        mandatory_password: 'الرجاء إدخال كلمة المرور!',
        mandatory_email: 'الرجاء إدخال بريدك الإلكتروني!',
        mandatory_confirm_password: 'الرجاء تأكيد كلمة المرور!',
        forgot_password: 'نسيت كلمة المرور؟',
        remember_me: 'تذكرني',
        failed: 'فشلت عملية المصادقة',
        recover_password_title: 'املأ النموذج أدناه لإعادة تعيين كلمة المرور',
        recover_password_subtitle: 'ستتلقى بريدًا إلكترونيًا يحتوي على رابط لإعادة تعيين كلمة المرور',
        recover_success: 'تم إرسال البريد بنجاح',
        activation_user_title: 'قم بتكوين كلمة المرور الخاصة بك',
        activation_user_subtitle: 'سيكون هذا مطلوبًا فقط عند الوصول الأول',
        header: 'ضمان ديجيتال وأصالة',
        fields: {
            username: 'اسم المستخدم',
            email: 'البريد الإلكتروني',
            send: 'إرسال'
        },
        placeholder: {
            input_password: 'أدخل كلمة المرور',
            input_confirm_password: 'أدخل كلمة المرور مرة أخرى'
        }
    },
    loginSSO: {
        label: 'تسجيل الدخول باستخدام حسابك',
        sso_button: 'تسجيل الدخول باستخدام SSO',
        with_credentials: 'تسجيل الدخول باستخدام بيانات الاعتماد الخاصة بك'
    },
    module: {
        listing: {
            title: 'إدارة {{moduleName}}'
        },
        add: {
            title: 'إضافة {{entityName}}'
        },
        edit: {
            title: 'تعديل {{entityName}}'
        },
        details: {
            title: 'تفاصيل {{entityName}}'
        },
        upload: {
            title: 'تحميل'
        }
    },
    users: {
        module_name: "المستخدمين",
        entity: {
            singular_name: "المستخدم",
            plural_name: "المستخدمين"
        },
        details: {
            store: 'المتجر',
            email: 'البريد الإلكتروني'
        },
        columns: {
            details: 'تفاصيل',
            store: 'المتجر',
            modified: 'تم التعديل',
            created: 'تم الإنشاء',
            surname: 'اللقب',
            name: 'الاسم',
            admin: 'مسؤول',
            username: 'اسم المستخدم',
            personal: 'شخصي',
            exception: 'استثناء'
        },
        fields: {
            username: 'اسم المستخدم',
            surname: 'اللقب',
            name: 'الاسم',
            storeType: 'نوع المتجر',
            store: 'المتجر',
            password: 'كلمة المرور',
            confirmPassword: 'تأكيد كلمة المرور',
            email: 'البريد الإلكتروني',
            role: 'الدور',
            admin: 'مسؤول',
            attributes: 'السمات',
            add: 'إضافة مستخدم',
            area: 'المنطقة',
            edit: 'تعديل المستخدم',
            userSoldTo: 'Sold To المستخدم',
            errors: {
                username: 'يرجى إدخال اسم المستخدم',
                password: 'يرجى إدخال كلمة المرور'
            }
        },
        add: {
            success: 'تمت إضافة المستخدم بنجاح',
            errorPasswordMatch: 'كلمتا المرور غير متطابقتين'
        },
        edit: {
            success: 'تم تعديل المستخدم بنجاح'
        }
    },
    prewarranties: {
        module_name: "التنشيطات المسبقة",
        entity: {
            singular_name: "التنشيط المسبق",
            plural_name: "التنشيطات المسبقة"
        },
    },
    hierarchies: {
        module_name: "المنطقة",
        entity: {
            singular_name: "منطقة جديدة",
            plural_name: "منطقة جديدة"
        },
    },
    partnerinventory: {
        module_name: "المخزون"
    },
    warranties: {
        module_name: "التنشيطات",
        entity: {
            singular_name: "التنشيط",
            plural_name: "التنشيطات"
        },
        columns: {
            details: 'تفاصيل',
            store: 'المتجر/مستخدم Ret',
            card: 'بطاقة الاستبدال',
            return: 'إرجاع',
            storeUserAct: 'المتجر/مستخدم Act',
            activation: 'التنشيط',
            serialNumber: 'الرقم التسلسلي',
        },
        details: {
            image: "صورة",
            product: "المنتج",
            date: "التاريخ",
            store: "المتجر",
            user: "المشغل",
            delete: "حذف التنشيط",
            activation: "التنشيط",
            return: "إرجاع",
            productPage: "صفحة المنتج",
            downloadPdf: "تنزيل PDF",
        },
        fields: {
            from: 'من',
            to: 'إلى',
            serialNumber: 'الرقم التسلسلي',
            returned: 'تم الإرجاع',
            storeType: 'نوع المتجر',
            store: 'المتجر'
        }
    },
    stores: {
        module_name: "المتاجر",
        entity: {
            singular_name: "المتجر",
            plural_name: "المتاجر"
        },
        columns: {
            details: 'تفاصيل',
            store: 'المتجر',
            type: 'النوع',
            activation: 'التنشيط',
            operationCountry: 'البلد/المنطقة التشغيلية',
            ISOCode: 'رمز ISO',
            soldTo: 'تم البيع إلى',
            status: 'الحالة'
        },
        fields: {
            from: 'من',
            to: 'إلى',
            storeType: 'نوع المتجر',
            store: 'المتجر',
            address: 'العنوان',
            users: 'المستخدمين',
            add: 'إضافة متجر',
            edit: 'تعديل المتجر',
            nameCodeStore: 'اسم/رمز المتجر',
            activationStatus: 'حالة التنشيط',
            operatingCountry: 'البلد/المنطقة التشغيلية',
            sold_to: 'تم البيع إلى',
            ship_to: 'الشحن إلى',
            errors: {
                store: 'يرجى إدخال اسم المتجر'
            }
        },
        details: {
            users: 'المستخدمين',
            lastAccess: 'آخر وصول: ',
            active: 'نشط',
            expectedDevices: 'الأجهزة المتوقعة',
            usbDevice: 'قارئ USB NFC',
            mobileDevice: 'هاتف ذكي'
        },
        add: {
            success: 'تم إنشاء المتجر بنجاح'
        },
        edit: {
            success: 'تم تعديل المتجر بنجاح!'
        }
    },
    masterdata: {
        module_name: "المنتجات",
        entity: {
            singular_name: "المنتج",
            plural_name: "المنتجات"
        }
    },
    settings: {
        set_system: 'ضبط النظام الخاص بك',
        save_system: "حفظ"
    },
    products: {
        columns: {
            details: 'تفاصيل',
            shortDescription: 'وصف مختصر',
            description: 'الوصف',
            creationDate: 'تاريخ الإنشاء',
            photo: 'الصورة',
            marketingDescription: 'وصف تسويقي',
            materialType: 'نوع المواد',
            nfcEmbedded: 'تم تضمين NFC',
        },
        fields: {
            id: 'المعرف',
            code: 'الرمز',
            description: 'الوصف',
            attributes: 'السمات',
            materialType: 'نوع المواد',
            NFC: 'تم تضمين NFC'
        },
        edit: {
            successEditDescription: 'تم تحديث المنتج بنجاح!'
        }
    },
    geolocation: {
        not_enabled: 'الموقع غير ممكّن'
    },
    image: {
        masterdata_image: "صورة المنتج"
    },
    landing: {
        identified_product: "المنتج المعرف",
        sku_upc: "SKU/UPC",
        serial: "الرقم التسلسلي",
        activation_store: "متجر التنشيط",
        activation_date: "تاريخ التنشيط",
        information: "مزيد من المعلومات حول تنشيط المنتج",
        card: "هبوط البطاقة"
    },
    profiles: {
        module_name: "الملفات الشخصية",
        entity: {
            singular_name: 'الملف الشخصي'
        }
    },
    'bu-manual': {
        module_name: "الدليل",
        entity: {
            singular_name: 'دليل'
        }
    }
}
