import { SaveOutlined } from "@ant-design/icons";
import { Component } from "react";
import CrudForm from "../../../components/CrudForm";
import { FormRow } from "../../../components/form/DynamicForm";
import DWEnvironmentManager from "../../../services/DWEnvironmentManager";
import { fetchParent } from "../../modules/AreaModule";
import { __b } from "../../translations/i18n";

interface Props {
    module: string,
    add: (data: any) => any
}

interface State {
    roles: any[]
}

export default class AreaAdd extends Component<Props, State>{
    state: State = {
        roles: []
    }

    moduleManager = DWEnvironmentManager.getModuleManager();
    
    getAddFormFields = (): FormRow[] => {
        return [
            {
                fields: [
                    {
                        name: 'description',
                        label: __b('warranties.fields.operatingCountry'),
                        type: 'text',
                        required: false,
                        requiredMessage: __b('warranties.fields.operatingCountry'),
                        colConf: { span: 12 }
                    },
                    {
                        name: 'parent',
                        label: __b('warranties.fields.parent'),
                        type: 'autocompleteselect',
                        required: false,
                        fetchOptions: (key: string) => (fetchParent(key)),
                        colConf: { span: 12 }
                    },
                    {
                        name: 'isoCode',
                        label: __b('warranties.fields.isoCode'),
                        type: 'text',
                        required: false,
                        mode: 'multiple',
                        colConf: {
                            span: 12
                        }
                    }
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    { name: 'addArea', label: __b('hierarchies.add'), type: 'button', htmlType: 'submit', block: 'true', icon: <SaveOutlined />, colConf: { span: 24 } }
                ]
            }
        ];
    }



    render() {
        const { module } = this.props
        return (<CrudForm module={module} callback={this.props.add} getFormFields={this.getAddFormFields} cardSize={12} type={"add"} />);
    }

}


export const fetchOneParent = async (id: string | number) => {

    const provider = DWEnvironmentManager.getDataProvider();
    return await provider.getOne(id, {}, 'hierarchies')
}
