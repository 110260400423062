// eslint-disable-next-line import/no-anonymous-default-export
export default {
    app: {
        name: 'Temera 디지털 보증',
        url: 'www.buccellati.com'
    },
    error: {
        generic_error: '일반 오류',
        error: '오류',
        forbidden: '금지됨',
        not_found: '찾을 수 없음',
        unauthorized: '권한 없음',
        invalid_credentials: '유효하지 않은 자격 증명!',
        duplicateKey: '중복된 키',
        api: 'API 오류 {{method}} {{api}}:\n {{error_message}}',
        requiredRowEditTable: '테이블에는 적어도 한 행이 있어야 합니다',
        error_upload_file: '파일 업로드에 실패했습니다.'
    },
    success: {
        confirm_delete: '항목이 성공적으로 삭제되었습니다',
        confirm_update: '성공적으로 업데이트되었습니다',
        confirm_password: '암호가 변경되었습니다. 앱에서 로그인할 수 있습니다.'
    },
    warning: {
        ask_delete: '정말로 이 항목을 삭제하시겠습니까?',
        unreversible_delete: '삭제를 취소할 수 없습니다',
        warning: '경고'
    },
    dashboard: {
        module_name: '대시보드'
    },
    common: {
        confirm: '확인',
        cancel: '취소',
        save: '저장',
        reset: '재설정',
        search: '검색',
        table: {
            total: '총 {{total}} 결과',
            export: '내보내기',
            changeMe: '나를 변경',
            key: '키',
            value: '값',
            attributes: '속성',
            addRow: '행 추가',
            keyDescription: '키 설명',
            valueDescription: '값 설명'
        },
        edit: '편집',
        details: '세부정보',
        addRow: '행 추가',
        delete: '삭제',
        success: '성공',
        fieldRequired: '필수 필드',
        api: 'API',
        email: '이메일',
        goBack: '뒤로 가기',
        goAhead: '앞으로 가기',
        description: '설명',
        import_success: '파일이 성공적으로 업로드되었습니다',
        click_upload: '파일을 업로드하려면 클릭하세요'
    },
    menu: {
        dashboard: '대시보드',
        users: '사용자',
        stores: '상점',
        products: '제품',
        logout: '로그아웃',
        digital_warranties: '디지털 보증',
        settings: '설정',
        about: '정보',
        modal: {
            copyright: '저작권 © 2015 Temera. 모든 권리 보유'
        },
        controlPanel: '제어판'
    },
    login: {
        username: '사용자 이름',
        password: '암호',
        confirm_password: '암호 확인',
        sign_in: '로그인',
        mandatory_username: '사용자 이름을 입력하세요!',
        mandatory_password: '암호를 입력하세요!',
        mandatory_email: '이메일을 입력하세요!',
        mandatory_confirm_password: '암호를 확인하세요!',
        forgot_password: '비밀번호를 잊으셨나요?',
        remember_me: '날 기억해줘',
        failed: '인증 실패',
        recover_password_title: '암호를 재설정하려면 아래 양식을 작성하십시오',
        recover_password_subtitle: '암호를 재설정하는 링크가 포함된 이메일을 받게 됩니다',
        recover_success: '이메일이 성공적으로 전송되었습니다',
        activation_user_title: '접근 암호를 설정하세요',
        activation_user_subtitle: '이 작업은 처음 액세스 시에만 필요합니다',
        header: '디지털 보증 및 신뢰성',
        fields: {
            username: '사용자 이름',
            email: '이메일',
            send: '보내기'
        },
        placeholder: {
            input_password: '암호 입력',
            input_confirm_password: '암호 다시 입력'
        }
    },
    loginSSO: {
        label: '귀하의 계정으로 로그인',
        sso_button: 'SSO로 로그인',
        with_credentials: '자격 증명으로 로그인'
    },
    module: {
        listing: {
            title: '{{moduleName}} 관리'
        },
        add: {
            title: '{{entityName}} 추가'
        },
        edit: {
            title: '{{entityName}} 편집'
        },
        details: {
            title: '{{entityName}} 세부정보'
        },
        upload: {
            title: '업로드'
        }
    },
    users: {
        module_name: "사용자",
        entity: {
            singular_name: "사용자",
            plural_name: "사용자"
        },
        details: {
            store: '상점',
            email: '이메일'
        },
        columns: {
            details: '세부정보',
            store: '상점',
            modified: '수정됨',
            created: '생성됨',
            surname: '성',
            name: '이름',
            admin: '관리자',
            username: '사용자 이름',
            personal: '개인',
            exception: '예외'
        },
        fields: {
            username: '사용자 이름',
            surname: '성',
            name: '이름',
            storeType: '상점 유형',
            store: '상점',
            password: '암호',
            confirmPassword: '암호 확인',
            email: '이메일',
            role: '역할',
            admin: '관리자',
            attributes: '속성',
            add: '사용자 추가',
            area: '영역',
            edit: '사용자 편집',
            userSoldTo: '사용자 판매 대상',
            errors: {
                username: '사용자 이름을 입력하세요',
                password: '암호를 입력하세요'
            }
        },
        add: {
            success: '사용자가 성공적으로 추가되었습니다',
            errorPasswordMatch: '암호가 일치하지 않습니다'
        },
        edit: {
            success: '사용자가 성공적으로 편집되었습니다'
        }
    },
    prewarranties: {
        module_name: "사전 보증",
        entity: {
            singular_name: "사전 보증",
            plural_name: "사전 보증"
        }
    },
    hierarchies: {
        module_name: "영역",
        entity: {
            singular_name: "새로운 영역",
            plural_name: "새로운 영역"
        },
    },
    partnerinventory: {
        module_name: "인벤토리"
    },
    warranties: {
        module_name: "보증",
        entity: {
            singular_name: "보증",
            plural_name: "보증"
        },
        columns: {
            details: '세부정보',
            store: '상점/사용자 Ret',
            card: '카드 교체',
            return: '반품',
            storeUserAct: '상점/사용자 Act',
            activation: '활성화',
            serialNumber: '일련 번호',
        },
        details: {
            image: "이미지",
            product: "제품",
            date: "날짜",
            store: "상점",
            user: "오퍼레이터",
            delete: "보증 삭제",
            activation: "활성화",
            return: "반품",
            productPage: "제품 페이지",
            downloadPdf: "PDF 다운로드"
        },
        fields: {
            from: '시작',
            to: '끝',
            serialNumber: '일련 번호',
            returned: '반품됨',
            storeType: '상점 유형',
            store: '상점'
        }
    },
    stores: {
        module_name: "상점",
        entity: {
            singular_name: "상점",
            plural_name: "상점"
        },
        columns: {
            details: '세부정보',
            store: '상점',
            type: '유형',
            activation: '활성화',
            operationCountry: '운영 국가/지역',
            ISOCode: 'ISO 코드',
            soldTo: '판매 대상',
            status: '상태'
        },
        fields: {
            from: '시작',
            to: '끝',
            storeType: '상점 유형',
            store: '상점',
            address: '주소',
            users: '사용자',
            add: '상점 추가',
            edit: '상점 편집',
            nameCodeStore: '상점 이름/코드',
            activationStatus: '활성화 상태',
            operatingCountry: '운영 국가/지역',
            sold_to: '판매 대상',
            ship_to: '배송 대상',
            errors: {
                store: '상점 이름을 입력하세요'
            }
        },
        details: {
            users: '사용자',
            lastAccess: '최근 접근:',
            active: '활성',
            expectedDevices: '예상 디바이스',
            usbDevice: 'USB NFC 리더',
            mobileDevice: '스마트폰'
        },
        add: {
            success: '상점이 성공적으로 생성되었습니다'
        },
        edit: {
            success: '상점이 성공적으로 편집되었습니다!'
        }
    },
    masterdata: {
        module_name: "제품",
        entity: {
            singular_name: "제품",
            plural_name: "제품"
        }
    },
    settings: {
        set_system: '시스템 설정',
        save_system: "저장"
    },
    products: {
        columns: {
            details: '세부정보',
            shortDescription: '간단한 설명',
            description: '설명',
            creationDate: '생성 날짜',
            photo: '사진',
            marketingDescription: '마케팅 설명',
            materialType: '재료 유형',
            nfcEmbedded: 'NFC 포함'
        },
        fields: {
            id: 'ID',
            code: '코드',
            description: '설명',
            attributes: '속성',
            materialType: '재료 유형',
            NFC: 'NFC 포함'
        },
        edit: {
            successEditDescription: '제품이 성공적으로 업데이트되었습니다!'
        }
    },
    geolocation: {
        not_enabled: '지오로케이션을 사용할 수 없습니다'
    },
    image: {
        masterdata_image: "제품 이미지"
    },
    landing: {
        identified_product: "식별된 제품",
        sku_upc: "SKU/UPC",
        serial: "일련 번호",
        activation_store: "활성화 상점",
        activation_date: "활성화 날짜",
        information: "제품 활성화에 대한 자세한 정보",
        card: "카드 랜딩"
    },
    profiles: {
        module_name: "프로필",
        entity: {
            singular_name: '프로필'
        }
    },
    'bu-manual': {
        module_name: "메뉴얼",
        entity: {
            singular_name: '메뉴얼'
        }
    }
}
