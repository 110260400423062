import dWUserManager from "../../services/DWUserManager";
import { addAction, addFilter } from "../../services/HooksManager";
import i18n from "../../translations/i18n";
import userCapability from "../config/UserCapabilityConfig";
import UserCapability from "../config/UserCapabilityConfig";

class BuUserManager {

    constructor() {
        addAction('action_after_login_pre_reload', this.setLanguageForUser);
        addFilter('user_has_capability', this.userHasCapability);
    }

    setLanguageForUser = () => {
        const user = dWUserManager.getSessionUser();

        if (user?.attributes?.DEFAULT_LANGUAGE) {
            i18n.changeLanguage(user?.attributes?.DEFAULT_LANGUAGE);
        }

    }

    // da gestire questione user attributes, se user.attributes ha massive la sezione è da abilitare. -> da effettuare controllo se capability è 'massive' allora controlliamo 
    userHasCapability(loggedUser: any, capability: any) {
        // ricerca capability in configurazione
        if (loggedUser.admin) {
            return true;
        }

        const roleUser = loggedUser?.role;
        const configCapability = UserCapability.getUserCapability();
        const role = configCapability[roleUser?.code];
        const cleanStringCapability = capability.replace(/[/]/g, "");


        if (cleanStringCapability === 'massiveActivation') {
            // ricerca in attributi utente per l'attivazione della sezione.
            if (loggedUser?.attributes?.massive_activations) {
                return true;
            } else {
                return false
            }
        } else if (cleanStringCapability === 'warrantyExtension') {
            return role.warrantyExtension;
        }

        // ricerca all'interno dell'array delle sezioni visibili
        let white = configCapability['whitelistPage'].findIndex((r: string) => r === cleanStringCapability);
        if (white !== -1) {
            return true;
        }
            
        let cap = role['sectionsVisible'].findIndex((r: string) => r === cleanStringCapability);

        if (cap !== -1) {
            return true;
        } else {
            return false;
        }

    }

    userCanAddUsers() {
        return userCapability.userCanAddUsers();
    }

    userCanAddStores() {
        return userCapability.userCanAddStores();
    }

    userCanEditStores() {
        return userCapability.userCanEditStores();
    }

    userCanEditUsers() {
        return userCapability.userCanEditUsers();
    }

    userCanAddWarranties() {
        return userCapability.userCanAddWarranties();
    }

    userCanAddPreWarranties() {
        return userCapability.userCanAddPreWarranties();
    }

    userCanDeletePreWarranties() {
        return userCapability.userCanDeletePreWarranties();
    }

}


const buUserManager = new BuUserManager();
export default buUserManager;
