import {FileExcelOutlined} from '@ant-design/icons';
import {message, Upload} from 'antd';
import React from 'react';
import {__b} from '../translations/i18n';
import UploadUtils from "../../utils/UploadUtils";

const MassiveActivation: React.FC = () => {

    const {Dragger} = Upload;

    const props = Object.assign({
        multiple: true,
        name: 'file',

        onChange: (info: any) => {

            const {status} = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(__b('massive_activation.success'));
            } else if (status === 'error') {
                message.error(__b('massive_activation.error'));
            }
        },

        onDrop: (e: any) => {
            console.log('Dropped files', e.dataTransfer.files);
        },
    }, UploadUtils.getUploadRequest('/warranties/createMassiveWarranty'));

    
    return (
        <Dragger {...props}>
            <p className="ant-upload-drag-icon"><FileExcelOutlined/></p>
            <p className="ant-upload-text"> {__b('massive_activation.name')}</p>
            <p className="ant-upload-hint"> {__b('massive_activation.label')}</p>
        </Dragger>
    )

}

export default MassiveActivation;