/* eslint-disable import/no-anonymous-default-export */
export default {
    app: {
        url: 'www.bulgari.com'
    },
    common: {
        close: 'Schließen'
    },
    menu: {
        digital_prewarranties: 'Voraktivierungen',
        areas: 'Bereiche',
        profiles: 'Berechtigungsprofil',
        manual: 'Handbücher Uhren',
        help: 'Hilfe',
        info: 'Info',
        massive_activations: 'Massenaktivierung',
        partnerinventories: 'Inventar'
    },
    warranties: {
        title: 'DW-Aktivierungen',
        columns: {
            photo: 'Foto',
            cardSequential: 'Sequenzielle Karte',
            sap: 'SAP-Code',
            description: 'Beschreibung',
            expiredDate: 'Ablaufdatum',
            activationType: 'Aktivierungstyp',
            activationUser: 'Aktivierungsbenutzer/Store',
            storeUser: 'Store/Benutzer Ret',
            substitution: 'Ersatz',
            serialNumber: 'Seriennummer',
            activation: 'Aktivierung',
            return: 'Rückgabe',
            returnUser: 'Rückgabebenutzer/Store',
            details: 'Details',
            operatingCountry: 'Land/Region der Operation',
            parent: 'Eltern',
            isoCode: 'ISO-Code',
            edit: 'Bearbeiten',
            returnDeactivate: 'Rückgabe/Deaktivierung',
            status: 'Status',
            storeCode: 'Store-Code'
        },
        fields: {
            sequentialCard: 'Sequenzielle Karte',
            sapCode: 'SAP-Code',
            serialNumber: 'Seriennummer',
            reso: 'Rückgabe',
            storeType: 'Store-Typ',
            materialType: 'Materialtyp',
            nameCodeStore: 'Name/Store-Code',
            operatingCountry: 'Land/Region der Operation',
            activationType: 'Aktivierungstyp',
            onBehalfOf: 'Im Namen von',
            masterCode: 'Mastercode',
            soldToParty: 'Verkauft an',
            responsibilityArea: 'Verantwortungsbereich',
            isoCode: 'ISO-Code',
            parent: 'Eltern',
            activationDate: 'Aktivierungsdatum',
            store: 'Store',
            user: 'Benutzer',
            add: 'Neues Produkt aktivieren',
            errors: {
                sapCode: 'Bitte geben Sie den SAP-Code ein!',
                activationDate: 'Wählen Sie ein Aktivierungsdatum aus!',
                serialNumber: 'Bitte geben Sie eine Seriennummer ein!',
                store: 'Bitte geben Sie einen Store ein!',
                user: 'Bitte geben Sie einen Benutzer ein!',
                sequentialCard: 'Bitte geben Sie eine sequenzielle Karte ein!',
            },
            values: {
                all: 'Alle',
                reso: 'Rückgabe',
                noReso: 'Keine Rückgabe',
                accessories: 'Zubehör',
                watches: 'Uhren',
                jewels: 'Schmuck',
                fineJewelry: 'Hochwertiger Schmuck',
                manuale: 'Handbuch',
                appManual: 'App-Handbuch',
                yes: 'Ja',
                no: 'Nein',
                internal: 'Intern',
                external: 'Extern',
                prospect: 'Aussicht',
                open: 'Geöffnet',
                closed: 'Geschlossen',
                phaseOut: 'Phase Out',
                future: 'Zukunft',
                notActivated: 'Nicht aktiviert',
                pending: 'Ausstehend',
                activated: 'Aktiviert'
            },
        },
        add: {
            success: 'Aktivierung erfolgreich hinzugefügt'
        },
        changeExpiration: {
            success: 'Ablaufdatum erfolgreich aktualisiert',
            error: 'Fehler bei der Aktualisierung',
            errorDescription: 'Das Ablaufdatum wurde nicht aktualisiert'
        },
        warning: 'Seriennummer und SAP-Code nicht kompatibel',
        error: {
            watchInvalidSerialTitle: 'Ungültige Seriennummer und SAP-Code',
            watchInvalidSerialDescription: 'Seriennummer und SAP-Code sind nicht für die ausgewählte Uhr konsistent.',
            warrantyAlreadyPresentTitle: 'Garantie bereits vorhanden',
            warrantyAlreadyPresentDescription: 'Die Garantie ist bereits für das ausgewählte Produkt vorhanden'
        }
    },
    prewarranties: {
        fields: {
            activationDate: 'Voraktivierungsdatum'
        },
        add: {
            success: 'Voraktivierung erfolgreich hinzugefügt'
        },
        details: {
            image: "Bild",
            product: "Produkt",
            date: "Datum",
            store: "Store",
            user: "Benutzer",
            delete: "Voraktivierung löschen",
            activation: "Aktivierung",
        },
        error: {
            prewarrantyAlreadyPresentTitle: 'Voraktivierung bereits vorhanden',
            prewarrantyAlreadyPresentDescription: 'Die Voraktivierung ist bereits für das ausgewählte Produkt vorhanden',
            checkSerialSAPErrorTitle: 'Seriennummer und SAP-Code nicht konsistent',
            checkSerialSAPErrorDescription: 'Die Seriennummer und der SAP-Code sind nicht konsistent.'
        }
    },
    partnerinventories: {
        fields: {
            inventoryDateFrom: 'Inventardatum von',
            inventoryDateTo: 'Inventardatum bis',
            sapCode: 'SAP-Code',
            serialNumbers: 'Seriennummern',
            store: 'Store',
            soldTo: 'Verkauft an',
            creationDateFrom: 'Erstellungsdatum von',
            creationDateTo: 'Erstellungsdatum bis',
            lastInventory: 'Letztes Inventar'
        },
        columns: {
            inventoryDate: 'Inventardatum',
            serialNumber: 'Seriennummer',
            store: 'Store',
            soldTo: 'Verkauft an',
            product: 'Produkt',
            creationDate: 'Erstellungsdatum',
            storeCode: 'Store-Code',
            quantity: 'Menge',
            sapCode: 'SAP-Code'
        },
        button: {
            upload: 'Inventar hochladen',
            download: 'Vorlagen herunterladen'
        }
    },
    hierarchies: {
        add: 'Neuen Bereich hinzufügen',
        success: 'Neuer Bereich erfolgreich hinzugefügt',
        entity: {
            singular_name: "Neuer Bereich",
            plural_name: "Neue Bereiche"
        },
        edit: {
            modify: 'Bereich bearbeiten',
            success: 'Bereich erfolgreich bearbeitet'
        }
    },
    settings: {
        choose_language: 'Sprache auswählen',
        language: 'Sprache'
    },
    stores: {
        fields: {
            code: "Code",
            telephone: "Telefon",
            state: "Status",
            bvlAfss: "BVL AFSS",
            openingDate: "Eröffnungsdatum",
            closingDate: "Schließungsdatum",
            phaseOutDate: "Phase-Out-Datum",
            phaseOut: "Phase Out",
            soldToParty: "Verkauft an",
            responsabilityArea: "Verantwortungsbereich",
            masterCodeCode: "Mastercode-Code",
            masterCodeName: "Name des Mastercodes",
            soldTo: "Verkauft an",
            shipTo: "Versand an",
            operatingCountry: "Land/Region der Operation",
            areaDefaultEmail: "Standard-E-Mail des Bereichs",
            nfcReader: "USB NFC-Reader-Geräte",
            smartphone: "Smartphone-Geräte"
        },
        details: {
            activateStore: "Store aktivieren",
            sendAgain: "Erneut senden",
            activateSmartphone: "Smartphone aktivieren",
            requestActivationSuccess: "Aktivierungsanfrage erfolgreich abgeschlossen",
            activationSuccess: "Store erfolgreich aktiviert!",
            activate: "Aktivieren",
            askActivation: "Aktivierung des Stores bestätigen",
            askActivationDescription: "Möchten Sie diesen Store wirklich aktivieren:"
        }
    },
    profiles: {
        columns: {
            profileName: 'Profilname',
            jobId: 'Job-ID',
            departmentId: 'Abteilungs-ID',
            jobDescription: 'Jobbeschreibung',
            departmentDescription: 'Abteilungsbeschreibung',
            storeType: 'Store-Typ',
            storeId: 'Name/Store-Code',
            dwArea: 'DW-Bereich',
            edit: 'Bearbeiten'
        },
        fields: {
            profileName: 'Profilname',
            jobId: 'Job-ID',
            departmentId: 'Abteilungs-ID',
            jobDescription: 'Jobbeschreibung',
            departmentDescription: 'Abteilungsbeschreibung',
            storeType: 'Store-Typ',
            storeId: 'Name/Store-Code',
            dwArea: 'DW-Bereich',
            add: 'Neues Profil hinzufügen',
            edit: 'Profil bearbeiten',
            delete: 'Profil löschen',
            created: 'Store erstellt'
        },
        upload: {
            label: "Sie können Ihre XLS-Dateien hochladen, um sie in Profile umzuwandeln.",
            name: "Klicken Sie hier, um die Datei hochzuladen oder ziehen Sie sie hierhin",
            error: "Fehler beim Hochladen der Datei.",
            success: "Datei wurde erfolgreich hochgeladen."
        }
    },
    manual: {
        columns: {
            photo: 'Foto',
            sapCode: 'SAP-Code',
            instruction: 'Handbuch',
            brochure: 'Broschüre',
        },
        fields: {
            sapCode: 'SAP-Code'
        }
    },
    help: {
        ask_support: 'UNTERSTÜTZUNG ANFORDERN',
        how_to_use: 'WIE MAN VERWENDET',
        how_to_use_video: 'Sehen Sie sich das folgende Video an, um eine schnelle Anleitung zur Verwendung der Digital Warranty-Funktionen zu erhalten',
        sent: 'E-Mail gesendet',
        not_sent: 'E-Mail nicht gesendet',
        sales_activate_watches: 'WIE MAN EIN VERKAUFSTAG ZUR AKTIVIERUNG VON UHREN VERWENDET',
        shipping_activate_watches: 'WIE MAN EIN VERSANDTAG ZUR AKTIVIERUNG VON SCHMUCK VERWENDET',
        sales_activate_accessories: 'WIE MAN EIN VERKAUFSTAG ZUR AKTIVIERUNG VON ACCESSOIRES VERWENDET',
        active_watches_type: 'Um Uhren zu aktivieren, geben Sie ein:',
        sap_code: 'SAP-CODE',
        serial_number: 'SERIENNUMMER'
    },
    splash: {
        welcome: "Willkommen bei",
        presentation: "DIGITAL WARRANTY & AUTHENTICITY ist eine Anwendung zur vollständig digitalen Verwaltung von Authentizität und Garantien. Treten Sie ein.",
        enter: 'Eingeben'
    },
    info: {
        header: {
            watches: 'UHREN - GARANTIEINFORMATIONEN',
            accessories: 'ACCESSOIRES - INFORMATIONEN ZUR AUTHENTIZITÄT',
            jewellery_pieces: 'SCHMUCKSTÜCKE - INFORMATIONEN ZU AUTHENTIZITÄT UND GARANTIE',
            high_jewellery: 'HOCHWERTIGER SCHMUCK - INFORMATIONEN ZUR AUTHENTIZITÄT'
        },
        body: {
            watches: 'Diese Uhr ist eine Kreation von BVLGARI, hergestellt von Bulgari Horlogerie SA, Rue de Monruz 34, Neuchâtel, Schweiz, unter Einhaltung der höchsten Qualitätsstandards der Schweizer Uhrenindustrie. Die beim Kauf aktivierte Garantie gilt weltweit und deckt alle Herstellungsfehler ab. Diese Garantie gilt ausschließlich für bei autorisierten und offiziellen BVLGARI-Verkaufsstellen erworbenen Produkte, BVLGARI-Geschäfte oder autorisierte Händler. Bitte beachten Sie die spezifischen Garantiebedingungen Ihres Produkts, die über das Scannen des QR-Codes auf der Garantiekarte oder die NFC-Funktion abgerufen werden können. Ihre BVLGARI-Uhr ist nur dann durch diese Garantie abgedeckt, wenn die internationale Garantiebescheinigung ordnungsgemäß bei der Übernahme durch den Verkäufer zum Zeitpunkt des Kaufs ausgefüllt und datiert wurde. Während der Garantiezeit wird Ihre Uhr bei Herstellungsfehlern kostenlos repariert. Diese Garantie deckt nicht den normalen Verschleiß der Uhr und ihrer Accessoires, Verlustfälle, Diebstahl, unsachgemäße Verwendung oder Fahrlässigkeit ab. Die Garantie gilt nicht, wenn die Seriennummer unleserlich oder gelöscht ist oder die Uhr von nicht autorisierten Dritten geändert oder repariert wurde. Diese Garantie deckt keine mittelbaren oder Folgeschäden aufgrund von Betriebsstörungen, Mängeln oder Ungenauigkeiten der Uhr ab. Wichtig: Für Wartungs- oder Reparaturarbeiten können Sie Ihre BVLGARI-Uhr zusammen mit der entsprechenden Garantiebescheinigung in eine unserer Boutiquen oder zu einem unserer autorisierten Händler bringen. Ihre internationale Garantie- und Echtheitsbescheinigung ermöglicht es Ihnen auch, durch Scannen des QR-Codes oder die NFC-Funktion auf diese Funktionen zuzugreifen. Diese internationale Garantie beschränkt nicht die gesetzlichen Rechte des Käufers gemäß anwendbarem Recht.',
            accessories: 'Dieses Produkt ist eine Originalkreation von BVLGARI, hergestellt und geprüft nach strengsten Qualitätsstandards. Wenn Sie die Karte aktivieren, sind alle Herstellungsfehler zwei Jahre ab dem Kaufdatum weltweit abgedeckt. Dieses Bulgari-Produkt enthält einen passiven NFC (Near Field Communication) Tag. Der Tag enthält einige Informationen zum Produkt (eindeutige Chip-ID, Produktcode, digitale Echtheitssignatur), um die Authentizität zu gewährleisten. Dieser Tag kann von einem NFC-Gerät gelesen werden. Dieser Tag enthält keine personenbezogenen Daten und kann den Besitzer des Produkts nicht identifizieren. Weitere Informationen erhalten Sie unter privacy@bulgari.com',
            jewellery_pieces: 'Dieses Schmuckstück ist eine Originalkreation von BVLGARI, hergestellt und zertifiziert nach den strengsten Qualitätsstandards. Der Schmuck wird mit einem Echtheitszertifikat und einer internationalen Garantie geliefert, die beim Kauf in einem BVLGARI-Geschäft oder bei einem autorisierten Händler von BVLGARI-Schmuck aktiviert werden muss. Die internationale Garantie deckt Herstellungsfehler für einen Zeitraum von 24 Monaten ab dem Kaufdatum ab und ist weltweit gültig, sofern sie zum Zeitpunkt des Kaufs ordnungsgemäß aktiviert wurde. Die in das Schmuckstück eingeschlagene Seriennummer muss im Garantiezertifikat vermerkt sein. Wenn das Zertifikat nicht aktiviert wird, gilt die internationale Garantie nicht. Die Garantie ist auch nicht gültig, wenn die auf dem Schmuckstück eingeschlagene Seriennummer nicht klar lesbar ist, geändert oder gelöscht wurde. Während der Garantiezeit kann der Schmuck im Falle von Herstellungsfehlern kostenlos repariert werden. Die Garantie deckt nicht den normalen Verschleiß des Schmucks oder Fehler oder Schäden aufgrund unsachgemäßer Verwendung, schlechter Wartung, Fahrlässigkeit oder Unfälle. Verlust oder Diebstahl sind nicht abgedeckt. Die Garantie ist ebenfalls nicht gültig, wenn nicht autorisierte Änderungen oder Reparaturen außerhalb eines BVLGARI-Geschäfts oder eines autorisierten Händlers für BVLGARI-Schmuck vorgenommen wurden.\n' +
                'Wichtig: Zur Beantragung von Reparaturen müssen Sie Ihr BVLGARI-Schmuckstück zusammen mit dem Echtheitszertifikat in einem unserer Geschäfte oder bei einem autorisierten Händler für BVLGARI-Schmuck vorlegen. Durch Scannen des QR-Codes auf dem internationalen Garantie- und Echtheitszertifikat oder die NFC-Technologie können Sie die BVLGARI-Schmuckpflegeanweisungen und die Unterlagen zu Ihrem Schmuck anzeigen. Diese internationale Garantie beschränkt nicht die gesetzlichen Rechte des Käufers gemäß anwendbarem Recht.',
            high_jewellery: 'Dieses Produkt ist eine Originalkreation von BVLGARI, hergestellt und geprüft nach strengsten Qualitätsstandards. Wenn Sie die Karte aktivieren, sind alle Herstellungsfehler zwei Jahre ab dem Kaufdatum weltweit abgedeckt.'
        }
    },
    return: {
        title: "Geben Sie nur für HJ die Seriennummer oder den SAP-Code ein",
        return: "Rückgabe",
        fields: {
            serial: "Seriennummer",
            next: "Weiter",
            required: "Seriennummer ist erforderlich"
        },
        choiceProduct: "Produkt auswählen für die Rückgabe",
        summaryProduct: "Produktzusammenfassung",
        codeSAP: "SAP-Code",
        activationDate: "Aktivierungsdatum",
        store: "Geschäft",
        expiryDate: "Ablaufdatum",
        duration: "Dauer",
        reasonReturn: "Grund im folgenden Formular angeben",
        errorTitle: "Rückgabe nicht verfügbar",
        errorSubTitle: "Die Rückgabe dieses Produkts ist nicht autorisiert.",
        imageProduct: "Produktbild",
        infoProduct: "Produktinformation",
        infoActivation: "Aktivierungsinformation",
        warrantyExpired: "Garantie abgelaufen",
        successTitle: "Rückgabe erfolgreich!",
        successSubtitle: "Die Rückgabe des ausgewählten Produkts wurde erfolgreich durchgeführt."
    },
    massive_activation: {
        label: "Sie können Ihre XLS-Dateien hochladen, um sie in Aktivierungen umzuwandeln.",
        name: "Klicken Sie hier, um die Datei hochzuladen oder ziehen Sie sie hierhin",
        error: "Fehler beim Hochladen der Datei.",
        success: "Datei wurde erfolgreich hochgeladen."
    },
    status: {
        title: "Geben Sie nur Seriennummer oder SAP-Code ein",
        status: "Status",
        fields: {
            serial: "Seriennummer",
            next: "Weiter",
            required: "Seriennummer ist erforderlich",
            email: "E-Mail",
            emailDescription: "Mehrere E-Mails durch ',' getrennt (Drücken Sie Enter, um zu bestätigen)"
        },
        choiceProduct: "Produkt auswählen",
        summaryProduct: "Zusammenfassung des Produkts",
        codeSAP: "SAP-Code",
        activationDate: "Aktivierungsdatum",
        store: "Filiale",
        user: "Benutzer",
        expiryDate: "Ablaufdatum",
        duration: "Laufzeit",
        reasonReturn: "Grund im Formular unten angeben",
        errorTitle: "Status nicht verfügbar",
        errorSubTitle: "Der Status dieses Produkts ist nicht autorisiert.",
        imageProduct: "Produktbild",
        infoProduct: "Produktinformationen",
        infoActivation: "Aktivierungsinformationen",
        warrantyExpired: "Garantie abgelaufen",
        successTitle: "Status erfolgreich erhalten!",
        successSubtitle: "Der Status des ausgewählten Produkts wurde erfolgreich abgerufen.",
        manualProduct: "Produkthandbuch drucken",
        versionPdf: "PDF-Version",
        returnDate: "Rückgabedatum",
        versionPdfTitle: "Zusammenfassung für das Produkt",
        versionPdfSubTitle: "Laden Sie das PDF auf Ihr Gerät herunter oder senden Sie es an den Kunden, der das Produkt gerade aktiviert hat.",
        sendEmail: "Per E-Mail senden",
        downloadPdf: "PDF herunterladen",
        sendEmailSuccess: "E-Mail erfolgreich gesendet!",
        manualProductTitle: "Produkthandbuch",
        manualProductSubTitle: "Laden Sie das PDF auf Ihr Gerät herunter",
        instructions: "Anleitungen",
        care: "Pflege",
        infoExpiryDate: "Ablaufdatuminformationen",
        changeExpiryDate: "Ablaufdatum ändern",
        confirmChangeExpiryDate: "Bestätigen",
        serialNumberError: "Ungültige Seriennummer!",
        serialNumberErrorMsg: "Die Seriennummer existiert nicht"
    },
    users: {
        fields: {
            managedStores: 'Verwaltete Geschäfte',
            managedStoreTypes: 'Verwaltete Geschäftstypen'
        },
        columns: {
            storeCode: 'Filialcode',
            role: 'Rolle'
        }
    },
    landing: {
        sapCode: 'SAP-Code',
        expiration_date: 'Ablaufdatum',
        warranty_duration: 'Garantiedauer',
        download_manual: 'Handbuch herunterladen',
        download_brochure: 'Brochure herunterladen',
        extend_warranty: 'Garantie verlängern',
        sp_pt_disclaimer: 'Diese internationale Garantie berührt keine anderen Rechte, die Ihnen möglicherweise gemäß den geltenden nationalen Gesetzen zustehen.'
    }
}
