import { User } from "../../models/User";
import { DWEnvironmentManager } from "../../services/DWEnvironmentManager";
import { call, CallRequestConfig } from "../../shared/api";
import DWUserDataProvider from "../DWUserDataProvider";

class DWUserDataProviderImp implements DWUserDataProvider {

    dwEnvironmentManager: DWEnvironmentManager | undefined = undefined;

    pathUser = '/users';
    pathOne = '/';

    getServiceKey(): string {
        return 'userDataProvider';
    }

    init = async (dwEnvironmentManager: DWEnvironmentManager) => {
        this.dwEnvironmentManager = dwEnvironmentManager
    }

    getOne(idUser: number): User {
        throw new Error("Method not implemented.");
    }

    getList(): User[] {
        throw new Error("Method not implemented.");
    }

    create(user: User): User {
        throw new Error("Method not implemented.");
    }

    async update( id: string, user: User ): Promise<any> {

        // const token = await AppStore.loadAuthToken ();

        const request: CallRequestConfig<any> = {
            // headers: {
            //     'Accept': 'application/json, text/plain, */*',
            //     'Content-Type': 'application/json;charset=UTF-8',
            //     // 'x-tmr-token': token ?? ''
            // },
            data: user
        }
        return await call<string> ( "PUT", this.pathUser + this.pathOne + `${ id }`, request );
    }

    delete(idUser: number): boolean {
        throw new Error("Method not implemented.");
    }

    async getToken(username: string, password: string): Promise<any> {

        const request: CallRequestConfig<any> = {
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: `username=${username}&password=${password}`
        }

        const tok = await call<string>("POST", `auth/startSession`, request);
        if (tok) {
            return tok;
        }
        return false;
    }

    async getLoggedUser(): Promise<User> {
        const res = await call<User>("GET", 'auth/loggedUser');
        return res;
    }

    async recoverPassword(username: string, email: string): Promise<any> {
        const request: CallRequestConfig<any> = {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: `username=${username}&email=${email}`
        }

        const res = await call("POST", 'users/requestresetpassword', request);
        return res;
    }

    logout() {
        throw new Error("Method not implemented.");
    }


}

export default new DWUserDataProviderImp();
