import { EyeOutlined, ProfileOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Image } from "antd";
import { FormRow } from "../components/form/DynamicForm";
import ParentModule from "../shared/module/CrudModule";
import CrudModule from "../components/CrudModule";
import PageViewer from "../components/PageViewer";
import {navigate} from "../shared/router";
import {__} from "../translations/i18n";
import {addFilter} from "../services/HooksManager";
import DateUtils from "../utils/DateUtils";
import CrudDetails from "../components/CrudDetails";
import ProductDetails from "../components/product/ProductDetails";
import { ReactNode } from "react";
import ProductEdit from "../components/product/ProductEdit";
import { FormLayout } from "antd/lib/form/Form";
import DWEnvironmentManager from "../services/DWEnvironmentManager";
import { successToast } from "../services/NotificationManager";
import { MenuNode } from "../components/MenuComponent";

interface Products {
    id: string | number
    photo: JSX.Element
    sku_upc: string
    creationDate: string
    shortDescription: string
}

class ProductModule extends ParentModule {

    attributes: any;

    init = () => {
        super.init()
        addFilter ( 'app_sub_menu', this.addMenu );

        addFilter('app_routes', (routes: any) => {
            routes[this.getRoute()] = {component: PageViewer, props: {content: <CrudModule module={this} tabs={["listing"]}/>, selectedKey: this.getRoute()}, visibility: 'private'};
            routes[this.getRouteDetail()] = {
                component: PageViewer,
                props: {content: <CrudDetails module={this}/>},
                visibility: 'private'
            };
            return routes;
        });

        addFilter('crud_detail_view_component_' + this.getModuleKey(), this.getDetailsComponent);

        addFilter('crud_detail_edit_component_' + this.getModuleKey(), this.getEditComponent);
    }

    addMenu = (menu: MenuNode[]) => {

        menu.push({
            label: <i >{ __ ( 'menu.products' ) }</i>,
            icon: <ProfileOutlined />,
            position: '40',
            key: this.getRoute()
        });

        return menu;
    }

    getModuleKey = (): string => {
        return "masterdata";
    }

    getShowExport = () => {
        return false;
    }

    __getFormFields = (): FormRow[] => {
        return [
            {
                fields: [
                    {
                        name: 'upc',
                        label: 'SKU/UPC',
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 6
                        }
                    },
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    { name: __('common.reset'), type: 'button', htmlType: 'reset', block: 'true', buttonType: "default", icon: <ReloadOutlined />, colConf: { span: 2, offset: 10}},
                    { name: __('common.search'), type: 'button', htmlType: 'submit', block: 'true', icon: <SearchOutlined />, colConf: { span: 2}}
                ]
            }
        ];

    }

    __getColumnsTable = () => {
        return [
            {
                title: __('products.columns.photo'),
                dataIndex: 'photo',
                key: 'photo'
            },
            {
                title: 'SKU/UPC',
                dataIndex: 'sku_upc',
                key: 'SKU',
                sorter: true
            },
            {
                title: __('products.columns.creationDate'),
                dataIndex: 'creationDate',
                key: 'CREATION_DATE',
                sorter: true
            },
            {
                title: __('products.columns.shortDescription'),
                dataIndex: 'shortDescription',
                key: 'shortDescription'
            },
            {
                title: __('products.columns.details'),
                dataIndex: 'id',
                key: 'id',
                render: ( id: string | number ) => (
                    <Button icon={ <EyeOutlined/> } size={ "small" } shape={ "circle" } onClick={() => navigate(this.getRouteDetail(id))} type="primary"></Button>
                )
            }
        ];
    }

    __getMapData = ( row: any ): Products => {

        return {
            id: row.id,
            photo: <Image fallback={DWEnvironmentManager.getNotFoundImg()}  />,
            sku_upc: row.code,
            shortDescription: row.description,
            creationDate: DateUtils.formatDate(row.creationDate)
        }
    }

    __getLayout = (): FormLayout => {
        return "vertical";
    }

    getDetailsComponent = (component: ReactNode, entity: any) => {
        return (<ProductDetails entity={entity} />);
    }

    getEditComponent = (component: ReactNode, entity: any) => {
        return (<ProductEdit entity={entity} module={this.getModuleKey()} edit={(data) => this.edit(data, entity)} getAttributes={this.getAttributes}/>);
    }

    getAttributes = (data: any) => {
        this.attributes = data;
    }

    edit = async (data: any, entity: any) => {
        const provider = DWEnvironmentManager.getDataProvider ();

        // ------ parametri fissi non settabili
        data.lastModified = null;
        data.sku = null;
        data.creationDate = new Date().getTime();
        if (!!entity.attributes && !this.attributes) {
            data.attributes = entity.attributes
        } else if (!!this.attributes) {
            data.attributes = {...this.attributes}
        }

        delete data.Salva;
        const req = {
            data: Object.assign({}, data),
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const res = await provider.addOne(req, `masterdata/${entity.id}`);
        if (res) {
            successToast(__('common.success'), __('products.edit.successEditDescription'));
            navigate(this.getRoute());
        }
    }

}


const module = new ProductModule();
export default module;
