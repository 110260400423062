import ParentModule from "../../shared/module/CrudModule";
import { addFilter } from "../../services/HooksManager";
import { __ } from "../../translations/i18n";
import { Image } from "antd";
import DateUtils from "../../utils/DateUtils";
import BuEnvironmentManager from "../services/BuEnvironmentManager";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { __b } from "../translations/i18n";

class ProductModule extends ParentModule {

    buEnvironmentManager: any;

    init = () => {
        this.buEnvironmentManager = BuEnvironmentManager;

        addFilter('masterdata_table_search_form', this.addFieldsTosearch, 50);
        addFilter('masterdata_table_columns', this.addToTableColumns, 50);
        addFilter('masterdata_table_data_row', this.addDataToTableRow, 50);
        addFilter('masterdata_image', this.getImageProductDetail, 50);
    }

    getModuleKey = (): string => {
        return "bu-masterdata";
    }

    addFieldsTosearch = (fields: any[], module :any) => {
        fields[0]['fields'].push(
            {
                name: 'MatType',
                label: __('products.fields.materialType'),
                type: 'select',
                required: false,
                selectValue: [
                    { label: __b ( "warranties.fields.values.all" ), value: 'all' },
                    { label: __b ( "warranties.fields.values.accessories" ), value: 'PFMA' },
                    { label: __b ( "warranties.fields.values.watches" ), value: 'PFMO' },
                    { label: __b ( "warranties.fields.values.jewels" ), value: 'FINP' },
                    { label: __b ( "warranties.fields.values.fineJewelry" ), value: 'BCI' },
                ],
                colConf: { span: 6 }
            },
            {
                name: 'NfcTag',
                label: __('products.fields.NFC'),
                type: 'select',
                required: false,
                selectValue: [
                    {label: __b ( "warranties.fields.values.all" ), value: 'all'},
                    {label: __b ( "warranties.fields.values.yes" ), value: 'true'},
                    {label: __b ( "warranties.fields.values.no" ), value: 'false'}
                ],
                colConf: { span: 6 }
            }
        );

        return fields;
    }

    addToTableColumns = (cols: any[]) => {

        cols.splice ( 4, 0,
            {
                title: __('products.columns.description'),
                dataIndex: 'description',
                key: 'description'
            },
            {
                title: __ ( 'products.columns.marketingDescription' ),
                dataIndex: 'marketingDescription',
                key: 'marketingDescription'
            },
            {
                title: __ ( 'products.columns.materialType' ),
                dataIndex: 'materialType',
                key: 'materialType'
            },
            {
                title: __ ( 'products.columns.nfcEmbedded' ),
                dataIndex: 'nfcEmbedded',
                key: 'nfcEmbedded'
            }
        )

        return cols
    }

    addDataToTableRow = (row: any, data: any) => {

        return {
            id: data.id,
            photo: <Image fallback={DWEnvironmentManager.getNotFoundImg()}  src={ `${DWEnvironmentManager.getStoragePath()}assets/images/imported/${data.code}.jpg` }/>,
            sku_upc: data.code,
            creationDate: DateUtils.formatDate ( data.creationDate ),
            description: data.attributes.longDescription?.value,
            shortDescription: data.description,
            marketingDescription: '-',
            materialType: data.attributes?.MatType?.value,
            nfcEmbedded: data.attributes?.NfcTag?.value === 'true' ? 'Si' : 'No',
        }
    }

    getImageProductDetail = ( item: any, entity: any ) => {

        return <Image fallback={DWEnvironmentManager.getNotFoundImg()}  src={ this.getImagesBasePath () + entity?.attributes?.image_thumb?.value }
                    alt={ __ ( 'image.masterdata_image' ) }/>
    }

    getImagesBasePath = (): string => {

        return `${ DWEnvironmentManager.getStoragePath() }assets/`;
    }
}


const productModule = new ProductModule();
export default productModule;
