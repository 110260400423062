import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import {Avatar, Button, Card, Col, Divider, Row} from "antd";
import Meta from "antd/lib/card/Meta";
import {useContext, useEffect, useState} from "react";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { addFilter, applyFilters } from "../../services/HooksManager";
import { CallRequestConfig } from "../../shared/api";
import { navigate } from "../../shared/router";
import {__} from "../../translations/i18n";
import DateUtils from "../../utils/DateUtils";
import { TabContext } from "../TabContext";

interface Prop {
    entity: any
}

const StoreDetails = (props: Prop) => {

    const {entity} = props;

    const [users, setUsers] = useState<any>([]);
    const key = useContext(TabContext);

    useEffect(() => {
        const storeId = entity.id;
        const provider = DWEnvironmentManager.getDataProvider ();
        const config: CallRequestConfig<any> = {
            params: {
                storeId: storeId
            }
        }
        provider.getList(undefined, undefined, config, 'users' ).then( (users: any) => {
            if (users) {
                setUsers(users);
            }
        });

        addFilter('stores_details_section_active', sectionActive);
    }, [])

    const getUserDetail = (id: number | string) => {
        navigate(`/users/${id}`);
    }

    const sectionActive = (component: any) => {
        return entity?.activationDate && (
            <div style={{paddingTop: 20}}>
                <span style={active}>{__('stores.details.active')}</span><br />
                <span><CheckOutlined style={checked}/>{DateUtils.formatDate(entity?.activationDate)}</span>
            </div>
        )
    }

    return (
        <div className="site-card-wrapper">
            <Row gutter={[24, 24]} justify="start">
                
                <Col span={10} offset={6}>
                    <Card style={{textAlign: 'start'}} bordered={false} >
                    <Meta
                        title={entity?.description ?? '-'}      
                        description={entity?.address ?? '-'}
                    />
                    {applyFilters('stores_details_section_active', <></>)}
                    </Card>
                </Col>
                
                <Col span={10} offset={6}>
                {users.length > 0 && <Card bordered={false}>
                    <Divider style={{fontSize: 20}}>{__("stores.details.users")}</Divider>
                    {users && (users.map((user: any) => 
                        <>
                        <Card.Grid style={gridStyle} onClick={() => getUserDetail(user?.id)}>
                        <Meta
                            avatar={<Avatar style={{ backgroundColor: '#AA8A5F' }}>{user?.name.substring(0, 1).toUpperCase() + user?.surname?.substring(0, 1).toUpperCase()}</Avatar>}
                            title={`${user?.name} ${user?.surname ?? ''}` ?? '-'}
                            description={user?.role?.description ?? '-'}
                            style={{paddingBottom: 10}}
                            />
                            <Meta
                            avatar={<Avatar style={{ backgroundColor: '#AA8A5F' }} size="small">@</Avatar>}
                            title={user?.attributes?.email ?? '-'}
                            style={{paddingBottom: 20}}
                            />
                            <div style={additional}>
                                <em >{__('stores.details.lastAccess')} </em><span style={{paddingLeft: 10}}>{DateUtils.formatDate(user?.lastLogin)}</span>
                            </div>
                        </Card.Grid>
                        </>
                    ))}

                    </Card>}
                </Col>
                
                <Col span={10} offset={6}>
                    <Button style={{width: '100%', fontSize: 20, height: '50px', marginBottom: 40}} onClick={() => key.setActiveKey('edit')} type="primary" icon={<EditOutlined />}>{__("common.edit")}</Button>
                </Col>
            </Row>
        </div>

    )

}

const gridStyle: React.CSSProperties = {
    width: "100%",
    cursor: "pointer"
  };

const additional: React.CSSProperties = {
    width: "100%",
    display: "flex",
    marginTop: "1rem",
    color: "#000000",
    opacity: "0.4",
    paddingBottom: 10
}

const active: React.CSSProperties = {
    fontWeight: 'bold',
    fontSize: 15
}

const checked: React.CSSProperties = {
    color: "#AA8A5F",
    fontSize: 15,
    paddingRight: 10
}

export default StoreDetails;
