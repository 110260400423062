// eslint-disable-next-line import/no-anonymous-default-export
export default {
    app: {
        name: 'テメラデジタル保証',
        url: 'www.buccellati.com'
    },
    error: {
        generic_error: '一般エラー',
        error: 'エラー',
        forbidden: 'アクセス拒否',
        not_found: '見つかりません',
        unauthorized: '認証失敗',
        invalid_credentials: '無効な資格情報！',
        duplicateKey: '重複キー',
        api: 'APIエラー {{method}} {{api}}:\n {{error_message}}',
        requiredRowEditTable: 'テーブルには少なくとも1行必要です',
        error_upload_file: 'ファイルのアップロードに失敗しました。'
    },
    success: {
        confirm_delete: "アイテムを削除しました",
        confirm_update: "更新が成功しました",
        confirm_password: "パスワードが変更されました。アプリにログインできます"
    },
    warning: {
        ask_delete: '要素を削除してもよろしいですか？',
        unreversible_delete: "削除は取り消しできません",
        warning: '警告'
    },
    dashboard: {
        module_name: 'ダッシュボード'
    },
    common: {
        confirm: '確認',
        cancel: 'キャンセル',
        save: '保存',
        reset: 'リセット',
        search: '検索',
        table: {
            total: '合計{{total}}の結果',
            export: 'エクスポート',
            changeMe: '変更する',
            key: 'キー',
            value: '値',
            attributes: '属性',
            addRow: '行を追加',
            keyDescription: 'キーの説明',
            valueDescription: '値の説明'
        },
        edit: '編集',
        details: '詳細',
        addRow: '行を追加',
        delete: '削除',
        success: '成功',
        fieldRequired: '必須フィールド',
        api: 'API',
        email: '電子メール',
        goBack: '戻る',
        goAhead: '進む',
        description: '説明',
        import_success: 'ファイルのアップロードに成功',
        click_upload: 'ファイルをアップロードするにはクリック'
    },
    menu: {
        dashboard: 'ダッシュボード',
        users: 'ユーザー',
        stores: 'ストア',
        products: '製品',
        logout: 'ログアウト',
        digital_warranties: 'デジタル保証',
        settings: '設定',
        about: '情報',
        modal: {
            copyright: '著作権 © 2015 Temera。全著作権所有'
        },
        controlPanel: 'コントロールパネル'
    },
    login: {
        username: 'ユーザー名',
        password: 'パスワード',
        confirm_password: 'パスワード確認',
        sign_in: 'ログイン',
        mandatory_username: 'ユーザー名を入力してください！',
        mandatory_password: 'パスワードを入力してください！',
        mandatory_email: 'メールアドレスを入力してください！',
        mandatory_confirm_password: 'パスワードを確認してください！',
        forgot_password: 'パスワードをお忘れですか？',
        remember_me: '私を覚えてます',
        failed: '認証に失敗しました',
        recover_password_title: 'パスワードをリセットするには、以下のフォームに記入してください',
        recover_password_subtitle: 'パスワードをリセットするためのリンクが含まれたメールが届きます',
        recover_success: 'メールが正常に送信されました',
        activation_user_title: 'アクセスパスワードを設定してください',
        activation_user_subtitle: 'この操作は初回アクセス時のみ必要です',
        header: 'デジタル保証＆認証',
        fields: {
            username: 'ユーザー名',
            email: 'メールアドレス',
            send: '送信'
        },
        placeholder: {
            input_password: 'パスワードを入力',
            input_confirm_password: 'パスワードを再度入力'
        }
    },
    loginSSO: {
        label: 'アカウントでログイン',
        sso_button: 'SSOでログイン',
        with_credentials: '資格情報でログイン'
    },
    module: {
        listing: {
            title: '{{moduleName}}の管理'
        },
        add: {
            title: '{{entityName}}を追加'
        },
        edit: {
            title: '{{entityName}}を編集'
        },
        details: {
            title: '{{entityName}}の詳細'
        },
        upload: {
            title: 'アップロード'
        }
    },
    users: {
        module_name: "ユーザー",
        entity: {
            singular_name: "ユーザー",
            plural_name: "ユーザー"
        },
        details: {
            store: 'ストア',
            email: 'メールアドレス'
        },
        columns: {
            details: '詳細',
            store: 'ストア',
            modified: '変更済み',
            created: '作成済み',
            surname: '姓',
            name: '名前',
            admin: '管理者',
            username: 'ユーザー名',
            personal: '個人',
            exception: '例外'
        },
        fields: {
            username: 'ユーザー名',
            surname: '姓',
            name: '名前',
            storeType: 'ストアの種類',
            store: 'ストア',
            password: 'パスワード',
            confirmPassword: 'パスワード確認',
            email: 'メールアドレス',
            role: '役割',
            admin: '管理者',
            attributes: '属性',
            add: 'ユーザーを追加',
            area: 'エリア',
            edit: 'ユーザーを編集',
            userSoldTo: 'ユーザー売却先',
            errors: {
                username: 'ユーザー名を入力してください',
                password: 'パスワードを入力してください'
            }
        },
        add: {
            success: 'ユーザーが正常に追加されました',
            errorPasswordMatch: 'パスワードが一致しません'
        },
        edit: {
            success: 'ユーザーが正常に編集されました'
        }
    },
    prewarranties: {
        module_name: "事前認証",
        entity: {
            singular_name: "事前認証",
            plural_name: "事前認証"
        }
    },
    hierarchies: {
        module_name: "エリア",
        entity: {
            singular_name: "新しいエリア",
            plural_name: "新しいエリア"
        },
    },
    partnerinventory: {
        module_name: "在庫"
    },
    warranties: {
        module_name: "認証",
        entity: {
            singular_name: "認証",
            plural_name: "認証"
        },
        columns: {
            details: '詳細',
            store: 'ストア/ユーザー Ret',
            card: 'カードの交換',
            return: '返品',
            storeUserAct: 'ストア/ユーザー Act',
            activation: 'アクティベーション',
            serialNumber: 'シリアル番号',
        },
        details: {
            image: "画像",
            product: "製品",
            date: "日付",
            store: "ストア",
            user: "オペレーター",
            delete: "認証の削除",
            activation: "アクティベーション",
            return: "返品",
            productPage: "製品ページ",
            downloadPdf: "PDFをダウンロード"
        },
        fields: {
            from: 'から',
            to: 'まで',
            serialNumber: 'シリアル番号',
            returned: '返品済み',
            storeType: 'ストアの種類',
            store: 'ストア'
        }
    },
    stores: {
        module_name: "ストア",
        entity: {
            singular_name: "ストア",
            plural_name: "ストア"
        },
        columns: {
            details: '詳細',
            store: 'ストア',
            type: 'タイプ',
            activation: 'アクティベーション',
            operationCountry: '操作国/地域',
            ISOCode: 'ISOコード',
            soldTo: '販売先',
            status: 'ステータス'
        },
        fields: {
            from: 'から',
            to: 'まで',
            storeType: 'ストアの種類',
            store: 'ストア',
            address: '住所',
            users: 'ユーザー',
            add: 'ストアを追加',
            edit: 'ストアを編集',
            nameCodeStore: 'ストア名/コード',
            activationStatus: 'アクティベーションステータス',
            operatingCountry: '操作国/地域',
            sold_to: '販売先',
            ship_to: '発送先',
            errors: {
                store: 'ストア名を入力してください'
            }
        },
        details: {
            users: 'ユーザー',
            lastAccess: '最終アクセス：',
            active: 'アクティブ',
            expectedDevices: '予想デバイス',
            usbDevice: 'USB NFCリーダー',
            mobileDevice: 'スマートフォン'
        },
        add: {
            success: 'ストアが正常に作成されました'
        },
        edit: {
            success: 'ストアが正常に編集されました！'
        }
    },
    masterdata: {
        module_name: "製品",
        entity: {
            singular_name: "製品",
            plural_name: "製品"
        }
    },
    settings: {
        set_system: 'システムを設定',
        save_system: "保存"
    },
    products: {
        columns: {
            details: '詳細',
            shortDescription: '短い説明',
            description: '説明',
            creationDate: '作成日',
            photo: '写真',
            marketingDescription: 'マーケティングの説明',
            materialType: '素材の種類',
            nfcEmbedded: 'NFC対応'
        },
        fields: {
            id: 'ID',
            code: 'コード',
            description: '説明',
            attributes: '属性',
            materialType: '素材の種類',
            NFC: 'NFC対応'
        },
        edit: {
            successEditDescription: '製品が正常に更新されました！'
        }
    },
    geolocation: {
        not_enabled: 'ジオロケーションが無効です'
    },
    image: {
        masterdata_image: "製品画像"
    },
    landing: {
        identified_product: "識別された製品",
        sku_upc: "SKU/UPC",
        serial: "シリアル番号",
        activation_store: "アクティベーションストア",
        activation_date: "アクティベーション日",
        information: "製品のアクティベーションに関する詳細情報",
        card: "カードランディング"
    },
    profiles: {
        module_name: "プロフィール",
        entity: {
            singular_name: 'プロフィール'
        }
    },
    'bu-manual': {
        module_name: "マニュアル",
        entity: {
            singular_name: 'マニュアル'
        }
    }
}
