import { Button, Card, Col, Divider, Form, Input, Row, Typography } from "antd"
import Title from "antd/lib/typography/Title"
import { __b } from "../../translations/i18n"

interface Props {
    finishFirstStep: (data: any) => any
}

const FirstStep = (props: Props) => {

    return (
            <>
                <Row gutter={[24,24]} justify="center">
                    <Col span={18}>
                        <Card>
                            <Col span={24} style={{textAlign: "center"}}>
                                <Typography>
                                    <Title level={4}>{__b('status.title')}</Title>
                                </Typography>
                                <Form layout="vertical" style={{marginTop: 50}} onFinish={props.finishFirstStep}>
                                    <Form.Item label={__b('return.fields.serial')} name={'serial'} rules={[{ required: true, message: __b('return.fields.required')}]}>
                                        <Input type="text" />
                                    </Form.Item>
                                    <Divider style={{fontSize: 20, marginTop: 20}}></Divider>
                                    <Form.Item name={"button"}>
                                        <Button htmlType="submit" type="primary" block={true} size={"large"}>{__b('return.fields.next')}</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Card>
                    </Col>
                </Row>
            </>)
}
export default FirstStep;