import { Menu, MenuProps } from "antd";
import React, { useEffect, useState } from "react";
import DWEnvironmentManager from "../services/DWEnvironmentManager";
import dWUserManager from "../services/DWUserManager";
import { applyFilters } from "../services/HooksManager";
import { navigate } from "../shared/router";

type MenuItem = Required<MenuProps>['items'][number];
export interface MenuNode {
    label: React.ReactNode,
    icon?: React.ReactNode,
    position: React.Key,
    children?: MenuNode[],
    key?: React.Key,
    isRoute?: boolean,
    cap?: boolean
}

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    isRoute?: boolean,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        isRoute,
        children,
        label,
    } as MenuItem;
}

interface Props {
    selectedKey: string
}

const MenuComponent = (props: Props) => {

    const [selectedKey, setSelectedKey] = useState<string[]>([]);

    useEffect(() => {
        let arr: string[] = selectedKey;
        arr.push(props.selectedKey);
        setSelectedKey(arr);
    }, []);

    const sortMenu = (a: MenuNode, b: MenuNode): number => {
        return (a.position > b.position ? 1 : -1);
    }

    const mapMenu = (item: MenuNode): MenuItem => {
        // have children ?
        let key;
        if (item.key) {
            key = item.key
        } else {
            key = item.position
        }

        if (item.children) {

            // Create menu for each child
            let listOfChild: MenuItem[] = []
            item.children.sort(sortMenu);
            item.children.map(children => {
                listOfChild.push(mapMenu(children));
            });

            // and create a menu with his childrens
            return getItem(item.label, key, item.icon, item.isRoute, listOfChild);

        } else {
            // create a simple menu
            return getItem(item.label, key, item.icon, item.isRoute);
        }
    }

    const getMenu = (): any => {

        let menu: { label: React.ReactNode, icon?: React.ReactNode, position: React.Key, children?: { label: React.ReactNode, position: React.Key, key?: React.Key }[], key?: React.Key, cap?: boolean }[] = [];

        /**
         *  Cattura il menu eventualmente modificato dall'estensione
         *  @hook app_menu
         *  @param menu:{ label: React.ReactNode, icon: React.ReactNode, position: React.Key, children?:{label: React.ReactNode, position: React.Key}[] }[] Array per la struttura del menu
         */
        menu = applyFilters('app_menu', menu);
        menu = menu.sort(sortMenu);

        let listOfMenu: MenuItem[] = []
        menu.map(item => {
            // ricerca capability in base alla chiave del menu, se il menu ha sottomenu fare lo stesso processo -> se ha i sottomenu senza cap bisogna eliminarli
            if (dWUserManager.isLoggedUser()) {
                item.cap = dWUserManager.hasCapability(item.key);

                if (item.children) { 
                    let menuItemChildren: { label: React.ReactNode; position: React.Key; key?: React.Key | undefined; }[] | undefined = []
                    item.children.forEach((c) => {
                        if (dWUserManager.hasCapability(c.key)) {
                            menuItemChildren?.push(c);
                        }
                    })
    
                    item.children = menuItemChildren;
                }
    
                if (item.cap) {
                    listOfMenu.push(mapMenu(item));
                }
            }
        });

        return listOfMenu;
    }

    const navigateRoute = (item: any) => {
        const menu = getMenu();

        const menuitem = menu.find((m: any) => m.key === item.key);

        // verifico nei submenu presenti in controlPanel
        if (menuitem === undefined) {
            const childrenMenu = menu.find((m: any) => m.key === 'controlPanel');
            const subMenuItem = childrenMenu.children.find((m: any) => m.key === item.key);

            if (subMenuItem === undefined || subMenuItem.isRoute === true || subMenuItem.isRoute === undefined) {
                navigate(item.key);
            }
        }

        if (menuitem === undefined || menuitem.isRoute === true || menuitem.isRoute === undefined) {
            navigate(item.key);
        }
    }

    return (
        <Menu theme="dark" mode="inline" items={getMenu()} defaultSelectedKeys={selectedKey} onClick={navigateRoute} />
    )
}

export default MenuComponent;