import { Card, Col, Image, Layout, Row } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { navigate } from '../../shared/router';
import BuEnvironmentManager from '../services/BuEnvironmentManager';
import { __b } from '../translations/i18n';

const { Content } = Layout;

const CustomDashboard: React.FC = () => {

    const returnCapability = DWEnvironmentManager.getUserManager().hasCapability('return');
    const statusCapability = DWEnvironmentManager.getUserManager().hasCapability('status');

    return (
        <Content >
            <Row justify={"center"}>
                <Col span={24} style={{textAlign: "center"}}>
                    <Image fallback={DWEnvironmentManager.getNotFoundImg()}  preview={false} src={DWEnvironmentManager.getMainLogo()} className='App-logo' alt={DWEnvironmentManager.getApplicationName()} />
                </Col>
            </Row>

            <Row gutter={[24, 24]} justify={"center"} align={"middle"}>
                { statusCapability && <Col span={returnCapability ? 12 : 24} style={{textAlign: "center"}}>
                    <Card bordered={false} hoverable onClick={() => navigate('/status')} style={{minHeight: 600}} className='cardDashboard'>
                        <Image fallback={DWEnvironmentManager.getNotFoundImg()}  preview={false} src={BuEnvironmentManager.getStatusImg()} alt={BuEnvironmentManager.getStatusImg()} style={{marginTop: 150}}/>
                        <Title level={4} style={{color: 'white'}}>{__b('warranties.columns.status').toUpperCase()}</Title>
                    </Card>
                </Col>}

                { returnCapability && <Col span={statusCapability ? 12 : 24} style={{textAlign: "center"}}>
                    <Card bordered={false} hoverable onClick={() => navigate('/return')} style={{minHeight: 600}} className='cardDashboard'>
                        <Image fallback={DWEnvironmentManager.getNotFoundImg()}  preview={false} src={BuEnvironmentManager.getReturnImg()} alt={BuEnvironmentManager.getReturnImg()} style={{marginTop: 150}} />
                        <Title level={4} style={{color: 'white'}}>{__b('warranties.columns.returnDeactivate').toUpperCase()}</Title>
                    </Card>
                </Col>}
            </Row>

            { !statusCapability && !returnCapability && 
                <Row justify={"center"} align={"bottom"} style={{ marginTop: 150}}>
                    <Col span={12} style={{textAlign: "center"}}>
                        <Title level={3}>{__b('splash.welcome')}</Title>
                        <Paragraph style={{ fontSize: 20}}>{__b('splash.presentation')}</Paragraph>
                    </Col>
                </Row>}
        </Content>
    );
}

export default CustomDashboard;
