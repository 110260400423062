import {CheckOutlined, EyeOutlined, ReloadOutlined, SearchOutlined, UserSwitchOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {FormRow} from "../components/form/DynamicForm";
import ParentModule from "../shared/module/CrudModule";
import CrudModule from "../components/CrudModule";
import PageViewer from "../components/PageViewer";
import {navigate} from "../shared/router";
import {__} from "../translations/i18n";
import {addFilter, applyFilters} from "../services/HooksManager";
import DateUtils from "../utils/DateUtils";
import {ReactNode} from "react";
import UserDetails from "../components/users/UserDetails";
import CrudDetails from "../components/CrudDetails";
import {fetchStores} from "./StoreModule";
import {FormLayout} from "antd/lib/form/Form";
import UserAdd from "../components/users/UserAdd";
import DWEnvironmentManager from "../services/DWEnvironmentManager";
import UserEdit from "../components/users/UserEdit";
import {Store} from "../models/Store";
import {User} from "../models/User";
import {errorToast, successToast} from "../services/NotificationManager";
import {MenuNode} from "../components/MenuComponent";
import {fetchSoldToParties} from "./WarrantyModule";

interface Users {
    id: string
    username: string
    admin: boolean
    name: string
    surname: string
    created: string
    modified: string
    store: string
}

class UserModule extends ParentModule {

    attributes: any;

    init = () => {
        super.init();
        addFilter('app_sub_menu', this.addMenu);

        addFilter('app_routes', (routes: any) => {
            routes[this.getRoute()] = {
                component: PageViewer,
                props: {content: <CrudModule module={this}/>, selectedKey: this.getRoute()},
                visibility: 'private'
            };
            routes[this.getRouteDetail()] = {
                component: PageViewer,
                props: {content: <CrudDetails module={this}/>},
                visibility: 'private'
            };
            return routes;
        });

        addFilter('crud_detail_view_component_' + this.getModuleKey(), this.getDetailsComponent);
        addFilter('crud_detail_edit_component_' + this.getModuleKey(), this.getEditComponent);
        addFilter('crud_create_component_' + this.getModuleKey(), this.getAddComponent)
    }

    addMenu = (menu: MenuNode[]) => {

        menu.push({
            label: <i>{__('menu.users')}</i>,
            icon: <UserSwitchOutlined/>,
            position: '20',
            key: this.getRoute()
        });

        return menu;
    }

    getModuleKey = (): string => {
        return "users";
    }

    fetchStores = async (key: string) => {
        return await fetchStores(key)
    }

    __getFormFields = (): FormRow[] => {
        return [
            {
                fields: [
                    {
                        name: 'username_like',
                        label: __('users.fields.username'),
                        type: 'text',
                        required: false,
                        requiredMessage: "test",
                        colConf: {span: 6}
                    },
                    {
                        name: 'name',
                        label: __('users.fields.name'),
                        type: 'text',
                        required: false,
                        requiredMessage: "test",
                        colConf: {span: 4}
                    },
                    {
                        name: 'surname',
                        label: __('users.fields.surname'),
                        type: 'text',
                        required: false,
                        requiredMessage: "test",
                        colConf: {span: 4}
                    },
                    {
                        name: 'externalCode',
                        label: __('users.fields.storeType'),
                        type: 'select',
                        required: false,
                        requiredMessage: "test",
                        selectValue: ["All", "Botique", "Corner", "Retailer", "Shop in shop"], colConf: {span: 4}
                    },
                    {
                        name: 'store',
                        label: __('users.fields.store'),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: (key: string) => (this.fetchStores(key)), colConf: {span: 6}
                    },

                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    {
                        name: __('common.reset'),
                        type: 'button',
                        htmlType: 'reset',
                        block: 'true',
                        buttonType: "default",
                        icon: <ReloadOutlined/>,
                        colConf: {span: 2, offset: 10}
                    },
                    {
                        name: __('common.search'),
                        type: 'button',
                        htmlType: 'submit',
                        block: 'true',
                        icon: <SearchOutlined/>,
                        colConf: {span: 2}
                    }
                ]
            }
        ];

    }

    __getColumnsTable = () => {
        return [
            {
                title: __('users.columns.username'),
                dataIndex: 'username',
                key: 'username',
                sorter: true
            },
            {
                title: __('users.columns.admin'),
                dataIndex: 'admin',
                key: 'admin',
                render: (admin: any) => {

                    return admin ? <CheckOutlined/> : <></>
                }
            },
            {
                title: __('users.columns.name'),
                dataIndex: 'name',
                key: 'name',
                sorter: true
            },
            {
                title: __('users.columns.surname'),
                dataIndex: 'surname',
                key: 'surname',
                sorter: true
            },
            {
                title: __('users.columns.created'),
                dataIndex: 'created',
                key: 'creationDate',
                sorter: true
            },
            {
                title: __('users.columns.modified'),
                dataIndex: 'modified',
                key: 'lastModified',
                sorter: true
            },
            {
                title: __('users.columns.store'),
                dataIndex: 'store',
                key: 'store'
            },
            {
                title: __('common.details'),
                dataIndex: 'id',
                key: 'id',
                render: (id: string | number) => (
                    <Button onClick={() => navigate(this.getRouteDetail(id))} icon={<EyeOutlined/>} size={"small"}
                            shape={"circle"} type="primary"/>
                )
            }
        ];
    }

    __getMapData = (row: any): Users => {

        return {
            id: row.id,
            username: row.username,
            admin: row.admin,
            name: row.name,
            surname: row.surname,
            created: DateUtils.formatDate(row.creationDate),
            modified: DateUtils.formatDate(row.lastModified),
            store: row.store?.address
        }
    }

    __getLayout = (): FormLayout => {
        return "vertical";
    }

    getDetailsComponent = (component: ReactNode, entity: any) => {
        return (<UserDetails entity={entity}/>);
    }

    getAddComponent = (component: ReactNode, entity: any) => {
        const roles = this.loadAll().then(res => {
            return res
        });
        return (<UserAdd module={this.getModuleKey()} add={(data) => this.add(data, roles)}
                         getAttributes={this.getAttributes} roles={roles} />);
    }

    getAttributes = (data: any) => {
        let attributes = {};
        for (const property in data) {
            attributes = {...attributes, ...{[property]: data[property].value}};
        }

        this.attributes = attributes;
    }

    getEditAttributes = (data: any) => {
        let attributes = {};
        for (const property in data) {
            if (property !== 'email') {
                attributes = {...attributes, ...{[property]: data[property]}};
            }
        }

        this.attributes = attributes;
    }

    add = async (data: any, roles: any) => {
        const provider = DWEnvironmentManager.getDataProvider();
        const role = await roles;
        if (data.password !== data.confirmPassword) {
            errorToast(__('error.error'), __('users.add.errorPasswordMatch'));
        } else {
            const roleId = data.role
            if (!!roleId) {
                data.role = role.find(({label}: any) => label === roleId).item
            }

            data.attributes = {email: data.email?.toString()}
            if (!!this.attributes) {
                data.attributes = {...data.attributes, ...this.attributes};
            }

            delete data.email
            delete data.confirmPassword
            if (data.storeId) {
                const store: Store = await provider.getOne(data.storeId, {}, 'stores')
                data.store = store;
            }

            /**
             * Ritorna il payload da usare nella chiamata di modifica utente, permette la modifica da aprte di una verticalizzazione
             * @hook users_edit_payload
             * @param data : dati del payload
             */
            const payload = await applyFilters('users_add_payload', data);


            const req = {
                data: Object.assign({}, payload),
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            const res: User = await provider.addOne(req, 'users')
            if (res) {
                successToast(__('common.success'), __('users.add.success'));
                navigate(this.getRoute());
            }
        }
    }

    getEditComponent = (component: ReactNode, entity: any) => {
        const roles = this.loadAll().then(res => {
            return res
        });
        const soldToParty: Promise<any[]> = fetchSoldToParties("").then(res => {
            let resEdit: any[] = [];
            entity.soldTo.forEach((s: string) => {
                res.forEach( (r: any) => {
                    let matching = new RegExp(s);
                    let sold = String(r.value).match(matching);
                    if (sold != null) {
                        resEdit.push(r);
                    }
                })
            })
            return resEdit
        })
        return (<UserEdit entity={entity} module={this.getModuleKey()} edit={(data) => this.edit(data, entity, roles)}
                          roles={roles} getAttributes={this.getAttributes} soldTo={soldToParty}/>);
    }

    edit = async (data: any, entity: any, roles: any) => {
        const role = await roles;
        const provider = DWEnvironmentManager.getDataProvider();

        if ((data.password || data.confirmPassword) && (data.password !== data.confirmPassword)) {
            errorToast(__('error.error'), __('users.add.errorPasswordMatch'));
        } else {

            // come verifichiamo se il ruolo non è presente? -> è possibile che alcuni utenti non abbiano il ruolo?
            if (data.role) {
                const roleId = data.role;

                if (!!roleId) {
                    if (roleId.value) {
                        data.role = role.find(({value}: any) => value === roleId.value).item;
                    } else {
                        data.role = role.find(({label}: any) => label === roleId).item;
                    }
                }
            }

            if (!data.attributes && !this.attributes) {
                this.getEditAttributes(entity.attributes);
            }

            data.attributes = {email: data.email.toString()};
            if (!!this.attributes) {
                data.attributes = {...data.attributes, ...this.attributes};
            }
            delete data.email

            if (data.storeId) {
                if (data.storeId.value) {
                    const store: Store = await provider.getOne(data.storeId.value, {}, 'stores')
                    data.store = store;
                } else {
                    const store: Store = await provider.getOne(data.storeId, {}, 'stores')
                    data.store = store;
                }
            }

            /**
             * Ritorna il payload da usare nella chiamata di modifica utente, permette la modifica da aprte di una verticalizzazione
             * @hook users_edit_payload
             * @param data : dati del payload
             */
            const payload = await applyFilters('users_edit_payload', data);
            const res: User = await DWEnvironmentManager.getUserDataProvider().update(entity.id, payload);
            if (res) {
                successToast(__('common.success'), __('users.edit.success'));
                navigate(this.getRoute());
            }
        }
    }

    loadAll = async () => {
        const roles = await fetchRoles()
        return roles;
    }

    fetchUserSoldTo(userId: string) {
        const provider = DWEnvironmentManager.getDataProvider();
        return provider.getOne(userId,  {}, 'usersoldto')
    }

}

const module = new UserModule();
export default module;

export const fetchRoles = async () => {

    const provider = DWEnvironmentManager.getDataProvider();
    let rolesArray: any[] = []
    await provider.getList(0, 0, {}, 'roles')
        .then((response) => {
            response.forEach((element: any) => {
                rolesArray.push({label: element.code, value: element.id, item: element})
            });

        });
    return rolesArray
}

export const fetchRolesByKey = async (key: any) => {

    const provider = DWEnvironmentManager.getDataProvider();
    let rolesArray: any[] = []
    await provider.getList(0, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, 'roles')
        .then((response) => {
            response.forEach((element: any) => {
                rolesArray.push({label: element.description, value: element.code, item: element})
            });

        });
    return rolesArray
}
