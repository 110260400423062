import { DownloadOutlined, ReloadOutlined, SearchOutlined, TagsOutlined, UploadOutlined } from "@ant-design/icons";
import { FormLayout } from "antd/lib/form/Form";
import moment from "moment";
import CrudModule from "../../components/CrudModule";
import { FormRow } from "../../components/form/DynamicForm";
import { MenuNode } from "../../components/MenuComponent";
import PageViewer from "../../components/PageViewer";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { addFilter } from "../../services/HooksManager";
import ParentModule from "../../shared/module/CrudModule";
import { __ } from "../../translations/i18n";
import DateUtils from "../../utils/DateUtils";
import { __b } from "../translations/i18n";
import ObjectUtils from "../utils/ObjectsUtil";




class PartnerInventoriesModule extends ParentModule {

    disableDate = true;

    init = () => {
        super.init();
        addFilter('app_sub_menu', this.addMenu);

        addFilter('app_routes', (routes: any) => {
            routes[this.getRoute()] = {
                component: PageViewer,
                props: { content: <CrudModule tabs={['listing']} module={this} />, selectedKey: this.getRoute() },
                visibility: 'private'
            };
            return routes;
        });

        addFilter('search_form_data_' + this.getModuleKey(), this.getSearchData);
        // addFilter('search_form_data_default_params_' + this.getModuleKey(), this.defaultParams);
        // addFilter('export_form_data_default_params_' + this.getModuleKey(), this.defaultParams);
        addFilter('partnerinventory_search_on_change_state_fields', this.onChangeField);

    }

    addMenu = (menu: MenuNode[]) => {
        menu.push({
            label: <i >{__b('menu.partnerinventories')}</i>,
            icon: <TagsOutlined />,
            position: '42',
            key: this.getRoute()
        });

        return menu;
    }

    getModuleKey = (): string => {
        return "partnerinventory";
    }

    __getFormFields = (): FormRow[] => {

        return [
            {
                fields: [
                    {
                        name: 'soldTo',
                        label: __b('partnerinventories.fields.soldTo'),
                        type: 'autocompleteselect',
                        required: false,
                        allowClear: true,
                        fetchOptions: (key: string) => (this.fetchStoresCompanyCode(key)),
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'store',
                        label: __b('partnerinventories.fields.store'),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: (key: string) => (this.fetchStores(key)),
                        colConf: {
                            span: 6
                        }
                    },
                    {
                        name: 'sapCode',
                        label: __b('partnerinventories.fields.sapCode'),
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 4,
                            offset: 2
                        }
                    },
                    {
                        name: 'serialNumber',
                        label: __b('partnerinventories.fields.serialNumbers'),
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 4,
                            offset: 2
                        }
                    },
                    {
                        name: 'creationDateFrom',
                        label: __b('partnerinventories.fields.creationDateFrom'),
                        type: 'date',
                        required: false,
                        disabled: this.disableDate,
                        format: 'DD/MM/YYYY',
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'creationDateTo',
                        label: __b('partnerinventories.fields.creationDateTo'),
                        type: 'date',
                        required: false,
                        disabled: this.disableDate,
                        format: 'DD/MM/YYYY',
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'inventoryDateFrom',
                        label: __b('partnerinventories.fields.inventoryDateFrom'),
                        type: 'date',
                        required: false,
                        disabled: this.disableDate,
                        format: 'DD/MM/YYYY',
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'inventoryDateTo',
                        label: __b('partnerinventories.fields.inventoryDateTo'),
                        type: 'date',
                        required: false,
                        disabled: this.disableDate,
                        format: 'DD/MM/YYYY',
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'lastInventory',
                        label: __b('partnerinventories.fields.lastInventory'),
                        type: 'switch',
                        onChange: () => (this.onChangeLastInventory()),
                        required: false,
                        defaultChecked: true,
                        value: true,
                        colConf: {
                            span: 4,
                            offset: 2
                        }
                    }
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    {
                        name: __('common.reset'),
                        type: 'button',
                        htmlType: 'reset',
                        block: 'true',
                        buttonType: "default",
                        icon: <ReloadOutlined />,
                        colConf: { span: 2, offset: 10 }
                    },
                    {
                        name: __('common.search'),
                        type: 'button',
                        htmlType: 'submit',
                        block: 'true',
                        icon: <SearchOutlined />,
                        colConf: { span: 2 }
                    },
                    {
                        name: __b('partnerinventories.button.upload'),
                        type: 'buttonUpload',
                        action: this.getModuleKey() + '/importinventory',
                        htmlType: 'upload',
                        block: 'true',
                        icon: <UploadOutlined />,
                        colConf: { span: 3, offset: 9 }
                    },
                    {
                        name: __b('partnerinventories.button.download'),
                        onClick:() => {this.download()},
                        type: 'button',
                        htmlType: 'download',
                        block: 'true',
                        icon: <DownloadOutlined />,
                        colConf: { span: 3 }
                    }
                ]
            }
        ];
    }

    onChangeLastInventory = () => {
        this.disableDate = !this.disableDate;
    }

    onChangeField = (formFields: any) => {
        formFields[0].fields[4].disabled = this.disableDate;
        formFields[0].fields[5].disabled = this.disableDate;
        formFields[0].fields[6].disabled = this.disableDate;
        formFields[0].fields[7].disabled = this.disableDate;

        return formFields;
    }

    getSearchData = (data: { [key: string]: any }) => {

        let dataClone: { [key: string]: any } = {};
        Object.keys(data).map((key) => {
            if (moment.isMoment(data[key])) {
                return dataClone[key] = data[key].format('YYYY/MM/DD');
            } else {
                return dataClone[key] = data[key];
            }
        });

        return dataClone;
    }

    defaultParams = (data: { [key: string]: any }) => {
        data = {
            lastInventory: true
        }
        return data;
    }


    download = async () => {
        await this.exportTemplate();
    }

    exportTemplate = async () => {

        const req = {
            timeout: 200000,
            responseType: "arraybuffer"
        };
        const provider = DWEnvironmentManager.getDataProvider();
        await provider.export(req, this.getModuleKey() + '/downloadTemplate');
    }

    __getColumnsTable = () => {
        return [
            {
                title: __b('partnerinventories.columns.inventoryDate'),
                dataIndex: 'inventoryDate',
                sorter: true,
                key: 'inventoryDate'
            },
            {
                title: __b('partnerinventories.columns.soldTo'),
                dataIndex: 'soldTo',
                sorter: true,
                key: 'soldTo'
            },
            {
                title: __b('partnerinventories.columns.storeCode'),
                dataIndex: 'storeCode',
                sorter: true,
                key: 'storeCode'
            },
            {
                title: __b('partnerinventories.columns.store'),
                dataIndex: 'store',
                sorter: true,
                key: 'store'
            },
            {
                title: __b('partnerinventories.columns.product'),
                dataIndex: 'product',
                key: 'product'
            },
            {
                title: __b('partnerinventories.columns.sapCode'),
                dataIndex: 'sapCode',
                sorter: true,
                key: 'sapCode'
            },
            {
                title: __b('partnerinventories.columns.serialNumber'),
                dataIndex: 'serialNumber',
                key: 'serialNumber'
            },
            {
                title: __b('partnerinventories.columns.quantity'),
                dataIndex: 'quantity',
                key: 'quantity'
            },
            {
                title: __b('partnerinventories.columns.creationDate'),
                dataIndex: 'creationDate',
                key: 'creationDate'
            }
        ];
    }

    __getMapData = (row: any) => {
        return {
            inventoryDate: DateUtils.formatDateWithoutTime(row.inventoryDate),
            soldTo: row.soldTo,
            storeCode: row?.store?.code,
            store: row?.store?.description,
            product: row.product.description,
            sapCode: row.product.code,
            serialNumber: row.serialNumber,
            quantity: row.quantity,
            creationDate: DateUtils.formatDate(row.creationDate)
        }
    }

    __getLayout = (): FormLayout => {
        return "vertical";
    }

    fetchStores = async (key: string) => {
        return await fetchStores(key);
    }

    fetchStoresCompanyCode = async (key: string) => {
        return await fetchStoresCompanyCode(key)
    }
}

export const fetchStores = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();
    const moduleManager = DWEnvironmentManager.getModuleManager();
    const storeModule = moduleManager.getModule('stores');

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, storeModule.getModuleKey())
        .then((response) =>
            response.map((res: any) => ({
                label: `${res.code} - ${res.description}`,
                value: `${res.code}-${res.description}`,
            })),
        );
}

export const fetchStoresCompanyCode = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider ();

    return provider.getList ( 20, 0, {},
        `stores/soldtoparties/search?description=${key}` )
        .then ( ( response ) =>
            ObjectUtils.stringIncludesWithOutLabel(response, key)
        );
}

const partnerInventoriesModule = new PartnerInventoriesModule();
export default partnerInventoriesModule;
