import {EyeOutlined, AppstoreOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {FormRow} from "../components/form/DynamicForm";
import CrudModule from "../components/CrudModule";
import {addFilter} from "../services/HooksManager";
import PageViewer from "../components/PageViewer";
import {navigate} from "../shared/router";
import {__} from "../translations/i18n";
import ParentModule from "../shared/module/CrudModule";
import DWEnvironmentManager from "../services/DWEnvironmentManager";
import CrudDetails from "../components/CrudDetails";
import { ReactNode } from "react";
import StoreDetails from "../components/store/StoreDetails";
import StoreAdd from "../components/store/StoreAdd";
import { FormLayout } from "antd/lib/form/Form";
import StoreEdit from "../components/store/StoreEdit";
import { User } from "../models/User";
import { Store } from "../models/Store";
import { CallRequestConfig } from "../shared/api";
import { successToast } from "../services/NotificationManager";
import { MenuNode } from "../components/MenuComponent";
import moment from "moment";
import { __b } from "../bulgari/translations/i18n";

interface Stores {
    id: string
    description: string
    storeType: string
}

class StoreModule extends ParentModule {

    attributes: any;

    init = () => {
        super.init()
        addFilter ( 'app_sub_menu', this.addMenu );

        addFilter('app_routes', (routes: any) => {
            routes[this.getRoute()] = {component: PageViewer, props: {content: <CrudModule module={this}/>, selectedKey: this.getRoute()}, visibility: 'private'};
            routes[this.getRouteDetail()] = {
                component: PageViewer,
                props: {content: <CrudDetails module={this}/>},
                visibility: 'private'
            };
            return routes;
        });

        addFilter('crud_detail_view_component_' + this.getModuleKey(), this.getDetailsComponent);
        addFilter('crud_detail_edit_component_' + this.getModuleKey(), this.getEditComponent);
        addFilter('crud_create_component_' + this.getModuleKey(), this.getAddComponent);
        addFilter('search_form_data_' + this.getModuleKey(), this.getSearchData)

    }

    addMenu = (menu: MenuNode[]) => {
        menu.push({
            label: <i > {__('menu.stores')}</i>,
            icon: <AppstoreOutlined />,
            position: '30',
            key: this.getRoute()
        });

        return menu;
    }

    getModuleKey = (): string => {
        return "stores";
    }

    fetchStores = async (key: string) => {
        return await fetchStores(key)
    }

    fetchUsers = async (key: string) => {
        return await fetchUsers(key)
    }
    __getFormFields = (): FormRow[] => {
        return [
            {
                fields: [
                    {
                        name: 'activationDateFrom',
                        label: __('stores.fields.from'),
                        type: 'date',
                        required: false,
                        format: 'DD/MM/YYYY',
                        colConf: {
                            span: 2
                        }
                    },
                    {
                        name: 'activationDateTo',
                        label: __('stores.fields.to'),
                        type: 'date',
                        required: false,
                        format: 'DD/MM/YYYY',
                        colConf: {
                            span: 2
                        }
                    },
                    {
                        name: 'storeId',
                        label: __('stores.fields.store'),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: (key: string) => (this.fetchStores(key)),
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'externalCode',
                        label: __('stores.fields.storeType'),
                        type: 'select',
                        required: false,
                        selectValue: [
                            {label: __b ( "warranties.fields.values.all" ), value: ''},
                            {label: "Buotique", value: 'boutique'},
                            {label: "Corner", value: 'corner'},
                            {label: "Retailer", value: 'retailer'},
                            {label: "Shop in shop", value: 'shop in shop'},
                        ],
                        colConf: {
                            span: 4
                        }
                    },

                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    { name: __('common.reset'), type: 'button', htmlType: 'reset', block: 'true', buttonType: "default", icon: <ReloadOutlined />, colConf: { span: 2, offset: 10}},
                    { name: __('common.search'), type: 'button', htmlType: 'submit', block: 'true', icon: <SearchOutlined />, colConf: {span: 2 }}
                ]
            }
        ];

    }

    __getColumnsTable = () => {
        return [
            {
                title: __('stores.columns.store'),
                dataIndex: 'description',
                key: 'description',
                sorter: true
            },
            {
                title: __('stores.columns.type'),
                dataIndex: 'storeType',
                key: 'externalType',
                sorter: true
            },
            {
                title: __('common.details'),
                dataIndex: 'id',
                key: 'id',
                render: (id: string | number) => (
                    <Button icon={<EyeOutlined/>} onClick={() => navigate(this.getRouteDetail(id))} size={"small"} shape={"circle"} type="primary"></Button>
                )
            }
        ];
    }

    __getMapData = (row: any): Stores => {
        return {
            id: row.id,
            description: row.description,
            storeType: row.storeType
        }
    }

    __getLayout = (): FormLayout => {
        return "vertical";
    }

    getDetailsComponent = (component: ReactNode, entity: any) => {
        return (<StoreDetails entity={entity} />);
    }

    getAddComponent = (component: ReactNode, entity: any) => {
        return (<StoreAdd module={this.getModuleKey()} add={this.add} />);
    }

    getSearchData = (data: { [key: string]: any }) => {

        let dataClone: { [key: string]: any } = {};
        Object.keys(data).map((key) => {
            if (moment.isMoment(data[key])) {
                dataClone[key] = data[key].format('YYYY/MM/DD');
            } else {
                dataClone[key] = data[key];
            }
        });

        return dataClone;
    }


    add = async (data: any) => {
        const provider = DWEnvironmentManager.getDataProvider ();
        // ------ parametri fissi non settabili
        data.storeType = 'STORE'
        data.syncCode = ''
        data.attributes = { expectedDevicesReaders:0, expectedDevicesSmartphones : 0 }
        // ------
        data.attributes = {...this.attributes};
        const req = {
            data: Object.assign({}, data),
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const res : Store = await provider.addOne(req, 'stores')


        // TODO call a kernel.api
        data.users.forEach(async (userId : any) => {
            const user : User = await provider.getOne(userId,{} , 'users')
            user.store = res
            user.storeId = res.id
            DWEnvironmentManager.getUserDataProvider().update(user.id,user)
        });


        if (res) {
            successToast(__('common.success'), __('stores.add.success'));
            navigate(this.getRoute());
        }
    }

    getEditComponent = (component: ReactNode, entity: any) => {
        const users = this.loadUser(entity).then(res => {return res});
        return (<StoreEdit entity={entity} module={this.getModuleKey()} edit={(data: any) => this.edit(data, entity, users)} users={users}/>);
    }

    loadUser = async (entity: any) => {
        const provider = DWEnvironmentManager.getDataProvider ();
        const config: CallRequestConfig<any> = {
            params: {
                storeId: entity.id
            }
        }
        const userList = await provider.getList(undefined, undefined, config, 'users' );
        if (userList) {
            const users = userList.map( (user: any) => { return {label: user.username, value: user.id}});
            return users;
        }
    }

    edit = async (data: any, entity: any, users: any) => {
        const provider = DWEnvironmentManager.getDataProvider ();
        // ------ parametri fissi non settabili
        data.storeType = 'STORE'
        data.syncCode = ''
        data.attributes = { expectedDevicesReaders:0, expectedDevicesSmartphones : 0 }
        // ------
        delete data.addStore;

        const req = {
            data: Object.assign({}, data),
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const res : Store = await provider.addOne(req, `stores/${entity.id}`);

        // TODO call a kernel.api

        let updateUser: User[] = []
        data.users.forEach(async (userId : any) => {
            const user : User = await provider.getOne(userId,{} , 'users');
            user.store = res;
            user.storeId = res.id;
            updateUser.push(user);
            DWEnvironmentManager.getUserDataProvider().update(user.id,user);
        });


        if (users.length > 0) {
            users.forEach(async (u : any) => {
                const findUser = updateUser.find( (user: any) => user.id = u.value);
                 if (!findUser) {
                    const user : User = await provider.getOne(u.value,{} , 'users');
                    user.store = null;
                    user.storeId = null;
                    DWEnvironmentManager.getUserDataProvider().update(user.id,user)
                 }
            });
        }

        if (res) {
            successToast(__('common.success'), __('stores.edit.success'));
            navigate(this.getRoute());
        }
    }

}

const module = new StoreModule();
export default module;


export const fetchStores = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();
    const moduleManager = DWEnvironmentManager.getModuleManager();
    const storeModule = moduleManager.getModule('stores');

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, storeModule.getModuleKey())
        .then((response) =>
            response.map((res: any) => ({
                label: `${res.code} - ${res.description}`,
                value: res.id,
            })),
        );
}



export const fetchUsers = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();
    const moduleManager = DWEnvironmentManager.getModuleManager();
    const storeModule = moduleManager.getModule('users');

    return provider.getList(20, 0, {
        params: {
            username_like: key
        }
    }, storeModule.getModuleKey())
        .then((response) =>
            response.map((res: any) => ({
                label: `${res.username}`,
                value: res.id,
            })),
        );
}
