import { Card, Col, Layout, Row } from 'antd';
import React from 'react';
import './Dashboard.css';
import DWEnvironmentManager from "../services/DWEnvironmentManager";
import { __ } from '../translations/i18n';
import { navigate } from '../shared/router';

const { Content } = Layout;

const Dashboard: React.FC = () => {

    return (
        <Content className='dashboard'>
            <img src={DWEnvironmentManager.getMainLogo()} className='App-logo' alt={DWEnvironmentManager.getApplicationName()} />
        </Content>
    );
}

export default Dashboard;
