import { EditOutlined } from "@ant-design/icons";
import { Component } from "react";
import CrudForm from "../../../components/CrudForm";
import { FormRow } from "../../../components/form/DynamicForm";
import DWEnvironmentManager from "../../../services/DWEnvironmentManager";
import { CallRequestConfig } from "../../../shared/api";
import { fetchParent } from "../../modules/AreaModule";
import { __b } from "../../translations/i18n";
import ObjectUtils from "../../utils/ObjectsUtil";

interface Props {
    entity: any,
    module: string
    edit: (data: any) => any
}

interface State {
    users: any,
    loadedUser: boolean
    roles: any[]
}

export default class AreaEdit extends Component<Props, State> {

    loadAll = async () => {
        const roles = await fetchAllRoles()
        this.setState({ roles: roles })
    }

    state: State = {
        users: [],
        loadedUser: false,
        roles: []
    }

    provider = DWEnvironmentManager.getDataProvider();

    async componentDidMount(): Promise<void> {
        await this.loadUser();
        await this.loadAll();
    }

    loadUser = async () => {
        const config: CallRequestConfig<any> = {
            params: {
                storeId: this.props.entity.id
            }
        }
        const userList = await this.provider.getList(undefined, undefined, config, 'users');
        if (userList) {
            const users = userList.map((user: any) => { return { label: user.username, value: user.id } });
            this.setState({ users, loadedUser: true });
        }
    }

    getEditFormFields = (): FormRow[] => {

        return [
            {
                fields: [
                    {
                        name: 'description',
                        label: __b('warranties.fields.operatingCountry'),
                        type: 'text',
                        required: false,
                        initialValue: this.props.entity.description,
                        value: this.props.entity.description,
                        fetchOptions: (key: string) => (this.fetchHierarchies(key)),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'parent',
                        label: __b('warranties.fields.parent'),
                        type: 'autocompleteselect',
                        initialValue: this.props.entity.parent ? { label: this.props.entity.parent.code, value: this.props.entity.parent.id } : undefined,
                        fetchOptions: (key: string) => (this.fetchParent(key)),
                        required: false,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'isoCode',
                        label: __b('warranties.fields.isoCode'),
                        type: 'text',
                        required: false,
                        initialValue: this.props.entity.isoCode,
                        value: this.props.entity.isoCode,
                        colConf: {
                            span: 8
                        }
                    },
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    {
                        name: 'addAreas',
                        label: __b('hierarchies.edit.modify'),
                        type: 'button',
                        htmlType: 'submit',
                        block: 'true',
                        icon: <EditOutlined />,
                        colConf: { span: 4, offset: 10 }
                    }
                ]
            }
        ];
    }


    fetchHierarchies = async (key: string) => {
        return await fetchHierarchies(key);
    }

    fetchRoles = async (key: string) => {
        return await fetchRoles(key);
    }

    fetchParent = async (key: string) => {
        return await fetchParent(key);
    }

    render() {
        const { users } = this.state;
        const { loadedUser } = this.state;
        const { module } = this.props
        if (users.length <= 0 && !loadedUser) {
            return <div />
        }
        return (<CrudForm module={module} callback={this.props.edit} getFormFields={this.getEditFormFields} cardSize={12} type={"edit"} />);
    }
}

export const fetchHierarchies = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, 'hierarchies')
        .then((response) =>
            response.map((res: any) => ({
                label: res.description,
                value: res.id,
            })),
        );
}

export const fetchRoles = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();

    return provider.getList(20, 0, {},
        'roles')
        .then((response) =>
            ObjectUtils.stringIncludesDescription(response, key)
        );
}


export const fetchAllRoles = async () => {

    const provider = DWEnvironmentManager.getDataProvider();
    let rolesArray: any[] = []
    await provider.getList(0, 0, {}, 'roles')
        .then((response) => {
            response.forEach((element: any) => {
                rolesArray.push({ label: element.code, value: element.id, item: element })
            });

        });
    return rolesArray
}


