import config from "../../config/config";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { addFilter, applyFilters } from "../../services/HooksManager";
import { ModuleList } from "../../services/ModulesManager";
import bulgari from "../assets/images/bulgari.png";
import status_logo from "../assets/images/exe_card.svg";
import activation_logo from "../assets/images/exe_correct.svg";
import return_logo from "../assets/images/exe_return.svg";
import logo from "../assets/images/logo.svg";
import areaModule from "../modules/AreaModule";
import customDashbardModule from "../modules/CustomDashboardModule";
import helpModule from "../modules/HelpModule";
import {infoModule} from "../modules/InfoModule";
import landingModule from "../modules/LandingModule";
import manualModule from "../modules/ManualModule";
import massiveActivationMoule from "../modules/MassiveActivationModule";
import partnerInventoriesModule from "../modules/PartnerInventoriesModule";
import prewarrantyModule from "../modules/PrewarrantyModule";
import productModule from "../modules/ProductModule";
import profileModule from "../modules/ProfileModule";
import returnModule from "../modules/ReturnModule";
import settingModule from "../modules/SettingModule";
import splashModule from "../modules/SplashModule";
import statusModule from "../modules/StatusModule";
import storeModule from "../modules/StoreModule";
import userModule from "../modules/UserModule";
import warrantyModule from "../modules/WarrantyModule";
import resetPasswordModule from "../modules/ResetPasswordModule";



class BuEnvironmentManager {

    constructor() {
        addFilter('app_main_logo', this.getMainLogo);
        addFilter('app_name', this.getApplicationName);
        addFilter('app_header_logo', this.getHeaderLogo);
        addFilter('app_default_route', this.getDefaultRoute)
        // addFilter('app_modules', this.getModules)
        addFilter('app_version', this.getVersion);

        addFilter('app_modules', this.addModules, 90);
    }


    getVersion(string: any) {

        return 'v 2.1.0';
    }

    getMainLogo(main_logo: any) {
        return logo;
    }

    getHeaderLogo(header_logo: any){
        return bulgari;
    }

    getApplicationName(name: string) {
        return 'Bulgari Digital Warranty';
    }

    getDefaultRoute(name: string) {
        return DWEnvironmentManager.getUserManager().isLoggedUser() ? '/dashboard' : '/welcome';
    }

    getActivationImg() {
        /**
       * Ritorna il logo dell'attivazione
       * @hook app_activation_logo
       * @param activation_logo:string logo
       */
        return applyFilters('app_activation_logo', activation_logo);
    }

    getStatusImg() {
        /**
       * Ritorna il logo dell'attivazione
       * @hook app_activation_logo
       * @param activation_logo:string logo
       */
        return applyFilters('app_status_logo', status_logo);
    }


    getReturnImg() {
        /**
       * Ritorna il logo del reso
       * @hook app_return_logo
       * @param activation_logo:string logo
       */
        return applyFilters('app_return_logo', return_logo);
    }


    addModules = (modules: ModuleList) => {
        modules[productModule.getModuleKey()] = productModule;
        modules[storeModule.getModuleKey()] = storeModule;
        modules[userModule.getModuleKey()] = userModule;
        modules[warrantyModule.getModuleKey()] = warrantyModule;
        modules[settingModule.getModuleKey()] = settingModule;
        modules[prewarrantyModule.getModuleKey()] = prewarrantyModule;
        modules[areaModule.getModuleKey()] = areaModule;
        modules[profileModule.getModuleKey()] = profileModule;
        modules[manualModule.getModuleKey()] = manualModule;
        modules[helpModule.getModuleKey()] = helpModule;
        modules[infoModule.getModuleKey()] = infoModule;
        modules[splashModule.getModuleKey()] = splashModule;
        modules[customDashbardModule.getModuleKey()] = customDashbardModule;
        modules[returnModule.getModuleKey()] = returnModule;
        modules[statusModule.getModuleKey()] = statusModule;
        modules[massiveActivationMoule.getModuleKey()] = massiveActivationMoule;
        modules[partnerInventoriesModule.getModuleKey()] = partnerInventoriesModule;
        modules[landingModule.getModuleKey()] = landingModule;
        modules[resetPasswordModule.getModuleKey()] = resetPasswordModule;

        return modules;
    }

    getTrackerRedirect() {
        return config.TRACKER_REDIRECT;
    }

    getExtendedRedirect() {
        return config.EXTENDED_REDIRECT;
    }

    getTrackerV2Redirect() {
        return config.TRACKERV2_REDIRECT;
    }
}


const buEnvironmentManager = new BuEnvironmentManager();
export default buEnvironmentManager;

