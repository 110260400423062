import { Component } from "react";
import { Button, Col, Row, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import DWDataProvider from "../providers/DWDataProvider";
import { __ } from "../translations/i18n";
import { Content } from "antd/lib/layout/layout";
import { FileExcelOutlined } from "@ant-design/icons";
import { applyFilters } from "../services/HooksManager";
import DWEnvironmentManager from "../services/DWEnvironmentManager";

export type DWColumn = {
    column: string,
    render?: any
}

interface Props {
    columns: ColumnsType<any>;
    data?: any;
    provider: DWDataProvider;
    onMapData?: (data: any) => any;
    params?: any;
    showButton?: boolean;
    module: any;
}

interface State {
    dataSource: any;
    total: number;
    offset: number;
    defaultPageSize: number;
    loadingExport: boolean;
    loadingTableData: boolean;
    defaultParams: any;
}

export default class DWTable extends Component<Props, State> {

    state: State = {
        dataSource: null,
        total: 0,
        defaultPageSize: 25,
        offset: 0,
        loadingExport: false,
        loadingTableData: false,
        defaultParams: null
    }

    async componentDidMount() {
        /**
         * Ritorna i default params per la ricerca
         * @hook search_form_data_default_params_${this.props.getModuleKey()}
         * @param params:{} 
         */
        const defaultParams = applyFilters(`search_form_data_default_params_` + this.props.module.getModuleKey(), this.props.params);
        this.setState({defaultParams});
        // this.props.params = defaultParams;
        
        // Caricamento dati ricerca se presenti filtri in Local Storage
        const storage = DWEnvironmentManager.getStorage();
        const storedData = await storage.load(`search_form_data_${this.props.module.getModuleKey()}`);
        if (storedData) {
            await this.reloadData(this.state.defaultPageSize, this.state.offset, storedData);
            return;
        }

        await this.reloadData(this.state.defaultPageSize, this.state.offset, defaultParams);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.params !== this.props.params) {
            this.reloadData(this.state.defaultPageSize, this.state.offset, this.props.params).then(() => { });
        }
    }

    /**
     * Metodo per la gestione della paginazione
     * @param page:number Pagina corrente
     * @param pageSize:number Grandezza pagina
     */
    pagination = async (page: number, pageSize: number) => {
        await this.reloadData(pageSize, (page - 1) * pageSize, this.props.params, false);
    }

    reloadData = async (pageSize: number, offset: number, params: { [key: string]: string }, needCount = true) => {
        this.setState({loadingTableData: true});
        let {defaultParams} = this.state;
        const req = {
            params: Object.assign({}, defaultParams, params)
        };

        const count = !needCount ? { value: this.state.total } : await this.props.provider.getCount(pageSize, offset, req);
        const response = await this.props.provider.getList(pageSize, offset, req);
        const dataSource = await this.props.onMapData?.(response) ?? response;
        this.setState({
            dataSource: dataSource,
            total: count.value,
            defaultPageSize: pageSize,
            offset: offset,
            loadingTableData: false
        })
    }


    export = async () => {
        /**
         * Ritorna i default params per l'export della tabella
         * @hook export_form_data_default_params_${this.props.module.getModuleKey()}
         * @param params: {}
         */
        const defaultParams = applyFilters(`export_form_data_default_params_` + this.props.module.getModuleKey(), this.props.params);

        if (!this.props.params && defaultParams) {
            await this.exportList(defaultParams);
        } else {
            await this.exportList(this.props.params);
        }
    }

    exportList = async (params: { [key: string]: string }) => {
        this.setState({loadingExport: true});
        const req = {
            params: Object.assign({}, params),
            timeout: 3000000,
            responseType: "arraybuffer"
        };
        await this.props.provider.export(req);
        this.setState({loadingExport: false});
    }

    /**
     * Metodo per la visualizzazione dei totali
     * @param total:number Totale elementi
     * @returns descrizione Totale elementi
     */
    total = (total: number) => {
        return __('common.table.total', { total: total })
    }

    /**
     * Metodo per la gestione del sorting
     * @param pagination Paginazione attuale
     * @param filters Filtri se presenti
     * @param sorter Sorting tabella
     */
    handleSorting = async (pagination: any, filters: any, sorter: any) => {
        const storage = DWEnvironmentManager.getStorage();
        const offset = (pagination.current - 1) * pagination.pageSize;
        const limit = pagination.pageSize;
        const storedData = await storage.load(`search_form_data_${this.props.module.getModuleKey()}`);
        let params = {};
        if (sorter.hasOwnProperty("column") && sorter.column !== undefined) {
            params = { asc: sorter.order === "ascend" ? true : false, desc: sorter.order === "descend" ? true : false, orderBy: sorter.columnKey };
            this.reloadData(limit, offset, Object.assign({}, params, storedData));
        } else {
            this.pagination(pagination.current, pagination.pageSize);
        }
    };


    render() {

        const { columns, data, showButton } = this.props;
        const { dataSource, total, defaultPageSize, loadingExport, loadingTableData } = this.state;

        return <div>
            {showButton && (
                <Content style={{marginTop: 20}}>
                    <Row gutter={[24, 24]} justify="end">
                        <Col span={2} >
                            <Spin spinning={loadingExport} delay={500}>
                                <Button onClick={() => this.export()} className="exp" type="primary" icon={<FileExcelOutlined />} block>{__('common.table.export').toUpperCase()}</Button>
                            </Spin>
                        </Col>
                    </Row>
                </Content>)}
            {!dataSource && !data && (<div id={"loader-container"} className="loader-container" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}><Spin /></div>)}
            {(dataSource || data) && (<Table dataSource={dataSource ?? data} columns={columns} onChange={this.handleSorting}
                pagination={{
                    defaultPageSize: defaultPageSize,
                    showSizeChanger: true,
                    pageSizeOptions: [defaultPageSize.toString(), '50', '100', '150'],
                    position: ["bottomCenter", "topCenter"],
                    total: total,
                    showQuickJumper: true,
                    showTotal: this.total,
                    onChange: this.pagination,
                    onShowSizeChange: this.pagination
                }} loading={loadingTableData} />)}
        </div>

    }
}

