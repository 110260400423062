import {Button, Card, Checkbox, Col, Form, Input, Modal, Row} from "antd";
import React, {Component} from "react";
import dWUserManager from "../services/DWUserManager";
import {__} from "../translations/i18n";
import dwEnvironmentManager from "../services/DWEnvironmentManager";
import Link from "antd/lib/typography/Link";
import type {FormInstance} from "antd/es/form";
import {errorToast, infoModal, successToast} from "../services/NotificationManager";
import {
    InfoCircleTwoTone,
    LockOutlined,
    LoginOutlined,
    SendOutlined,
    SettingTwoTone,
    UserOutlined
} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import {getQueryParams, navigate} from "../shared/router";
import {applyActions, applyFilters} from "../services/HooksManager";
import {Header} from "antd/lib/layout/layout";

interface IProps { }

interface IState {
    username: string;
    password: string;
    remindMe: boolean;
    credentialError: boolean;
    openRecoverModal: boolean;
    openActivationModal: boolean;
}

class LoginPage extends Component<IProps, IState> {
    formRef = React.createRef<FormInstance>();
    activationToken: string = '';
    usernameQueryParams = null;

    activationTokenParamName = 'activation_token';
    usernameParamName = 'username';

    __activationTokenParamNameFilter() {
        return applyFilters('activation_token_param_name_filter', this.activationTokenParamName);
    }

    __usernameParamNameFilter() {
        return applyFilters('username_param_name_filter', this.usernameParamName);
    }
    recoverPassword: boolean = true

    constructor(props: IProps) {
        super(props);
        this.state = {
            username: "",
            password: "",
            remindMe: false,
            credentialError: false,
            openRecoverModal: false,
            openActivationModal: false
        };

        // addFilter('activation_user_modal_filter', this.activationUserModal);
        // addFilter('recover_user_password_modal_filter', this.recoverUserPasswordModal);
        const queryParams = getQueryParams();
        this.activationToken = queryParams[this.__activationTokenParamNameFilter()];
        this.usernameQueryParams = queryParams[this.__usernameParamNameFilter()];
    }

    async componentDidMount() {
        if (this.activationToken) {
            this.setState({openActivationModal: true});
        }
    }

    onChangeUsername(value: string) {
        this.setState({ username: value });
    }

    onChangePassword(value: string) {
        this.setState({ password: value });
    }

    onChangeRemindMe(value: boolean) {
        this.setState({ remindMe: value });
    }

    __getRecoverPassword() {
        return applyFilters('login_recover_password_visible', this.recoverPassword);
    }

    onReset = () => {
        this.formRef.current!.resetFields();
    };

    sendLogin() {
        if (this.state.username && this.state.password) {
            dWUserManager
                .doLogin(this.state.username, this.state.password, this.state.remindMe)
                .then((login) => {
                    if (login) {

                        /**
                         * Applica un azione dopo aver effetuato Login e prima del Reload dell'App
                         * @hook action_after_login_pre_reload
                         */
                        applyActions('action_after_login_pre_reload');

                        // effettua reload dell'app
                        dwEnvironmentManager.reload();

                        /**
                         * Applica azione dopo aver effettuato Login e dopo Reload dell'App
                         * @hook action_after_login_post_reload
                         */
                        applyActions('action_after_login_post_reload');
                    } else {
                        this.onReset();
                        errorToast(__("error.unauthorized"), __("login.failed"));
                    }
                });
        }
    }

    getLogo = () => {
        return dwEnvironmentManager.getMainLogo();
    };

    sendRecoverPassword = async (data: any) => {
        const provider  = dwEnvironmentManager.getUserDataProvider();
        await provider.recoverPassword(data.username, data.email);
        if (data.username && data.email) {
            successToast(__('common.success'), __('login.recover_success'));
            this.setState({ openRecoverModal: false});
        }
    }


    showModal() {
        infoModal({
            title: dwEnvironmentManager.getApplicationName(),
            content: (<div>{dwEnvironmentManager.getVersion()}<p> {__('menu.modal.copyright')} </p></div>)
        })
    };

    async activateUser(data: any, activationToken: any, username: any) {
        if (data.password !== data.confirmPassword) {
            errorToast(__('error.error'), __('users.add.errorPasswordMatch'));
        } else {
            const provider = dwEnvironmentManager.getDataProvider();
            const request = {
                username: username,
                password: data.password,
                token: activationToken
            }
            const res = await provider.addOne({
                params: request,  headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }}, 'users/activate');

            if (res) {
                this.setState({ username: data.username, password: data.password});
                this.sendLogin();
            }
        }

    }

    closeActivationModal() {
        this.setState({ openActivationModal: false});
        navigate('/login');
    }


    getHeaderLogo = () => {
        return dwEnvironmentManager.getHeaderLogo();
    }

    render() {
        const { openRecoverModal, openActivationModal } = this.state;

        return (
            <div className="App">
                <Header className='login'>
                <img className="headerLogo" src={this.getHeaderLogo()} alt="logo" />
                <h4>{__("login.header")}</h4>
                 <SettingTwoTone twoToneColor="#AA8A5F" onClick={() => navigate('/settingsExplore')} />
                 <InfoCircleTwoTone twoToneColor="#AA8A5F" onClick={this.showModal}/>
                </Header>
                <header className="App-header">
                    <img src={this.getLogo()} className="App-logo" alt="logo" />
                </header>
                <Row gutter={[24, 24]} justify={"center"} style={{ marginTop: 20 }}>
                    <Col xs={24} lg={12}>
                        <Card>
                            <Form
                                ref={this.formRef}
                                name="basic"
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Col span={24}>
                                    <Form.Item
                                        label={__("login.username")}
                                        name="username"
                                        rules={[
                                            {
                                                required: true,
                                                message: __("login.mandatory_username"),
                                            },
                                        ]}
                                    >
                                        <Input
                                            onChange={(event) =>
                                                this.onChangeUsername(event.target.value)
                                            }
                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label={__("login.password")}
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: __("login.mandatory_password"),
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            onChange={(event) =>
                                                this.onChangePassword(event.target.value)
                                            }
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    {this.__getRecoverPassword() && <div style={{ marginBottom: 10 }}>
                                        <Link onClick={() => this.setState({ openRecoverModal: true })}>{__("login.forgot_password")}</Link>
                                    </div>}

                                    <Form.Item name="remember" valuePropName="checked">
                                        <Checkbox
                                            onChange={(event) =>
                                                this.onChangeRemindMe(event.target.checked)
                                            }
                                        >
                                            {__("login.remember_me")}
                                        </Checkbox>
                                    </Form.Item>
                                </Col>

                                <Row gutter={[24, 24]} justify="center">
                                    <Col span={12}>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={openActivationModal}
                                                onClick={() => this.sendLogin()}
                                                icon={<LoginOutlined />}
                                                block
                                            >
                                                {__("login.sign_in")}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>
                <Modal open={openRecoverModal} onCancel={() => this.setState({ openRecoverModal: false })} width={700} centered footer={false}>
                    <Row gutter={[24, 24]} justify={"center"}>
                        <Col span={20} style={{ textAlign: "center" }}>
                            <Title level={3}>{__('login.recover_password_title')}</Title>
                        </Col>
                        <Col span={20} style={{ textAlign: "center" }}>
                            <Title level={5} italic style={{ color: "grey" }}>{__('login.recover_password_subtitle')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={[24, 24]} justify={"center"} style={{ marginTop: 20}}>
                        <Col span={18}>
                            <Form layout="vertical" onFinish={this.sendRecoverPassword}>
                                <Form.Item rules={[{ required: true, message: __("login.mandatory_username") }]} label={__('login.fields.username')} name={'username'}>
                                    <Input type="text" />
                                </Form.Item>

                                <Form.Item rules={[{ required: true, message: __("login.mandatory_email") }]} label={__('login.fields.email')} name={'email'}>
                                    <Input type="email" />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" block icon={<SendOutlined />}>{__('login.fields.send')}</Button>
                                </Form.Item>

                            </Form>
                        </Col>
                    </Row>
                </Modal>
                {/*{openRecoverModal && applyFilters('recover_user_password_modal_filter', <></>)}*/}
                {/*{openActivationModal && applyFilters('activation_user_modal_filter', <></>)}*/}
                <Modal open={openActivationModal} onCancel={() => this.closeActivationModal()} width={700} centered footer={false}>
                    <Row gutter={[24, 24]} justify={"center"}>
                        <Col span={20} style={{ textAlign: "center" }}>
                            <Title level={3}>{__('login.activation_user_title')}</Title>
                        </Col>
                        <Col span={20} style={{ textAlign: "center" }}>
                            <Title level={5} italic style={{ color: "grey" }}>{__('login.activation_user_subtitle')}</Title>
                        </Col>
                    </Row>

                    <Row gutter={[24, 24]} justify={"center"} style={{ marginTop: 20}}>
                        <Col span={18}>
                            <Form layout="vertical" onFinish={(data: any) => this.activateUser(data, this.activationToken, this.usernameQueryParams)}>
                                <Form.Item rules={[{ required: true, message: __("login.mandatory_password") }]} label={__('login.password')} name={'password'}>
                                    <Input.Password
                                        placeholder={__("login.placeholder.input_password")}
                                    />
                                </Form.Item>

                                <Form.Item rules={[{ required: true, message: __("login.mandatory_confirm_password") }]} label={__('login.confirm_password')} name={'confirmPassword'}>
                                    <Input.Password
                                        placeholder={__("login.placeholder.input_confirm_password")}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit" block icon={<SendOutlined />}>{__('login.fields.send')}</Button>
                                </Form.Item>

                            </Form>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}

export default LoginPage;
