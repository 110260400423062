// eslint-disable-next-line import/no-anonymous-default-export
export default {
    app: {
        name: 'Цифровая гарантия Temera',
        url: 'www.buccellati.com'
    },
    error: {
        generic_error: 'Общая ошибка',
        error: 'Ошибка',
        forbidden: 'Доступ запрещен',
        not_found: 'Не найдено',
        unauthorized: 'Не авторизован',
        invalid_credentials: 'Недействительные учетные данные!',
        duplicateKey: 'Дублирующий ключ',
        api: 'Ошибка API {{method}} {{api}} :\n {{error_message}}',
        requiredRowEditTable: 'Таблица должна содержать хотя бы одну строку',
        error_upload_file: 'Не удалось загрузить файл.'
    },
    success: {
        confirm_delete: 'Элемент успешно удален',
        confirm_update: 'Успешно обновлен',
        confirm_password: 'Пароль успешно изменен, теперь вы можете войти в приложение'
    },
    warning: {
        ask_delete: 'Вы уверены, что хотите удалить элемент?',
        unreversible_delete: 'Удаление будет необратимым',
        warning: 'Предупреждение'
    },
    dashboard: {
        module_name: 'Панель управления'
    },
    common: {
        confirm: 'Подтвердить',
        cancel: 'Отмена',
        save: 'Сохранить',
        reset: 'Сбросить',
        search: 'Поиск',
        table: {
            total: 'Всего {{total}} результатов',
            export: 'Экспорт',
            changeMe: 'Изменить меня',
            key: 'Ключ',
            value: 'Значение',
            attributes: 'Атрибуты',
            addRow: 'Добавить строку',
            keyDescription: 'Описание ключа',
            valueDescription: 'Описание значения'
        },
        edit: 'Изменить',
        details: 'Подробности',
        addRow: 'Добавить строку',
        delete: 'Удалить',
        success: 'Успешно',
        fieldRequired: 'Обязательное поле',
        api: 'API',
        email: 'Электронная почта',
        goBack: 'Назад',
        goAhead: 'Далее',
        description: 'Описание',
        import_success: 'Файл успешно загружен',
        click_upload: 'Нажмите, чтобы загрузить файл'
    },
    menu: {
        dashboard: 'Панель управления',
        users: 'Пользователи',
        stores: 'Магазины',
        products: 'Продукты',
        logout: 'Выйти',
        digital_warranties: 'Цифровые гарантии',
        settings: 'Настройки',
        about: 'О нас',
        modal: {
            copyright: 'Авторское право © 2015 Temera. Все права защищены'
        },
        controlPanel: 'Панель управления'
    },
    login: {
        username: 'Имя пользователя',
        password: 'Пароль',
        confirm_password: 'Подтвердите пароль',
        sign_in: 'Войти',
        mandatory_username: 'Пожалуйста, введите ваше имя пользователя!',
        mandatory_password: 'Пожалуйста, введите ваш пароль!',
        mandatory_email: 'Пожалуйста, введите вашу электронную почту!',
        mandatory_confirm_password: 'Пожалуйста, подтвердите ваш пароль!',
        forgot_password: 'Забыли пароль?',
        remember_me: 'Запомнить меня',
        failed: 'Ошибка аутентификации',
        recover_password_title: 'Заполните форму ниже, чтобы сбросить ваш пароль',
        recover_password_subtitle: 'Вы получите письмо с ссылкой для сброса пароля',
        recover_success: 'Письмо успешно отправлено',
        activation_user_title: 'Настройте ваш пароль для доступа',
        activation_user_subtitle: 'Это действие будет необходимо только при первом входе',
        header: 'ЦИФРОВАЯ ГАРАНТИЯ И ДОСТОВЕРНОСТЬ',
        fields: {
            username: 'Имя пользователя',
            email: 'Электронная почта',
            send: 'Отправить'
        },
        placeholder: {
            input_password: 'Введите пароль',
            input_confirm_password: 'Введите пароль еще раз'
        }
    },
    loginSSO: {
        label: 'Войти с помощью вашей учетной записи',
        sso_button: 'Вход с помощью SSO',
        with_credentials: 'Войти с помощью учетных данных'
    },
    module: {
        listing: {
            title: 'Управление {{moduleName}}'
        },
        add: {
            title: 'Добавить {{entityName}}'
        },
        edit: {
            title: 'Изменить {{entityName}}'
        },
        details: {
            title: 'Подробности о {{entityName}}'
        },
        upload: {
            title: 'Загрузка'
        }
    },
    users: {
        module_name: "Пользователи",
        entity: {
            singular_name: "Пользователь",
            plural_name: "Пользователи"
        },
        details: {
            store: 'Магазин',
            email: 'Электронная почта'
        },
        columns: {
            details: 'Подробности',
            store: 'Магазин',
            modified: 'Изменен',
            created: 'Создан',
            surname: 'Фамилия',
            name: 'Имя',
            admin: 'Администратор',
            username: 'Имя пользователя',
            personal: 'Личный',
            exception: 'Исключение'
        },
        fields: {
            username: 'Имя пользователя',
            surname: 'Фамилия',
            name: 'Имя',
            storeType: 'Тип магазина',
            store: 'Магазин',
            password: 'Пароль',
            confirmPassword: 'Подтвердите пароль',
            email: 'Электронная почта',
            role: 'Роль',
            admin: 'Администратор',
            attributes: 'Атрибуты',
            add: 'Добавить пользователя',
            area: 'Зона',
            edit: 'Изменить пользователя',
            userSoldTo: 'Пользователь Sold To',
            errors: {
                username: 'Введите имя пользователя',
                password: 'Введите пароль'
            }
        },
        add: {
            success: 'Пользователь успешно добавлен',
            errorPasswordMatch: 'Пароли не совпадают'
        },
        edit: {
            success: 'Пользователь успешно изменен'
        }
    },
    prewarranties: {
        module_name: "Предварительные гарантии",
        entity: {
            singular_name: "Предварительная гарантия",
            plural_name: "Предварительные гарантии"
        }
    },
    hierarchies: {
        module_name: "Зоны",
        entity: {
            singular_name: "Новая зона",
            plural_name: "Новая зона"
        },
    },
    partnerinventory: {
        module_name: "Инвентарь"
    },
    warranties: {
        module_name: "Гарантии",
        entity: {
            singular_name: "Гарантия",
            plural_name: "Гарантии"
        },
        columns: {
            details: 'Подробности',
            store: 'Магазин/Пользователь Ret',
            card: 'Замена карты',
            return: 'Возврат',
            storeUserAct: 'Магазин/Пользователь Act',
            activation: 'Активация',
            serialNumber: 'Серийный номер',
        },
        details: {
            image: "Изображение",
            product: "Продукт",
            date: "Дата",
            store: "Магазин",
            user: "Оператор",
            delete: "Удалить гарантию",
            activation: "Активация",
            return: "Возврат",
            productPage: "Страница продукта",
            downloadPdf: "Скачать PDF"
        },
        fields: {
            from: 'От',
            to: 'До',
            serialNumber: 'Серийный номер',
            returned: 'Возвращено',
            storeType: 'Тип магазина',
            store: 'Магазин'
        }
    },
    stores: {
        module_name: "Магазины",
        entity: {
            singular_name: "Магазин",
            plural_name: "Магазины"
        },
        columns: {
            details: 'Подробности',
            store: 'Магазин',
            type: 'Тип',
            activation: 'Активация',
            operationCountry: 'Страна/Регион операции',
            ISOCode: 'Код ISO',
            soldTo: 'Продано',
            status: 'Статус'
        },
        fields: {
            from: 'От',
            to: 'До',
            storeType: 'Тип магазина',
            store: 'Магазин',
            address: 'Адрес',
            users: 'Пользователи',
            add: 'Добавить магазин',
            edit: 'Изменить магазин',
            nameCodeStore: 'Имя/Код магазина',
            activationStatus: 'Статус активации',
            operatingCountry: 'Страна/Регион операции',
            sold_to: 'Продано',
            ship_to: 'Отправлено',
            errors: {
                store: 'Введите имя магазина'
            }
        },
        details: {
            users: 'Пользователи',
            lastAccess: 'Последний доступ: ',
            active: 'Активно',
            expectedDevices: 'Ожидаемые устройства',
            usbDevice: 'USB NFC считыватель',
            mobileDevice: 'Смартфон'
        },
        add: {
            success: 'Магазин успешно создан'
        },
        edit: {
            success: 'Магазин успешно изменен!'
        }
    },
    masterdata: {
        module_name: "Продукты",
        entity: {
            singular_name: "Продукт",
            plural_name: "Продукты"
        }
    },
    settings: {
        set_system: 'Настройте вашу систему',
        save_system: "Сохранить"
    },
    products: {
        columns: {
            details: 'Подробности',
            shortDescription: 'Краткое описание',
            description: 'Описание',
            creationDate: 'Дата создания',
            photo: 'Фото',
            marketingDescription: 'Маркетинговое описание',
            materialType: 'Тип материала',
            nfcEmbedded: 'Встроенный NFC',
        },
        fields: {
            id: 'Идентификатор',
            code: 'Код',
            description: 'Описание',
            attributes: 'Атрибуты',
            materialType: 'Тип материала',
            NFC: 'Встроенный NFC'
        },
        edit: {
            successEditDescription: 'Продукт успешно обновлен!'
        }
    },
    geolocation: {
        not_enabled: 'Геолокация не включена'
    },
    image: {
        masterdata_image: "Изображение продукта"
    },
    landing: {
        identified_product: "Идентифицированный продукт",
        sku_upc: "SKU/UPC",
        serial: "Серийный номер",
        activation_store: "Магазин активации",
        activation_date: "Дата активации",
        information: "Подробная информация об активации продукта",
        card: "Посадочная карта"
    },
    profiles: {
        module_name: "Профили",
        entity: {
            singular_name: 'Профиль'
        }
    },
    'bu-manual': {
        module_name: "Руководство",
        entity: {
            singular_name: 'Руководство'
        }
    }
}
