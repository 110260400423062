/* eslint-disable import/no-anonymous-default-export */
export default {
    app: {
        url: 'www.bulgari.com'
    },
    common: {
        close: '关闭'
    },
    menu: {
        digital_prewarranties: '数字预保修',
        areas: '地区',
        profiles: '授权配置',
        manual: '手册',
        help: '帮助',
        info: '信息',
        massive_activations: '批量激活',
        partnerinventories: '库存'
    },
    warranties: {
        title: 'DW激活',
        columns: {
            photo: '照片',
            cardSequential: '卡序列号',
            sap: 'SAP代码',
            description: '描述',
            expiredDate: '到期日期',
            activationType: '激活类型',
            activationUser: '激活用户/店铺',
            storeUser: '店铺/用户收到',
            substitution: '替代',
            serialNumber: '序列号',
            activation: '激活',
            return: '退还',
            returnUser: '退还用户/店铺',
            details: '详细信息',
            operatingCountry: '运营国家/地区',
            parent: '上级',
            isoCode: 'ISO代码',
            edit: '编辑',
            returnDeactivate: '退还/停用',
            status: '状态',
            storeCode: '店铺代码'
        },
        fields: {
            sequentialCard: '卡序列号',
            sapCode: 'SAP代码',
            serialNumber: '序列号',
            reso: '退还',
            storeType: '店铺类型',
            materialType: '材料类型',
            nameCodeStore: '名称/店铺代码',
            operatingCountry: '运营国家/地区',
            activationType: '激活类型',
            onBehalfOf: '代表',
            masterCode: '主代码',
            soldToParty: '售给方',
            responsibilityArea: '责任区域',
            isoCode: 'ISO代码',
            parent: '上级',
            activationDate: '激活日期',
            store: '店铺',
            user: '用户',
            add: '激活新产品',
            errors: {
                sapCode: '请输入SAP代码！',
                activationDate: '请选择激活日期！',
                serialNumber: '请输入序列号！',
                store: '请输入店铺！',
                user: '请输入用户！',
                sequentialCard: '请输入卡序列号！',
            },
            values: {
                all: '全部',
                reso: '退还',
                noReso: '非退还',
                accessories: '配饰',
                watches: '手表',
                jewels: '珠宝',
                fineJewelry: '高级珠宝',
                manuale: '手册',
                appManual: '应用手册',
                yes: '是',
                no: '否',
                internal: '内部',
                external: '外部',
                prospect: '预备',
                open: '开启',
                closed: '关闭',
                phaseOut: '退出阶段',
                future: '未来',
                notActivated: '未激活',
                pending: '待处理',
                activated: '已激活'
            },
        },
        add: {
            success: '激活成功添加'
        },
        changeExpiration: {
            success: '到期日期已成功更新',
            error: '更新失败',
            errorDescription: '到期日期未更新'

        },
        warning: '序列号和SAP代码不匹配',
        error: {
            watchInvalidSerialTitle: '序列号和SAP代码不一致',
            watchInvalidSerialDescription: '所选手表的序列号和SAP代码不一致。',
            warrantyAlreadyPresentTitle: '保修已存在',
            warrantyAlreadyPresentDescription: '所选产品已存在保修'
        }
    },
    prewarranties: {
        fields: {
            activationDate: '预激活日期'
        },
        add: {
            success: '成功添加预激活'
        },
        details: {
            image: "图像",
            product: "产品",
            date: "日期",
            store: "店铺",
            user: "操作员",
            delete: "删除预激活",
            activation: "激活",
        },
        error: {
            prewarrantyAlreadyPresentTitle: '预保修已存在',
            prewarrantyAlreadyPresentDescription: '所选产品已存在预保修',
            checkSerialSAPErrorTitle: '序列号和SAP代码不一致',
            checkSerialSAPErrorDescription: '序列号和SAP代码不一致'
        }
    },
    partnerinventories: {
        fields: {
            inventoryDateFrom: '库存日期从',
            inventoryDateTo: '库存日期到',
            sapCode: 'SAP代码',
            serialNumbers: '序列号',
            store: '店铺',
            soldTo: '售给',
            creationDateFrom: '创建日期从',
            creationDateTo: '创建日期到',
            lastInventory: '最后库存'
        },
        columns: {
            inventoryDate: '库存日期',
            serialNumber: '序列号',
            store: '店铺',
            soldTo: '售给',
            product: '产品',
            creationDate: '创建日期',
            storeCode: '店铺代码',
            quantity: '数量',
            sapCode: 'SAP代码'
        },
        button: {
            upload: '上传库存',
            download: '下载模板'
        }
    },
    hierarchies: {
        add: '添加新区域',
        success: '成功添加新区域',
        entity: {
            singular_name: "新区域",
            plural_name: "新区域"
        },
        edit: {
            modify: '修改区域',
            success: '成功修改区域'

        }

    },
    settings: {
        choose_language: '选择语言',
        language: '语言',
    },
    stores: {
        fields: {
            code: "代码",
            telephone: "电话",
            state: "状态",
            bvlAfss: "BVL AFSS",
            openingDate: "开业日期",
            closingDate: "关闭日期",
            phaseOutDate: "退出阶段日期",
            phaseOut: "退出阶段",
            soldToParty: "售给方",
            responsabilityArea: "责任区域",
            masterCodeCode: "主代码代码",
            masterCodeName: "主代码名称",
            soldTo: "售给",
            shipTo: "送货至",
            operatingCountry: "运营国家/地区",
            areaDefaultEmail: "区域默认电子邮件",
            nfcReader: "NFC读卡器设备",
            smartphone: "智能手机设备"
        },
        details: {
            activateStore: "激活店铺",
            sendAgain: "重新发送",
            activateSmartphone: "激活智能手机",
            requestActivationSuccess: "激活请求成功",
            activationSuccess: "店铺激活成功！",
            activate: "激活",
            askActivation: "确认激活店铺",
            askActivationDescription: "您确定要激活此店铺吗："
        }
    },
    profiles: {
        columns: {
            profileName: '配置名称',
            jobId: '工作ID',
            departmentId: '部门ID',
            jobDescription: '工作描述',
            departmentDescription: '部门描述',
            storeType: '店铺类型',
            storeId: '名称/店铺代码',
            dwArea: 'DW区域',
            edit: '编辑',
        },
        fields: {
            profileName: '配置名称',
            jobId: '工作ID',
            departmentId: '部门ID',
            jobDescription: '工作描述',
            departmentDescription: '部门描述',
            storeType: '店铺类型',
            storeId: '名称/店铺代码',
            dwArea: 'DW区域',
            add: '添加新配置',
            edit: '编辑配置',
            delete: '删除配置',
            created: '创建的店铺'
        },
        upload: {
            label: "您可以上传您的xls文件以将其转换为配置。",
            name: "单击或将文件拖放到此区域以进行上传",
            error: "文件上传失败。",
            success: "文件已成功上传。"
        }
    },
    manual: {
        columns: {
            photo: '照片',
            sapCode: 'SAP代码',
            instruction: '手册',
            brochure: '宣传册',
        },
        fields: {
            sapCode: 'SAP代码'
        }
    },
    help: {
        ask_support: '请求支持',
        how_to_use: '如何使用',
        how_to_use_video: '观看以下视频，以快速了解数字保修功能的使用方法',
        sent: '已发送电子邮件',
        not_sent: '未发送电子邮件',
        sales_activate_watches: '如何使用销售标签激活手表',
        shipping_activate_watches: '如何使用发货标签激活珠宝',
        sales_activate_accessories: '如何使用销售标签激活配饰',
        active_watches_type: '要激活手表，请输入：',
        sap_code: 'SAP代码',
        serial_number: '序列号'
    },
    splash: {
        welcome: "欢迎来到",
        presentation: "数字保修和真伪鉴别是一款完全数字化的应用程序，旨在管理产品的真伪和保修。加入我们吧。",
        enter: '进入'
    },
    info: {
        header: {
            watches: '手表 - 保修信息',
            accessories: '配饰 - 真伪信息',
            jewellery_pieces: '珠宝 - 真伪和Bulgari国际保修信息',
            high_jewellery: '高级珠宝 - 保修信息'
        },
        body: {
            watches: '此手表是BVLGARI的原创产品，由Bulgari Horlogerie SA位于瑞士纳沙泰尔的rue de Monruz 34制造，符合瑞士钟表业的最高质量标准。购买时激活的保修将在全球范围内得以承认，覆盖所有制造缺陷。此保修仅适用于在官方授权的BVLGARI销售网络、BVLGARI商店或授权经销商购买的产品。请查看产品的特定保修条件，可通过扫描卡片上的QR代码或使用卡片上的NFC功能访问。只有在购买时卖家在购买时填写、签署并日期的国际保修证书被正确激活后，您的BVLGARI手表才受此保修的保护。在保修有效期内，如果发现制造缺陷，您的手表将免费维修。此保修不包括手表和其附件的正常磨损、丢失、盗窃、不当使用或疏忽。此保修不适用于卡片上的序列号不清晰、已删除或由未经授权的第三方修改或尝试修理的情况。此保修不包括因手表不工作、制造缺陷或不准确而导致的任何间接或后果性损失。重要提示：对于任何维护或维修操作，您可以携带手表及其保修卡前往我们的精品店或经销商。通过扫描保修卡上的QR代码或使用卡片上的NFC功能，您还可以访问有关您的产品的用户手册和维护建议、保修条款以及保修期。这份国际保修不影响适用法律下的任何其他权利。',
            accessories: '此产品是BVLGARI的原创作品，经过最严格的质量测试制造和检验。通过激活卡片，所有制造缺陷都将在购买日期后的两年内得到全球范围内的承认。此Bulgari产品包含被动NFC（近场通信）标签。该标签包含有关产品的一些信息（芯片的唯一ID、产品代码、数字签名以确认真实性）。此标签可以由NFC设备读取。该标签不包含个人数据，也不能识别产品的所有者。如需更多信息，请联系privacy@bulgari.com',
            jewellery_pieces: '此珠宝是BVLGARI的原创作品，经过最严格的质量测试制造和检验。该珠宝附有一份国际真伪鉴别和保修证书，可在BVLGARI精品店或授权的BVLGARI珠宝经销商处购买时激活。国际保修覆盖所有制造缺陷，自购买日期起24个月，全球范围内有效，前提是在购买时已正确激活。在珠宝上刻有的序列号必须出现在保修证书上。如果未激活证书，则不适用国际保修。如果珠宝上刻有的序列号不清晰、已更改或删除，也不适用此保修。在保修期内，如果出现制造缺陷，可免费维修珠宝。保修不包括珠宝的正常磨损，也不包括不当使用、不当保养、疏忽或事故造成的缺陷或损害。不包括遗失或被盗情况。如果未经授权进行更改或尝试修复，也不适用此保修。重要提示：要进行维护操作，需要携带BVLGARI的原创珠宝和真伪鉴别和保修证书前往BVLGARI精品店或授权的BVLGARI珠宝经销商。通过扫描证书上的QR代码或使用NFC技术，还可以访问珠宝护理说明和您的珠宝的相关文档。此国际保修不影响根据适用法律享有的任何其他权利。',
            high_jewellery: '此产品是BVLGARI的原创作品，经过最严格的质量测试制造和检验。通过激活卡片，所有制造缺陷都将在购买日期后的两年内得到全球范围内的承认。'
        }
    },
    return: {
        title: "仅适用于HJ的产品，请输入序列号或SAP代码",
        return: "退货",
        fields: {
            serial: "序列号",
            next: "下一步",
            required: "序列号为必填项"
        },
        choiceProduct: "选择产品进行退货",
        summaryProduct: "产品摘要",
        codeSAP: "SAP代码",
        activationDate: "激活日期",
        store: "店铺",
        expiryDate: "到期日",
        duration: "持续时间",
        reasonReturn: "请在下面的表格中写下原因",
        errorTitle: "退货不可用",
        errorSubTitle: "不允许退货此产品。",
        imageProduct: "产品图片",
        infoProduct: "产品信息",
        infoActivation: "激活信息",
        warrantyExpired: "保修已过期",
        successTitle: "退货成功！",
        successSubtitle: "您已成功退货所选产品。"
    },
    massive_activation: {
        label: "您可以上传您的xls文件以将其转换为激活。",
        name: "单击或将文件拖放到此区域以进行上传",
        error: "文件上传失败。",
        success: "文件已成功上传。"
    },
    status: {
        title: "仅适用于HJ的产品，请输入序列号或SAP代码",
        status: "状态",
        fields: {
            serial: "序列号",
            next: "下一步",
            required: "序列号为必填项",
            email: "电子邮件",
            emailDescription: "多个电子邮件，用逗号隔开（按Enter键确认）"
        },
        choiceProduct: "选择产品",
        summaryProduct: "产品摘要",
        codeSAP: "SAP代码",
        activationDate: "激活日期",
        store: "店铺",
        user: "用户",
        expiryDate: "到期日期",
        duration: "持续时间",
        reasonReturn: "请在下面的表格中写下原因",
        errorTitle: "状态不可用",
        errorSubTitle: "不允许此产品的状态。",
        imageProduct: "产品图片",
        infoProduct: "产品信息",
        infoActivation: "激活信息",
        warrantyExpired: "保修已过期",
        successTitle: "成功获取状态！",
        successSubtitle: "已成功获取所选产品的状态。",
        manualProduct: "打印产品手册",
        versionPdf: "PDF版本",
        returnDate: "退货日期",
        versionPdfTitle: "产品摘要",
        versionPdfSubTitle: "下载PDF到您的设备，或发送给刚刚激活产品的客户",
        sendEmail: "通过电子邮件发送",
        downloadPdf: "下载PDF",
        sendEmailSuccess: "已成功发送电子邮件！",
        manualProductTitle: "产品手册",
        manualProductSubTitle: "将PDF下载到您的设备",
        instructions: "操作指南",
        care: "护理",
        infoExpiryDate: "信息到期日期",
        changeExpiryDate: "更改到期日期",
        confirmChangeExpiryDate: "确认",
        serialNumberError: "序列号无效！",
        serialNumberErrorMsg: "序列号不存在"
    },
    users: {
        fields: {
            managedStores: '管理的商店',
            managedStoreTypes: '管理的商店类型'
        },
        columns: {
            storeCode: '商店代码',
            role: '角色'
        }
    },
    landing: {
        sapCode: 'SAP代码',
        expiration_date: '到期日期',
        warranty_duration: '保修期限',
        download_manual: '下载手册',
        download_brochure: '下载宣传册',
        extend_warranty: '延长保修期',
        sp_pt_disclaimer: '本国际保修不影响您根据任何适用的国家法律可能拥有的任何其他权利。'
    }
}