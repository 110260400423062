// eslint-disable-next-line import/no-anonymous-default-export
export default {
    app: {
        name: 'Temera Digital Warranty',
        url: 'www.buccellati.com'
    },
    error: {
        generic_error: 'Errore Generico',
        error: 'Errore',
        forbidden: 'Forbidden',
        not_found: 'Not Found',
        unauthorized: 'Unauthorized',
        invalid_credentials: 'Credenziali non valide!',
        duplicateKey: 'Chiave Duplicata',
        api: 'Errore API {{method}} {{api}} :\n {{error_message}}',
        requiredRowEditTable: 'La tabella deve avere almeno una riga',
        error_upload_file: 'Caricamento file non riuscito.'
    },
    success: {
        confirm_delete: "Item rimosso con successo",
        confirm_update: "Modifica apportata con successo",
        confirm_password: "Password cambiata, ora puoi accedere dall'app"
    },
    warning: {
        ask_delete: 'Sicuro di voler elimanare l\'elemento?',
        unreversible_delete: "L'eliminazione sarà irreversibile",
        warning: 'Attenzione'
    },
    dashboard: {
        module_name: 'Dashboard'
    },
    common: {
        confirm: 'Conferma',
        cancel: 'Annulla',
        save: 'Salva',
        reset: 'Azzera',
        search: 'Cerca',
        table: {
            total: 'Totale {{total}} risultati',
            export: 'Export',
            changeMe: 'Cambiami',
            key: 'Chiave',
            value: 'Valore',
            attributes: 'Attributi',
            addRow: 'Aggiungi Riga',
            keyDescription: 'Descrizione Chiave',
            valueDescription: 'Descrizione Valore'
        },
        edit: 'Modifica',
        details: 'Dettagli',
        addRow: 'Aggiungi Riga',
        delete: 'Elimina',
        success: 'Success',
        fieldRequired: 'Campo Obbligatorio',
        api: 'API',
        email: 'Email',
        goBack: 'Indietro',
        goAhead: ' Avanti',
        description: 'Descrizione',
        import_success: 'File caricato correttamente',
        click_upload: 'Premi per caricare un file'
    },
    menu: {
        dashboard: 'Dashboaard',
        users: 'Utenti',
        stores: 'Negozi',
        products: 'Prodotti',
        logout: 'Logout',
        digital_warranties: 'Digital Warranties',
        settings: 'Impostazioni',
        about:'Info',
        modal:{
            copyright:'Copyright © 2015 Temera Tutti i diritti riservati'
        },
        controlPanel:'Pannello di controllo'
    },
    login: {
        username: 'Username',
        password: 'Password',
        confirm_password: 'Conferma password',
        sign_in: 'Accedi',
        mandatory_username: 'Per favore inserisci il tuo username!',
        mandatory_password: 'Per favore inserisci la tua password!',
        mandatory_email:'Per favore inserisci la tua email!',
        mandatory_confirm_password: 'Per favore conferma la tua password!',
        forgot_password: 'Dimenticata la password?',
        remember_me: 'Ricordami',
        failed: 'Autenticazione fallita',
        recover_password_title: 'Compila il form in basso per reimpostare la tua password',
        recover_password_subtitle: 'Riceverai una mail contenente un link per reimpostare la password',
        recover_success: 'Mail inviata con successo',
        activation_user_title: 'Configura la tua password di accesso',
        activation_user_subtitle: 'Questa operazione sarà necessaria solo al primo accesso',
        header: 'DIGITAL WARRANTY & AUTHENTICITY',
        fields: {
            username: 'Username',
            email: 'Email',
            send: 'Invia'
        },
        placeholder: {
            input_password: 'Inserisci password',
            input_confirm_password: 'Inserisci di nuovo la password'
        }
    },
    loginSSO: {
        label: 'Accedi con il tuo Account',
        sso_button: 'Accesso SSO',
        with_credentials : 'Accedi con le tue credenziali'
    },
    module: {
        listing: {
            title: 'Gestione {{moduleName}}'
        },
        add: {
            title: 'Aggiungi {{entityName}}'
        },
        edit: {
            title: 'Modifica {{entityName}}'
        },
        details: {
            title: 'Dettagli {{entityName}}'
        },
        upload:{
            title: 'Upload'
         }
    },
    users: {
        module_name: "Utenti",
        entity: {
            singular_name: "Utente",
            plural_name: "Utenti"
        },
        details: {
            store: 'Negozio',
            email: 'Email'
        },
        columns: {
            details: 'Dettagli',
            store: 'Negozio',
            modified: 'Modificato',
            created: 'Creato',
            surname: 'Cognome',
            name: 'Nome',
            admin: 'Amministratore',
            username: 'Nome Utente',
            personal:'Personale',
            exception:'Eccezione'
        },
        fields: {
            username: 'Nome Utente',
            surname: 'Cognome',
            name: 'Nome',
            storeType: 'Tipo di Negozio',
            store: 'Negozio',
            password: 'Password',
            confirmPassword: 'Conferma Password',
            email: 'Email',
            role: 'Ruolo',
            admin: 'Amministratore',
            attributes: 'Attributi',
            add: 'Aggiungi Utente',
            area: 'Area',
            edit: 'Modifica Utente',
            userSoldTo: 'Sold To Utente',
            errors: {
                username: 'Inserisci un Username',
                password: 'Inserisci una Password'
            }
        },
        add: {
            success: 'Utente aggiunto con successo',
            errorPasswordMatch: 'Le password non corrispondono'
        },
        edit: {
            success: 'Utente modificato con successo'
        }
    },
    prewarranties: {
        module_name: "Pre attivazioni",
        entity: {
            singular_name: "Pre attivazione",
            plural_name: "Pre attivazioni"
        },
    },
    hierarchies: {
        module_name: "Area",
        entity: {
            singular_name: "Nuova area",
            plural_name: "Nuova area"
        },
    },
     partnerinventory: {
        module_name: "Inventario"
    },
    warranties: {
        module_name: "Attivazioni",
        entity: {
            singular_name: "Attivazione",
            plural_name: "Attivazioni"
        },
        columns: {
            details: 'Dettagli',
            store: 'Negozio/Utente Ret',
            card: 'Sostituzione Carta',
            return: 'Reso',
            storeUserAct: 'Negozio/Utente Act',
            activation: 'Attivazione',
            serialNumber: 'Numero di serie',
        },
        details: {
            image: "Immagine",
            product: "Prodotto",
            date: "Data",
            store: "Negozio",
            user: "Operatore",
            delete: "Elimina Attivazione",
            activation: "Attivazione",
            return: "Reso",
            productPage: "Pagina Prodotto",
            downloadPdf: "Scarica PDF",
        },
        fields: {
            from: 'Da',
            to: 'A',
            serialNumber: 'Numero di Serie',
            returned: 'Restituito',
            storeType: 'Tipo di Negozio',
            store: 'Negozio'
        }
    },
    stores: {
        module_name: "Negozi",
        entity: {
            singular_name: "Negozio",
            plural_name: "Negozi"
        },
        columns: {
            details: 'Dettagli',
            store: 'Negozio',
            type:'Tipo',
            activation: 'Attivazione',
            operationCountry: 'Paese/Regione di operazione',
            ISOCode: 'ISO Code',
            soldTo: 'Venduto a',
            status: 'Stato'
        },
        fields:{
            from:'Da',
            to:'A',
            storeType:'Tipo di Negozio',
            store:'Negozio',
            address:'Indirizzo',
            users:'Utenti',
            add:'Aggiungi Negozio',
            edit: 'Modifica Negozio',
            nameCodeStore: 'Nome/Codice Negozio',
            activationStatus: 'Stato attivazione',
            operatingCountry: "Paese/Regione d'operazine",
            sold_to: 'Venduto a',
            ship_to: 'Spedito a',
            errors:{
                store:'Inserisci il nome del negozio'
            }
        },
        details: {
            users: 'Utenti',
            lastAccess: 'ultimo accesso: ',
            active: 'Attivo',
            expectedDevices: 'Dispositivi Previsti',
            usbDevice: 'Lettore USB NFC',
            mobileDevice: 'Smartphone'
        },
        add: {
            success: 'Negozio creato con successo'
        },
        edit: {
            success: 'Negozio modificato correttamente!'
        }
    },
    masterdata: {
        module_name: "Prodotti",
        entity: {
            singular_name: "Prodotto",
            plural_name: "Prodotti"
        }
    },
    settings: {
        set_system: 'Configura il tuo sistema',
        save_system: "Salva"
    },
    products: {
        columns: {
            details: 'Dettagli',
            shortDescription: 'Breve descrizione',
            description: 'Descrizione',
            creationDate: 'Data di creazione',
            photo: 'Foto',
            marketingDescription: 'Descrizione marketing',
            materialType: 'Tipologia materiale',
            nfcEmbedded: 'NFC abilitato',
        },
        fields: {
            id: 'Id',
            code: 'Codice',
            description: 'Descrizione',
            attributes: 'Attributi',
            materialType: 'Tipologia materiale',
            NFC: 'NFC Abilitato'
        },
        edit: {
            successEditDescription: 'Prodotto aggiornato correttamente!'
        }
    },
    geolocation:{
        not_enabled: 'Geolocalizzazione non abilitata'
    },
    image:{
        masterdata_image: "Immagine prodotto"
    },
    landing : {
        identified_product: "Prodotto identificato",
        sku_upc: "SKU/UPC",
        serial: "Numero di serie",
        activation_store: "Negozio di attivazione",
        activation_date: "Data di attivazione",
        information:"Maggiori informazioni sull'attivazione del prodotto",
        card: "Atterraggio Carta"
    },
    profiles:{
        module_name: "Profili",
        entity:{
            singular_name: 'Profilo'
        }
    },
    'bu-manual':{
        module_name: "Manuale",
        entity:{
            singular_name: 'Manuale'
        }
    }
}
