import { CheckOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Result, Row, Steps } from "antd";
import { Component } from "react";
import { ClipLoader } from "react-spinners";
import DWEnvironmentManager from "../../../services/DWEnvironmentManager";
import { addAction } from "../../../services/HooksManager";
import { navigate } from "../../../shared/router";
import { __ } from "../../../translations/i18n";
import { __b } from "../../translations/i18n";
import FirstStep from "./ReturnFirstStep";
import SecondStep from "./ReturnSecondStep";
import { errorToast } from "../../../services/NotificationManager";

interface State {
    currentTabs: number,
    product: any[],
    productDetail: any[],
    loadProduct: boolean,
    result?: 'error' | 'ok',
    errorSubTitle?: string,
    errorTitle?: string 
}

interface Props {
    module: any
}


export default class ReturnComponent extends Component<Props, State> {
    
    state: State =  {
        currentTabs: 0,
        product: [],
        productDetail: [],
        loadProduct: false
    }

    navManager = DWEnvironmentManager.getNavigationManager();

    componentDidMount(): void {
        // init breadcrumb, da verificare perchp non va
        addAction('nav_route_change', this.onRouteChange);
    }

    onRouteChange = (location: any, navManager: any) => {
        const route = this.getRoute();
        const basename = DWEnvironmentManager.getBasename();
        if (location.pathname.indexOf(route) === 0) {
            navManager.addBreadcrumb({key: `dashboard`, route: `${basename}dashboard`, label: <span>{__('dashboard.module_name')}</span>})
            navManager.addBreadcrumb({key: `${this.props.module}_details`, route: this.getRouteBasename(), label: <span>{__b('return.return')}</span>})
        }
    }

    getRouteBasename = () => {
        const basename = DWEnvironmentManager.getBasename();
        return `${basename}return`
    }

    getRoute = () => {
        return '/return';
    }

    finishFirstStep = async (data: any) => {
        // chiamata che controlla il seriale, se seriale corretto andiamo su secondo step - altrimenti si ferma
        // se chiamata andata a buon fine allora precarica i dari del seriale e poi passa a secondo step
        this.setState({loadProduct: true});
        const provider = DWEnvironmentManager.getDataProvider();
        const product = await provider.getList(undefined, undefined, {}, `warranties/operations?itemSerialNumber=${data.serial}`);
        if (product !== null && product.length > 0) {
            this.setState({product: product});
            // effettuare chiamata find per informazioni prodotto.
            let productFind: any[] = [];

            for await (const prod of product) {
                const products = await provider.addOne({data: [prod.item.upc]}, `masterdata/find`);
                let object = {};
                let vl = {value: product.find( (pr: any) => pr.item.upc === prod.item.upc), detail: products[prod.item.upc]};
                object = {...object, ...vl};
                
                productFind.push(object);
            }

            this.setState({productDetail: productFind});
            this.setState({loadProduct: false});
            this.setState({currentTabs: 1});
        } else {
            this.setState({loadProduct: false});
            errorToast(__b("status.serialNumberError"), __b("status.serialNumberErrorMsg"));
        }
    }

    goBack = ()=> this.setState({currentTabs: 0});

    returnProduct = async (product: any) => {
        const storage = DWEnvironmentManager.getStorage();
        const provider = DWEnvironmentManager.getDataProvider();
        let returnOk = false;
        const loggedUser = await storage.load(`user`, undefined);
        if(product.value.creationStore.externalCode === 'ownership') {
            if(loggedUser.store.externalCode === 'ownership'){
              //reso ok
              returnOk = true;
            }
        } else {
            if(loggedUser.store.externalCode !== 'ownership') {
              if(product.value.creationStore.id === loggedUser.store.id) {
                returnOk = true;
              }else{
                if(loggedUser.store.attributes.sold_to && product.value.creationStore.attributes.sold_to) {
                  if(loggedUser.store.attributes.sold_to === product.value.creationStore.attributes.sold_to) {
                    //reso ok
                    returnOk = true;
                  }
                }
              }
            }
        }

        if (returnOk) {
            const req = {
                data: {},
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Accept': 'application/json, text/plain, */*'
                },
            };

            const res = await provider.addOne(req, `/warranties/operations/${product.value.id}/return`);
            if (res.id) {
                this.setState({result: 'ok'});
            } else {
                this.setState({errorTitle: __b('return.errorTitle')});
                const error = res.replace(/it.temera.tmr.bulgaridw.exception.WarrantyServiceException:/g, '');
                this.setState({errorSubTitle: error});
                this.setState({result: 'error'});
            }
        } else {
            this.setState({errorTitle: __b('return.errorTitle')});
            this.setState({errorSubTitle: __b('return.errorSubTitle')});
            this.setState({result: 'error'});
        }
    }

    steps = [
        {
          title: '',
        },
        {
          title: '',
        },
      ];
      

    render() {
        const {currentTabs, loadProduct, result, errorSubTitle, errorTitle} = this.state;

        if (loadProduct) {
            return <div id={"loader-container"} className="loader-container" style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}><ClipLoader size={150} /></div>
        } else {
            return (
                <>
                {!result && 
                    <>
                        <Row gutter={[24, 24]} justify={"center"} style={{marginTop: 50}}>
                            <Col span={2}>
                                <Steps current={currentTabs} items={this.steps} size={"small"} />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} justify={"center"} style={{marginTop: 20}}>
                            {currentTabs === 0 && (
                                <Col span={8}>
                                    <FirstStep finishFirstStep={this.finishFirstStep}/>
                                </Col>
                            )}
                            {currentTabs === 1 && (
                                <Col span={20}>
                                    <SecondStep product={this.state.productDetail} goBack={this.goBack} returnProduct={this.returnProduct}/>
                                </Col>
                            )}
                        </Row>
                    </>
                }
                    
                {result && result === 'ok' && 
                    <Modal open={true} centered closable={false} footer={null}>
                        <Result 
                        status={"success"} 
                        title={__b('return.successTitle')} 
                        subTitle={__b('return.successSubtitle')}
                        extra={[<Button type="primary" key="back" onClick={() => navigate('/dashboard')} size={"large"} icon={<CheckOutlined />}>{__('common.confirm')}</Button>]}
                        />
                    </Modal>
                }
                {result && result === 'error' && 
                    <Modal open={true} centered closable={false} footer={null}>
                        <Result 
                        status={"error"} 
                        title={errorTitle} 
                        subTitle={errorSubTitle}
                        extra={[<Button type="primary" key="back" onClick={() => this.setState({result: undefined})} size={"large"} icon={<ClockCircleOutlined />}>{__b('common.close')}</Button>]}
                        />
                    </Modal>
                }
                </>
            )
        }
    }
        

}