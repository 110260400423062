import React, { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { __b } from "../translations/i18n";
import { useLocation } from "react-router-dom";
import Module from '../modules/InfoModule';
import { applyFilters } from "../../services/HooksManager";

interface Props {
    module: Module;
}

export interface locationStateType {
    uid: string;
    language: string;
    matType: keyof typeof MatType;
}

interface warrantyInfoData {
    id: string
    creationDate: number
    lastModified: number
    matType: string
    startActivationDate: number
    message: string
    language: string
}

enum MatType {
    PFMA = 'accessories',
    PFMO = 'watches',
    FINP = 'jewellery_pieces',
    BCI = 'high_jewellery',
}

export const Info = (props: Props) => {
    const {state} = useLocation<locationStateType>();
    const materialType = state?.matType ? MatType[state.matType] : '';
    const [infoData, setInfodata] = useState<warrantyInfoData[]>([]);

    const getInfoData = async () => {
        const infoData: warrantyInfoData[] = await applyFilters(`${props?.module?.getModuleKey()}_warranty_info_data`, state);
        setInfodata(infoData);
    }
    
    useEffect(() => {
        getInfoData();
    }, [])

    return (
        <div className={`info-content ${materialType ? "single-material" : ""}`}>
            <Row justify={materialType ? 'center' : 'start'}>     
            {
                materialType ?
                    <Col xs={24} lg={12}>
                        <h3 style={{fontSize: 'x-large', margin: '3%'}}> {__b(`info.header.${materialType}`)}</h3>  
                            {
                                infoData[0]?.message ? 
                                    infoData[0].message.split("\\n").map((item, i) => <Typography key={i}>{item}</Typography>)
                                : 
                                <Typography>{__b(`info.body.${materialType}`)}</Typography>
                            }                   
                    </Col>          
                :
                Object.values(MatType).map(material => (
                    <Col xs={24} lg={12}>
                        <h3 style={{fontSize: 'x-large', margin: '3%'}}> {__b(`info.header.${material}`)}</h3>
                        <Typography>{__b(`info.body.${material}`)}</Typography>
                    </Col>
                ))
            }
            </Row>
            <Row style={ { textAlign: 'center', display:'block', padding: '2%' } }>
                <h3 style={{fontSize: 'x-large', margin: '1%'}}><a href={DWEnvironmentManager.getMainSite()}>www.bulgari.com</a></h3>
            </Row>
        </div>
        
    )
}
