import {applyFilters} from "../../../services/HooksManager";
import {ComponentClass} from "react";
import TextField from "./TextField"
import DateField from "./DateField";
import SelectField from "./SelectField";
import ButtonField from "./ButtonField";
import NumberField from "./NumberField";
import TableField from "./TableField";
import CheckboxField from "./CheckboxField";
import AutoCompleteField from "./AutoCompleteField";
import AutoCompleteSelectField from "./AutoCompleteSelectField";
import ButtonUpload from "./ButtonUpload";
import SwitchField from "./SwitchField";

const fieldMap:{[key: string]: ComponentClass} = applyFilters('form_field_compnents', {
    'text': TextField,
    'date': DateField,
    'select': SelectField,
    'button': ButtonField,
    'buttonUpload': ButtonUpload,
    'number': NumberField,
    'table':TableField,
    'checkbox':CheckboxField,
    'autocomplete': AutoCompleteField,
    'autocompleteselect': AutoCompleteSelectField,
    'switch': SwitchField
});

export default fieldMap;
