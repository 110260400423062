import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import { Component } from "react";
import DWEnvironmentManager from "../../../services/DWEnvironmentManager";
import { errorModal, errorToast, successToast } from "../../../services/NotificationManager";
import { __ } from "../../../translations/i18n";
import UploadUtils from "../../../utils/UploadUtils";
import FieldProps, { FieldState } from "./FieldProps";
import { isArray, isObject } from "lodash";


export default class ButtonUpload extends Component<{} & FieldProps, FieldState> {

    constructor( props: FieldProps | Readonly<FieldProps> ) {
        super ( props );
        this.state = {};
    }


    showModal(error:[]) {
        errorModal({
            title: DWEnvironmentManager.getApplicationName(),
            content: (<div dangerouslySetInnerHTML={{__html: error.join('</br>')}}></div>)
        })
    };

    render() {

        const { field } = this.props;

        const props = Object.assign({
            multiple: false,
            name: 'file',

            onChange: (info: any) => {

                const {status} = info.file;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    successToast(__('common.success'),`${ info.file.name } ${__('common.import_success')}.`);
                } else if (status === 'error') {

                    //with different file we could receive a different response
                     if ( info.file.response?.result ) {
                        const error: any = [info.file.response.result]
                        return this.showModal( error ?? [] )
                     }else if ( info.file.response ) {
                        return this.showModal(info.file.response ?? [] )
                     }else { // generic one
                         errorToast( __( 'error.error' ), __( 'error.error_upload_file' ) );
                     }
                }
            }
        }, UploadUtils.getUploadRequest(field.action));


        return (

            <Form.Item
                name={ field.name }
                rules={ [
                    {
                        required: field.required,
                        message: field.requiredMessage,
                    },
                ] }
            >
                <Upload { ...props }>
                    <Button icon={ <UploadOutlined/> }>{field.name}</Button>
                </Upload>
            </Form.Item>
        )
    }
}
