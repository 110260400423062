import { CheckOutlined, EyeOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Image } from "antd";
import { FormLayout } from "antd/lib/form/Form";
import moment from 'moment';
import { ReactNode } from "react";
import CrudDetails from "../../components/CrudDetails";
import CrudModule from "../../components/CrudModule";
import { FormRow } from "../../components/form/DynamicForm";
import { MenuNode } from "../../components/MenuComponent";
import PageViewer from "../../components/PageViewer";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { addFilter } from "../../services/HooksManager";
import { errorToast, successToast } from "../../services/NotificationManager";
import { call, CallRequestConfig } from "../../shared/api";
import ParentModule from "../../shared/module/CrudModule";
import { navigate } from "../../shared/router";
import { __ } from "../../translations/i18n";
import DateUtils from "../../utils/DateUtils";
import PreWarrantiesAdd from "../components/prewarranty/PreWarrantiesAdd";
import PrewarrantyDetails from "../components/prewarranty/PrewarrantyDetails";
import DWPrewarrantyDataProviderImp from "../providers/implementation/DWPrewarrantyDataProviderImp";
import buUserManager from "../services/BuUserManager";
import { __b } from "../translations/i18n";
import ObjectUtils from "../utils/ObjectsUtil";


class PrewarrantyModule extends ParentModule {


    init = () => {
        super.init();
        addFilter ( 'app_sub_menu', this.addMenu );

        addFilter ( 'app_routes', ( routes: any ) => {
            routes[this.getRoute()] = {
                component: PageViewer,
                props: { content: <CrudModule module={ this }/>, selectedKey: this.getRoute() },
                visibility: 'private'
            };
            routes[this.getRouteDetail()] = {
                component: PageViewer,
                props: { content: <CrudDetails module={ this } tabs={["details"]} /> },
                visibility: 'private'
            };
            return routes;
        } );

        addFilter ( 'search_form_data_' + this.getModuleKey (), this.getSearchData )

        addFilter ( 'crud_detail_view_component_' + this.getModuleKey (), this.getDetailsComponent )
        addFilter ( 'crud_create_component_' + this.getModuleKey (), this.getAddComponent);
        addFilter('prewarranties_get_list_map', this.modifyListResponse);
        addFilter('crud_tabs_prewarranties', this.modifyTabs);
    }

    modifyListResponse = async (res: any) => {
        // modify response of listing call 
        for await (const el of res) {
            const provider = DWEnvironmentManager.getDataProvider();
            const detail = await provider.addOne({ data: [el.item.upc] }, 'masterdata/find');
            el.detail = detail;
        }
        
        return res;
    }

    modifyTabs(tabsItem: any) {
        const tabs = tabsItem;
        if (!buUserManager.userCanAddPreWarranties()) {
            tabs.pop();
        }

        return tabs; 
    }


    addMenu = ( menu: MenuNode[] ) => {
        menu.push ( {
            label: <i >{ __b ( 'menu.digital_prewarranties' ) }</i>,
            icon: <CheckOutlined />,
            position: '11',
            key: this.getRoute()
        } );

        return menu;
    }

    getModuleKey = (): string => {
        return "prewarranties";
    }


    __getFormFields = (): FormRow[] => {

        return [
            {
                fields: [
                    {
                        name: 'dateFrom',
                        label: __ ( 'warranties.fields.from' ),
                        type: 'date',
                        required: false,
                        format: 'DD/MM/YYYY',
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'dateTo',
                        label: __ ( 'warranties.fields.to' ),
                        type: 'date',
                        required: false,
                        format: 'DD/MM/YYYY',
                        colConf: {
                            span: 2
                        }
                    },
                    {
                        name: 'sequential',
                        label: __b ( 'warranties.fields.sequentialCard' ),
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'itemUpc',
                        label: __b ( 'warranties.fields.sapCode' ),
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 4
                        }
                    },
                    {
                        name: 'itemSerialNumber',
                        label: __b ( 'warranties.fields.serialNumber' ),
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 10
                        }
                    },
                    {
                        name: 'returned',
                        label: __b ( 'warranties.fields.reso' ),
                        type: 'select',
                        required: false,
                        selectValue: [
                            { label: __b ( "warranties.fields.values.all" ), value: '' },
                            { label: __b ( "warranties.fields.values.reso" ), value: 'true' },
                            { label: __b ( "warranties.fields.values.noReso" ), value: 'false' }
                        ],
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'externalCode',
                        label: __b ( 'warranties.fields.storeType' ),
                        type: 'select',
                        required: false,
                        selectValue: [
                            { label: __b ( "warranties.fields.values.all" ), value: '' },
                            { label: "Ownership", value: 'ownership' },
                            { label: "Wholesale", value: 'wholesale' },
                            { label: "Travel Retail", value: 'travel retail' },
                            { label: "Franchisee", value: 'franchisee' },
                            { label: "Service Center", value: 'service center' },
                            { label: "Family and Friends", value: 'family and friends' },
                            { label: "Vintage", value: 'vintage' },
                            { label: "Online", value: 'online' }
                        ],
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'MatType',
                        label: __b ( 'warranties.fields.materialType' ),
                        type: 'select',
                        required: false,
                        selectValue: [
                            { label: __b ( "warranties.fields.values.all" ), value: 'all' },
                            { label: __b ( "warranties.fields.values.accessories" ), value: 'PFMA' },
                            { label: __b ( "warranties.fields.values.watches" ), value: 'PFMO' },
                            { label: __b ( "warranties.fields.values.jewels" ), value: 'FINP' },
                            { label: __b ( "warranties.fields.values.fineJewelry" ), value: 'BCI' },
                        ],
                        colConf: { span: 8 }
                    },
                    {
                        name: 'store',
                        label: __b ( 'warranties.fields.nameCodeStore' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: ( key: string ) => ( this.fetchStores ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'hierarchy',
                        label: __b ( 'warranties.fields.operatingCountry' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: ( key: string ) => ( this.fetchHierarchies ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'activationSource',
                        label: __b ( 'warranties.fields.activationType' ),
                        type: 'select',
                        required: false,
                        selectValue: [
                            { label: __b ( "warranties.fields.values.all" ), value: '' },
                            { label: "Tag", value: 'TAG' },
                            { label: __b ( "warranties.fields.values.manuale" ), value: 'MANUAL' },
                            { label: "Backoffice", value: 'BACKOFFICE' },
                            { label: __b ( "warranties.fields.values.appManual" ), value: 'MANUAL_APP' },
                            { label: "Tag APP", value: 'TAG_APP' },
                            { label: "NO Encoding", value: 'NO_ENCODING' },
                            { label: "NO Encoding APP", value: 'NO_ENCODING_APP' },
                        ],
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'masterCode',
                        label: __b ( 'warranties.fields.masterCode' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: ( key: string ) => ( this.fetchMasterCode ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'soldToParty',
                        label: __b ( 'warranties.fields.soldToParty' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: ( key: string ) => ( this.fetchSoldToParty ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'responsibilityArea',
                        label: __b ( 'warranties.fields.responsibilityArea' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: ( key: string ) => ( this.fetchResponsibilityareas ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    {
                        name: __ ( 'common.reset' ),
                        type: 'button',
                        htmlType: 'reset',
                        block: 'true',
                        buttonType: "default",
                        icon: <ReloadOutlined />,
                        colConf: { span: 2, offset: 10 }
                    },
                    { name: __ ( 'common.search' ), type: 'button', htmlType: 'submit', block: 'true', icon: <SearchOutlined />, colConf: { span: 2 } }
                ]
            }
        ];

    }

    getSearchData = ( data: { [ key: string ]: any } ) => {

        let dataClone: { [ key: string ]: any } = {};
        Object.keys ( data ).map ( ( key ) => {
            if( moment.isMoment ( data[ key ] ) ) {
                return dataClone[ key ] = data[ key ].format ( 'YYYY/MM/DD' );
            } else {
                return dataClone[ key ] = data[ key ];
            }
        } );

        return dataClone;
    }

    __getColumnsTable = () => {
        return [
            {
                title: __b ( 'warranties.columns.photo' ),
                dataIndex: 'photo',
                key: 'photo'
            },
            {
                title: __b ( 'warranties.columns.cardSequential' ),
                dataIndex: 'cardSequential',
                sorter: true,
                key: 'cardSequential'
            },
            {
                title: __b ( 'warranties.columns.sap' ),
                dataIndex: 'sap',
                sorter: true,
                key: 'sap'
            },
            {
                title: __b ( 'warranties.columns.serialNumber' ),
                dataIndex: 'serialNumber',
                key: 'serialNumber'
            },
            {
                title: __b ( 'warranties.columns.description' ),
                dataIndex: 'description',
                key: 'description'
            },
            {
                title: __b ( 'warranties.columns.activation' ),
                dataIndex: 'activation',
                sorter: true,
                key: 'activation'
            },
            {
                title: __b ( 'warranties.columns.activationUser' ),
                dataIndex: 'activationUser',
                key: 'activationUser'
            },
            {
                title: __b ( 'warranties.columns.details' ),
                dataIndex: 'id',
                key: 'id',
                render: ( id: string | number ) => (
                    <Button onClick={ () => navigate (this.getRouteDetail(id)) } icon={ <EyeOutlined/> } size={ "small" }
                            shape={ "circle" } type="primary"/>
                )
            }
        ];
    }

    __getMapData = ( row: any) => {
        const hJ = row?.detail[row.item.upc]?.attributes?.MatType?.value === 'BCI' ? true : false;
        return {
            photo: <Image fallback={DWEnvironmentManager.getNotFoundImg()}
                          src={ this.getSrcImageListing(row) }
                alt='images'/>,
            cardSequential: row?.warrantyBadge?.sequential,
            sap: row.item.upc,
            serialNumber: hJ ? '' : row.item.serialNumber,
            description: row?.detail[row.item.upc]?.description ?? '-',
            activation: DateUtils.formatDate ( row?.creationDate ),
            activationUser: <div><strong>{ row.creationStore.code } - { row.creationStore.description } ({ row?.createdBy?.name })</strong>
                <div>{ row?.createdBy?.name } { row?.createdBy?.surname }</div>
            </div>,
            id: row.id
        }
    }

    getSrcImageListing = (row: any) => {
        // if (row?.detail[row.item.upc]?.attributes?.image_thumb?.value) {
        //     return `${DWEnvironmentManager.getStoragePath()}${row?.detail[row.item.upc]?.attributes?.image_thumb?.value}`;
        // }

        return `${DWEnvironmentManager.getStoragePath()}assets/images/imported/${row.item.upc}.jpg`;
    }

    __getLayout = (): FormLayout => {
        return "vertical";
    }

    getDetailsComponent = ( component: ReactNode, entity: any ) => {
        return ( <PrewarrantyDetails entity={ entity }/> );
    }

    getAddComponent = (component: ReactNode, entity: any) => {
        return (<PreWarrantiesAdd module={this.getModuleKey()} add={this.add} checkSerial={this.checkSerial} />);
    }

    add = async (data: any, selectedStore: any) => {
        data.item = { epc: data.sapCode + data.serialNumber, type: "WarrantyItemDto", serialNumber: data.serialNumber, upc: data.sapCode };
        data.warrantyBadge = { sequential: data.sequential };
        data.activationDate = data.activationDate.valueOf();

        const provider = DWEnvironmentManager.getDataProvider();

        // recuperare zona di sold
        let zoneSold = await this.getZoneSold(data);

        if (zoneSold.length === 0) {
            // create zone SOLD
            zoneSold = await this.createZoneSold(selectedStore);
        }

        // trasferimento dell'item verso la zona di sold - kernel.api
        await this.transferToZoneItem(data, zoneSold[0]);

        // attivazione (con meno campi del previsto)
        const userId = data.userId;
        delete data.sequential;
        delete data.sapCode;
        delete data.serialNumber;
        delete data.storeId;
        delete data.userId;
        delete data.addWarranty;

        const req = {
            data: Object.assign({}, data),
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                user: userId
            }
        };
        const res: any = await provider.addOne(req, 'prewarranties/admin/create');
        
        if (res.id) {
            successToast(__('common.success'), __b('prewarranties.add.success'));
            navigate(`/prewarranties/${res.id}`);
        } else {
            errorToast(__('error.error'), res)
        }
    }


    fetchHierarchies = async ( key: string ) => {
        return await fetchHierarchies ( key );
    }

    fetchStores = async ( key: string ) => {
        return await fetchStores ( key );
    }

    fetchMasterCode = async ( key: string ) => {
        return await fetchMasterCode ( key );
    }

    fetchSoldToParty = async ( key: string ) => {
        return await fetchSoldToParty ( key );
    }

    fetchResponsibilityareas = async ( key: string ) => {
        return await fetchResponsibilityareas ( key );
    }

    getPreWarrantyByBadgeUrl() {
        return this.getModuleKey() + `/operations/check`;
    }

    async getPreWarrantyByBadge(id:  number | string) {

        const provider = DWEnvironmentManager.getDataProvider();
        let params:{[key: string]: any} = {}
        params["warrantyBadgeUid"] = id;
        params["returned"] = false;
        params["expired"] = false;

        const conf = {
            params: params,
            url: this.getPreWarrantyByBadgeUrl()
        }

        const res = await provider.getList(1, 0, conf, this.getModuleKey(), false);
        // Uso getList ma ritorna un solo oggetto, no array
        if (res) {
            return res;
        }
    }


    getZoneSold = async (data: any) => {
        const baseUrl = DWEnvironmentManager.getStoragePath();
        const method = 'GET';
        const path = '/tmr-api/kernel.api/zones';
        const request: CallRequestConfig<any> = {
            params: {
                storeId: data.storeId,
                zoneType: 'SOLD'
            },
            baseURL: baseUrl
        };
        return await call<any[]>(method, path, request);
    }

    createZoneSold = async (selectedStore: any) => {
        const baseUrl = DWEnvironmentManager.getStoragePath();
        const method = 'POST';
        const path = '/tmr-api/kernel.api/zones';
        const request: CallRequestConfig<any> = {
            data: {
                store: selectedStore,
                code: `${selectedStore}-sold`,
                zoneType: 'SOLD'
            },
            baseURL: baseUrl
        };
        return await call<any[]>(method, path, request);
    }

    transferToZoneItem = async (data: any, zoneSold: any) => {
        const baseUrl = DWEnvironmentManager.getStoragePath();
        const method = 'POST';
        const path = `/tmr-api/kernel.api/zones/${zoneSold.id}/transfer`;
        const request: CallRequestConfig<any> = {
            data: {
                items: [data.item],
                type: "TransferToZone"
            },
            baseURL: baseUrl
        };
        return await call<any[]>(method, path, request);
    }

    checkSerial = async (data: any) => {
        const checkSerial = await DWPrewarrantyDataProviderImp.checkSerial({
            params: {
                sap: data.sapCode,
                serial: data.serialNumber
            }
        },
            'serialnumber/check');
        if (checkSerial === null || !checkSerial.value) {
            return false;
        } else {
            return true;
        }
    }
}


export const fetchHierarchies = async ( key: string ) => {

    const provider = DWEnvironmentManager.getDataProvider ();

    return provider.getList ( 20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, 'hierarchies' )
        .then ( ( response ) =>
            response.map ( ( res: any ) => ( {
                label: res.description,
                value: res.code,
            } ) ),
        );
}

export const fetchStores = async ( key: string ) => {

    const provider = DWEnvironmentManager.getDataProvider ();
    const moduleManager = DWEnvironmentManager.getModuleManager ();
    const storeModule = moduleManager.getModule ( 'stores' );

    return provider.getList ( 20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, storeModule.getModuleKey () )
        .then ( ( response ) =>
            response.map ( ( res: any ) => ( {
                label: `${ res.code } - ${ res.description }`,
                value: `${ res.code }-${ res.description }`,
            } ) ),
        );
}

export const fetchMasterCode = async ( key: string ) => {

    const provider = DWEnvironmentManager.getDataProvider ();

    return provider.getList ( 20, 0, {},
        `stores/mastercodes/search?description=${key}` )
        .then ( ( response ) =>
            ObjectUtils.stringIncludes(response, key)
        );
}

export const fetchSoldToParty = async ( key: string ) => {

    const provider = DWEnvironmentManager.getDataProvider ();

    return provider.getList ( 20, 0, {},
        `stores/soldtoparties/search?description=${key}` )
        .then ( ( response ) =>
            ObjectUtils.stringIncludes(response, key)
        );
}

export const fetchResponsibilityareas = async ( key: string ) => {

    const provider = DWEnvironmentManager.getDataProvider ();

    return provider.getList ( 20, 0, {},
        `stores/responsibilityareas/search?description=${key}` )
        .then ( ( response ) =>
            ObjectUtils.stringIncludes(response, key)
        );
}

const prewarrantyModule = new PrewarrantyModule ();
export default prewarrantyModule;
