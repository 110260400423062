import {Component} from "react";
import {fetchStores} from "../../modules/StoreModule";
import {fetchRolesByKey} from "../../modules/UserModule";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import {__} from "../../translations/i18n";
import CrudForm from "../CrudForm";
import {FormRow} from "../form/DynamicForm";
import {applyActions} from "../../services/HooksManager";

interface Props {
    module: string,
    add: (data: any) => any,
    getAttributes: (data: any) => any,
    roles: any
}

interface State {
    roles: any[],
    dataTable?: any
}

export default class UserAdd extends Component<Props,State>{

    state: State = {
        roles: []
    }
    moduleManager = DWEnvironmentManager.getModuleManager();

    provider = DWEnvironmentManager.getDataProvider ();

    async componentDidMount(): Promise<void> {
        const roles = await this.props.roles;
        applyActions('user_add_loaded_roles', roles);
        this.setState({roles: roles});
    }


    getAddFormFields = (): FormRow[] => {

        return [
            {
                fields: [
                    { name: 'username', label:__('users.fields.username'), type: 'text', required: true, requiredMessage: __('users.fields.errors.username'), colConf: { span: 24 } },
                    { name: 'password', label:__('users.fields.password'), textType: 'password', type: 'text',  required: true, requiredMessage: __('users.fields.errors.password'), colConf: { span: 12 } },
                    { name: 'confirmPassword', label:__('users.fields.confirmPassword'), type: 'text', textType: 'password', required: true, requiredMessage: __('users.fields.errors.password'), colConf: { span: 12 } },
                    { name: 'email', label:__('users.fields.email'), type: 'select', selectType:'email', mode: 'tags',required: false, visibility: 'hidden', colConf: { span: 24 } },
                    { name: 'name', label:__('users.fields.name'), type: 'text', required: false , colConf: { span: 12 }},
                    { name: 'surname', label:__('users.fields.surname'), type: 'text', required: false, colConf: { span: 12 } },
                    {
                        name: 'role',
                        label: __('users.fields.role'),
                        type: 'autocompleteselect',
                        required: false,
                        fetchOptions: (key: string) => (fetchRolesByKey(key)),
                        colConf: { span: 12 }
                    },
                    {
                        name: 'storeId',
                        label: __('users.fields.store'),
                        type: 'autocompleteselect',
                        required: false,
                        fetchOptions: (key: string) => (fetchStores(key)),
                        colConf: { span: 12 }
                    },
                    { name: 'admin', label:__('users.fields.admin'), type: 'checkbox', required: false ,value: false, colConf: { span: 12 } }
                ]
            }
        ];

    }

    getAttributes = (data: any) => {
        if (this.props.getAttributes) {
            this.props.getAttributes(data);
        }

        this.setState({dataTable: data});
    }

    getEditTable = () => {
        return {
            getAttributes: this.getAttributes,
            columnsTable: [
                {
                    title: __('common.table.key'),
                    dataIndex: 'key',
                    key: 'key'
                },
                {
                    title: __('common.table.value'),
                    dataIndex: 'value',
                    key: 'value'
                }],
            formFields: [
                {
                    fields:[
                        { name: "key", label: __('common.table.key'), type: 'text', required: true, requiredMessage: __('common.fieldRequired'), colConf: {span: 12} },
                        { name: 'value', label: __('common.table.value'), type: 'text', required: true, requiredMessage: __('common.fieldRequired'), colConf: {span: 12} },
                    ]
                }
            ],
            label: __('common.table.attributes'),
            labelButton: __('users.fields.add'),
            required: false
        }
    }

    render(){
        const {module} = this.props
        return (<CrudForm module={module} callback={this.props.add} getFormFields={this.getAddFormFields} cardSize={12} type={"add"} editTable={this.getEditTable()} />);
    }
}
