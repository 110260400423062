import { Breadcrumb } from "antd";
import { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import DWEnvironmentManager from "../services/DWEnvironmentManager";

interface Prop {

}

const Breadcrumbs = (props: Prop) => {

    let location = useLocation();

    const [breadcrumbs, setBreadcrumbs] = useState<{[key: string]: any}>({});

    const navManager = DWEnvironmentManager.getNavigationManager();

    useEffect(() => {
        const currentBreadcrumbs = navManager.getBreadcrumbs();
        if (currentBreadcrumbs !== breadcrumbs) {
            setBreadcrumbs(navManager.getBreadcrumbs());
        }

    }, []);

    useEffect( () => {
        navManager.setReloadBreadcrumb(setBreadcrumbs)
    }, [setBreadcrumbs, navManager]);

    useEffect( () => {
        navManager.onRouteChange(location);
    }, [location])

    return (<>
        {breadcrumbs && 
        
        <Breadcrumb separator=">">
            {
                Object.entries(breadcrumbs).map( ([key, bread]) =>  {
                   return  <Breadcrumb.Item href={bread.route} key={bread.key}>
                        {bread.label}
                    </Breadcrumb.Item>
                    })
            }
        </Breadcrumb>}
        </>)

}


export default withRouter(Breadcrumbs);