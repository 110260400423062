/* eslint-disable import/no-anonymous-default-export */
export default {
    app: {
        url: 'www.bulgari.com'
    },
    common: {
        close: '關閉'
    },
    menu: {
        digital_prewarranties: '預保固',
        areas: '區域',
        profiles: '授權檔案',
        manual: '手冊',
        help: '幫助',
        info: '資訊',
        massive_activations: '大規模啟用',
        partnerinventories: '庫存'
    },
    warranties: {
        title: 'DW 啟用',
        columns: {
            photo: '照片',
            cardSequential: '卡片連續號碼',
            sap: 'SAP 編號',
            description: '描述',
            expiredDate: '到期日期',
            activationType: '啟用類型',
            activationUser: '啟用用戶/商店',
            storeUser: '商店/用戶退回',
            substitution: '替代',
            serialNumber: '序列號',
            activation: '啟用',
            return: '退回',
            returnUser: '退回用戶/商店',
            details: '詳細信息',
            operatingCountry: '經營國家/地區',
            parent: '父項',
            isoCode: 'ISO 編碼',
            edit: '編輯',
            returnDeactivate: '退回/停用',
            status: '狀態',
            storeCode: '商店編號'
        },
        fields: {
            sequentialCard: '卡片連續號碼',
            sapCode: 'SAP 編號',
            serialNumber: '序列號',
            reso: '退回',
            storeType: '商店類型',
            materialType: '物料類型',
            nameCodeStore: '名稱/商店編號',
            operatingCountry: '經營國家/地區',
            activationType: '啟用類型',
            onBehalfOf: '代表',
            masterCode: '主代碼',
            soldToParty: '購買方',
            responsibilityArea: '負責區域',
            isoCode: 'ISO 編碼',
            parent: '父項',
            activationDate: '啟用日期',
            store: '商店',
            user: '用戶',
            add: '新增產品啟用',
            errors: {
                sapCode: '輸入 SAP 編號！',
                activationDate: '選擇啟用日期！',
                serialNumber: '輸入序列號！',
                store: '輸入商店！',
                user: '輸入用戶！',
                sequentialCard: '輸入卡片連續號碼！',
            },
            values: {
                all: '全部',
                reso: '退回',
                noReso: '非退回',
                accessories: '附件',
                watches: '手錶',
                jewels: '珠寶首飾',
                fineJewelry: '高級珠寶',
                manuale: '手冊',
                appManual: '應用程式手冊',
                yes: '是',
                no: '否',
                internal: '內部',
                external: '外部',
                prospect: '展望',
                open: '開放',
                closed: '關閉',
                phaseOut: '淘汰',
                future: '未來',
                notActivated: '未啟用',
                pending: '待定',
                activated: '已啟用'
            },
        },
        add: {
            success: '成功新增啟用'
        },
        changeExpiration: {
            success: '成功更新到期日期',
            error: '更新失敗',
            errorDescription: '到期日期未更新'

        },
        warning: '序列號和 SAP 編號不相容',
        error: {
            watchInvalidSerialTitle: '手錶序列號和 SAP 編號不一致',
            watchInvalidSerialDescription: '所選手錶的序列號和 SAP 編號不一致。',
            warrantyAlreadyPresentTitle: '保固已存在',
            warrantyAlreadyPresentDescription: '所選產品已存在保固'
        }
    },
    prewarranties: {
        fields: {
            activationDate: '預保固日期'
        },
        add: {
            success: '成功新增預保固'
        },
        details: {
            image: "圖片",
            product: "產品",
            date: "日期",
            store: "商店",
            user: "操作員",
            delete: "刪除預保固",
            activation: "啟用",
        },
        error: {
            prewarrantyAlreadyPresentTitle: '預保固已存在',
            prewarrantyAlreadyPresentDescription: '所選產品已存在預保固',
            checkSerialSAPErrorTitle: '序列號和 SAP 編號不一致',
            checkSerialSAPErrorDescription: '所選序列號和 SAP 編號不一致。'
        }
    },
    partnerinventories: {
        fields: {
            inventoryDateFrom: '庫存日期由',
            inventoryDateTo: '庫存日期至',
            sapCode: 'SAP 編號',
            serialNumbers: '序列號',
            store: '商店',
            soldTo: '售予',
            creationDateFrom: '創建日期由',
            creationDateTo: '創建日期至',
            lastInventory: '最後庫存'
        },
        columns: {
            inventoryDate: '庫存日期',
            serialNumber: '序列號',
            store: '商店',
            soldTo: '售予',
            product: '產品',
            creationDate: '創建日期',
            storeCode: '商店編號',
            quantity: '數量',
            sapCode: 'SAP 編號'
        },
        button: {
            upload: '上傳庫存',
            download: '下載模板'
        }
    },
    hierarchies: {
        add: '新增區域',
        success: '成功新增新區域',
        entity: {
            singular_name: "新區域",
            plural_name: "新區域"
        },
        edit: {
            modify: '修改區域',
            success: '成功修改區域'

        }

    },
    settings: {
        choose_language: '選擇語言',
        language: '語言',
    },
    stores: {
        fields: {
            code: "編號",
            telephone: "電話",
            state: "狀態",
            bvlAfss: "BVL AFSS",
            openingDate: "開業日期",
            closingDate: "關閉日期",
            phaseOutDate: "淘汰日期",
            phaseOut: "淘汰",
            soldToParty: "賣給方",
            responsabilityArea: "負責區域",
            masterCodeCode: "主代碼編號",
            masterCodeName: "主代碼名稱",
            soldTo: "賣給",
            shipTo: "運送到",
            operatingCountry: "經營國家/地區",
            areaDefaultEmail: "區域預設電子郵件",
            nfcReader: "USB NFC 讀卡器設備",
            smartphone: "智能手機設備"
        },
        details: {
            activateStore: "啟用商店",
            sendAgain: "重新發送",
            activateSmartphone: "啟用智能手機",
            requestActivationSuccess: "成功啟用要求",
            activationSuccess: "成功啟用商店！",
            activate: "啟用",
            askActivation: "確認商店啟用",
            askActivationDescription: "您確定要啟用此商店嗎:"
        }
    },
    profiles: {
        columns: {
            profileName: '檔案名稱',
            jobId: '工作 ID',
            departmentId: '部門 ID',
            jobDescription: '工作描述',
            departmentDescription: '部門描述',
            storeType: '商店類型',
            storeId: '名稱/商店編號',
            dwArea: 'DW 區域',
            edit: '編輯',
        },
        fields: {
            profileName: '檔案名稱',
            jobId: '工作 ID',
            departmentId: '部門 ID',
            jobDescription: '工作描述',
            departmentDescription: '部門描述',
            storeType: '商店類型',
            storeId: '名稱/商店編號',
            dwArea: 'DW 區域',
            add: '新增新檔案',
            edit: '編輯檔案',
            delete: '刪除檔案',
            created: '已建立的商店'
        },
        upload: {
            label: "您可以上傳您的 xls 檔案，將其轉換為檔案。",
            name: "單擊或拖放文件到此區域以上傳",
            error: "文件上傳失敗。",
            success: "文件已成功上傳。"
        }
    },
    manual: {
        columns: {
            photo: '照片',
            sapCode: 'SAP 編號',
            instruction: '手冊',
            brochure: '宣傳冊',
        },
        fields: {
            sapCode: 'SAP 編號'
        }
    },
    help: {
        ask_support: '尋求支援',
        how_to_use: '如何使用',
        how_to_use_video: '觀看以下視頻以快速瞭解數字保固功能的使用方式',
        sent: '已發送電子郵件',
        not_sent: '電子郵件未發送',
        sales_activate_watches: '如何使用銷售標籤來啟用手錶',
        shipping_activate_watches: '如何使用運送標籤來啟用首飾',
        sales_activate_accessories: '如何使用銷售標籤來啟用配件',
        active_watches_type: '要啟用手錶，輸入:',
        sap_code: 'SAP 編號',
        serial_number: '序列號'
    },
    splash: {
        welcome: "歡迎來到",
        presentation: "數字保固和真偽識別是一個全數字化的應用程序，旨在完全數字化地管理真實性和保固。加入我們。",
        enter: '進入'
    },
    info: {
        header: {
            watches: '手錶 - 保固資訊',
            accessories: '配件 - 真實性資訊',
            jewellery_pieces: '首飾 - 真實性和 BVLGARI 國際保固資訊',
            high_jewellery: '高級珠寶 - 真實性資訊'
        },
        body: {
            watches: '這款手錶是 BVLGARI 的原創作品，由瑞士布爾加里手錶 SA 在瑞士諾伊舒泰爾街 34 號製造，符合瑞士鐘錶行業的最高質量標準。購買時激活的保固適用於全球任何地方，並涵蓋所有製造缺陷。此保固僅適用於在 BVLGARI 正式授權的銷售網絡、BVLGARI 商店或經銷商購買的產品。請參閱您的產品的特定保固條件，方法是掃描保固卡上的 QR 編碼或使用卡上的 NFC 功能。只有在購買時，您的 BVLGARI 手錶的國際保固卡被正確激活、填寫並日期由銷售商填寫時，此保固才適用。在保固期內，如果出現製造缺陷，您的手錶將免費維修。此保固不適用於手錶及其附件的正常磨損、遺失、被盗、不正當使用、疏忽等情況。如果手錶序列號無法辨認或被刪除或修改，或由未獲授權的第三方進行維修或維修嘗試，則此保固將無效。此保固不適用於因手錶無法正常運行、製造缺陷或不准確而引起的間接或後果損害。重要提示: 如需進行任何維護或維修，您可以攜帶手錶和相關的保固卡到我們的專賣店或經銷商處。通過掃描國際保固卡上的 QR 編碼或使用其 NFC 功能，您還可以訪問產品的用戶手冊和維護建議、產品的保固條件和保固期限。此國際保固不影響根據適用法律享有的任何其他權利。',
            accessories: '這款 BVLGARI 產品是原創作品，經過最嚴格的質量測試和控制。激活卡後，所有製造缺陷都在購買之日起兩年內全球范圍內涵蓋。這款 BVLGARI 產品包含被動 NFC（近場通信）標籤。該標籤包含產品信息（芯片的唯一 ID、產品代碼、數字簽名），以確保其真實性。這個標籤可以被 NFC 設備讀取。該標籤不包含個人信息，也無法識別產品的擁有者。有關詳細信息，請聯繫 privacy@bulgari.com',
            jewellery_pieces: '這款珠寶是 BVLGARI 的原創作品，經過最嚴格的質量測試。該珠寶附有國際認證和保固證書，可在購買時在 BVLGARI 精品店或授權的 BVLGARI 珠寶經銷商那里激活。國際保固在購買日期起 24 個月內涵蓋所有製造缺陷，適用于世界各國，前提是它在購買時已正確激活。刻在珠寶上的序號必須記錄在保固證書上。如果證書未激活，則此保固無效。如果刻在珠寶上的序號不清晰可讀、已被修改或刪除，則此保固同樣無效。在保固期內，如果出現製造缺陷，該珠寶可免費維修。保固不包括珠寶的正常磨損，以及由於不當使用、維護不當、疏忽或意外事件造成的缺陷或損害。遺失或被盗的情況不在保固范圍之內。此保固不適用于未經授權的第三方進行的修改或維修嘗試。重要提示: 如需維修，您需要將您的 BVLGARI 珠寶連同真實性和保固證書一起攜帶到我們的精品店或經銷商處。您還可以通過掃描國際認證和保固證書上的 QR 編碼或使用 NFC 技術來查閱有關珠寶護理的 BVLGARI 說明以及有關您的珠寶的相關文件。此國際保固不影響根據適用法律享有的其他權利。',
            high_jewellery: '這款產品是 BVLGARI 的原創作品，經過最嚴格的質量測試。激活卡後，所有製造缺陷都在購買之日起兩年內全球范圍內涵蓋。'
        }
    },
    return: {
        title: "僅適用於 HJ 的序號或 SAP 編號",
        return: "退貨",
        fields: {
            serial: "Seriale",
            next: "下一步",
            required: "Seriale 為必填項"
        },
        choiceProduct: "選擇退貨產品",
        summaryProduct: "產品摘要",
        codeSAP: "SAP 編號",
        activationDate: "激活日期",
        store: "商店",
        expiryDate: "到期日期",
        duration: "保固期限",
        reasonReturn: "在下面的表格中輸入原因",
        errorTitle: "無法退貨",
        errorSubTitle: "無法授權退貨此產品",
        imageProduct: "產品圖片",
        infoProduct: "產品資訊",
        infoActivation: "激活資訊",
        warrantyExpired: "保固已過期",
        successTitle: "退貨成功！",
        successSubtitle: "所選產品已成功退貨"
    },
    massive_activation: {
        label: "您可以上傳 xls 檔案以批次進行激活",
        name: "按一下或拖動檔案到此區域以上傳",
        error: "檔案上傳失敗",
        success: "檔案上傳成功"
    },
    status: {
        title: "僅適用於 HJ 的序號或 SAP 編號",
        status: "狀態",
        fields: {
            serial: "Seriale",
            next: "下一步",
            required: "Seriale 為必填項",
            email: "電子郵件",
            emailDescription: "多個電子郵件，用逗號分隔（按 Enter 確認）"
        },
        choiceProduct: "選擇產品",
        summaryProduct: "產品摘要",
        codeSAP: "SAP 編號",
        activationDate: "激活日期",
        store: "商店",
        user: "用戶",
        expiryDate: "到期日期",
        duration: "保固期限",
        reasonReturn: "在下面的表格中輸入原因",
        errorTitle: "無法取得狀態",
        errorSubTitle: "無法授權此產品的狀態",
        imageProduct: "產品圖片",
        infoProduct: "產品資訊",
        infoActivation: "激活資訊",
        warrantyExpired: "保固已過期",
        successTitle: "成功取得狀態！",
        successSubtitle: "已成功取得所選產品的狀態",
        manualProduct: "產品手冊",
        versionPdf: "PDF 版本",
        returnDate: "退貨日期",
        versionPdfTitle: "產品摘要",
        versionPdfSubTitle: "下載 PDF 至您的設備，或發送給剛剛激活產品的客戶",
        sendEmail: "發送郵件",
        downloadPdf: "下載 PDF",
        sendEmailSuccess: "郵件發送成功！",
        manualProductTitle: "產品手冊",
        manualProductSubTitle: "下載 PDF 至您的設備",
        instructions: "使用說明",
        care: "保護",
        infoExpiryDate: "到期日期資訊",
        changeExpiryDate: "更改到期日期",
        confirmChangeExpiryDate: "確認",
        serialNumberError: "序號錯誤！",
        serialNumberErrorMsg: "無法找到序號"
    },
    users: {
        fields: {
            managedStores: '管理的商店',
            managedStoreTypes: '管理的商店類型'
        },
        columns: {
            storeCode: '商店編號',
            role: '角色'
        }
    },
    landing: {
        sapCode: 'SAP 編號',
        expiration_date: '到期日期',
        warranty_duration: '保固期限',
        download_manual: '下載手冊',
        download_brochure: '下載宣傳冊',
        extend_warranty: '延長保固',
        sp_pt_disclaimer: '本國際保固不影響您根據任何適用的國家法律可能擁有的任何其他權利。'
    }
}