import { Layout } from 'antd';
import React from 'react';
import DynamicEditForm, { EditRow } from "../components/form/DynamicEditForm";
import { applyFilters } from "../services/HooksManager";
import languageManager from '../services/LanguageManager';
import { __ } from "../translations/i18n";

const { Content } = Layout;

const SettingExplore: React.FC = () => {

    const sendUpdate = (dataForm: any) => {
        languageManager.sendUpdate(dataForm)
    }

    const getFields = (): EditRow[] => {

        let EditfieldList = [
            {
                fields: []
            },
            {
                fields: [
                    { name: __('settings.save_system'), type: 'button', htmlType: 'submit' }
                ]
            }
        ];

        /**
         * Ritorna la lista di campi per i settings
         * @hook setting_fields
         * @param EditfieldList: EditRow[] Lista dei campi
         *
         */
        return applyFilters('setting_fields', EditfieldList);
    }

    const EditfieldList: EditRow[] = getFields();

    return (
        <Content className='settings'>
            <h3>{__('settings.set_system')}</h3>
            <DynamicEditForm EditFormFields={EditfieldList} onFilters={sendUpdate} />
        </Content>
    );
}

export default SettingExplore;