import { EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Divider, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { useContext } from "react";
import { navigate } from "../../shared/router";
import { __ } from "../../translations/i18n";
import { TabContext } from "../TabContext";

interface Prop {
    entity: any
}

const UserDetails = (props: Prop) => {

    const {entity} = props;
    const key = useContext(TabContext);

    const getStoreDetail = () => {
        const storeId = entity?.store?.id;
        navigate(`/stores/${storeId}`);
    }

    const getDescription = () => {
        let name = null;
        let surname = null;

        if (entity?.name) {
            name = entity?.name.substring(0, 1).toUpperCase();
        }

        if (entity?.surname) {
            surname  = entity?.surname.substring(0, 1).toUpperCase();
        }

        if (name !== null && surname !== null) {
            return name + surname;
        } else if (name !== null && surname === null) {
            return name;
        } else if (name === null && surname !== null) {
            return surname;
        } else {
            return '-';
        }
    }

    const getTitleDescription = () => {
        let name = null;
        let surname = null;

        if (entity?.name) {
            name = entity?.name;
        }

        if (entity?.surname) {
            surname  = entity?.surname;
        }

        if (name !== null && surname !== null) {
            return `${name} ${surname}`;
        } else if (name !== null && surname === null) {
            return name;
        } else if (name === null && surname !== null) {
            return surname;
        } else {
            return '-';
        }
    }

    // entity?.name ? entity?.name.substring(0, 1).toUpperCase() : '' + entity?.surname ? entity?.surname.substring(0, 1).toUpperCase() : ''

    return (
        <div className="site-card-wrapper">
            <Row gutter={[24, 24]} justify="start">
                
                <Col span={10} offset={6}>
                    <Card style={{textAlign: 'start'}} bordered={false} >
                    <Meta
                        avatar={<Avatar style={{ backgroundColor: '#AA8A5F' }} size={{xl: 60, xxl: 80}}>{getDescription()}</Avatar>}
                        title={getTitleDescription()}
                        description={entity?.role?.description ?? '-'}
                    />
                    </Card>
                </Col>
                
                <Col span={10} offset={6}>
                    <Card bordered={false}>
                    <Divider style={{fontSize: 20}}>{__("users.details.store")}</Divider>
                        <Button type='ghost' onClick={getStoreDetail}>{entity?.store?.description}</Button> <br /><br />
                        <em>{entity?.store?.address ?? '-'}</em>
                        <Divider style={{fontSize: 20}}>{__("users.details.email")}</Divider>
                        <Meta
                        avatar={<Avatar style={{ backgroundColor: '#AA8A5F' }}>@</Avatar>}
                        title={entity?.attributes?.email ?? '-'}
                    />
                    </Card>
                </Col>
                
                <Col span={10} offset={6}>
                    <Button style={{width: '100%', fontSize: 20, height: '50px'}} onClick={() => key.setActiveKey('edit')} type="primary" icon={<EditOutlined />}>{__("common.edit")}</Button>
                </Col>
            </Row>
        </div>

    )

}

export default UserDetails;
