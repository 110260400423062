import {call, CallRequestConfig, download} from "../../../shared/api";
import {applyFilters} from "../../../services/HooksManager";
import {Method} from "axios";
import ObjectUtils from "../../../utils/ObjectUtil";
import {DWEnvironmentManager} from "../../../services/DWEnvironmentManager";
import DWPrewarrantyDataProvider from "../DWPrewarrantyDataProvider";

class DWPrewarrantyDataProviderImp implements DWPrewarrantyDataProvider {

    dwEnvironmentManager: DWEnvironmentManager | undefined = undefined;

    endpoint = '';

    getServiceKey(): string {
        return 'dataProvider'
    }

    init = async (dwEnvironmentManager: DWEnvironmentManager) => {
        this.dwEnvironmentManager = dwEnvironmentManager
    }

    private __getEndpoint(overrideModule?: string) {
        return overrideModule ?? this.endpoint;
    }

    private __getExportEndpoint(overrideModule?: string) {
        return overrideModule ?? this.endpoint + '/export';
    }

    applyPreRequestFilter = (call: string, method: Method, path: string, request: CallRequestConfig<any>): {
        method: Method,
        path: string,
        request: CallRequestConfig<any>
    } => {

        /**
         * Prepara la richiesta per il methdo ${call} del Data Provider
         * @hook data_pre_${call}
         * @param {method:Method, path: string, request: CallRequestConfig<any>} dati della richiesta
         */
        return applyFilters(`data_pre_${call}`, {
            method: method,
            path: path,
            request: request
        });
    }

    private static __initRequest(parameters: CallRequestConfig<any> | undefined, request: any) {
        return ObjectUtils.assignDeep(ObjectUtils.filterIfValueIsEmpty(parameters as any), ObjectUtils.filterIfValueIsEmpty(request));
    }

    async checkSerial(parameters?: CallRequestConfig<any>, overrideModule?: string): Promise<any> {
        let res = DWPrewarrantyDataProviderImp.__initRequest(parameters, {});
        const {method, path, request} = this.applyPreRequestFilter('get_one', 'GET', this.__getEndpoint(overrideModule), res);
        return await call<any[]>(method, path, request);
    }

    setModule(module: string) {
        this.endpoint = module;
    }

    async addOne(parameters?: CallRequestConfig<any>, overrideModule?: string){
        let res = DWPrewarrantyDataProviderImp.__initRequest(parameters, {});
        const {method, path, request} = this.applyPreRequestFilter('add_one', 'POST', this.__getEndpoint(overrideModule), res);
        return await call<any>(method, path, request);
    }

    async find(parameters?: CallRequestConfig<any>, overrideModule?: string){
        let res = DWPrewarrantyDataProviderImp.__initRequest(parameters, {});
        const {method, path, request} = this.applyPreRequestFilter('find', 'POST', this.__getEndpoint(overrideModule), res);
        return await call<any>(method, path, request);
    }


}

export default new DWPrewarrantyDataProviderImp();
