import moment from "moment";

class DateUtils {
    static timestampToDate = ( timestamp: number ) => {
        // convert timestamp to date
        let creationDateConverted = new Date ( timestamp );
        return `${ creationDateConverted.getDay () }/${ creationDateConverted.getMonth () }/${ creationDateConverted.getFullYear () } ${ creationDateConverted.getHours () }:${ creationDateConverted.getMinutes () } `;
    }

    static formatDate = (date: Date|number) => {
        return moment(date).format('DD/MM/YYYY HH:mm:ss')
    }

    static formatDateWithoutTime = (date: Date | number) => {
        return moment(date).format('DD/MM/YYYY');
    }

    static getDurationWarranty = (creationDate: Date | number, expiryDate: Date | number) => {
        const duration = new Date(expiryDate).getFullYear() - new Date(creationDate).getFullYear();
        return duration;
    }
}


export default DateUtils;
