import buUserManager from './bulgari/services/BuUserManager';
import buEnvironmentManager from "./bulgari/services/BuEnvironmentManager";
import {DWEnvironmentManager, IService} from "./services/DWEnvironmentManager";

class Ext implements IService {

    dwEnvironmentManager: DWEnvironmentManager | undefined = undefined;

    buEnvironmentManager: any;
    buUserManager: any;

    getServiceKey(): string {
        return 'customExt';
    }

    init = async (dwEnvironmentManager: DWEnvironmentManager) => {
        this.dwEnvironmentManager = dwEnvironmentManager;
        this.buUserManager = buUserManager;
        this.buEnvironmentManager = buEnvironmentManager;
    }

}

const customExt = new Ext();
export default customExt;