import React, {Component} from 'react';
import './App.less';
import DWApp from './components/DWApp';

interface State {
    error: any
}

class App extends Component<{}, State> {

    render() {
        return (
            <DWApp/>
        )
    }
}


export default App;
