// eslint-disable-next-line import/no-anonymous-default-export
export default {
    app: {
        name: 'Temera Digital Warranty',
        url: 'www.buccellati.com'
    },
    error: {
        generic_error: 'Generischer Fehler',
        error: 'Fehler',
        forbidden: 'Verboten',
        not_found: 'Nicht gefunden',
        unauthorized: 'Nicht autorisiert',
        invalid_credentials: 'Ungültige Anmeldedaten!',
        duplicateKey: 'Duplizierter Schlüssel',
        api: 'API Fehler {{method}} {{api}} :\n {{error_message}}',
        requiredRowEditTable: 'Die Tabelle muss mindestens eine Zeile haben',
        error_upload_file: 'Datei-Upload fehlgeschlagen.'
    },
    success: {
        confirm_delete: "Element erfolgreich entfernt",
        confirm_update: "Änderungen erfolgreich bestätigt",
        confirm_password: "Passwort erfolgreich geändert, Sie können sich jetzt in der App anmelden"
    },
    warning: {
        ask_delete: 'Möchten Sie das Element wirklich löschen?',
        unreversible_delete: "Das Löschen ist nicht rückgängig zu machen",
        warning: 'Warnung'
    },
    dashboard: {
        module_name: 'Dashboard'
    },
    common: {
        confirm: 'Bestätigen',
        cancel: 'Abbrechen',
        save: 'Speichern',
        reset: 'Zurücksetzen',
        search: 'Suchen',
        table: {
            total: 'Insgesamt {{total}} Ergebnisse',
            export: 'Exportieren',
            changeMe: 'Ändere mich',
            key: 'Schlüssel',
            value: 'Wert',
            attributes: 'Attribute',
            addRow: 'Zeile hinzufügen',
            keyDescription: 'Schlüsselbeschreibung',
            valueDescription: 'Wertbeschreibung'
        },
        edit: 'Bearbeiten',
        details: 'Details',
        addRow: 'Zeile hinzufügen',
        delete: 'Löschen',
        success: 'Erfolg',
        fieldRequired: 'Pflichtfeld',
        api: 'API',
        email: 'E-Mail',
        goBack: 'Zurück',
        goAhead: 'Weiter',
        description: 'Beschreibung',
        import_success: 'Datei erfolgreich hochgeladen',
        click_upload: 'Klicken Sie zum Hochladen einer Datei'
    },
    menu: {
        dashboard: 'Dashboard',
        users: 'Benutzer',
        stores: 'Geschäfte',
        products: 'Produkte',
        logout: 'Abmelden',
        digital_warranties: 'Digitale Garantien',
        settings: 'Einstellungen',
        about: 'Info',
        modal: {
            copyright: 'Urheberrecht © 2015 Temera. Alle Rechte vorbehalten'
        },
        controlPanel: 'Steuerfeld'
    },
    login: {
        username: 'Benutzername',
        password: 'Passwort',
        confirm_password: 'Passwort bestätigen',
        sign_in: 'Anmelden',
        mandatory_username: 'Bitte geben Sie Ihren Benutzernamen ein!',
        mandatory_password: 'Bitte geben Sie Ihr Passwort ein!',
        mandatory_email: 'Bitte geben Sie Ihre E-Mail-Adresse ein!',
        mandatory_confirm_password: 'Bitte bestätigen Sie Ihr Passwort!',
        forgot_password: 'Passwort vergessen?',
        remember_me: 'Angemeldet bleiben',
        failed: 'Anmeldung fehlgeschlagen',
        recover_password_title: 'Bitte füllen Sie das Formular unten aus, um Ihr Passwort zurückzusetzen',
        recover_password_subtitle: 'Sie erhalten eine E-Mail mit einem Link zum Zurücksetzen des Passworts',
        recover_success: 'E-Mail erfolgreich versendet',
        activation_user_title: 'Konfigurieren Sie Ihr Anmeldepasswort',
        activation_user_subtitle: 'Dies ist nur bei der ersten Anmeldung erforderlich',
        header: 'DIGITAL WARRANTY & AUTHENTICITY',
        fields: {
            username: 'Benutzername',
            email: 'E-Mail',
            send: 'Senden'
        },
        placeholder: {
            input_password: 'Passwort eingeben',
            input_confirm_password: 'Passwort erneut eingeben'
        }
    },
    loginSSO: {
        label: 'Mit Ihrem Konto anmelden',
        sso_button: 'SSO-Anmeldung',
        with_credentials: 'Mit Ihren Anmeldeinformationen anmelden'
    },
    module: {
        listing: {
            title: 'Verwaltung von {{moduleName}}'
        },
        add: {
            title: '{{entityName}} hinzufügen'
        },
        edit: {
            title: '{{entityName}} bearbeiten'
        },
        details: {
            title: '{{entityName}}-Details'
        },
        upload: {
            title: 'Hochladen'
        }
    },
    users: {
        module_name: "Benutzer",
        entity: {
            singular_name: "Benutzer",
            plural_name: "Benutzer"
        },
        details: {
            store: 'Geschäft',
            email: 'E-Mail'
        },
        columns: {
            details: 'Details',
            store: 'Geschäft',
            modified: 'Geändert',
            created: 'Erstellt',
            surname: 'Nachname',
            name: 'Name',
            admin: 'Administrator',
            username: 'Benutzername',
            personal: 'Persönlich',
            exception: 'Ausnahme'
        },
        fields: {
            username: 'Benutzername',
            surname: 'Nachname',
            name: 'Name',
            storeType: 'Geschäftstyp',
            store: 'Geschäft',
            password: 'Passwort',
            confirmPassword: 'Passwort bestätigen',
            email: 'E-Mail',
            role: 'Rolle',
            admin: 'Administrator',
            attributes: 'Attribute',
            add: 'Benutzer hinzufügen',
            area: 'Bereich',
            edit: 'Benutzer bearbeiten',
            userSoldTo: 'Verkauft an Benutzer',
            errors: {
                username: 'Bitte geben Sie einen Benutzernamen ein',
                password: 'Bitte geben Sie ein Passwort ein'
            }
        },
        add: {
            success: 'Benutzer erfolgreich hinzugefügt',
            errorPasswordMatch: 'Die Passwörter stimmen nicht überein'
        },
        edit: {
            success: 'Benutzer erfolgreich bearbeitet'
        }
    },
    prewarranties: {
        module_name: "Voraktivierungen",
        entity: {
            singular_name: "Voraktivierung",
            plural_name: "Voraktivierungen"
        }
    },
    hierarchies: {
        module_name: "Bereich",
        entity: {
            singular_name: "Neuer Bereich",
            plural_name: "Neuer Bereich"
        }
    },
    partnerinventory: {
        module_name: "Inventar"
    },
    warranties: {
        module_name: "Aktivierungen",
        entity: {
            singular_name: "Aktivierung",
            plural_name: "Aktivierungen"
        },
        columns: {
            details: 'Details',
            store: 'Geschäft/Benutzer Ret',
            card: 'Ersatzkarte',
            return: 'Rückgabe',
            storeUserAct: 'Geschäft/Benutzer Act',
            activation: 'Aktivierung',
            serialNumber: 'Seriennummer',
        },
        details: {
            image: "Bild",
            product: "Produkt",
            date: "Datum",
            store: "Geschäft",
            user: "Benutzer",
            delete: "Aktivierung löschen",
            activation: "Aktivierung",
            return: "Rückgabe",
            productPage: "Produktseite",
            downloadPdf: "PDF herunterladen",
        },
        fields: {
            from: 'Von',
            to: 'Bis',
            serialNumber: 'Seriennummer',
            returned: 'Zurückgegeben',
            storeType: 'Geschäftstyp',
            store: 'Geschäft'
        }
    },
    stores: {
        module_name: "Geschäfte",
        entity: {
            singular_name: "Geschäft",
            plural_name: "Geschäfte"
        },
        columns: {
            details: 'Details',
            store: 'Geschäft',
            type: 'Typ',
            activation: 'Aktivierung',
            operationCountry: 'Land/Region der Operation',
            ISOCode: 'ISO Code',
            soldTo: 'Verkauft an',
            status: 'Status'
        },
        fields: {
            from: 'Von',
            to: 'Bis',
            storeType: 'Geschäftstyp',
            store: 'Geschäft',
            address: 'Adresse',
            users: 'Benutzer',
            add: 'Geschäft hinzufügen',
            edit: 'Geschäft bearbeiten',
            nameCodeStore: 'Name/Code Geschäft',
            activationStatus: 'Aktivierungsstatus',
            operatingCountry: "Land/Region des Betriebs",
            sold_to: 'Verkauft an',
            ship_to: 'Verschickt an',
            errors: {
                store: 'Bitte geben Sie den Geschäftsnamen ein'
            }
        },
        details: {
            users: 'Benutzer',
            lastAccess: 'letzter Zugriff: ',
            active: 'Aktiv',
            expectedDevices: 'Erwartete Geräte',
            usbDevice: 'USB-NFC-Reader',
            mobileDevice: 'Smartphone'
        },
        add: {
            success: 'Geschäft erfolgreich erstellt'
        },
        edit: {
            success: 'Geschäft erfolgreich bearbeitet!'
        }
    },
    masterdata: {
        module_name: "Produkte",
        entity: {
            singular_name: "Produkt",
            plural_name: "Produkte"
        }
    },
    settings: {
        set_system: 'Konfigurieren Sie Ihr System',
        save_system: "Speichern"
    },
    products: {
        columns: {
            details: 'Details',
            shortDescription: 'Kurzbeschreibung',
            description: 'Beschreibung',
            creationDate: 'Erstellungsdatum',
            photo: 'Foto',
            marketingDescription: 'Marketingbeschreibung',
            materialType: 'Materialtyp',
            nfcEmbedded: 'NFC aktiviert',
        },
        fields: {
            id: 'ID',
            code: 'Code',
            description: 'Beschreibung',
            attributes: 'Attribute',
            materialType: 'Materialtyp',
            NFC: 'NFC aktiviert'
        },
        edit: {
            successEditDescription: 'Produkt erfolgreich aktualisiert!'
        }
    },
    geolocation: {
        not_enabled: 'Standortbestimmung nicht aktiviert'
    },
    image: {
        masterdata_image: "Produktbild"
    },
    landing: {
        identified_product: "Identifiziertes Produkt",
        sku_upc: "SKU/UPC",
        serial: "Seriennummer",
        activation_store: "Aktivierungsgeschäft",
        activation_date: "Aktivierungsdatum",
        information: "Weitere Informationen zur Produktaktivierung",
        card: "Landing-Karte"
    },
    profiles: {
        module_name: "Profile",
        entity: {
            singular_name: 'Profil'
        }
    },
    'bu-manual': {
        module_name: "Handbuch",
        entity: {
            singular_name: 'Handbuch'
        }
    }
}
