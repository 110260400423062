import { BookOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Image } from "antd";
import { FormLayout } from "antd/lib/form/Form";
import moment from 'moment';
import CrudModule from "../../components/CrudModule";
import { FormRow } from "../../components/form/DynamicForm";
import { MenuNode } from "../../components/MenuComponent";
import PageViewer from "../../components/PageViewer";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { addFilter } from "../../services/HooksManager";
import ParentModule from "../../shared/module/CrudModule";
import { __ } from "../../translations/i18n";
import { __b } from "../translations/i18n";


class ManualModule extends ParentModule {


    init = () => {
        super.init();
        addFilter ( 'app_sub_menu', this.addMenu );

        addFilter ( 'app_routes', ( routes: any ) => {
            routes[this.getRoute()] = {
                component: PageViewer,
                props: { content: <CrudModule tabs={['listing']} module={ this }/>, selectedKey: this.getRoute() },
                visibility: 'private'
            };
            return routes;
        } );

        addFilter ( 'search_form_data_' + this.getModuleKey (), this.getSearchData );

        // Override provider
        addFilter(`crud_listing_component_${this.getModuleKey()}`, this.getCrudListing);
    }

    addMenu = ( menu: MenuNode[] ) => {
        menu.push ( {
            label: <i >{ __b ( 'menu.manual' ) }</i>,
            icon: <BookOutlined />,
            position: '41',
            key: this.getRoute()
        } );

        return menu;
    }

    getShowExport = () => {
        return false;
    }

    getCrudListing = ( component: any, module: any ) => {

        const provider = DWEnvironmentManager.getDataProvider();
        provider.setModule('masterdata');

        return component;
    }

    getModuleKey = (): string => {
        return "bu-manual";
    }

    __getFormFields = (): FormRow[] => {

        return [
            {
                fields: [
                    {
                        name: 'upc',
                        label: __b ( 'manual.fields.sapCode' ),
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 8
                        }
                    }
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    {
                        name: __ ( 'common.reset' ),
                        type: 'button',
                        htmlType: 'reset',
                        block: 'true',
                        buttonType: 'default',
                        icon: <ReloadOutlined />,
                        colConf: { span: 2, offset: 10 }
                    },
                    { name: __ ( 'common.search' ), type: 'button', htmlType: 'submit', block: 'true', icon: <SearchOutlined />, colConf: { span: 2 } }
                ]
            }
        ];

    }

    getSearchData = ( data: { [ key: string ]: any }, module:any) => {
        data[ 'MatType' ] = 'PFMO';

        let dataClone: { [ key: string ]: any } = {};
        Object.keys ( data ).map ( ( key ) => {
            if( moment.isMoment ( data[ key ] ) ) {
                return dataClone[ key ] = data[ key ].format ( 'YYYY/MM/DD' );
            } else {
                return dataClone[ key ] = data[ key ];
            }
        } );

        return dataClone;
    }

    __getColumnsTable = () => {
        return [
            {
                title: __b ( 'manual.columns.photo' ),
                dataIndex: 'photo',
                key: 'photo'
            },
            {
                title: __b ( 'manual.columns.sapCode' ),
                dataIndex: 'sapCode',
                key: 'sapCode'
            },
            {
                title: __b ( 'manual.columns.instruction' ),
                dataIndex: 'instruction',
                key: 'instruction'
            },
            {
                title: __b ( 'manual.columns.brochure' ),
                dataIndex: 'brochure',
                key: 'brochure'
            }
        ];
    }

    __getMapData = ( row: any ) => {

        let imgDownload =  <Image preview={false} width={50} fallback={DWEnvironmentManager.getNotFoundImg()} src={ DWEnvironmentManager.getStoragePath () +'assets/img/download_pdf_off.svg' } alt='download_pdf_off.svg'/>;

        let manual =  row?.attributes?.manual?.value ?
            <a href={ `${ DWEnvironmentManager.getStoragePath () }assets/pdf/${ row?.attributes?.manual?.value }` }> {imgDownload}  </a> :  <a> </a>
        let brochure =  row?.attributes?.manual?.value ? <a href={ `${ DWEnvironmentManager.getStoragePath () }assets/pdf/${ row?.attributes?.brochure?.value }` }>{imgDownload} </a> :  <a> </a>

        return {
            photo: <Image fallback={DWEnvironmentManager.getNotFoundImg()} src={ `${ DWEnvironmentManager.getStoragePath () }assets/images/imported/${ row.code }.jpg` }/>,
            instruction: manual,
            brochure: brochure,
            sapCode: row.code,
        }
    }

    __getLayout = (): FormLayout => {
        return "vertical";
    }
}

const manualModule = new ManualModule ();
export default manualModule;
