import { User } from '../models/User';
import DWUserDataProvider from "../providers/DWUserDataProvider";
import { addFilter, applyActions, applyFilters } from "../services/HooksManager";
import { CallRequestConfig } from "../shared/api";
import ObjectUtils from "../utils/ObjectUtil";
import { DWEnvironmentManager, IService } from "./DWEnvironmentManager";


class DWUserManager implements IService {

    dwEnvironmentManager: DWEnvironmentManager | undefined = undefined;
    dWUserDataProvider: DWUserDataProvider | undefined;

    authToken: string|undefined = undefined;
    loggedUser: User|undefined = undefined;
    expiryDate: Date | undefined = undefined;

    ENABLED = 'enabled';
    DENIED = 'denied';
    AUTHTOKEN = `authToken`;
    USER = `user`;
    EXPIRATIONDATE = `expiryDate`;

    getServiceKey(): string {
        return 'userManager'
    }

    /**
     * Init
     */
    init = async (dwEnvironmentManager: DWEnvironmentManager) => {
        this.dwEnvironmentManager = dwEnvironmentManager
        this.dWUserDataProvider = this.dwEnvironmentManager.getUserDataProvider()

        // Carico dati utente da local Storage
        await this.loadUser()

        addFilter('api_request', this.addToken);

        await this.verifyLoginUser()
    }

    addToken = (request: CallRequestConfig<any>) => {
        if (this.authToken) {
            request = ObjectUtils.assignDeep({}, request, {
                headers: {
                    'x-tmr-token': this.authToken
                }
            })
        }

        return request;
    }

    /**
     * Esegue il logou e notifica l'esito
     * @param username:string
     * @param password:string
     * @param remindMe:boolean
     */
    async doLogout() {
        await this.cleanUser()

        /**
         *  Notifica evento di logout completato con successo
         *  @hook logout_success
         */
        applyActions('logout_success')

    }

    /**
     * Esegue la login e notifica l'esito
     * @param username:string
     * @param password:string
     * @param remindMe:boolean
     */
    async doLogin(username: string, password: string, remindMe: boolean) {

        try {

            // Catturo token
            const token = await this.dWUserDataProvider?.getToken(username, password);

            if (token) {

                // Token valido
                this.authToken = token;
                const expire = remindMe ? 14 : 1
                return this.completeLogin(token, expire);
            }

        } catch (err) {

            // Log errore
            console.error(err);
        }

        // Successo qualcosa durante il login
        // Forzo logout per sicurezza
        await this.doLogout()

        /**
         *  Notifica evento di login fallito
         *  @hook login_failed
         *  @param erro Messaggio del server
         */
        applyActions('login_failed', username, password)

        return false;
    }

    async completeLogin(token: any, expire: any) {
        const user = await this.getLoggedUser();

        if (user) {

            // User valido
            this.loggedUser = user;

            // Salvo dati su local storage per futuro utilizzo
            await this.storeUser(user, token, expire);

            // Geo local
            await this.saveGeolocation();

            /**
             *  Notifica evento di login completato con successo
             *  @hook login_success
             *  @param user Utente loggato
             */
            applyActions('login_success', user)

            return true;
        }
    }

    /**
     * Salvo dati utente
     * @param token
     * @param expire
     */
    private async storeUser(user: User, token: string, expire = 0) {

        const storage = this.dwEnvironmentManager?.getStorage()

        // Calcolo scadenza token
        let date = new Date();
        date.setDate(date.getDate() + expire);


        this.authToken = token
        this.loggedUser = user

        await storage.save(this.__authorizationToken(), token)
        await storage.save(this.__userFilter(), user)
        await storage.save(this.__expirationDate(), date);
    }

    /**
     * Elimino dati utente
     */
    private async cleanUser() {

        const storage = this.dwEnvironmentManager?.getStorage();

        await storage.remove(this.__authorizationToken());
        await storage.remove(this.__userFilter());
        await storage.remove(this.__expirationDate());

        this.authToken = undefined;
        this.loggedUser = undefined;
        this.expiryDate = undefined;

    }

    /**
     * Metodo per la verifica di Capability 
     */
    hasCapability(capability: any) {
        return applyFilters('user_has_capability', this.loggedUser, capability);
    }

    /**
     * Carico dati utente
     */
    private async loadUser() {

        const storage = this.dwEnvironmentManager?.getStorage()
        this.authToken = await storage.load(this.__authorizationToken(), undefined)
        this.loggedUser = await storage.load(this.__userFilter(), undefined)
        this.expiryDate = await storage.load(this.__expirationDate(), undefined)
    }

    private __authorizationToken(){
        return applyFilters('authorization_token_filter', this.AUTHTOKEN);
    }

    private __userFilter(){
        return applyFilters('user_filter', this.USER);
    }

    private __expirationDate(){
        return applyFilters('expiration_date_filter', this.EXPIRATIONDATE);
    }

    /**
     * Chiamata per accedere dopo aver salvato il token nello Storage
     */
    async getLoggedUser() : Promise<User|undefined> {

        const user = await this.dWUserDataProvider?.getLoggedUser();
        return user;
    }

    /**
     * Ritorna stato loggato/non loggato
     */
    isLoggedUser = (): boolean => {
        const storage = this.dwEnvironmentManager?.getStorage();
        const expiryDate = storage.getItem('dwarranty::expiryDate');
        if (expiryDate && (new Date(expiryDate).getTime() > new Date().getTime()) && this.loggedUser) {
            return true;
        } else if (!expiryDate) {
            return !!this.loggedUser;
        }
        return !!this.loggedUser;
    }

    verifyLoginUser = async () => {
        const storage = this.dwEnvironmentManager?.getStorage();
        const token = await storage.load('authToken', undefined);
        if (token) {
            const user = await storage.load('user', undefined);
            if (!user) {
                const res = await this.completeLogin(token, 2)
                return res;
            }
        }
    }

    getExpiryDate = async () => {
        const storage = this.dwEnvironmentManager?.getStorage();
        this.expiryDate = await storage.load(this.EXPIRATIONDATE, undefined);
        return this.expiryDate;
    }

    /**
     * Ritorna utente loggato
     */
    getSessionUser() {
        return this.loggedUser;
    }

    /**
     * Chiamata per aggiornare la geolocalizzazione dell' utente
     */
    saveGeolocation = async () => {

        let user = this.getSessionUser();

        if (user?.attributes.geolocationPermission === this.DENIED) {

            navigator.geolocation.getCurrentPosition((position) => {

                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;

                if (latitude && longitude) {

                    // set the geolocation enabled
                    user!.attributes.geolocationPermission = this.ENABLED;
                    user!.attributes.geolocation = `geo:${latitude},${longitude}`;

                    /**
                     * Ritorna l'utente a cui saranno aggiornati gli attributi della geolocalizzazione
                     * @hook before_user_geolocation_update
                     * @param user: User
                     * @param geo: [{latitude: number, longitude: number}}
                     *
                     */
                    user = applyFilters('before_user_geolocation_update', user, {
                        latitude: latitude,
                        longitude: longitude
                    });

                    if (user?.id && this.dWUserDataProvider) {

                        this.dWUserDataProvider.update(
                            user?.id,
                            user
                        )
                    }
                }

            }, (error) => {

                // set the geolocation denied
                user!.attributes.geolocationPermission = this.DENIED;

                if (user?.id && this.dWUserDataProvider) {

                    this.dWUserDataProvider.update(
                        user?.id,
                        user
                    )
                }
            });
        }
    }

    /**
     * Metodo per ricaricare l'utente attualmente loggato su DW
     */
    reloadLoggedUser = async () => {
        const storage = this.dwEnvironmentManager?.getStorage();
        const authToken = await storage.load(this.__authorizationToken(), undefined);
        const expiryDate = await storage.load(this.__expirationDate(), undefined);
        if (authToken) {
            await dWUserManager.completeLogin(authToken, expiryDate );
        }
    }
}

const dWUserManager = new DWUserManager();
export default dWUserManager;
