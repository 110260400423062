import i18n, {__} from '../../translations/i18n';
import en from '../../bulgari/translations/en';
import it from '../../bulgari/translations/it';
import fr from '../../bulgari/translations/fr';
import de from '../../bulgari/translations/de';
import es from '../../bulgari/translations/es';
import pt from '../../bulgari/translations/pt';
import ru from '../../bulgari/translations/ru';
import ko from '../../bulgari/translations/ko';
import zht from '../../bulgari/translations/zht';
import zhs from '../../bulgari/translations/zhs';
import ja from '../../bulgari/translations/ja';
import ar from '../../bulgari/translations/ar';
import {TOptions} from "i18next";

i18n.addResourceBundle('en', 'bulgari', en);
i18n.addResourceBundle('it', 'bulgari', it);
i18n.addResourceBundle('fr', 'bulgari', fr);
i18n.addResourceBundle('de', 'bulgari', de);
i18n.addResourceBundle('es', 'bulgari', es);
i18n.addResourceBundle('pt', 'bulgari', pt);
i18n.addResourceBundle('ru', 'bulgari', ru);
i18n.addResourceBundle('ko', 'bulgari', ko);
i18n.addResourceBundle('zht', 'bulgari', zht);
i18n.addResourceBundle('zhs', 'bulgari', zhs);
i18n.addResourceBundle('ja', 'bulgari', ja);
i18n.addResourceBundle('ar', 'bulgari', ar);

export const __b = (translation: string, interpolation?: TOptions) => {

    if(interpolation === undefined) {
        interpolation = {}
    }

    return __(translation, Object.assign(interpolation, {ns: 'bulgari'}))
}