import { Button, Col, Form, Row } from 'antd';
import React, { Component } from "react";
import { InfoCircleTwoTone, SettingTwoTone } from '@ant-design/icons';
import { __ } from '../../translations/i18n';
import { Header } from 'antd/lib/layout/layout';
import { infoModal } from '../../services/NotificationManager';
import { navigate } from '../../shared/router';
import dwEnvironmentManager from "../../services/DWEnvironmentManager";
import { __b } from '../translations/i18n';


interface IProps {

}

interface IState {

}

class SplashPage extends Component<IProps, IState> {


    getLogo = () => {
        return dwEnvironmentManager.getMainLogo();
    }

    getHeaderLogo = () => {
        return dwEnvironmentManager.getHeaderLogo();
    }


    showModal() {
        infoModal({
            title: dwEnvironmentManager.getApplicationName(),
            content: (<div>{dwEnvironmentManager.getVersion()}<p> {__('menu.modal.copyright')} </p></div>)
        })
    };

    render() {
        return (
            <div className="App">
                <Header className='login'>
                    <img src={this.getHeaderLogo()} alt="logo" />
                    <h4>{__("login.header")}</h4>
                    <SettingTwoTone twoToneColor="#AA8A5F" onClick={() => navigate('/settingsExplore')} />
                    <InfoCircleTwoTone twoToneColor="#AA8A5F" onClick={this.showModal} />
                </Header>
                <main>
                    <Row gutter={[24, 24]} justify={"center"}>
                        <Form className='splash'>
                            <div>
                                {__b('splash.welcome')}
                            </div>
                            <img src={this.getLogo()} className='App-logo' alt="logo" />
                            <div>
                                {__b('splash.presentation')}
                            </div>
                            <Col span={24}>
                                    <Button type="primary" htmlType="submit" onClick={() => navigate('/login') } className='enter'>
                                        {__b('splash.enter')}
                                    </Button>
                                </Col>
                        </Form>
                    </Row>
                </main>
            </div>
        )
    }
}

export default SplashPage;
