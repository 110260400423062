import {Affix, Alert, Image, Layout} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { addFilter, applyFilters } from "../services/HooksManager";
import { navigate } from '../shared/router';
import { capitalize } from "lodash";
import dwEnvironmentManager from "../services/DWEnvironmentManager";
import Breadcrumbs from "./Breadcrumbs";
import MenuComponent from "./MenuComponent";
// import Marquee from "react-fast-marquee";

interface Props {
    content: JSX.Element[]
    location: any,
    selectedKey: string
}

interface State {
    collapsed: boolean
}

const { Header, Sider } = Layout;

class PageViewer extends Component<{} & Props, State> {

    constructor(props: Props | Readonly<Props>) {
        super(props);
        this.state = {
            collapsed: true
        };

        addFilter('toolbar_menu', this.getUserDataForToolbar);
    }

    setCollapsed(value: any) {
        this.setState({
            collapsed: value
        });
    }

    getToolbar() {
        let toolbar: { key: string, item: JSX.Element }[] = [];
        toolbar.push(
            {
                key: 'ImgLogo',
                item: <Image preview={false} src={dwEnvironmentManager.getMainLogo()} />

            },
            {
                key: 'goHome',
                item: <span style={{ cursor: "pointer" }}
                    onClick={() => navigate('/dashboard')}> {dwEnvironmentManager.getApplicationName()} </span>
            }
        );

        /**
         *  Cattura la toolbar eventualmente modificato dall'estensione
         *  @hook toolbar_menu
         *  @param toolbar: { key: string, item: JSX.Element }[] Array per la struttura della toolbar
         */
        return applyFilters('toolbar_menu', toolbar);
    }

    getUserDataForToolbar(toolbar: { key: string, item: JSX.Element }[]) {

        const userManager = dwEnvironmentManager.getUserManager()

        let userProfile = {
            key: 'User',
            item:
                <span>{capitalize(userManager.getSessionUser()?.name)} - {capitalize(userManager.getSessionUser()?.surname)}</span>
        }

        // check if item is present
        const index = toolbar.findIndex(object => object.key === userProfile.key);
        if (index === -1) {
            toolbar.push(userProfile);
        }

        return toolbar
    }

    getClassName = () => {
        const routeClassName = 'page-' + this.props.location.pathname.replace(/^\/|\/$/g, '').replace("/", '-');
        return routeClassName
    }

    render() {
        const { content, selectedKey } = this.props

        return (
            <div>
            {/*    <Alert*/}
            {/*    banner*/}
            {/*    message={*/}
            {/*        <Marquee pauseOnHover gradient={false} speed={50}>*/}
            {/*            Next Monday 20th the site could have disservices due to maintenance for the activation of a new feature. This will happen in the morning from 9:30AM (CET) to 10:30AM (CET) (Rome time zone) and we will inform you once completed.*/}
            {/*        </Marquee>*/}
            {/*    }*/}
            {/*/>*/}
                <Layout style={{ minHeight: '100vh' }} className={this.getClassName()}>
                    <Sider className='sider' collapsible collapsed={this.state.collapsed}
                        onCollapse={value => this.setCollapsed(value)}>
                        <div className="logo" />
                        <MenuComponent selectedKey={selectedKey} />
                    </Sider>
                    <Layout className="site-layout">
                        <Affix>
                            <header className={'headerToolbar'} style={{}}>
                                {this.getToolbar().map((item: {
                                    key: string;
                                    item: JSX.Element
                                }) =>
                                    <div id={item.key} key={item.key}>{item.item}</div>)
                                }
                            </header>
                        </Affix>
                        <div id='contentContainer' style={{ marginTop: 10}}>
                            <Breadcrumbs></Breadcrumbs>
                            {content}
                        </div>
                    </Layout>
                </Layout>
            </div >
        )
    }
}

// @ts-ignore
export default withRouter(PageViewer);
// export default PageViewer
