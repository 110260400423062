import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, Col, Collapse, DatePicker, Form, FormInstance, Image, Input, Popconfirm, Row, Select, Typography } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DWEnvironmentManager from "../../../services/DWEnvironmentManager";
import { errorToast } from "../../../services/NotificationManager";
import { __ } from "../../../translations/i18n";
import { fetchStores } from "../../modules/StoreModule";
import { __b } from "../../translations/i18n";

interface Props {
    module: string,
    add: (data: any, selectedStore: any) => any,
    checkSerial: (data: any) => any
}

const PreWarrantiesAdd = (props: Props) => {
    const formRef = React.createRef<FormInstance>();

    const [storesOptions, setStoresOptions] = useState<any>([]);
    const [usersOptions, setUsersOptions] = useState<any>([]);
    const [sapCodeOptions, setSapCodeOptions] = useState<any>([]);
    const [sapCode, setSapCode] = useState<any>({});
    const [selectedStore, setSelectedStore] = useState<string>('');
    const [validSerial, setValidSerial] = useState<boolean>(true);
    const [formData, setFormData] = useState<any>();
    const [serial, setSerial] = useState<string>('');

    useEffect(() => {
        searchUser();
    }, [selectedStore]);

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        // Can not select days after today
        return current > moment().endOf('day');
      };

    const customForm = () => {
        const { Panel } = Collapse;

        return <>
        <div className="site-card-wrapper">
        <Row gutter={[24, 24]} justify="center">
            <Col span={12}>
                <Card bordered={false} style={{marginBottom: 50, marginTop: 30 }}>

                    <Form name="warrantyAdd" layout="vertical" onFinish={submitForm} ref={formRef} >
                        <Row gutter={[24, 24]}>
                            <Col span={14}>
                                <Form.Item label={__b('warranties.fields.sapCode')} name={"sapCode"} rules={[{ required: true, message: __b('warranties.fields.errors.sapCode')}]}>
                                    <Select showSearch notFoundContent={null} defaultActiveFirstOption={false} showArrow={false} filterOption={false} onSearch={(key: string) => searchSAP(key)} options={sapCodeOptions} allowClear onSelect={selectSap} />
                                </Form.Item>
                            </Col>

                            { sapCodeOptions.length > 0 && <Col span={24}>
                                <Collapse defaultActiveKey={['1']} bordered={false} ghost>
                                    <Panel header={""} key="1">
                                        <Row gutter={[24, 24]}>
                                            <Col span={12}>
                                                <Image fallback={DWEnvironmentManager.getNotFoundImg()} src={ `${DWEnvironmentManager.getStoragePath()}assets${sapCode?.attributes?.image_thumb?.value}`} />
                                            </Col>

                                            <Col span={12}>
                                                <Typography>
                                                    <Paragraph>{sapCode.code}</Paragraph>
                                                    <Title level={3}>{sapCode.description}</Title>
                                                    <Title level={4}>{__('common.description')}</Title>
                                                    <Paragraph>{sapCode?.attributes?.longDescription?.value}</Paragraph>
                                                </Typography>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </Col>}

                            <Col span={14}>
                                <Form.Item name={"activationDate"} label={__b('warranties.fields.activationDate')} rules={[{ required: true, message: __b('warranties.fields.errors.activationDate')}]}>
                                    <DatePicker disabledDate={disabledDate} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item name={"serialNumber"} label={__b('warranties.fields.serialNumber')} rules={[{ required: true, message: __b('warranties.fields.errors.serialNumber')}]}>
                                    <Input defaultValue={serial} value={serial} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name={"storeId"} label={__b('warranties.fields.store')} rules={[{ required: true, message: __b('warranties.fields.errors.store')}]} >
                                    <Select showSearch filterOption={false} onSearch={(key: string) => searchStore(key)} onSelect={ (value: any) => selectStore(value)} options={storesOptions} allowClear onClear={clearSelect} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item key={"userId"} name={"userId"} label={__b('warranties.fields.user')} rules={[{ required: true, message: __b('warranties.fields.errors.user')}]}>
                                    <Select showSearch filterOption={false} options={usersOptions} disabled={storesOptions.length === 0} allowClear />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item name={"sequential"} label={__b('warranties.fields.sequentialCard')} rules={[{ required: true, message: __b('warranties.fields.errors.sequentialCard')}]}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item name={"addWarranty"}>
                                    <Popconfirm placement={"left"} open={!validSerial} title={__b('warranties.warning')} onConfirm={() => props.add(formData, selectedStore)} okText={__('common.confirm')} cancelText={__('common.cancel')} onCancel={() => setValidSerial(true)}> 
                                    </Popconfirm>
                                    <Button htmlType="submit" block={true} icon={<SaveOutlined />} type={"primary"}>{__b('warranties.fields.add')}</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
        </div>
        </>
    }

    const searchStore = async (key: string) => {
        const storesOptions = await fetchStore(key);
        setStoresOptions(storesOptions);
    }

    const searchUser = async () => {
        const usersOptions = await fetchUsersWithStore();
        setUsersOptions(usersOptions);
    }

    const searchSAP = async (key: string) => {
        const sapCodeOptions = await fetchSAPCode(key);
        const sapCode = sapCodeOptions[key];
        setSapCodeOptions([{label: `${sapCode.code} - ${sapCode.description}`, value: sapCode.code}]);
        setSapCode(sapCode);
    }

    const selectSap = () => {
        setSerial('pluto');
    }

    const fetchSAPCode = async (key: string) => {
        const provider = DWEnvironmentManager.getDataProvider();
        return await provider.addOne({ data: [key] }, 'masterdata/find');
    }


    const selectStore = (value: any) => {
        setSelectedStore(value);
    }

    const fetchUsersWithStore = () => {
        const provider = DWEnvironmentManager.getDataProvider();
        const moduleManager = DWEnvironmentManager.getModuleManager();
        const storeModule = moduleManager.getModule('users');

        return provider.getList(20, 0, {
            params: {
                storeId: selectedStore
            }
        }, storeModule.getModuleKey())
            .then((response) =>
                response.map((res: any) => ({
                    label: `${res.username}`,
                    value: res.id,
                })),
            );
    }

    const fetchStore = async (key: string) => {

        const provider = DWEnvironmentManager.getDataProvider();
        const moduleManager = DWEnvironmentManager.getModuleManager();
        const storeModule = moduleManager.getModule('stores');

        return provider.getList(20, 0, {
            params: {
                description: key,
                orderBy: 'DESCRIPTION'
            }
        }, storeModule.getModuleKey())
            .then((response) =>
                response.map((res: any) => ({
                    label: `${res.username}`,
                    value: res.id,
                })),
            );
    }

    const clearSelect = () => {
        setStoresOptions([]);
        setUsersOptions([]);
        setSelectedStore('');
        formRef.current!.setFieldsValue({ userId: undefined });
    }

    const submitForm = async (data: any) => {
        setFormData(data);
        let validSerial = await checkWarranty(data);
        if (!validSerial) return;

        const serial = props.checkSerial(data);
        if (serial) {
            setValidSerial(true);
            props.add(data, selectedStore);
        }
        
        // gestione casisitca seriale per accessori ed alta gioielleria - in questo caso non dev'essere controllata coerenza sap seriale
        if (!serial && sapCode?.attributes?.MatType?.value === 'PFMA') {
            setValidSerial(true);
            props.add(data, selectedStore);
        }
    }

    const checkWarranty = async (data: any) => {
        // verify if Prewarranty is already present in DW
        const provider = DWEnvironmentManager.getDataProvider();
        const preWarranty = await provider.getList(undefined, undefined, {
            params: {
                itemEpc: data.sapCode + data.serialNumber,
                returned: 'false'
            }
        }, '/prewarranties/operations');

        if (preWarranty.length > 0) {
            errorToast(__b('prewarranties.error.prewarrantyAlreadyPresentTitle'), __b('prewarranties.error.prewarrantyAlreadyPresentDescription'));
            return false;
        } else {
            return true;
        }
    }
    
    return customForm();
     
}

export default PreWarrantiesAdd;