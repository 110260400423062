/* eslint-disable import/no-anonymous-default-export */
export default {
    app: {
        name: 'Temera Digital Warranty',
        url: 'www.buccellati.com'
    },
    error: {
        generic_error: 'Erreur générique',
        error: 'Erreur',
        forbidden: 'Interdit',
        not_found: 'Non trouvé',
        unauthorized: 'Non autorisé',
        invalid_credentials: 'Identifiants invalides !',
        duplicateKey: 'Clé en double',
        api: 'Erreur API {{method}} {{api}} :\n {{error_message}}',
        requiredRowEditTable: 'La table doit avoir au moins une ligne',
        error_upload_file: 'Échec de chargement du fichier.'
    },
    success: {
        confirm_delete: "Élément supprimé avec succès",
        confirm_update: "Modification réussie",
        confirm_password: "Mot de passe modifié, vous pouvez maintenant vous connecter à l'application"
    },
    warning: {
        ask_delete: 'Êtes-vous sûr de vouloir supprimer l\'élément ?',
        unreversible_delete: "La suppression sera irréversible",
        warning: 'Avertissement'
    },
    dashboard: {
        module_name: 'Tableau de bord'
    },
    common: {
        confirm: 'Confirmer',
        cancel: 'Annuler',
        save: 'Enregistrer',
        reset: 'Réinitialiser',
        search: 'Chercher',
        table: {
            total: 'Total de {{total}} résultats',
            export: 'Exportation',
            changeMe: 'Changez-moi',
            key: 'Clé',
            value: 'Valeur',
            attributes: 'Attributs',
            addRow: 'Ajouter une ligne',
            keyDescription: 'Description de la clé',
            valueDescription: 'Description de la valeur'
        },
        edit: 'Modifier',
        details: 'Détails',
        addRow: 'Ajouter une ligne',
        delete: 'Supprimer',
        success: 'Succès',
        fieldRequired: 'Champ obligatoire',
        api: 'API',
        email: 'E-mail',
        goBack: 'Retour',
        goAhead: 'Continuer',
        description: 'Description',
        import_success: 'Fichier importé avec succès',
        click_upload: 'Cliquez pour télécharger un fichier'
    },
    menu: {
        dashboard: 'Tableau de bord',
        users: 'Utilisateurs',
        stores: 'Magasins',
        products: 'Produits',
        logout: 'Déconnexion',
        digital_warranties: 'Garanties numériques',
        settings: 'Paramètres',
        about: 'À propos',
        modal: {
            copyright: 'Copyright © 2015 Temera. Tous droits réservés'
        },
        controlPanel: 'Panneau de contrôle'
    },
    login: {
        username: 'Nom d\'utilisateur',
        password: 'Mot de passe',
        confirm_password: 'Confirmer le mot de passe',
        sign_in: 'Se connecter',
        mandatory_username: 'Veuillez entrer votre nom d\'utilisateur !',
        mandatory_password: 'Veuillez entrer votre mot de passe !',
        mandatory_email: 'Veuillez entrer votre e-mail !',
        mandatory_confirm_password: 'Veuillez confirmer votre mot de passe !',
        forgot_password: 'Mot de passe oublié ?',
        remember_me: 'Se souvenir de moi',
        failed: 'Authentification échouée',
        recover_password_title: 'Remplissez le formulaire ci-dessous pour réinitialiser votre mot de passe',
        recover_password_subtitle: 'Vous recevrez un e-mail contenant un lien pour réinitialiser le mot de passe',
        recover_success: 'E-mail envoyé avec succès',
        activation_user_title: 'Configurez votre mot de passe d\'accès',
        activation_user_subtitle: 'Cette opération ne sera nécessaire qu à la première connexion',
        header: 'GARANTIE NUMÉRIQUE & AUTHENTICITÉ',
        fields: {
            username: 'Nom d\'utilisateur',
            email: 'E-mail',
            send: 'Envoyer'
        },
        placeholder: {
            input_password: 'Entrez le mot de passe',
            input_confirm_password: 'Entrez à nouveau le mot de passe'
        }
    },
    loginSSO: {
        label: 'Se connecter avec votre compte',
        sso_button: 'Connexion SSO',
        with_credentials: 'Se connecter avec vos identifiants'
    },
    module: {
        listing: {
            title: 'Gestion de {{moduleName}}'
        },
        add: {
            title: 'Ajouter {{entityName}}'
        },
        edit: {
            title: 'Modifier {{entityName}}'
        },
        details: {
            title: 'Détails de {{entityName}}'
        },
        upload: {
            title: 'Télécharger'
        }
    },
    users: {
        module_name: "Utilisateurs",
        entity: {
            singular_name: "Utilisateur",
            plural_name: "Utilisateurs"
        },
        details: {
            store: 'Magasin',
            email: 'E-mail'
        },
        columns: {
            details: 'Détails',
            store: 'Magasin',
            modified: 'Modifié',
            created: 'Créé',
            surname: 'Nom de famille',
            name: 'Nom',
            admin: 'Administrateur',
            username: 'Nom d\'utilisateur',
            personal: 'Personnel',
            exception: 'Exception'
        },
        fields: {
            username: 'Nom d\'utilisateur',
            surname: 'Nom de famille',
            name: 'Prénom',
            storeType: 'Type de magasin',
            store: 'Magasin',
            password: 'Mot de passe',
            confirmPassword: 'Confirmer le mot de passe',
            email: 'E-mail',
            role: 'Rôle',
            admin: 'Administrateur',
            attributes: 'Attributs',
            add: 'Ajouter un utilisateur',
            area: 'Zone',
            edit: 'Modifier l\'utilisateur',
            userSoldTo: 'Utilisateur Sold To',
            errors: {
                username: 'Entrez un nom d\'utilisateur',
                password: 'Entrez un mot de passe'
            }
        },
        add: {
            success: 'Utilisateur ajouté avec succès',
            errorPasswordMatch: 'Les mots de passe ne correspondent pas'
        },
        edit: {
            success: 'Utilisateur modifié avec succès'
        }
    },
    prewarranties: {
        module_name: "Pré-activations",
        entity: {
            singular_name: "Pré-activation",
            plural_name: "Pré-activations"
        },
    },
    hierarchies: {
        module_name: "Zone",
        entity: {
            singular_name: "Nouvelle zone",
            plural_name: "Nouvelle zone"
        },
    },
    partnerinventory: {
        module_name: "Inventaire"
    },
    warranties: {
        module_name: "Activations",
        entity: {
            singular_name: "Activation",
            plural_name: "Activations"
        },
        columns: {
            details: 'Détails',
            store: 'Magasin/Utilisateur Ret',
            card: 'Carte de remplacement',
            return: 'Retour',
            storeUserAct: 'Magasin/Utilisateur Act',
            activation: 'Activation',
            serialNumber: 'Numéro de série',
        },
        details: {
            image: "Image",
            product: "Produit",
            date: "Date",
            store: "Magasin",
            user: "Opérateur",
            delete: "Supprimer l'activation",
            activation: "Activation",
            return: "Retour",
            productPage: "Page du produit",
            downloadPdf: "Télécharger le PDF",
        },
        fields: {
            from: 'De',
            to: 'À',
            serialNumber: 'Numéro de série',
            returned: 'Retourné',
            storeType: 'Type de magasin',
            store: 'Magasin'
        }
    },
    stores: {
        module_name: "Magasins",
        entity: {
            singular_name: "Magasin",
            plural_name: "Magasins"
        },
        columns: {
            details: 'Détails',
            store: 'Magasin',
            type: 'Type',
            activation: 'Activation',
            operationCountry: 'Pays/Région d\'opération',
            ISOCode: 'Code ISO',
            soldTo: 'Vendu à',
            status: 'Statut'
        },
        fields: {
            from: 'De',
            to: 'À',
            storeType: 'Type de magasin',
            store: 'Magasin',
            address: 'Adresse',
            users: 'Utilisateurs',
            add: 'Ajouter un magasin',
            edit: 'Modifier le magasin',
            nameCodeStore: 'Nom/Code du magasin',
            activationStatus: 'Statut d\'activation',
            operatingCountry: 'Pays/Région d\'opération',
            sold_to: 'Vendu à',
            ship_to: 'Expédié à',
            errors: {
                store: 'Entrez le nom du magasin'
            }
        },
        details: {
            users: 'Utilisateurs',
            lastAccess: 'Dernière connexion : ',
            active: 'Actif',
            expectedDevices: 'Appareils prévus',
            usbDevice: 'Lecteur USB NFC',
            mobileDevice: 'Smartphone'
        },
        add: {
            success: 'Magasin créé avec succès'
        },
        edit: {
            success: 'Magasin modifié avec succès !'
        }
    },
    masterdata: {
        module_name: "Produits",
        entity: {
            singular_name: "Produit",
            plural_name: "Produits"
        }
    },
    settings: {
        set_system: 'Configurez votre système',
        save_system: "Enregistrer"
    },
    products: {
        columns: {
            details: 'Détails',
            shortDescription: 'Brève description',
            description: 'Description',
            creationDate: 'Date de création',
            photo: 'Photo',
            marketingDescription: 'Description marketing',
            materialType: 'Type de matériau',
            nfcEmbedded: 'NFC activé',
        },
        fields: {
            id: 'Id',
            code: 'Code',
            description: 'Description',
            attributes: 'Attributs',
            materialType: 'Type de matériau',
            NFC: 'NFC activé'
        },
        edit: {
            successEditDescription: 'Produit mis à jour avec succès !'
        }
    },
    geolocation: {
        not_enabled: 'Géolocalisation non activée'
    },
    image: {
        masterdata_image: "Image du produit"
    },
    landing: {
        identified_product: "Produit identifié",
        sku_upc: "SKU/UPC",
        serial: "Numéro de série",
        activation_store: "Magasin d'activation",
        activation_date: "Date d'activation",
        information: "Plus d'informations sur l'activation du produit",
        card: "Atterrissage de la carte"
    },
    profiles: {
        module_name: "Profils",
        entity: {
            singular_name: 'Profil'
        }
    },
    'bu-manual': {
        module_name: "Manuel",
        entity: {
            singular_name: 'Manuel'
        }
    }
}
