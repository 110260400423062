// eslint-disable-next-line import/no-anonymous-default-export
export default {
        app: {
            name: 'Garantia Digital Temera',
            url: 'www.buccellati.com'
        },
        error: {
            generic_error: 'Erro Genérico',
            error: 'Erro',
            forbidden: 'Proibido',
            not_found: 'Não Encontrado',
            unauthorized: 'Não Autorizado',
            invalid_credentials: 'Credenciais Inválidas!',
            duplicateKey: 'Chave Duplicada',
            api: 'Erro API {{method}} {{api}}:\n {{error_message}}',
            requiredRowEditTable: 'A tabela deve ter pelo menos uma linha',
            error_upload_file: 'Falha no envio do arquivo.'
        },
        success: {
            confirm_delete: "Item removido com sucesso",
            confirm_update: "Atualização realizada com sucesso",
            confirm_password: "Senha alterada, agora você pode fazer login no aplicativo"
        },
        warning: {
            ask_delete: 'Tem certeza de que deseja excluir o elemento?',
            unreversible_delete: "A exclusão será irreversível",
            warning: 'Aviso'
        },
        dashboard: {
            module_name: 'Painel'
        },
        common: {
            confirm: 'Confirmar',
            cancel: 'Cancelar',
            save: 'Salvar',
            reset: 'Redefinir',
            search: 'Pesquisar',
            table: {
                total: 'Total de {{total}} resultados',
                export: 'Exportar',
                changeMe: 'Mudar-me',
                key: 'Chave',
                value: 'Valor',
                attributes: 'Atributos',
                addRow: 'Adicionar Linha',
                keyDescription: 'Descrição da Chave',
                valueDescription: 'Descrição do Valor'
            },
            edit: 'Editar',
            details: 'Detalhes',
            addRow: 'Adicionar Linha',
            delete: 'Excluir',
            success: 'Sucesso',
            fieldRequired: 'Campo Obrigatório',
            api: 'API',
            email: 'Email',
            goBack: 'Voltar',
            goAhead: 'Avançar',
            description: 'Descrição',
            import_success: 'Arquivo enviado com sucesso',
            click_upload: 'Clique para enviar um arquivo'
        },
        menu: {
            dashboard: 'Painel',
            users: 'Usuários',
            stores: 'Lojas',
            products: 'Produtos',
            logout: 'Sair',
            digital_warranties: 'Garantias Digitais',
            settings: 'Configurações',
            about: 'Sobre',
            modal: {
                copyright: 'Copyright © 2015 Temera. Todos os direitos reservados'
            },
            controlPanel: 'Painel de Controle'
        },
        login: {
            username: 'Nome de Usuário',
            password: 'Senha',
            confirm_password: 'Confirmar Senha',
            sign_in: 'Entrar',
            mandatory_username: 'Por favor, insira seu nome de usuário!',
            mandatory_password: 'Por favor, insira sua senha!',
            mandatory_email: 'Por favor, insira seu email!',
            mandatory_confirm_password: 'Por favor, confirme sua senha!',
            forgot_password: 'Esqueceu a senha?',
            remember_me: 'Lembrar-me',
            failed: 'Autenticação falhou',
            recover_password_title: 'Preencha o formulário abaixo para redefinir sua senha',
            recover_password_subtitle: 'Você receberá um email com um link para redefinir a senha',
            recover_success: 'Email enviado com sucesso',
            activation_user_title: 'Configure sua senha de acesso',
            activation_user_subtitle: 'Esta operação será necessária apenas na primeira entrada',
            header: 'GARANTIA DIGITAL & AUTENTICIDADE',
            fields: {
                username: 'Nome de Usuário',
                email: 'Email',
                send: 'Enviar'
            },
            placeholder: {
                input_password: 'Insira a senha',
                input_confirm_password: 'Insira a senha novamente'
            }
        },
        loginSSO: {
            label: 'Acesse com sua Conta',
            sso_button: 'Acesso SSO',
            with_credentials: 'Acesse com suas credenciais'
        },
        module: {
            listing: {
                title: 'Gerenciamento de {{moduleName}}'
            },
            add: {
                title: 'Adicionar {{entityName}}'
            },
            edit: {
                title: 'Editar {{entityName}}'
            },
            details: {
                title: 'Detalhes {{entityName}}'
            },
            upload: {
                title: 'Enviar'
            }
        },
        users: {
            module_name: "Usuários",
            entity: {
                singular_name: "Usuário",
                plural_name: "Usuários"
            },
            details: {
                store: 'Loja',
                email: 'Email'
            },
            columns: {
                details: 'Detalhes',
                store: 'Loja',
                modified: 'Modificado',
                created: 'Criado',
                surname: 'Sobrenome',
                name: 'Nome',
                admin: 'Administrador',
                username: 'Nome de Usuário',
                personal: 'Pessoal',
                exception: 'Exceção'
            },
            fields: {
                username: 'Nome de Usuário',
                surname: 'Sobrenome',
                name: 'Nome',
                storeType: 'Tipo de Loja',
                store: 'Loja',
                password: 'Senha',
                confirmPassword: 'Confirmar Senha',
                email: 'Email',
                role: 'Função',
                admin: 'Administrador',
                attributes: 'Atributos',
                add: 'Adicionar Usuário',
                area: 'Área',
                edit: 'Editar Usuário',
                userSoldTo: 'Usuário Vendido Para',
                errors: {
                    username: 'Insira um Nome de Usuário',
                    password: 'Insira uma Senha'
                }
            },
            add: {
                success: 'Usuário adicionado com sucesso',
                errorPasswordMatch: 'As senhas não coincidem'
            },
            edit: {
                success: 'Usuário editado com sucesso'
            }
        },
        prewarranties: {
            module_name: "Pré-Ativações",
            entity: {
                singular_name: "Pré-Ativação",
                plural_name: "Pré-Ativações"
            },
        },
        hierarchies: {
            module_name: "Área",
            entity: {
                singular_name: "Nova Área",
                plural_name: "Nova Área"
            },
        },
        partnerinventory: {
            module_name: "Inventário"
        },
        warranties: {
            module_name: "Ativações",
            entity: {
                singular_name: "Ativação",
                plural_name: "Ativações"
            },
            columns: {
                details: 'Detalhes',
                store: 'Loja/Usuário Ret',
                card: 'Cartão de Substituição',
                return: 'Devolução',
                storeUserAct: 'Loja/Usuário Ativo',
                activation: 'Ativação',
                serialNumber: 'Número de Série',
            },
            details: {
                image: "Imagem",
                product: "Produto",
                date: "Data",
                store: "Loja",
                user: "Operador",
                delete: "Excluir Ativação",
                activation: "Ativação",
                return: "Devolução",
                productPage: "Página do Produto",
                downloadPdf: "Baixar PDF",
            },
            fields: {
                from: 'De',
                to: 'Para',
                serialNumber: 'Número de Série',
                returned: 'Devolvido',
                storeType: 'Tipo de Loja',
                store: 'Loja'
            }
        },
        stores: {
            module_name: "Lojas",
            entity: {
                singular_name: "Loja",
                plural_name: "Lojas"
            },
            columns: {
                details: 'Detalhes',
                store: 'Loja',
                type: 'Tipo',
                activation: 'Ativação',
                operationCountry: 'País/Região de Operação',
                ISOCode: 'Código ISO',
                soldTo: 'Vendido para',
                status: 'Estado'
            },
            fields: {
                from: 'De',
                to: 'Para',
                storeType: 'Tipo de Loja',
                store: 'Loja',
                address: 'Endereço',
                users: 'Usuários',
                add: 'Adicionar Loja',
                edit: 'Editar Loja',
                nameCodeStore: 'Nome/Código da Loja',
                activationStatus: 'Status de Ativação',
                operatingCountry: 'País/Região de Operação',
                sold_to: 'Vendido para',
                ship_to: 'Enviado para',
                errors: {
                    store: 'Insira o nome da loja'
                }
            },
            details: {
                users: 'Usuários',
                lastAccess: 'último acesso: ',
                active: 'Ativo',
                expectedDevices: 'Dispositivos Esperados',
                usbDevice: 'Leitor USB NFC',
                mobileDevice: 'Smartphone'
            },
            add: {
                success: 'Loja criada com sucesso'
            },
            edit: {
                success: 'Loja editada com sucesso!'
            }
        },
        masterdata: {
            module_name: "Produtos",
            entity: {
                singular_name: "Produto",
                plural_name: "Produtos"
            }
        },
        settings: {
            set_system: 'Configure seu sistema',
            save_system: "Salvar"
        },
        products: {
            columns: {
                details: 'Detalhes',
                shortDescription: 'Descrição Curta',
                description: 'Descrição',
                creationDate: 'Data de Criação',
                photo: 'Foto',
                marketingDescription: 'Descrição de Marketing',
                materialType: 'Tipo de Material',
                nfcEmbedded: 'NFC Habilitado',
            },
            fields: {
                id: 'ID',
                code: 'Código',
                description: 'Descrição',
                attributes: 'Atributos',
                materialType: 'Tipo de Material',
                NFC: 'NFC Habilitado'
            },
            edit: {
                successEditDescription: 'Produto atualizado com sucesso!'
            }
        },
        geolocation: {
            not_enabled: 'Geolocalização não ativada'
        },
        image: {
            masterdata_image: "Imagem do Produto"
        },
        landing: {
            identified_product: "Produto Identificado",
            sku_upc: "SKU/UPC",
            serial: "Número de Série",
            activation_store: "Loja de Ativação",
            activation_date: "Data de Ativação",
            information: "Mais informações sobre a ativação do produto",
            card: "Pouso do Cartão"
        },
        profiles: {
            module_name: "Perfis",
            entity: {
                singular_name: 'Perfil'
            }
        },
        'bu-manual': {
            module_name: "Manual",
            entity: {
                singular_name: 'Manual'
            }
        }
    }