import React from "react";
import { addFilter } from "../../services/HooksManager";
import PageViewer from "../../components/PageViewer";
import ParentModule from "../../shared/module/CrudModule";
import { navigate } from "../../shared/router";
import { __b } from "../translations/i18n";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Info, locationStateType } from "../components/Info";
import { MenuNode } from "../../components/MenuComponent";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { CallRequestConfig, call } from "../../shared/api";
import dWUserManager from "../../services/DWUserManager";

class InfoModule extends ParentModule{

    init = () => {
        addFilter ( 'app_menu', this.addMenu );

        addFilter ( 'app_routes', ( routes: any ) => {
            routes[this.getRoute()] = {
                component: PageViewer,
                props: { content: <Info module={this}/>, selectedKey: this.getRoute()},
                visibility: 'both'
            };
            return routes;
        } );

        addFilter(`${this.getModuleKey()}_warranty_info_data`, this.getInfoMsg);
    }

    addMenu = ( menu: MenuNode[] ) => {
        menu.push ( {
            label: <i >{ __b ( 'menu.info' ) }</i>,
            icon: <InfoCircleOutlined />,
            position: '310',
            key: this.getRoute()
        } );

        return menu;
    }

    getModuleKey = (): string => {
        return "bu-info";
    }

    getInfoMsg = async (data: locationStateType) => {
        const baseUrl = DWEnvironmentManager.getStoragePath();
        const method = 'GET';
        const path = 'tmr-api/bulgaridw-web/warrantyInfo';
        const request: CallRequestConfig<any> = {
            params: {...data},
            baseURL: baseUrl
        };
        return await call<any[]>(method, path, request);
    }

}

export const infoModule = new InfoModule();
export default InfoModule;
