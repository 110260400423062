import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Image, Row } from "antd";
import { ArgsProps } from "antd/lib/notification";
import { useEffect, useState } from "react";
import DWEnvironmentManager from "../../../services/DWEnvironmentManager";
import { addFilter, applyFilters } from "../../../services/HooksManager";
import { closeModal, toast, warningModal } from "../../../services/NotificationManager";
import { navigate } from "../../../shared/router";
import { __ } from "../../../translations/i18n";
import DateUtils from "../../../utils/DateUtils";
import DWDataProviderImp from "../../providers/implementation/DWDataProviderImp";
import buUserManager from "../../services/BuUserManager";
import { __b } from "../../translations/i18n";

interface Prop {
    entity: any
}

const PrewarrantyDetails = (props: Prop) => {

    const {entity} = props;

    const moduleManager = DWEnvironmentManager.getModuleManager();

    const [product, setProduct] = useState<any>([]);

    useEffect(() => {
        const productModule = moduleManager.getModule('masterdata');
        productModule.getEntityByField('upc', entity.item.upc).then((res: any) => {

            if (res) {
                setProduct(res)
            }
        });
        funAddDeleteActivation();
    }, [])

    const deletePrewarranties = () => {
        return buUserManager.userCanDeletePreWarranties();
    }

    const deleteItem = () => {

        if( entity.id ) {
            warningModal({
                title: __("warning.ask_delete"),
                content: __("warning.unreversible_delete"),
                onOk: args => {
                    const req = {
                        params: {
                            warrantyId:entity.id
                        }
                    };
                    DWDataProviderImp.deleteWarranties ( req, 'prewarranties/admin/delete' ).then (
                        ( res: any ) => {
                            closeModal();
                            successToast(__("success.confirm_delete"));
                            navigate('/prewarranties');
                        }
                    )
                }
            });
        }
    }

    const funAddDeleteActivation = () => {
        addFilter('prewarranty_details_button_delete_activation', deleteActivation);
    }


    const deleteActivation = (component: any) => {
        return (
                <Col span={24}>
                    <Button onClick={()=>deleteItem()} style={{width: '100%', fontSize: 20, height: '50px'}} icon={<DeleteOutlined />} type={"primary"} block>{__b("prewarranties.details.delete")}</Button>
                </Col>
            )
    }

    const successToast = (message: string) =>{
        const config: ArgsProps = {
            message: `${message}`,
            description:``,
            type: 'success',
            placement: 'bottomRight'
        }
        return toast(config);
    }

    const image = () => {
        return <Image fallback={DWEnvironmentManager.getNotFoundImg()}  src={DWEnvironmentManager.getStoragePath() + 'assets/' + product?.attributes?.image_thumb?.value} />
    }


    const {item, createdBy, creationStore, returnedBy, returnStore} = entity

    return (
        <div className="site-card-wrapper">
            <Row gutter={[24, 24]} justify="start">
                <Col span={11}>
                    <Card style={{textAlign: 'center'}} title={__b("prewarranties.details.image")} bordered={false} >{image()}</Card>
                </Col>
                <Col span={11}>
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            <Card title={__b("prewarranties.details.product")} bordered={false}>
                                <em>{product?.code} - {item?.serialNumber}</em><br/>
                                <strong>{product?.description}</strong>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Divider style={{fontSize: 20}}>{__b("prewarranties.details.activation")}</Divider>
                        </Col>
                        <Col span={8}>
                            <Card title={__b("prewarranties.details.date")} bordered={false}>
                                {DateUtils.formatDate(entity.creationDate)}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={__b("prewarranties.details.store")} bordered={false}>
                                {creationStore?.description}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={__b("prewarranties.details.user")} bordered={false}>
                                {createdBy?.name} {createdBy?.surname}
                            </Card>
                        </Col>
                        {( entity.returnDate &&
                            <>
                                <Col span={24}>
                                    <Divider style={{fontSize: 20}}>{__b("prewarranties.details.return")}</Divider>
                                </Col>
                                <Col span={8}>
                                    <Card title={__b("prewarranties.details.date")} bordered={false}>
                                        {DateUtils.formatDate(entity.returnDate)}
                                    </Card>
                                </Col>
                                    <Col span={8}>
                                    <Card title={__b("prewarranties.details.store")} bordered={false}>
                                {returnStore?.description}
                                    </Card>
                                    </Col>
                                    <Col span={8}>
                                    <Card title={__b("prewarranties.details.user")} bordered={false}>
                                {returnedBy?.name} {returnedBy?.surname}
                                    </Card>
                                    </Col>
                            </>
                        )}
                        {deletePrewarranties() && applyFilters('prewarranty_details_button_delete_activation', <></>)}

                    </Row>
                </Col>
            </Row>
            {applyFilters('prewarranty_details_extra', <></>)}
        </div>

    )

}

export default PrewarrantyDetails;
