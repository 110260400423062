import PageViewer from "../components/PageViewer";
import {addFilter} from "../services/HooksManager";
import Setting from "../pages/SettingExplore";
import ParentModule from "../shared/module/CrudModule";

class SettingExploreModule extends ParentModule {

    init = () => {
        addFilter('app_routes', (routes: any) => {
            routes['/settingsExplore'] = {component: PageViewer, props: {content: <Setting/>}, visibility: 'both'};
            return routes;
        }, -100);
    }

    getModuleKey = (): string => {
        return "settingsExplore";
    }

}

const settingExploreModule = new SettingExploreModule();
export default settingExploreModule;
