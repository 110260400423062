import { CheckOutlined } from "@ant-design/icons";
import { MenuNode } from "../../components/MenuComponent";
import PageViewer from "../../components/PageViewer";
import { addFilter } from "../../services/HooksManager";
import ParentModule from "../../shared/module/CrudModule";
import MassiveActivation from "../components/MassiveActivation";
import { __b } from "../translations/i18n";

class MassiveActivationMoule extends ParentModule{

    init = () => {
        addFilter ( 'app_sub_menu', this.addMenu );

        addFilter ( 'app_routes', ( routes: any ) => {
            routes[this.getRoute()] = {
                component: PageViewer,
                props: { content: <MassiveActivation/>, selectedKey: this.getRoute()},
                visibility: 'private'
            };
            return routes;
        } );
    }

    addMenu = ( menu: MenuNode[] ) => {
        menu.push ( {
            label: <i >{ __b ('menu.massive_activations') }</i>,
            icon: <CheckOutlined />,
            position: '31',
            key: this.getRoute()
        } );

        return menu;
    }

    getModuleKey = (): string => {
        return "massiveActivation";
    }

}

const massiveActivationMoule = new MassiveActivationMoule();
export default massiveActivationMoule;
