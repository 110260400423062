import { SettingOutlined } from "@ant-design/icons";
import PageViewer from "../components/PageViewer";
import {__} from "../translations/i18n";
import {addFilter} from "../services/HooksManager";
import Setting from "../pages/Setting";
import { MenuNode } from "../components/MenuComponent";
import ParentModule from "../shared/module/CrudModule";

class SettingModule extends ParentModule {

    init = () => {
        addFilter('app_menu', this.addMenu);

        addFilter('app_routes', (routes: any) => {
            routes[this.getRoute()] = {component: PageViewer, props: {content: <Setting/>, selectedKey: this.getRoute()}, visibility: 'private'};
            return routes;
        }, -100);
    }

    addMenu = (menu: MenuNode[]) => {

        menu.push({
            label: <i >{__('menu.settings')}</i>,
            icon: <SettingOutlined />,
            position: '300',
            key: this.getRoute()
        });

        return menu;
    }

    getModuleKey = (): string => {
        return "settings";
    }

}

const settingsModule = new SettingModule();
export default settingsModule;
