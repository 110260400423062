import PageViewer from "../../components/PageViewer";
import { addFilter } from "../../services/HooksManager";
import CrudModule from "../../shared/module/CrudModule";
import ReturnComponent from "../components/return/ReturnComponent";

class ReturnModule extends CrudModule {
    
    init(): void {
        addFilter('app_routes', (routes: any) => {
            routes[this.getRoute()] = {component: PageViewer, props: {content: <ReturnComponent module={this.getModuleKey()}/>}, visibility: 'private'};
            return routes;
        }, -100);
    }
    
    getModuleKey = (): string => {
        return "return";        
    }

}
const returnModule = new ReturnModule();
export default returnModule;