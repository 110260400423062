import React from "react";
import { addFilter } from "../../services/HooksManager";
import PageViewer from "../../components/PageViewer";
import ParentModule from "../../shared/module/CrudModule";
import { navigate } from "../../shared/router";
import { __b } from "../translations/i18n";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Help } from "../components/Help";
import { MenuNode } from "../../components/MenuComponent";

class HelpModule extends ParentModule{

    init = () => {
        addFilter ( 'app_menu', this.addMenu );

        addFilter ( 'app_routes', ( routes: any ) => {
            routes[this.getRoute()] = {
                component: PageViewer,
                props: { content: <Help />, selectedKey: this.getRoute()},
                visibility: 'private'
            };
            return routes;
        } );
    }

    addMenu = ( menu: MenuNode[] ) => {
        menu.push ( {
            label: <i >{ __b ( 'menu.help' ) }</i>,
            icon: <QuestionCircleOutlined />,
            position: '110',
            key: this.getRoute()
        } );

        return menu;
    }

    getModuleKey = (): string => {
        return "bu-help";
    }

}

const helpModule = new HelpModule();
export default helpModule;
