import { EditOutlined, GlobalOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FormLayout } from "antd/lib/form/Form";
import moment from "moment";
import { ReactNode } from "react";
import CrudDetails from "../../components/CrudDetails";
import CrudModule from "../../components/CrudModule";
import { FormRow } from "../../components/form/DynamicForm";
import { MenuNode } from "../../components/MenuComponent";
import PageViewer from "../../components/PageViewer";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { addFilter } from "../../services/HooksManager";
import { successToast } from "../../services/NotificationManager";
import ParentModule from "../../shared/module/CrudModule";
import { navigate } from "../../shared/router";
import { __ } from "../../translations/i18n";
import AreaAdd, { fetchOneParent } from "../components/area/AreaAdd";
import AreaEdit from "../components/area/AreaEdit";
import { __b } from "../translations/i18n";




class AreaModule extends ParentModule {

    init = () => {
        super.init();
        addFilter ( 'app_sub_menu', this.addMenu );

        addFilter('app_routes', (routes: any) => {
            routes[this.getRoute()] = {
                component: PageViewer,
                props: { content: <CrudModule module={this} />, selectedKey: this.getRoute() },
                visibility: 'private'
            };
            routes[this.getRouteDetail()] = {
                component: PageViewer,
                props: { content: <CrudDetails module={this} tabs= {['edit']} /> },
                visibility: 'private'
            };
            return routes;
        });

        addFilter('search_form_data_' + this.getModuleKey(), this.getSearchData)

        addFilter('crud_detail_edit_component_' + this.getModuleKey(), this.getEditComponent);

        addFilter ( 'crud_create_component_' + this.getModuleKey (), this.getAddComponent);

    }

    addMenu = (menu: MenuNode[]) => {
        menu.push({
            label: <i >{__b('menu.areas')}</i>,
            icon: <GlobalOutlined  />,
            position: '33',
            key: this.getRoute()
        });

        return menu;
    }

    getModuleKey = (): string => {
        return "hierarchies";
    }

    __getFormFields = (): FormRow[] => {

        return [
            {
                fields: [

                    {
                        name: 'description',
                        label: __b('warranties.fields.operatingCountry'),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: (key: string) => (this.fetchHierarchies(key)),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'parent',
                        label: __b('warranties.fields.parent'),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: (key: string) => (this.fetchParent(key)),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'isoCode',
                        label: __b('warranties.fields.isoCode'),
                        type: 'text',
                        required: false,
                        mode: 'multiple',
                        colConf: {
                            span: 8
                        }
                    }
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    {
                        name: __('common.reset'),
                        type: 'button',
                        htmlType: 'reset',
                        buttonType: 'default',
                        block: 'true',
                        icon: <ReloadOutlined />,
                        colConf: { span: 2, offset: 10 }
                    },
                    { name: __('common.search'), type: 'button', htmlType: 'submit', block: 'true', icon: <SearchOutlined />, colConf: { span: 2 } }
                ]
            }
        ];

    }

    getSearchData = (data: { [key: string]: any }) => {

        let dataClone: { [key: string]: any } = {};
        Object.keys(data).map((key) => {
            if (moment.isMoment(data[key])) {
                return dataClone[key] = data[key].format('YYYY/MM/DD');
            } else {
                return dataClone[key] = data[key];
            }
        });

        return dataClone;
    }

    __getColumnsTable = () => {
        return [
            {
                title: __b('warranties.columns.operatingCountry'),
                dataIndex: 'operatingCountry',
                sorter: true,
                key: 'operatingCountry'
            },
            {
                title: __b('warranties.columns.parent'),
                dataIndex: 'parent',
                key: 'parent'
            },
            {
                title: __b('warranties.columns.isoCode'),
                dataIndex: 'isoCode',
                key: 'isoCode'
            },
            {
                title: __b('warranties.columns.edit'),
                dataIndex: 'id',
                key: 'id',
                render: (id: string | number) => (
                    <Button onClick={() => navigate(this.getRouteDetail(id))} icon={<EditOutlined/>} size={"small"}
                        shape={"circle"} type="primary" />
                )
            }
        ];
    }

    __getLayout = (): FormLayout => {
        return "vertical";
    }


    fetchHierarchies = async (key: string) => {
        return await fetchHierarchies(key);
    }

    fetchParent = async (key: string) => {
        return await fetchParent(key);
    }

    getEditComponent = (component: ReactNode, entity: any) => {
        return (<AreaEdit entity={entity} edit={(data: any) => this.edit(data, entity)} module={this.getModuleKey()} />);
    }

    getAddComponent = (component: ReactNode, entity: any) => {
        return (<AreaAdd add={this.add} module={this.getModuleKey()} />);
    }

    __getMapData = (row: any) => {
        return {
            operatingCountry: row.description,
            parent: row.parent?.code,
            isoCode: row.isoCode,
            id: row.id
        }
    }

    add = async (data: any) => {

        const provider = DWEnvironmentManager.getDataProvider();
        // ------ parametri fissi non settabili
        data.code = data.description
        data.parent = await fetchOneParent(data.parent)
        // -----
        

        const req = {
            data: Object.assign({}, data),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json, text/plain, */*'

            },
        };

        const res = await provider.addOne(req, 'hierarchies')

        if (res) {
            successToast(__('common.success'),'');
            navigate('/areas');
        }
    }

    edit = async (data: any , entity: any) => {

        const provider = DWEnvironmentManager.getDataProvider();
        data.id = entity.id;
        data.code = data.description;
        if (data.parent.value) {
            data.parent = await provider.getOne(data.parent.value, {}, 'hierarchies')
        } else {
            data.parent = await provider.getOne(data.parent, {}, 'hierarchies')
        }

        const req = {
            data: Object.assign({}, data),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json, text/plain, */*'
            },
        };
        const res = await provider.editOne(req, `hierarchies/${entity.id}`);

        if (res) {
            successToast(__('common.success'),'');
            navigate('/areas');
        }
    }

}

export const fetchHierarchies = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, 'hierarchies')
        .then((response) =>
            response.map((res: any) => ({
                label: res.description,
                value: res.description,
            })),
        );
}

export const fetchParent = async (key: string) => {

    const provider = DWEnvironmentManager.getDataProvider();

    return provider.getList(20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, 'hierarchies')
        .then((response) => 
            response.map((res: any) => ({
                label: res.description,
                value: res.code,
            }))  
        );
}

const areaModule = new AreaModule();
export default areaModule;
