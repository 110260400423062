import {UploadProps} from "antd";
import DWEnvironmentManager from "../services/DWEnvironmentManager";
import {applyFilters} from "../services/HooksManager";
import config from "../config/config";

/**
 * Class util per stringhe
 */
class StingUtils {

    /**
     * Elimina slash finale se presente da stringa in input
     * @param str
     */
    static removeRightSlash(str: string) {
        return str.replace(/\/+$/, '');
    }

    /**
     * Elimina slash iniziae se presente da stringa in input
     * @param str
     */
    static removeLeftSlash(str: string) {
        return str.replace(/^\/|\/$/g, '');
    }

}


export default StingUtils;