import { SaveOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row } from 'antd';
import { ArgsProps } from "antd/lib/notification";
import React from 'react';
import DynamicForm, { FormRow } from '../components/form/DynamicForm';
import dwEnvironmentManager from "../services/DWEnvironmentManager";
import { applyFilters } from "../services/HooksManager";
import { toast } from "../services/NotificationManager";
import { __ } from "../translations/i18n";
import ObjectUtils from "../utils/ObjectUtil";

const Setting: React.FC = () => {

    const dwUserManager= dwEnvironmentManager.getUserManager()
    const dWUserDataProvider = dwEnvironmentManager.getUserDataProvider();
    const userData = dwUserManager.getSessionUser()

    const sendUpdate = ( dataForm: any ) => {

        // get data from form
        dataForm = ObjectUtils.filterIfValueIsEmpty ( dataForm );

        let updatedUser = Object.assign({}, userData);

        // set the new email
        updatedUser!.attributes['email'] = dataForm.email;

        /**
         * Ritorna l'utente a cui saranno aggiornati i settings
         * @hook before_user_settings_update
         * @param updatedUser: User
         * @param oldUser: User
         * @param dataForm: {}
         *
         */
        updatedUser = applyFilters ( 'before_user_settings_update', updatedUser, userData, dataForm);

        if( updatedUser?.id ) {

            dWUserDataProvider.update (
                updatedUser?.id,
                updatedUser
            ).then(
                successToast(__("success.confirm_update"))
            )
        }
    }

    const successToast = (message: string): any => {
        const config: ArgsProps = {
            message: `${message}`,
            description:``,
            type: 'success',
            placement: 'bottomRight'
        }
        return toast(config);
    }

    const getFields = () : FormRow[]  => {

        let EditfieldList: FormRow[] = [
            {
                fields: [
                    {
                        label: __('common.email'),
                        name: 'email',
                        type: 'text',
                        required: false,
                        requiredMessage: "",
                        value: userData?.attributes?.email,
                        initialValue: userData?.attributes?.email,
                        colConf: {
                            span: 24
                        }
                    }
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    { name: __('settings.save_system'), label: __('settings.save_system'), type: 'button', htmlType: 'submit', icon: <SaveOutlined />}
                ],
                rowConf: {
                    gutter: [24,24],
                    justify: "center",
                    align: "top"
                }
            }
        ];

        /**
         * Ritorna la lista di campi per i settings
         * @hook setting_fields
         * @param EditfieldList: EditRow[] Lista dei campi
         *
         */
        return applyFilters ( 'setting_fields', EditfieldList );
    }

    const EditfieldList: FormRow[] = getFields();

    return (
            <Row gutter={[24, 24]} justify={"center"}>
                <Col span={10}>
                    <Card style={{ marginTop: 150 }} bordered={false}>
                        <Divider style={{fontSize: 20}}>{ __ ( 'settings.set_system' ) }</Divider>
                        <DynamicForm formFields={EditfieldList} onFilters={sendUpdate} module={"settings"} typeForm={"form"} formConf={{layout: "vertical"}}/>
                    </Card>
                </Col>
            </Row>
    );
}

export default Setting;
