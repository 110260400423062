import { Component } from "react";
import { fetchUsers } from "../../modules/StoreModule";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import {__} from "../../translations/i18n";
import { FormRow } from "../form/DynamicForm";
import CrudForm from "../CrudForm";
import { SaveOutlined } from "@ant-design/icons";

interface Props {
    module: string,
    add: (data: any) => any
}

interface State {
    dataTable?: any
}

export default class StoreAdd extends Component<Props, State> {

    provider = DWEnvironmentManager.getDataProvider ();

    getAddFormFields = (): FormRow[] => {

        return [
            {
                fields: [
                    {
                        name: 'description',
                        label: __ ( 'stores.fields.store' ),
                        type: 'text',
                        required: true,
                        requiredMessage: __ ( 'stores.fields.errors.store' ),
                        colConf: { span: 24 }
                    },
                    {
                        name: 'address',
                        label: __ ( 'stores.fields.address' ),
                        type: 'text',
                        required: false,
                        colConf: { span: 24 }
                    },
                    {
                        name: 'users',
                        label: __ ( 'stores.fields.users' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: ( key: string ) => ( fetchUsers ( key ) ),
                        colConf: { span: 24 }
                    },
                    {
                        name: 'externalCode',
                        label: __ ( 'stores.fields.storeType' ),
                        type: 'select',
                        required: false,
                        selectValue: [
                            { label: "Buotique", value: 'boutique' },
                            { label: "Corner", value: 'corner' },
                            { label: "Retailer", value: 'retailer' },
                            { label: "Shop in shop", value: 'shop in shop' },
                        ],
                        colConf: { span: 24 }
                    }
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    { name: 'addStore', label:__('stores.fields.add'), type: 'button', htmlType: 'submit', icon: <SaveOutlined />}
                ],
                rowConf: {
                    gutter: [24,24],
                    justify: "center",
                    align: "top"
                }
            }
        ];
    }

    render(){
        const {module} = this.props
        return (<CrudForm module={module} callback={this.props.add} getFormFields={this.getAddFormFields} cardSize={12} type={"add"} />)
    } 
}