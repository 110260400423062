// eslint-disable-next-line import/no-anonymous-default-export
export default {
    app: {
        name: 'Temera數字保修',
        url: 'www.buccellati.com'
    },
    error: {
        generic_error: '一般錯誤',
        error: '錯誤',
        forbidden: '禁止',
        not_found: '未找到',
        unauthorized: '未經授權',
        invalid_credentials: '憑證無效！',
        duplicateKey: '重複鍵',
        api: 'API錯誤 {{method}} {{api}}：\n {{error_message}}',
        requiredRowEditTable: '表格必須至少包含一行',
        error_upload_file: '文件上傳失敗'
    },
    success: {
        confirm_delete: '成功刪除項目',
        confirm_update: '成功更新',
        confirm_password: '密碼已更改，現在您可以從應用程序登錄'
    },
    warning: {
        ask_delete: '確定要刪除該項目嗎？',
        unreversible_delete: '刪除將無法恢復',
        warning: '警告'
    },
    dashboard: {
        module_name: '儀表板'
    },
    common: {
        confirm: '確認',
        cancel: '取消',
        save: '保存',
        reset: '重置',
        search: '搜索',
        table: {
            total: '共 {{total}} 個結果',
            export: '導出',
            changeMe: '更改我',
            key: '鍵',
            value: '值',
            attributes: '屬性',
            addRow: '添加行',
            keyDescription: '鍵描述',
            valueDescription: '值描述'
        },
        edit: '編輯',
        details: '詳情',
        addRow: '添加行',
        delete: '刪除',
        success: '成功',
        fieldRequired: '必填字段',
        api: 'API',
        email: '電子郵件',
        goBack: '返回',
        goAhead: '前進',
        description: '描述',
        import_success: '文件上傳成功',
        click_upload: '點擊上傳文件'
    },
    menu: {
        dashboard: '儀表板',
        users: '用戶',
        stores: '商店',
        products: '產品',
        logout: '登出',
        digital_warranties: '數字保修',
        settings: '設置',
        about: '關於',
        modal: {
            copyright: '版權 © 2015 Temera。保留所有權利'
        },
        controlPanel: '控制面板'
    },
    login: {
        username: '用戶名',
        password: '密碼',
        confirm_password: '確認密碼',
        sign_in: '登錄',
        mandatory_username: '請輸入您的用戶名！',
        mandatory_password: '請輸入您的密碼！',
        mandatory_email: '請輸入您的電子郵件！',
        mandatory_confirm_password: '請確認您的密碼！',
        forgot_password: '忘記密碼？',
        remember_me: '記住我',
        failed: '驗證失敗',
        recover_password_title: '填寫下面的表格以重置您的密碼',
        recover_password_subtitle: '您將收到包含重置密碼鏈接的電子郵件',
        recover_success: '郵件發送成功',
        activation_user_title: '設置您的訪問密碼',
        activation_user_subtitle: '此操作僅在第一次登錄時需要',
        header: '數字保修與真實性',
        fields: {
            username: '用戶名',
            email: '電子郵件',
            send: '發送'
        },
        placeholder: {
            input_password: '輸入密碼',
            input_confirm_password: '再次輸入密碼'
        }
    },
    loginSSO: {
        label: '使用您的帳戶登錄',
        sso_button: 'SSO登錄',
        with_credentials: '使用憑證登錄'
    },
    module: {
        listing: {
            title: '管理{{moduleName}}'
        },
        add: {
            title: '添加{{entityName}}'
        },
        edit: {
            title: '編輯{{entityName}}'
        },
        details: {
            title: '關於{{entityName}}的詳細信息'
        },
        upload: {
            title: '上傳'
        }
    },
    users: {
        module_name: '用戶',
        entity: {
            singular_name: '用戶',
            plural_name: '用戶'
        },
        details: {
            store: '商店',
            email: '電子郵件'
        },
        columns: {
            details: '詳情',
            store: '商店',
            modified: '修改',
            created: '創建',
            surname: '姓氏',
            name: '名字',
            admin: '管理員',
            username: '用戶名',
            personal: '個人',
            exception: '異常'
        },
        fields: {
            username: '用戶名',
            surname: '姓氏',
            name: '名字',
            storeType: '商店類型',
            store: '商店',
            password: '密碼',
            confirmPassword: '確認密碼',
            email: '電子郵件',
            role: '角色',
            admin: '管理員',
            attributes: '屬性',
            add: '添加用戶',
            area: '區域',
            edit: '編輯用戶',
            userSoldTo: '用戶售給',
            errors: {
                username: '請輸入用戶名',
                password: '請輸入密碼'
            }
        },
        add: {
            success: '成功添加用戶',
            errorPasswordMatch: '密碼不匹配'
        },
        edit: {
            success: '成功編輯用戶'
        }
    },
    prewarranties: {
        module_name: '預激活',
        entity: {
            singular_name: '預激活',
            plural_name: '預激活'
        }
    },
    hierarchies: {
        module_name: '區域',
        entity: {
            singular_name: '新區域',
            plural_name: '新區域'
        }
    },
    partnerinventory: {
        module_name: '庫存'
    },
    warranties: {
        module_name: '激活',
        entity: {
            singular_name: '激活',
            plural_name: '激活'
        },
        columns: {
            details: '詳情',
            store: '商店/用戶Ret',
            card: '替換卡',
            return: '退貨',
            storeUserAct: '商店/用戶Act',
            activation: '激活',
            serialNumber: '序列號',
        },
        details: {
            image: '圖片',
            product: '產品',
            date: '日期',
            store: '商店',
            user: '操作者',
            delete: '刪除激活',
            activation: '激活',
            return: '退貨',
            productPage: '產品頁面',
            downloadPdf: '下載PDF'
        },
        fields: {
            from: '從',
            to: '至',
            serialNumber: '序列號',
            returned: '退貨',
            storeType: '商店類型',
            store: '商店'
        }
    },
    stores: {
        module_name: '商店',
        entity: {
            singular_name: '商店',
            plural_name: '商店'
        },
        columns: {
            details: '詳情',
            store: '商店',
            type: '類型',
            activation: '激活',
            operationCountry: '操作國家/地區',
            ISOCode: 'ISO代碼',
            soldTo: '售給',
            status: '狀態'
        },
        fields: {
            from: '從',
            to: '至',
            storeType: '商店類型',
            store: '商店',
            address: '地址',
            users: '用戶',
            add: '添加商店',
            edit: '編輯商店',
            nameCodeStore: '名稱/代碼商店',
            activationStatus: '激活狀態',
            operatingCountry: '操作國家/地區',
            sold_to: '售給',
            ship_to: '發貨到',
            errors: {
                store: '請輸入商店名稱'
            }
        },
        details: {
            users: '用戶',
            lastAccess: '最後訪問：',
            active: '活躍',
            expectedDevices: '預期設備',
            usbDevice: 'USB NFC讀卡器',
            mobileDevice: '智能手機'
        },
        add: {
            success: '商店創建成功'
        },
        edit: {
            success: '商店編輯成功！'
        }
    },
    masterdata: {
        module_name: '產品',
        entity: {
            singular_name: '產品',
            plural_name: '產品'
        }
    },
    settings: {
        set_system: '配置您的系統',
        save_system: '保存'
    },
    products: {
        columns: {
            details: '詳情',
            shortDescription: '簡短描述',
            description: '描述',
            creationDate: '創建日期',
            photo: '照片',
            marketingDescription: '行銷描述',
            materialType: '材料類型',
            nfcEmbedded: '內置NFC',
        },
        fields: {
            id: 'ID',
            code: '代碼',
            description: '描述',
            attributes: '屬性',
            materialType: '材料類型',
            NFC: 'NFC已啟用'
        },
        edit: {
            successEditDescription: '產品更新成功！'
        }
    },
    geolocation: {
        not_enabled: '地理位置未啟用'
    },
    image: {
        masterdata_image: '產品圖片'
    },
    landing: {
        identified_product: '已識別產品',
        sku_upc: 'SKU/UPC',
        serial: '序列號',
        activation_store: '激活商店',
        activation_date: '激活日期',
        information: '產品激活的詳細信息',
        card: '降落卡'
    },
    profiles: {
        module_name: '配置文件',
        entity: {
            singular_name: '配置文件'
        }
    },
    'bu-manual': {
        module_name: '手冊',
        entity: {
            singular_name: '手冊'
        }
    }
}
