import {HomeOutlined} from "@ant-design/icons";
import PageViewer from "../../components/PageViewer";
import {addFilter} from "../../services/HooksManager";
import CustomDashboard from "../pages/CustomDashboard";
import { __ } from "../../translations/i18n";
import CrudModule from "../../shared/module/CrudModule";
import { MenuNode } from "../../components/MenuComponent";

class CustomDashboardModule extends CrudModule {

    init = () => {
        super.init();
        addFilter('app_menu', this.addMenu);

        addFilter('app_routes', (routes: any) => {
            routes[this.getRoute()] = {component: PageViewer, props: {content: <CustomDashboard/>, selectedKey: this.getRoute()}, visibility: 'private'};
            return routes;
        }, -100);
    }

    addMenu = (menu: MenuNode[]) => {

        menu.push({
            label: <i >{__('menu.dashboard')}</i>,
            icon: <HomeOutlined />,
            position: '100',
            key: this.getRoute()
        });

        return menu;
    }

    getModuleKey = (): string => {
        return "dashboard";
    }

}

const customDashbardModule = new CustomDashboardModule();
export default customDashbardModule;
