import {Button, Card, Col, Form, Input, Row} from "antd";
import React, {Component} from "react";
import dWUserManager from "../../services/DWUserManager";
import {__} from "../../translations/i18n";
import dwEnvironmentManager from "../../services/DWEnvironmentManager";
import type {FormInstance} from "antd/es/form";
import {errorToast, infoModal, successToast} from "../../services/NotificationManager";
import { InfoCircleTwoTone, LockOutlined, LoginOutlined, SettingTwoTone } from "@ant-design/icons";
import {getQueryParams, navigate} from "../../shared/router";
import {applyActions, applyFilters} from "../../services/HooksManager";
import {Header} from "antd/lib/layout/layout";
import { log } from "console";

interface IProps { }

interface IState {
    username: string;
    password: string;
    confirmPassword: string;
    remindMe: boolean;
    credentialError: boolean;
    openActivationModal: boolean;
}

class ResetPassword extends Component<IProps, IState> {
    formRef = React.createRef<FormInstance>();
    activationToken: string = '';
    usernameQueryParams = null;

    activationTokenParamName = 'activation_token';
    usernameParamName = 'username';

    __activationTokenParamNameFilter() {
        return applyFilters('activation_token_param_name_filter', this.activationTokenParamName);
    }

    __usernameParamNameFilter() {
        return applyFilters('username_param_name_filter', this.usernameParamName);
    }
    recoverPassword: boolean = true

    constructor(props: IProps) {
        super(props);
        this.state = {
            username: "",
            password: "",
            confirmPassword: "",
            remindMe: false,
            credentialError: false,
            openActivationModal: false
        };

        const queryParams = getQueryParams();
        this.activationToken = queryParams[this.__activationTokenParamNameFilter()];
        this.usernameQueryParams = queryParams[this.__usernameParamNameFilter()];
    }

    async componentDidMount() {
        if (this.activationToken) {
            this.setState({openActivationModal: true});
        }
    }


    onChangePassword(value: string) {
        this.setState({ password: value });
    }

    onChangeConfirmPassword(value: string) {
        this.setState({ confirmPassword: value });
    }

    __getRecoverPassword() {
        return applyFilters('login_recover_password_visible', this.recoverPassword);
    }

    onReset = () => {
        this.formRef.current!.resetFields();
    };

    getLogo = () => {
        return dwEnvironmentManager.getMainLogo();
    };


    showModal() {
        infoModal({
            title: dwEnvironmentManager.getApplicationName(),
            content: (<div>{dwEnvironmentManager.getVersion()}<p> {__('menu.modal.copyright')} </p></div>)
        })
    };

    async activateUser() {
        const data = {
            password : this.state.password,
            confirmPassword : this.state.confirmPassword
        }
        if (data.password !== data.confirmPassword) {
            errorToast(__('error.error'), __('users.add.errorPasswordMatch'));
        } else {
            const provider = dwEnvironmentManager.getDataProvider();
            let urlParams = new URLSearchParams(window.location.href);
            let resetToken = ''
            let activationToken = ''
            let username = ''
            urlParams.forEach((v, k) => { // not use the classic get because url params have special charts
                if(k.includes('resetToken')){
                  resetToken = v
                }
                if(k.includes('activationToken')){
                    activationToken = v
                }
                if(k.includes('username')){
                  username = v
                }
            })
            if (resetToken) {
                const res = await provider.addOne({
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'x-tmr-token': resetToken,
                    },
                    data: `username=${encodeURIComponent(username)}&token=${encodeURIComponent(resetToken)}&password=${encodeURIComponent(data.password)}`
                }, 'users/changepassword');

                if (res) {
                    successToast(__('common.success'), __('success.confirm_password'));
                    this.onReset();
                }
            }else{
                const res = await provider.addOne({
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'x-tmr-token': activationToken,
                    },
                    data: `username=${encodeURIComponent(username)}&token=${encodeURIComponent(activationToken)}&password=${encodeURIComponent(data.password)}`
                }, 'users/activate');

                if (res) {
                    successToast(__('common.success'), __('success.confirm_password'));
                    this.onReset();
                }
            }
        }

    }

    getHeaderLogo = () => {
        return dwEnvironmentManager.getHeaderLogo();
    }

    render() {
        const { openActivationModal } = this.state;

        return (
            <div className="App">
                <Header className='login'>
                <img className="headerLogo" src={this.getHeaderLogo()} alt="logo" />
                <h4>{__("login.header")}</h4>
                 <SettingTwoTone twoToneColor="#AA8A5F" onClick={() => navigate('/settingsExplore')} />
                 <InfoCircleTwoTone twoToneColor="#AA8A5F" onClick={this.showModal}/>
                </Header>
                <header className="App-header">
                    <img src={this.getLogo()} className="App-logo" alt="logo" />
                </header>
                <Row gutter={[24, 24]} justify={"center"} style={{ marginTop: 20 }}>
                    <Col xs={24} lg={12}>
                        <Card>
                            <Form
                                ref={this.formRef}
                                name="basic"
                                autoComplete="off"
                                layout="vertical"
                            >
                               <Col span={24}>
                                    <Form.Item
                                        label={__("login.password")}
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: __("login.mandatory_password"),
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            onChange={(event) =>
                                                this.onChangePassword(event.target.value)
                                            }
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label={__("login.confirm_password")}
                                        name="confirmPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: __("login.mandatory_confirm_password"),
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            onChange={(event) =>
                                                this.onChangeConfirmPassword(event.target.value)
                                            }
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                        />
                                    </Form.Item>
                                </Col>

                                <Row gutter={[24, 24]} justify="center">
                                    <Col span={12}>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={openActivationModal}
                                                onClick={() => this.activateUser()}
                                                icon={<LoginOutlined />}
                                                block
                                            >
                                                {__("common.save")}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ResetPassword;
