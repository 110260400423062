import { EllipsisOutlined } from "@ant-design/icons";
import { __ } from "../translations/i18n";
import { addFilter } from "../services/HooksManager";
import { IModule } from "../services/ModulesManager";
import { infoModal } from "../services/NotificationManager";
import DWEnvironmentManager from "../services/DWEnvironmentManager";
import { MenuNode } from "../components/MenuComponent";

class ModalModule implements IModule {

    init = () => {
        addFilter('app_menu', this.addMenu);
    }

    showModal() {
        infoModal({
            title: DWEnvironmentManager.getApplicationName(),
            content: (<div>{DWEnvironmentManager.getVersion()}<p> {__('menu.modal.copyright')} </p></div>)
        })
    };

    addMenu = (menu: MenuNode[]) => {

        menu.push({
            label: <i onClick={this.showModal}>{__('menu.about')} </i>,
            icon: <EllipsisOutlined onClick={this.showModal} />,
            position: '400',
            key: this.getModuleKey(),
            isRoute: false
        });

        return menu;
    }

    getModuleKey = (): string => {
        return "modal";
    }

}

const module = new ModalModule();
export default module;

