import dWUserManager from "../../services/DWUserManager";
import { applyFilters } from "../../services/HooksManager";

class UserCapability {

  capability = {};
  // loggedUser;
  // constructor() {
  //   this.loggedUser = dWUserManager.getSessionUser();
  // }



  getUserCapability = () => {

    this.capability = {
      whitelistPage: ["welcome", "dashboard", "lines", "settings_instore", "update", "search", "landing", "login", "bu-manual"],
      sales_person: {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties"]
      },
      sales_assistant: {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties"]
      },
      deputy_store_manager: {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties"]
      },
      bulgari_store_after_sales: {
        warrantyExtension: true,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties"]
      },
      customer_service_user: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "stores", "users"]
      },
      operations: {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties", "stores", "users", "masterdata"]
      },
      area_sales_executive: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties"]
      },
      dos_manager: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties"]
      },
      after_sales_network_support: {
        warrantyExtension: true,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties"]
      },
      local_it: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties"]
      },
      wholesales_manager: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties"]
      },
      wholesales_admin_activations: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties"]
      },
      wholesales_administrator: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties"]
      },
      japan_e_commerce_logistic: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["settings", "bu-help", "controlPanel", "warranties", "stores", "users", "masterdata"]
      },
      retailer_manager: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties"]
      },
      travel_retail_manager: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties"]
      },
      after_sales_worldwide: {
        warrantyExtension: true,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties"]
      },
      hq_manager: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["settings", "bu-help", "controlPanel", "warranties", "stores", "users", "masterdata"]
      },
      worldwide_sales_manager: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "controlPanel", "settings", "warranties"]
      },
      watch_business_unit_worldwide_manager: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "controlPanel", "settings", "warranties"]
      },
      accessories_business_unit_worldwide_manager: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties"]
      },
      country_manager: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties"]
      },
      ownership_manager: {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["settings", "bu-help", "controlPanel", "warranties", "stores", "users", "masterdata"]
      },
      store_manager: {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties"]
      },
      retailer_agent: {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties", "stores", "users", "masterdata"]
      },
      trainer: {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties"]
      },
      pre_activation_manager: {
        warrantyExtension: false,
        accessSalesPerson: false,
        isPreWarrantyRole: true,
        sectionsVisible: ["bu-help", "bu-info", "settings", "controlPanel", "prewarranties", "warranties"]
      },
      pre_activation_operative: {
        warrantyExtension: false,
        accessSalesPerson: true,
        isPreWarrantyRole: true,
        sectionsVisible: ["status", "bu-help", "bu-info", "settings", "controlPanel", "prewarranties"]
      },
      test_qty_inventories: {
        warrantyExtension: false,
        sectionsVisible: ["bu-help", "bu-info", "settings", "controlPanel","warranties", "prewarranties", "partnerinventory"]
      },
      business_partner_inventory: {
        warrantyExtension: false,
        sectionsVisible: ["bu-help", "bu-info", "settings", "controlPanel", "partnerinventory"]
      },
      'sales_person+bp': {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'sales_assistant+bp': {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'deputy_store_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'bulgari_store_after_sales+bp': {
        warrantyExtension: true,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'customer_service_user+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "stores", "users", "partnerinventory"]
      },
      'operations+bp': {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties", "stores", "users", "masterdata", "partnerinventory"]
      },
      'area_sales_executive+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "settings", "controlPanel", "warranties", "partnerinventory", "bu-help"]
      },
      'dos_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'after_sales_network_support+bp': {
        warrantyExtension: true,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'local_it+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'wholesales_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'wholesales_admin_activations+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'wholesales_administrator+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'japan_e_commerce_logistic+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["settings", "bu-help", "controlPanel", "warranties", "stores", "users", "masterdata", "partnerinventory"]
      },
      'retailer_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'travel_retail_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'after_sales_worldwide+bp': {
        warrantyExtension: true,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'hq_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["settings", "bu-help", "controlPanel", "warranties", "stores", "users", "masterdata", "partnerinventory"]
      },
      'worldwide_sales_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "controlPanel", "settings", "warranties", "partnerinventory"]
      },
      'watch_business_unit_worldwide_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "controlPanel", "settings", "warranties", "partnerinventory"]
      },
      'accessories_business_unit_worldwide_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'country_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["bu-info", "bu-help", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'ownership_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        sectionsVisible: ["settings", "bu-help", "controlPanel", "warranties", "stores", "users", "masterdata", "partnerinventory"]
      },
      'store_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'retailer_agent+bp': {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties", "stores", "users", "masterdata", "partnerinventory"]
      },
      'trainer+bp': {
        warrantyExtension: false,
        accessSalesPerson: true,
        sectionsVisible: ["return", "status", "bu-help", "bu-info", "settings", "controlPanel", "warranties", "partnerinventory"]
      },
      'pre_activation_manager+bp': {
        warrantyExtension: false,
        accessSalesPerson: false,
        isPreWarrantyRole: true,
        sectionsVisible: ["bu-help", "bu-info", "settings", "controlPanel", "prewarranties", "warranties", "partnerinventory"]
      },
      'pre_activation_operative+bp': {
        warrantyExtension: false,
        accessSalesPerson: true,
        isPreWarrantyRole: true,
        sectionsVisible: ["status", "bu-help", "bu-info", "settings", "controlPanel", "prewarranties", "partnerinventory"]
      }
    }

    return applyFilters('bu_user_capability_config', this.capability);
  }

  userCanAddUsers() {
    const loggedUser = dWUserManager.getSessionUser();
    return (loggedUser?.admin || loggedUser?.role.code === 'operations' || loggedUser?.role.code === 'operations+bp') ? true : false;
  };

  userCanAddStores() {
    const loggedUser = dWUserManager.getSessionUser();
    return (loggedUser?.admin || loggedUser?.role.code === 'operations' || loggedUser?.role.code === 'operations+bp') ? true : false;
  };

  userCanEditStores() {
    const loggedUser = dWUserManager.getSessionUser();
    return (loggedUser?.admin || loggedUser?.role.code === 'customer_service_user' || loggedUser?.role.code === 'operations' || loggedUser?.role.code === 'customer_service_user+bp' || loggedUser?.role.code === 'operations+bp') ? true : false;
  };

  userCanEditUsers() {
    const loggedUser = dWUserManager.getSessionUser();
    return (loggedUser?.admin || loggedUser?.role.code === 'customer_service_user' || loggedUser?.role.code === 'operations' || loggedUser?.role.code === 'customer_service_user+bp' || loggedUser?.role.code === 'operations+bp') ? true : false;
  };

  userCanAddWarranties() {
    const loggedUser = dWUserManager.getSessionUser();
    return (loggedUser?.admin || loggedUser?.role.code === 'wholesales_admin_activations' || loggedUser?.role.code === 'customer_service_user' || loggedUser?.role.code === 'operations' || loggedUser?.role.code === 'after_sales_network_support' || loggedUser?.role.code === 'local_it'
        || loggedUser?.role.code === 'wholesales_admin_activations+bp' || loggedUser?.role.code === 'customer_service_user+bp' || loggedUser?.role.code === 'operations+bp' || loggedUser?.role.code === 'after_sales_network_support+bp' || loggedUser?.role.code === 'local_it+bp'
    ) ? true : false;
  };

  userCanAddPreWarranties() {
    const loggedUser = dWUserManager.getSessionUser();
    return (loggedUser?.admin || loggedUser?.role.code === 'pre_activation_manager' || loggedUser?.role.code === 'pre_activation_manager+bp') ? true : false;
  };

  userCanDeletePreWarranties() {
    const loggedUser = dWUserManager.getSessionUser();
    return (loggedUser?.admin || loggedUser?.role.code === 'pre_activation_manager' || loggedUser?.role.code === 'pre_activation_manager+bp') ? true : false;
  };

}
const userCapability = new UserCapability();
export default userCapability;
