import { DeleteOutlined, FileOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Image, Row } from "antd";
import { ArgsProps } from "antd/lib/notification";
import { useEffect, useState } from "react";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { addFilter, applyFilters } from "../../services/HooksManager";
import { closeModal, toast, warningModal } from '../../services/NotificationManager';
import { navigate } from "../../shared/router";
import DWDataProviderImp from "../../bulgari/providers/implementation/DWDataProviderImp";
import { __ } from "../../translations/i18n";
import DateUtils from "../../utils/DateUtils";

interface Prop {
    entity: any
}

const WarrantyDetails = (props: Prop) => {

    const {entity} = props;

    const backofficeOperator = (entity.operations.filter ((o: { type: string; }) => o.type === "CREATION" && entity.activationSource === "BACKOFFICE"))[0]
    const moduleManager = DWEnvironmentManager.getModuleManager();
    const provider = DWEnvironmentManager.getDataProvider ();

    const [product, setProduct] = useState<any>([]);

    useEffect(() => {
        const productModule = moduleManager.getModule('masterdata');
        productModule.getEntityByField('upc', entity.item.upc).then((res: any) => {

            if (res) {
                setProduct(res)
            }
        });

        funcAddProductPage();
        funAddDeleteActivation();
        funcAddDownloadPdf();
        //funcRemoveFilter();
    }, [])

    const deleteItem = () => {

        if( entity.id ) {
            warningModal({
                title: __("warning.ask_delete"),
                content: __("warning.unreversible_delete"),
                onOk: args => {
                    const req = {
                        params: {
                            warrantyId:entity.id
                        }
                    };
                    DWDataProviderImp.deleteWarranties ( req, 'warranties/admin/delete' ).then (
                        ( res: any ) => {
                            closeModal();
                            if ((res === undefined) || (res === null)) {
                                successToast(__("success.confirm_delete"));
                                navigate('/warranties');
                            } else {
                                errorToast(__("error.generic_error"));
                            }
                        }
                    )
                }
            });
        }
    }

    const productPageButton = (component: any) => {
        return (
                    <Col span={8}>
                        <Button onClick={()=> navigate('/landing/' + entity.warrantyBadge.uid)} style={{width: '100%', fontSize: 20, height: '50px'}} type="primary" icon={<FileOutlined />}>{__("warranties.details.productPage")}</Button>
                    </Col>
                );
    }

    const funcAddProductPage = () => {
        addFilter('warranty_details_go_to_product_page', productPageButton);
    }

    // const funcRemoveProductPage = () => {
    //     removeFilter('warranty_details_go_to_product_page',productPageButton);
    // }

    const funAddDeleteActivation = () => {
        addFilter('warranty_details_button_delete_activation', deleteActivation);
    }

    const funcAddDownloadPdf = () => {
        addFilter('warranty_details_button_download_pdf', downloadPdfButton);
    }

    const downloadPdfButton = (component: any) => {
        return (
            <Col span={8}>
                <Button onClick={()=> downloadPdf()} style={{width: '100%', fontSize: 20, height: '50px'}} type="primary" icon={<FilePdfOutlined />}>{__("warranties.details.downloadPdf")}</Button>
            </Col>
        )
    }

    const downloadPdf = async () => {
        // return pdf
        const req = {
            params: Object.assign({}, {
                warranty: props.entity.id
            }),
            timeout: 200000,
            responseType: "arraybuffer"
        };
        await provider.export(req, "warranties/pdf");
    }

    const deleteActivation = (component: any) => {
        return (
                <Col span={8}>
                    <Button onClick={()=> deleteItem()} style={{width: '100%', fontSize: 20, height: '50px'}} type="primary" icon={<DeleteOutlined />}>{__("warranties.details.delete")}</Button>
                </Col>
            )
    }

    const successToast = (message: string) =>{
        const config: ArgsProps = {
            message: `${message}`,
            description:``,
            type: 'success',
            placement: 'bottomRight'
        }
        return toast(config);
    }

    const errorToast = (message: string) =>{
        const config: ArgsProps = {
            message: `${message}`,
            description:``,
            type: 'error',
            placement: 'bottomRight'
        }
        return toast(config);
    }

    const image = () => {
        return <Image fallback={DWEnvironmentManager.getNotFoundImg()} src={DWEnvironmentManager.getStoragePath() + 'assets/' + product?.attributes?.image_thumb?.value} />
    }

    const {item, createdBy, creationStore, returnedBy, returnStore, activationSource} = entity

    return (
        <div className="site-card-wrapper">
            <Row gutter={[24, 24]} justify="start">
                <Col span={11}>
                    <Card style={{textAlign: 'center'}} title={__("warranties.details.image")} bordered={false} >{image()}</Card>
                </Col>
                <Col span={11}>
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            <Card title={__("warranties.details.product")} bordered={false}>
                                <em>{product?.code} - {item?.serialNumber}</em><br/>
                                <strong>{product?.description}</strong>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Divider style={{fontSize: 20}}>{__("warranties.details.activation")}</Divider>
                        </Col>
                        <Col span={8}>
                            <Card title={__("warranties.details.date")} bordered={false}>
                                {DateUtils.formatDate(entity.creationDate)}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={__("warranties.details.store")} bordered={false}>
                                {creationStore?.description}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={__("warranties.details.user")} bordered={false} >
                                {createdBy?.name} {createdBy?.surname}
                                <div>
                                {backofficeOperator?.user?.name} {backofficeOperator?.user?.surname} {activationSource === "BACKOFFICE" && `(${activationSource})`}
                                </div>
                            </Card>
                        </Col>
                        {( entity.returnDate &&
                            <>
                                <Col span={24}>
                                    <Divider style={{fontSize: 20}}>{__("warranties.details.return")}</Divider>
                                </Col>
                                <Col span={8}>
                                    <Card title={__("warranties.details.date")} bordered={false}>
                                        {DateUtils.formatDate(entity.returnDate)}
                                    </Card>
                                </Col>
                                    <Col span={8}>
                                    <Card title={__("warranties.details.store")} bordered={false}>
                                {returnStore?.description}
                                    </Card>
                                    </Col>
                                    <Col span={8}>
                                    <Card title={__("warranties.details.user")} bordered={false}>
                                {returnedBy?.name} {returnedBy?.surname}
                                    </Card>
                                    </Col>
                            </>
                        )}
                        {applyFilters('warranty_details_button_download_pdf', <></>)}
                        {applyFilters('warranty_details_button_delete_activation', <></>)}
                        {applyFilters('warranty_details_go_to_product_page', <></>)}

                    </Row>
                </Col>
            </Row>
            {applyFilters('warranty_details_extra', <></>)}
        </div>

    )

}

export default WarrantyDetails;
