import i18n from "../translations/i18n";
import { DWEnvironmentManager, IService } from "../services/DWEnvironmentManager";
import { navigate } from "../shared/router";


export class LanguageManager implements IService {



    dwEnvironmentManager: DWEnvironmentManager | undefined = undefined;

    init(dwEnvironmentManager: DWEnvironmentManager): void {

        this.dwEnvironmentManager = dwEnvironmentManager

    }

    getServiceKey(): string {
        return 'languageManager';
    }

    /**
     *  Metodo che da la possibilità di scegliere la lingua dell'app 
     *  & refresh sulla loginPage
    */
    sendUpdate(dataForm: any) {
        const language = dataForm.language.split('_');
        i18n.changeLanguage(language[0]);
        return navigate('/loginPage')
    }
}




const languageManager = new LanguageManager();
export default languageManager;

