/* eslint-disable import/no-anonymous-default-export */
export default {
    app: {
        url: 'www.bulgari.com'
    },
    common: {
        close: 'Fechar'
    },
    menu: {
        digital_prewarranties: 'Pré-Garantias Digitais',
        areas: 'Áreas',
        profiles: 'Perfis de Autorização',
        manual: 'Manuais Bulgari',
        help: 'Ajuda',
        info: 'Informações',
        massive_activations: 'Ativações em Massa',
        partnerinventories: 'Inventário de Parceiros'
    },
    warranties: {
        title: 'Ativações Bulgari',
        columns: {
            photo: 'Foto',
            cardSequential: 'Cartão Sequencial',
            sap: 'Código SAP',
            description: 'Descrição',
            expiredDate: 'Data de Expiração',
            activationType: 'Tipo de Ativação',
            activationUser: 'Usuário/Loja de Ativação',
            storeUser: 'Loja/Usuário Retorno',
            substitution: 'Substituição',
            serialNumber: 'Número de Série',
            activation: 'Ativação',
            return: 'Devolução',
            returnUser: 'Usuário/Loja de Devolução',
            details: 'Detalhes',
            operatingCountry: 'País/Região de Operação',
            parent: 'Pai',
            isoCode: 'Código ISO',
            edit: 'Editar',
            returnDeactivate: 'Devolução/Desativação',
            status: 'Status',
            storeCode: 'Código da Loja'
        },
        fields: {
            sequentialCard: 'Cartão Sequencial',
            sapCode: 'Código SAP',
            serialNumber: 'Número de Série',
            reso: 'Devolução',
            storeType: 'Tipo de Loja',
            materialType: 'Tipo de Material',
            nameCodeStore: 'Nome/Código da Loja',
            operatingCountry: 'País/Região de Operação',
            activationType: 'Tipo de Ativação',
            onBehalfOf: 'Em Nome de',
            masterCode: 'Código Mestre',
            soldToParty: 'Vendido para',
            responsibilityArea: 'Área de Responsabilidade',
            isoCode: 'Código ISO',
            parent: 'Pai',
            activationDate: 'Data de Ativação',
            store: 'Loja',
            user: 'Usuário',
            add: 'Ativar novo produto',
            errors: {
                sapCode: 'Insira o Código SAP!',
                activationDate: 'Selecione uma data de ativação!',
                serialNumber: 'Insira um número de série!',
                store: 'Insira uma loja!',
                user: 'Insira um usuário!',
                sequentialCard: 'Insira um cartão sequencial!'
            },
            values: {
                all: 'Todos',
                reso: 'Devolução',
                noReso: 'Sem Devolução',
                accessories: 'Acessórios',
                watches: 'Relógios',
                jewels: 'Joias',
                fineJewelry: 'Alta Joalheria',
                manuale: 'Manual',
                appManual: 'Manual de Aplicativo',
                yes: 'Sim',
                no: 'Não',
                internal: 'Interno',
                external: 'Externo',
                prospect: 'Prospecto',
                open: 'Aberto',
                closed: 'Fechado',
                phaseOut: 'Fase de Encerramento',
                future: 'Futuro',
                notActivated: 'Não Ativado',
                pending: 'Pendente',
                activated: 'Ativado'
            },
        },
        add: {
            success: 'Ativação adicionada com sucesso'
        },
        changeExpiration: {
            success: 'Data de Expiração atualizada com sucesso',
            error: 'Falha na Atualização',
            errorDescription: 'A data de expiração não foi atualizada'

        },
        warning: 'Número de Série e Código SAP incompatíveis',
        error: {
            watchInvalidSerialTitle: 'Número de Série e Código SAP inválidos',
            watchInvalidSerialDescription: 'Número de Série e Código SAP não são compatíveis com o relógio selecionado.',
            warrantyAlreadyPresentTitle: 'Garantia já presente',
            warrantyAlreadyPresentDescription: 'A garantia já está presente para o produto selecionado'
        }
    },
    prewarranties: {
        fields: {
            activationDate: 'Data de Pré-Garantia'
        },
        add: {
            success: 'Pré-Garantia adicionada com sucesso'
        },
        details: {
            image: "Imagem",
            product: "Produto",
            date: "Data",
            store: "Loja",
            user: "Operador",
            delete: "Excluir Pré-Garantia",
            activation: "Ativação",
        },
        error: {
            prewarrantyAlreadyPresentTitle: 'Pré-Garantia já presente',
            prewarrantyAlreadyPresentDescription: 'A pré-garantia já está presente para o produto selecionado',
            checkSerialSAPErrorTitle: 'Número de Série e Código SAP inválidos',
            checkSerialSAPErrorDescription: 'O Número de Série e Código SAP não são compatíveis.'
        }
    },
    partnerinventories: {
        fields: {
            inventoryDateFrom: 'Data de Inventário de',
            inventoryDateTo: 'Data de Inventário Até',
            sapCode: 'Código SAP',
            serialNumbers: 'Números de Série',
            store: 'Loja',
            soldTo: 'Vendido para',
            creationDateFrom: 'Data de Criação de',
            creationDateTo: 'Data de Criação Até',
            lastInventory: 'Último Inventário'
        },
        columns: {
            inventoryDate: 'Data de Inventário',
            serialNumber: 'Número de Série',
            store: 'Loja',
            soldTo: 'Vendido para',
            product: 'Produto',
            creationDate: 'Data de Criação',
            storeCode: 'Código da Loja',
            quantity: 'Quantidade',
            sapCode: 'Código SAP'
        },
        button: {
            upload: 'Enviar Inventário',
            download: 'Baixar Modelo'
        }
    },
    hierarchies: {
        add: 'Adicionar Nova Área',
        success: 'Nova área adicionada com sucesso',
        entity: {
            singular_name: "Nova Área",
            plural_name: "Nova Área"
        },
        edit: {
            modify: 'Editar Área',
            success: 'Área editada com sucesso'

        }

    },
    settings: {
        choose_language: 'Escolher Idioma',
        language: 'Idioma',
    },
    stores: {
        fields: {
            code: "Código",
            telephone: "Telefone",
            state: "Estado",
            bvlAfss: "BVL AFSS",
            openingDate: "Data de Abertura",
            closingDate: "Data de Encerramento",
            phaseOutDate: "Data de Encerramento",
            phaseOut: "Fase de Encerramento",
            soldToParty: "Vendido para",
            responsabilityArea: "Área de Responsabilidade",
            masterCodeCode: "Código do Mestre",
            masterCodeName: "Nome do Mestre",
            soldTo: "Vendido Para",
            shipTo: "Entregue a",
            operatingCountry: "País/Região de Operação",
            areaDefaultEmail: "Email Padrão da Área",
            nfcReader: "Leitor NFC USB",
            smartphone: "Smartphone"
        },
        details: {
            activateStore: "Ativar Loja",
            sendAgain: "Enviar Novamente",
            activateSmartphone: "Ativar Smartphone",
            requestActivationSuccess: "Solicitação de Ativação bem-sucedida",
            activationSuccess: "Loja ativada com sucesso!",
            activate: "Ativar",
            askActivation: "Confirmar Ativação da Loja",
            askActivationDescription: "Tem certeza de que deseja ativar esta loja:"
        }
    },
    profiles: {
        columns: {
            profileName: 'Nome do Perfil',
            jobId: 'ID do Trabalho',
            departmentId: 'ID do Departamento',
            jobDescription: 'Descrição do Trabalho',
            departmentDescription: 'Descrição do Departamento',
            storeType: 'Tipo de Loja',
            storeId: 'Nome/Código da Loja',
            dwArea: 'Área da Bulgari',
            edit: 'Editar',
        },
        fields: {
            profileName: 'Nome do Perfil',
            jobId: 'ID do Trabalho',
            departmentId: 'ID do Departamento',
            jobDescription: 'Descrição do Trabalho',
            departmentDescription: 'Descrição do Departamento',
            storeType: 'Tipo de Loja',
            storeId: 'Nome/Código da Loja',
            dwArea: 'Área da Bulgari',
            add: 'Adicionar um Novo Perfil',
            edit: 'Editar um Perfil',
            delete: 'Excluir um Perfil',
            created: 'Loja criada'
        },
        upload: {
            label: "Você pode carregar seus arquivos XLS para convertê-los em perfis.",
            name: "Clique ou arraste o arquivo para esta área para carregá-lo",
            error: "Falha ao carregar o arquivo.",
            success: "O arquivo foi carregado com sucesso."
        }
    },
    manual: {
        columns: {
            photo: 'Foto',
            sapCode: 'Código SAP',
            instruction: 'Manual',
            brochure: 'Brochura',
        },
        fields: {
            sapCode: 'Código SAP'
        }
    },
    help: {
        ask_support: 'SOLICITAR SUPORTE',
        how_to_use: 'COMO USAR',
        how_to_use_video: 'Assista ao vídeo abaixo para obter um rápido guia sobre como usar as funções da Garantia Digital',
        sent: 'E-mail enviado',
        not_sent: 'E-mail não enviado',
        sales_activate_watches: 'COMO USAR UMA ETIQUETA DE VENDA PARA ATIVAR RELÓGIOS',
        shipping_activate_watches: 'COMO USAR UMA ETIQUETA DE REMESSA PARA ATIVAR JOIAS',
        sales_activate_accessories: 'COMO USAR UMA ETIQUETA DE VENDA PARA ATIVAR ACESSÓRIOS',
        active_watches_type: 'Para ativar os relógios, digite:',
        sap_code: 'CÓDIGO SAP',
        serial_number: 'NÚMERO DE SÉRIE'
    },
    splash: {
        welcome: "Bem-vindo ao",
        presentation: "GARANTIA DIGITAL & AUTENTICIDADE é um aplicativo projetado para gerenciar autenticidade e garantias de forma totalmente digital. Suba a bordo.",
        enter: 'Entrar'
    },
    info: {
        header: {
            watches: 'RELÓGIOS - INFORMAÇÕES DE GARANTIA',
            accessories: 'ACESSÓRIOS - INFORMAÇÕES DE AUTENTICIDADE',
            jewellery_pieces: 'JOIAS - INFORMAÇÕES SOBRE AUTENTICIDADE E GARANTIA INTERNACIONAL BULGARI',
            high_jewellery: 'ALTA JOALHERIA - INFORMAÇÕES DE AUTENTICIDADE'
        },
        body: {
            watches: 'Este relógio é uma criação da BVLGARI produzida pela Bulgari Horlogerie SA, Rue de Monruz 34, Neuchâtel, Suíça, em conformidade com os mais altos padrões de qualidade da indústria relojoeira suíça. A garantia ativada no momento da compra será honrada em todo o mundo e cobre todos os defeitos de fabricação. Esta garantia se aplica exclusivamente aos produtos adquiridos na rede oficial e autorizada da BVLGARI, lojas BVLGARI ou revendedores autorizados. Consulte as condições específicas de garantia do seu produto, acessando o código QR do cartão ou usando a função NFC do mesmo. Seu relógio BVLGARI está coberto por esta garantia apenas se o certificado de garantia internacional digital for devidamente ativado, preenchido e datado pelo vendedor no momento da compra. Durante o período de validade da garantia, em caso de defeitos de fabricação, seu relógio será reparado gratuitamente. Esta garantia não cobre o desgaste normal do relógio e seus acessórios, bem como os casos de perda, roubo, uso inadequado ou negligência. A garantia também não é válida em caso de número de série ilegível ou apagado, ou em caso de modificação ou tentativa de reparo do relógio por terceiros não autorizados. Esta garantia não cobre quaisquer danos indiretos ou consequentes decorrentes de falha, defeitos ou imprecisões do relógio. Importante: para qualquer manutenção ou reparo, você pode levar o seu relógio BVLGARI, juntamente com o certificado de garantia, a uma de nossas boutiques ou a um revendedor autorizado. Seu certificado de garantia internacional e autenticidade também permite acessar o manual do usuário e dicas de manutenção para o seu produto, bem como as condições de garantia e sua duração, digitalizando o código QR no cartão ou usando a função NFC. Esta garantia internacional não afeta quaisquer outros direitos do comprador sob a legislação aplicável.',
            accessories: 'Este produto é uma criação original da BVLGARI, fabricada e testada em conformidade com os mais rigorosos padrões de qualidade. Ativando o cartão, todos os defeitos de fabricação são cobertos por dois anos a partir da data da compra em todo o mundo. Este produto Bulgari contém uma etiqueta NFC (Near Field Communication) passiva. A etiqueta contém informações sobre o produto (ID exclusivo do chip, código do produto, assinatura digital de autenticidade) para garantir sua autenticidade. Essa etiqueta pode ser lida por um dispositivo NFC. Esta etiqueta não contém dados pessoais nem pode identificar o proprietário do produto. Para obter mais informações, entre em contato com privacy@bulgari.com.',
            jewellery_pieces: 'Esta joia é uma criação original da BVLGARI, fabricada e certificada em conformidade com os mais rigorosos padrões de qualidade. A joia é acompanhada por um certificado de autenticidade e garantia internacional, a ser ativado no momento da compra em uma boutique BVLGARI ou revendedor autorizado de joias BVLGARI. A garantia internacional cobre todos os defeitos de fabricação por um período de 24 meses a partir da data da compra e é válida em qualquer país do mundo, desde que seja devidamente ativada no momento da compra. O número de série gravado na joia deve ser registrado no certificado de garantia. Se o certificado não for ativado, esta garantia não se aplica. A garantia também não é válida se o número de série gravado na joia não estiver claramente legível, se estiver adulterado ou apagado. Durante o período de garantia, a joia pode ser reparada gratuitamente em caso de defeitos de fabricação. A garantia não cobre o desgaste normal da joia, nem defeitos ou danos decorrentes de uso inadequado, má manutenção, negligência ou acidentes. Não estão cobertos pela garantia casos de perda ou roubo. Esta garantia não se aplica em caso de modificações não autorizadas ou tentativas de reparo realizadas fora de uma boutique BVLGARI ou revendedor autorizado de joias BVLGARI. IMPORTANTE: Para solicitar um serviço, você deve apresentar a sua criação BVLGARI em uma boutique BVLGARI ou revendedor autorizado de joias BVLGARI, juntamente com o certificado de autenticidade e garantia. Você pode acessar as instruções BVLGARI para cuidados com joias e documentos relacionados à sua joia digitalizando o código QR no certificado de autenticidade e garantia internacional ou usando a tecnologia NFC. Esta garantia internacional não afeta o exercício de quaisquer outros direitos de que você possa dispor sob a legislação nacional vigente.',
            high_jewellery: 'Este produto é uma criação original da BVLGARI, fabricada e testada em conformidade com os padrões de qualidade mais rigorosos. Ativando o cartão, todos os defeitos de fabricação são cobertos por dois anos a partir da data da compra em todo o mundo.'
        }
    },
    return: {
        title: "Digite o número de série ou o código SAP apenas para ALTA JOALHERIA",
        return: "Devolução",
        fields: {
            serial: "Número de Série",
            next: "Próximo",
            required: "Número de Série obrigatório"
        },
        choiceProduct: "Escolha o Produto para Devolução",
        summaryProduct: "Resumo do Produto",
        codeSAP: "Código SAP",
        activationDate: "Data de Ativação",
        store: "Loja",
        expiryDate: "Data de Expiração",
        duration: "Duração",
        reasonReturn: "Escreva o motivo no formulário abaixo",
        errorTitle: "Devolução não disponível",
        errorSubTitle: "A devolução deste produto não está autorizada.",
        imageProduct: "Imagem do Produto",
        infoProduct: "Informações do Produto",
        infoActivation: "Informações de Ativação",
        warrantyExpired: "Garantia Expirada",
        successTitle: "Devolução com Sucesso !",
        successSubtitle: "A devolução do produto selecionado foi concluída com sucesso."
    },
    massive_activation: {
        label: "Você pode carregar seus arquivos XLS para convertê-los em ativações.",
        name: "Clique ou arraste o arquivo para esta área para carregá-lo",
        error: "Falha ao carregar o arquivo.",
        success: "O arquivo foi carregado com sucesso."
    },
    status: {
        title: "Digite o número de série ou o código SAP apenas para ALTA JOALHERIA",
        status: "Estado",
        fields: {
            serial: "Número de Série",
            next: "Próximo",
            required: "Número de Série obrigatório",
            email: "E-mail",
            emailDescription: "vários e-mails separados por ',' (pressione Enter para confirmar)"
        },
        choiceProduct: "Escolha o Produto",
        summaryProduct: "Resumo do Produto",
        codeSAP: "Código SAP",
        activationDate: "Data de Ativação",
        store: "Loja",
        user: "Usuário",
        expiryDate: "Data de Expiração",
        duration: "Duração",
        reasonReturn: "Escreva o motivo no formulário abaixo",
        errorTitle: "Status não disponível",
        errorSubTitle: "O status deste produto não está autorizado.",
        imageProduct: "Imagem do Produto",
        infoProduct: "Informações do Produto",
        infoActivation: "Informações de Ativação",
        warrantyExpired: "Garantia Expirada",
        successTitle: "Status obtido com Sucesso !",
        successSubtitle: "O status do produto selecionado foi obtido com sucesso.",
        manualProduct: "Imprimir Manual do Produto",
        versionPdf: "Versão PDF",
        returnDate: "Data de Devolução",
        versionPdfTitle: "Resumo do Produto",
        versionPdfSubTitle: "Baixe o PDF no seu dispositivo ou envie-o para o cliente que acabou de ativar o produto",
        sendEmail: "Enviar por E-mail",
        downloadPdf: "Baixar PDF",
        sendEmailSuccess: "E-mail Enviado com Sucesso!",
        manualProductTitle: "Manual do Produto",
        manualProductSubTitle: "Baixe o PDF no seu dispositivo",
        instructions: "Instruções",
        care: "Cuidados",
        infoExpiryDate: "Informações sobre a Data de Expiração",
        changeExpiryDate: "Alterar Data de Expiração",
        confirmChangeExpiryDate: "Confirmar",
        serialNumberError: "Número de Série Incorreto!",
        serialNumberErrorMsg: "O número de série não corresponde ao produto selecionado. Verifique o número de série inserido."
    },
    users: {
        fields: {
            managedStores: 'Lojas Gerenciadas',
            managedStoreTypes: 'Tipos de Lojas Gerenciadas'
        },
        columns: {
            storeCode: 'Código da Loja',
            role: 'Função'
        }
    },
    landing: {
        sapCode: 'Código SAP',
        expiration_date: 'Data de Expiração',
        warranty_duration: 'Duração da Garantia',
        download_manual: 'Baixar Manual',
        download_brochure: 'Baixar Brochura',
        extend_warranty: 'Estender Garantia',
        sp_pt_disclaimer: 'Esta garantia internacional não afeta quaisquer outros direitos que você possa ter sob qualquer legislação nacional aplicável.'
    }
}