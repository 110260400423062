import i18n, { TOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en'
import it from './it'
import fr from './fr'
import de from './de'
import es from './es'
import pt from './pt'
import ru from './ru'
import ko from './ko'
import zht from './zht'
import zhs from './zhs'
import ja from './ja'
import ar from './ar'


const resources = {
  en: { translation: en },
  it: { translation: it },
  fr: { translation: fr },
  de: { translation: de },
  es: { translation: es },
  pt: { translation: pt },
  ru: { translation: ru },
  ko: { translation: ko },
  zht: { translation: zht }, 
  zhs: { translation: zhs }, 
  ja: { translation: ja }, 
  ar: { translation: ar }, 
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

////////////////////////////////////////////////////
// HACK: use languages auto-completion
////////////////////////////////////////////////////
interface TranslationCategory {
  [key: string]: string
}

let TRANSLATION_MAP: any = en
// console.log(en);

// replace object values with keys
// Object.keys(en).forEach((catKey, idx) => {
//   const subCat: TranslationCategory = {}
//   // console.log(catKey);
//   Object.keys(TRANSLATION_MAP[catKey]).forEach((key) => {
//     // console.log(key);
//     subCat[key] = `${catKey}.${key}`
//   })

//   if (idx === 0) {
//     TRANSLATION_MAP = {} as typeof en
//   }

//   TRANSLATION_MAP[catKey] = subCat
// })
export { TRANSLATION_MAP as T }
////////////////////////////////////////////////////

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __ = (key: string, interpolationMap?: TOptions) => i18n.t(key, interpolationMap)

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __UP = (key: string, interpolationMap?: TOptions) => i18n.t(key, interpolationMap).toUpperCase()

export const changeLanguage = (locale: string) => {
  i18n.changeLanguage(locale)
}


export default i18n
