import PageViewer from "../../components/PageViewer";
import { addFilter } from "../../services/HooksManager";
import { IModule } from "../../services/ModulesManager";
import LandingPage from "../components/landing/LandingPage";

class LandingModule implements IModule {

    init = () => {

        addFilter('app_routes', (routes: any) => {
            routes['/landing/:id'] = {
                component: PageViewer,
                props: {content: <LandingPage />},
                visibility: 'both'
            };
            return routes;
        }, 50);
    }

    getModuleKey = (): string => {
        return "landing";
    }

}

const landingModule = new LandingModule();
export default landingModule;
