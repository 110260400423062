import ParentModule from "../../shared/module/CrudModule";
import { addFilter } from "../../services/HooksManager";
import { __b } from "../translations/i18n";
import { User } from "../../models/User";
import dWUserManager from '../../services/DWUserManager';
import i18n from "../../translations/i18n";
import { navigate } from "../../shared/router";

class SettingModule extends ParentModule {

    init = () => {
        addFilter ( 'setting_fields', this.addSettingFields, 50 );
        addFilter ( 'before_user_settings_update', this.updateSettingField, 50 );
    }

    getModuleKey = (): string => {
        return "bu-settings";
    }

    addSettingFields = ( fields: any[] ) => {
        const userData = dWUserManager.getSessionUser ();

        fields[ 0 ][ 'fields' ].push ( {
                label: __b ( 'settings.language' ),
                name: 'language',
                type: 'select',
                required: false,
                defaultValue: userData?.attributes[ 'DEFAULT_LANGUAGE' ],
                selectValue: [
                    { label: "Italiano", value: "it_IT" },
                    { label: "English", value: "en_EN" },
                    { label: "Español", value: "es_ES" },
                    { label: "Français", value: "fr_FR" },
                    { label: "Deutsch", value: "de_DE" },
                    { label: "Português", value: "pt_PT" },
                    { label: "Pусский (Russian)", value: "ru_RU" },
                    { label: "한국어 (Korean)", value: "ko_KO" },
                    { label: "简体中文 (Chinese Simplified)", value: "zhs" },
                    { label: "繁體中文 (Chinese Traditional)", value: "zht" },
                    { label: "日本語 (Japanese)", value: "ja_JA" },
                    { label: "العَرَبِيَّة (Arabic)", value: "ar_AR" },
                ],
                colConf: {
                    span: 24
                }
            }
        );

        return fields;
    }

    updateSettingField = ( updatedUser: User, userData: User, dataForm: any ) => {

        // set the new email
        updatedUser!.attributes[ 'DEFAULT_LANGUAGE' ] = dataForm.language;

        const language = dataForm.language.split('_');
        i18n.changeLanguage(language[0]);
        navigate('/dashboard');

        return updatedUser;
    }

}


const settingModule = new SettingModule ();
export default settingModule;
