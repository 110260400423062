import { ArrowLeftOutlined } from "@ant-design/icons"
import { Button, Card, Col, Divider, Image, Row } from "antd"
import TextArea from "antd/lib/input/TextArea"
import Paragraph from "antd/lib/typography/Paragraph"
import Text from "antd/lib/typography/Text"
import Title from "antd/lib/typography/Title"
import { useEffect, useState } from "react"
import DWEnvironmentManager from "../../../services/DWEnvironmentManager"
import { __ } from "../../../translations/i18n"
import DateUtils from "../../../utils/DateUtils"
import { __b } from "../../translations/i18n"

interface Props {
    product: any[],
    goBack: () => any,
    returnProduct: (data: any) => any
}

const SecondStep = (props: Props) => {

    const [product, setProduct] = useState<any>();
    const [creationDate, setCreationDate] = useState<any>();
    const [expiryDate, setExpiryDate] = useState<any>();
    const [isExpired, setIsExpired] = useState<boolean>();

    useEffect(()=> {
        if (props.product.length === 1) {
            setProduct(props.product[0]);
        }
    }, []);

    useEffect(() => {

        if (product) {
            setCreationDate(DateUtils.formatDateWithoutTime(product.value.creationDate));
            setExpiryDate(DateUtils.formatDateWithoutTime(product.value.expirationDate));
            if (new Date(product.value.expirationDate).getTime() < new Date().getTime()) {
                setIsExpired(true);
            }
        }

    }, [product])

    return (
            <>
                <Row gutter={[24, 24]}>
                {props.product?.length > 1 && !product && (
                        <Col span={24} style={{textAlign: "center", marginTop: 20}}>
                            <Title level={3}>{__b('return.choiceProduct')}</Title>
                        </Col>)}
                    {props.product?.length > 1 && !product && props.product.map((prod: any) =>
                        <Col span={8} style={{marginTop: 20}}>
                            <Card hoverable={true} onClick={() => setProduct(prod)} style={{minHeight: 670}}>
                                <Col span={24}>
                                    <Title level={4}>{prod.value?.item?.upc}</Title>

                                    <Title level={4}>{prod.detail?.attributes?.AestheticLine?.valueDescription}</Title>
                                </Col>
                                <Image fallback={DWEnvironmentManager.getNotFoundImg()}  preview={false} src={ `${DWEnvironmentManager.getStoragePath()}assets/images/imported/${prod.value?.item?.upc}.jpg`}></Image>
                                <Title level={5}>{__('common.description')}</Title>
                                <Paragraph>{prod.detail.attributes?.longDescription?.valueDescription}</Paragraph>
                            </Card>
                        </Col>
                    )}
                    {product &&
                        <>
                            <Col span={24} style={{textAlign: "center"}}>
                                <Title level={3}>{__b('return.summaryProduct')}</Title>
                                <Title level={4}>{product.detail?.attributes?.AestheticLine?.valueDescription}</Title>
                            </Col>
                            <Col span={24}>
                                <Card>
                                    <Row gutter={[24,24]}>
                                        <Col span={8}>
                                            <Divider style={{fontSize: 20}}>{__b('return.imageProduct')}</Divider>
                                            <Image fallback={DWEnvironmentManager.getNotFoundImg()}  src={ `${DWEnvironmentManager.getStoragePath()}assets/images/imported/${product.value?.item?.upc}.jpg`}  />
                                        </Col>
                                        <Col span={8}>
                                            <Divider style={{fontSize: 20}}>{__b('return.infoProduct')}</Divider>
                                            <Paragraph>
                                                <Text>{__b('return.codeSAP')}</Text>
                                                <Text strong> {product.detail.code}</Text>
                                            </Paragraph>
                                            <Paragraph>
                                                <Text>{__b('return.fields.serial')}</Text>
                                                <Text strong> {product.value.item.serialNumber}</Text>
                                            </Paragraph>
                                            <Paragraph>
                                                <Text strong> {product.detail?.attributes?.AestheticLine?.valueDescription}</Text>
                                            </Paragraph>
                                        </Col>
                                        <Col span={8}>
                                            <Divider style={{fontSize: 20}}>{__b('return.infoActivation')}</Divider>
                                            <Title level={5} type={"secondary"}>{__b('return.activationDate')}</Title>
                                            <Paragraph><Text delete={isExpired}>{creationDate}</Text></Paragraph>
                                            <Title level={5} type={"secondary"}>{__b('return.store')}</Title>
                                            <Paragraph><Text >{product.value.creationStore.description}</Text></Paragraph>
                                            <Title level={5} type={"secondary"}>{__b('return.expiryDate')}</Title>
                                            <Paragraph><Text>{expiryDate}</Text></Paragraph>
                                            <Title level={5} type={"secondary"}>{__b('return.duration')}</Title>
                                            <Paragraph><Text>{DateUtils.getDurationWarranty(product.value.creationDate, product.value.expirationDate)}</Text></Paragraph>
                                        </Col>
                                        {isExpired &&
                                            <>
                                                <Col span={24}>
                                                    <Title level={5} type={"secondary"}>{__b('return.reasonReturn')}</Title>
                                                    <TextArea rows={4}/>
                                                    <Divider style={{fontSize: 20, marginTop: 50}}><Title level={4} type={"danger"}>{__b('return.warrantyExpired')}</Title></Divider>
                                                </Col>
                                            </>}
                                    </Row>
                                </Card>
                            </Col>


                        </>
                    }
                </Row>
                <Row gutter={[24, 24]} justify={"center"} style={{marginTop: 70}}>
                    <Col span={8}>
                        <Button onClick={props.goBack} block={true} size={"large"} type={"default"} style={{marginBottom: 40}} icon={<ArrowLeftOutlined />}>{__('common.goBack')}</Button>
                    </Col>
                    {product &&
                        <>
                            <Col span={8}>
                                <Button onClick={() => props.returnProduct(product)} block={true} size={"large"} type={"primary"}>{__b('return.return')}</Button>
                            </Col>
                        </>}
                </Row>
            </>
        )
}
export default SecondStep;
