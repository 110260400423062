import {call, CallRequestConfig, download} from "../../shared/api";
import DWDataProvider from "../DWDataProvider";
import {applyFilters} from "../../services/HooksManager";
import {Method} from "axios";
import ObjectUtils from "../../utils/ObjectUtil";
import {DWEnvironmentManager} from "../../services/DWEnvironmentManager";
import { __ } from "../../translations/i18n";

class DWDataProviderImp implements DWDataProvider {

    dwEnvironmentManager: DWEnvironmentManager | undefined = undefined;

    endpoint = '';
    pathCount = '/count';
    pathOne = '/';
    pathAdmin = '/admin';
    pathDelete = this.pathAdmin + '/delete';

    getServiceKey(): string {
        return 'dataProvider'
    }

    init = async (dwEnvironmentManager: DWEnvironmentManager) => {
        this.dwEnvironmentManager = dwEnvironmentManager
    }

    private __getEndpoint(overrideModule?: string) {
        return overrideModule ?? this.endpoint;
    }

    private __getExportEndpoint(overrideModule?: string) {
        return overrideModule ?? this.endpoint + '/export';
    }

    applyPreRequestFilter = (call: string, method: Method, path: string, request: CallRequestConfig<any>): {
        method: Method,
        path: string,
        request: CallRequestConfig<any>
    } => {

        /**
         * Prepara la richiesta per il methdo ${call} del Data Provider
         * @hook data_pre_${call}
         * @param {method:Method, path: string, request: CallRequestConfig<any>} dati della richiesta
         */
        return applyFilters(`data_pre_${call}`, {
            method: method,
            path: path,
            request: request
        });
    }

    private static __initRequest(parameters: CallRequestConfig<any> | undefined, request: any) {
        return ObjectUtils.assignDeep(ObjectUtils.filterIfValueIsEmpty(parameters as any), ObjectUtils.filterIfValueIsEmpty(request));
    }

    async getList(limit?: number, offset?: number, parameters?: CallRequestConfig<any>, overrideModule?: string, mapRes: boolean = true): Promise<any> {
        let searchButton = document.querySelector("#"+ __ ( 'common.search' ) + "") as HTMLInputElement;
        let req = DWDataProviderImp.__initRequest(parameters, {
            params: {
                limit: limit,
                offset: offset
            }
        });

        const {method, path, request} = this.applyPreRequestFilter('get_list', 'GET', this.__getEndpoint(overrideModule), req);
        const res = await call<any[]>(method, path, request);
        setTimeout(() => {
            if (searchButton)
                searchButton.disabled = false;
        },2500)
        if (mapRes) {
            return this.__mapDataList(res, this.__getEndpoint(overrideModule));
        }
        return res;
    }

    async __mapDataList(res: any, endpoint: string) {
        return await applyFilters(endpoint + '_get_list_map', res);
    }

    async getCount(limit?: number, offset?: number, parameters?: CallRequestConfig<any>, overrideModule?: string): Promise<any> {
        let searchButton = document.querySelector("#"+ __ ( 'common.search' ) + "") as HTMLInputElement;
        if (searchButton)
            searchButton.disabled = true;
        let res = DWDataProviderImp.__initRequest(parameters, {
            params: {
                limit: limit,
                offset: offset
            }
        });
        const {method, path, request} = this.applyPreRequestFilter('get_count', 'GET', this.__getEndpoint(overrideModule) + this.pathCount, res);
        return await call<any[]>(method, path, request);
    }

    async getOne(id: number | string, parameters?: CallRequestConfig<any>, overrideModule?: string): Promise<any> {
        let res = DWDataProviderImp.__initRequest(parameters, {});
        const {method, path, request} = this.applyPreRequestFilter('get_one', 'GET', this.__getEndpoint(overrideModule) + this.pathOne + id, res);
        return await call<any[]>(method, path, request);
    }

    async export(parameters?: CallRequestConfig<any>, overrideModule?: string): Promise<any> {
        let res = DWDataProviderImp.__initRequest(parameters, {});
        const {path, request} = this.applyPreRequestFilter('get_list', 'GET', this.__getExportEndpoint(overrideModule), res);
        return await download<any[]>(path, request);
    }

    setModule(module: string) {
        this.endpoint = module;
    }

    async addOne(parameters?: CallRequestConfig<any>, overrideModule?: string){
        let res = DWDataProviderImp.__initRequest(parameters, {});
        const {method, path, request} = this.applyPreRequestFilter('add_one', 'POST', this.__getEndpoint(overrideModule), res);
        return await call<any>(method, path, request);
    }

    async editOne(parameters?: CallRequestConfig<any>, overrideModule?: string){
        let res = DWDataProviderImp.__initRequest(parameters, {});
        const {method, path, request} = this.applyPreRequestFilter('edit_one', 'PUT', this.__getEndpoint(overrideModule), res);
        return await call<any>(method, path, request);
    }

    async deleteOne( id: string, pkey = 'id', parameters?: CallRequestConfig<any>, overrideModule?: string ): Promise<any>{
        let body: {[key: string]: any} = {}
        body[pkey] = id
        let res = DWDataProviderImp.__initRequest(parameters, {
            params: body
        });
        const {method, path, request} = this.applyPreRequestFilter('delete_one', 'DELETE', this.__getEndpoint(overrideModule) + this.pathDelete, res);
        return await call<any[]>(method, path, request);
    }

}

export default new DWDataProviderImp();
