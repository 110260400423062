import {Button, Card, Col, Image, Row} from "antd";
import {useContext, useEffect} from "react";
import {__} from "../../translations/i18n";
import {applyFilters} from "../../services/HooksManager";
import { TabContext } from "../TabContext";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { EditOutlined } from "@ant-design/icons";

interface Prop {
    entity: any
}

const ProductDetails = (props: Prop) => {
    console.log (props)

    const {entity} = props;
    const key = useContext(TabContext);

    useEffect(() => {
    }, [])

    const image = () => {
        return  applyFilters('masterdata_image', <Image fallback={DWEnvironmentManager.getNotFoundImg()}  src={DWEnvironmentManager.getStoragePath() + 'assets/' + entity?.attributes?.image_thumb?.value } />, entity);
    }

    return (
        <div className="site-card-wrapper">
            <Row gutter={[24, 24]} justify="start">
                <Col span={11}>
                    <Card style={{textAlign: 'center'}} title={__("warranties.details.image")} bordered={false} >{image()}</Card>
                </Col>
                <Col span={11}>
                    <Row gutter={[16, 24]}>
                        <Col span={24}>
                            <Card title={__("warranties.details.product")} bordered={false}>
                                <span>{entity?.attributes?.SBUCode?.valueDescription} - {entity?.code}</span>
                                <br />
                                <strong>{entity?.description}</strong>
                                <br />
                                <small><b>{__('products.columns.nfcEmbedded')}: </b>{entity?.attributes?.NfcTag?.value == 'true' ? 'Si' : 'No'}</small>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Button style={{width: '100%', fontSize: 20, height: '50px'}} onClick={() => key.setActiveKey('edit')} type="primary" icon={<EditOutlined />}>{__("common.edit")}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>

    )

}

export default ProductDetails;
