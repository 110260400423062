/* eslint-disable import/no-anonymous-default-export */
export default {
    BACKEND_API: process.env.REACT_APP_BACKEND_URL ?? "http://localhost:8080/bulgaridw-web/",
    STORAGE_API: process.env.REACT_APP_STORAGE_URL ?? "http://localhost:8080/",
    MAIN_SITE: "https://www.bulgari.com",
    BASENAME: process.env.REACT_APP_BASENAME ?? "/dw-tmr/#!/",
    SSO_LOGIN: process.env.REACT_APP_SSO_LOGIN ?? 'false',
    TRACKER_REDIRECT: process.env.REACT_APP_TRACKER_REDIRECT ?? "https://tracker-qty.temera.it/t?bid=BGVT&uid=",
    EXTENDED_REDIRECT: process.env.REACT_APP_EXTENDED_REDIRECT ?? "https://test.bulgari.com/en-int/warranty",
    TRACKERV2_REDIRECT: process.env.REACT_APP_TRACKERV2_REDIRECT ?? "https://bulgari.urely.io/?v=1&uid="
}
