import PageViewer from "../../components/PageViewer";
import { addFilter } from "../../services/HooksManager";
import CrudModule from "../../shared/module/CrudModule";
import StatusComponent from "../components/status/StatusComponent";

class StatusModule extends CrudModule {
    
    init(): void {
        addFilter('app_routes', (routes: any) => {
            routes[this.getRoute()] = {component: PageViewer, props: {content: <StatusComponent module={this.getModuleKey()}/>}, visibility: 'private'};
            return routes;
        }, -100);
    }
    
    getModuleKey = (): string => {
        return "status";        
    }

}
const statusModule = new StatusModule();
export default statusModule;